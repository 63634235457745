
export const findSubStringEndAndStart = (str: string, subStr: string) => {
  const start = str.indexOf(subStr);
  const end = start + subStr.length;
  return { start, end };
};

export const replaceSubStringsWithComponents = (
  str: string,
  elements: { subStr: string; component: React.ReactNode }[],
) => {
  let result = str;
  const elementsWithStartsAndEnds = elements.map((element) => {
    return {
      ...element,
      ...findSubStringEndAndStart(result, element.subStr),
    };
  });
  elementsWithStartsAndEnds.sort((a, b) => {
    return a.start - b.start;
  });
  const arrayResult: (string | React.ReactNode)[] = [];

  elementsWithStartsAndEnds.forEach((element, index) => {
    const { start, end, component } = element;
    const firstPart = result.slice(0, start);
    const secondPart = result.slice(end);
    arrayResult.push(firstPart);
    arrayResult.push(component);
    result = secondPart;
    if (index === elementsWithStartsAndEnds.length - 1) {
      arrayResult.push(secondPart);
    }
  });
  return arrayResult;
};


export function replaceDoubleCurlyBraces(inputText) {
  // Define a regular expression pattern to match {{word}}
  let pattern = /{{(.*?)}}/g;

  // Use the replace method to replace the matched pattern with {word}
  let outputText = inputText.replace(pattern, '{$1}');

  return outputText;
}

export function interpolateValues(inputString, values) {
  return inputString.replace(/{(.*?)}/g, (match, p1) => values[p1] || match);
}

export function interpolateDoubleCurly(inputString, values) {
  return interpolateValues(replaceDoubleCurlyBraces(inputString), values);
}


