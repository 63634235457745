import { TCheckOutOrder } from "apiTypes";
import {
  NextCheckoutItemSource,
  nextCheckoutItemSourceManager,
} from "customHooks/useNextCheckoutItemSource";
import { getMoclid } from "../Steps/DonationAmount/utils";
import axiosInstance, {
  MolhamError,
  MolhamResponse,
  MolhamResult,
} from "../axiosInstance";

const createCheckoutOrder = async (data: {
  currency_code: string;
  payment_option_id?: number;
  quantity?: number | string;
  amount?: number | string;
  frequency_cycles?: number | null;
  payment_requests_ids?: number[];
  click_reference?: string;
  source?: NextCheckoutItemSource;
  metadata?: Record<string, any>;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  try {
    const moclid = getMoclid();
    let referencedData = data;
    if (moclid) referencedData = { ...data, click_reference: moclid };
    referencedData = {
      ...referencedData,
      source: nextCheckoutItemSourceManager.get(),
    };
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders`,
      referencedData
    );
    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};
const updateCheckoutOrder = async (data: {
  currency_code: string;
  id: number;
  payment_option_id?: number;
  quantity?: number | string;
  amount?: number | string;
  frequency_cycles?: number | null;
  click_reference?: string;
  metadata?: Record<string, any>;
  monthly_subscription_settings?: {
    billing_day: number;
    total_payments: number;
    automatic_billing: boolean;
  };
}): Promise<MolhamResult<TCheckOutOrder>> => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...referencedData, click_reference: moclid };
  try {
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders/${referencedData.id}`,
      referencedData
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};

const setTeamSupport = async (data: {
  currency_code: string;
  id: number;
  amount: number | string;
  click_reference?: string;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...data, click_reference: moclid };
  try {
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders/${referencedData.id}/team-support`,
      referencedData
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};
const sendDonationMotivation = async (data: {
  message: string;
  id: number | string;
  click_reference?: string;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  console.log("dgjasjkajgshkjsajkh");
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...data, click_reference: moclid };
  try {
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders/${referencedData.id}/donation-motivation`,
      referencedData
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};
const setProviderFee = async (data: {
  currency_code: string;
  id: number;
  payment_provider_fees_checkout: boolean;
  click_reference?: string;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...data, click_reference: moclid };
  try {
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders/${referencedData.id}/payment-provider-fees`,
      referencedData
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};
const setGuest = async (data: {
  id: number;
  click_reference?: string;
  name: string;
  email: string;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...data, click_reference: moclid };
  try {
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders/${referencedData.id}/guest-info`,
      referencedData
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};
const setSubscriptionSettings = async (data: {
  currency_code: string;
  id: number;
  click_reference?: string;
  billing_method: "automatic" | "manual";
  billing_day: number;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...data, click_reference: moclid };
  try {
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders/${referencedData.id}/monthly-billing-settings`,
      referencedData
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};
const cancleCheckoutOrder = async (data: {
  id: number;
}): Promise<MolhamResult<unknown>> => {
  try {
    const res = await axiosInstance.delete<unknown>(
      `/auth/checkout-orders/${data.id}`
    );

    return res as MolhamResponse<unknown>;
  } catch (err) {
    return err as MolhamError;
  }
};
const getCheckoutOrder = async (data: {
  idOrReference: number | string;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  try {
    const res = await axiosInstance.get<TCheckOutOrder>(
      `/checkout-orders/${data.idOrReference}`
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};

const setShippingAddress = async (data: {
  shipping_address_id: number | string;
  checkout_order_id: number | string;
  click_reference?: string;
}): Promise<MolhamResult<TCheckOutOrder>> => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...data, click_reference: moclid };
  try {
    const res = await axiosInstance.post<TCheckOutOrder>(
      `/checkout-orders/${data.checkout_order_id}/shipping-address`,
      referencedData
    );

    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};

const checkoutActions = {
  createCheckoutOrder,
  cancleCheckoutOrder,
  updateCheckoutOrder,
  setTeamSupport,
  setProviderFee,
  setSubscriptionSettings,
  getCheckoutOrder,
  setShippingAddress,
  sendDonationMotivation,
  setGuest,
};
export default checkoutActions;
