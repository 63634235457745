import { useAuthContext } from "context/AuthContext";
import { useRouter } from "next/router";
import useRTL from "../../customHooks/useRTL";

type Props = {
  Notif: any;
  Custom?: any;
};

const NavBackBtn = ({ Notif, Custom }: Props) => {
  const router = useRouter();
  const { donor } = useAuthContext();
  const onBackBtnClick = () => {
    router.back();
  };
  const isRTL = useRTL();
  if (Custom) return Custom;
  if (router.asPath === "/" && donor) return <Notif />;
  if (router.asPath === "/" && !donor)
    return (
      <span
        style={{ minWidth: 44, justifyContent: "flex-start" }}
        className={`navbar-tool-icon-box cursor d-flex align-items-center d-lg-none`}
      >
        {/* <i className={`fe-log-in fs-xxl`}></i> */}
      </span>
    );
  return (
    <>
      <button
        type="button"
        style={{ minWidth: 44, justifyContent: "flex-start" }}
        className={`navbar-tool-icon-box btn p-0 text-gray cursor d-flex align-items-center d-lg-none`}
        onClick={onBackBtnClick}
      >
        <i className={`ai-arrow-${isRTL ? "right" : "left"} fs-xxl`}></i>
      </button>
    </>
  );
};

export default NavBackBtn;
