
const rtlChars = [
  "\u0600-\u06FF",
  "\u0750-\u077F",
  "\uFB50-\uFDFF",
  "\uFE70-\uFEFF",
  /* hebrew range*/
  "\u05D0-\u05FF",
].join("");

const reRTL = new RegExp("[" + rtlChars + "]", "g");

export const isRTL = (text: string) => {
  var textCount = text?.replace(/[0-9\s\\\/.,\-+="']/g, "").length;
  var rtlCount = (text?.match(reRTL) || []).length;
  return rtlCount >= textCount - rtlCount && textCount > 0;
};
