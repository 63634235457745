import { TCheckOutOrder, TPaymentOption } from "apiTypes";
import { TQuickDonationPaymentOption } from "customHooks/useUtilities";
import React from "react";
import { StateCreator, create as _create } from "zustand";
import { immer } from "zustand/middleware/immer";
import CalculatedZakatStep from "../Steps/CalculatedZakat/CalculatedZakatStep";
import CheckoutEndStep from "../Steps/CheckoutEnd/CheckoutEndStep";
import ConfirmationStep from "../Steps/Confirmation/ConfirmationStep";
import DonationAmountStep from "../Steps/DonationAmount/DonationAmountStep";
import GeneralDonationStep from "../Steps/GeneralDonation/GeneralDonationStep";
import MonthlySubscriptionStep from "../Steps/MonthlySubscription/MonthlySubscriptionStep";
import PaymentMethodStep from "../Steps/PaymentMethod/PaymentMethodStep";
import PaymentOptionStep from "../Steps/PaymentOption/PaymentOptionStep";
import NewBraintreeCardStep from "../Steps/PaymentSteps/Braintree/NewBraintreeCardStep";
import SavedBraintreeCardStep from "../Steps/PaymentSteps/Braintree/SavedBraintreeCardStep";
import NewCardPaymentStep from "../Steps/PaymentSteps/CardPayment/NewCardPaymentStep";
import SavedCardPaymentStep from "../Steps/PaymentSteps/CardPayment/SavedCardPaymentStep";
import NewGiropayPaymentStep from "../Steps/PaymentSteps/GiropayPayment/NewGiropayPaymentStep";
import NewIdealPaymentStep from "../Steps/PaymentSteps/IdealPayment/NewIdealPaymentStep";
import SavedIdealPaymentStep from "../Steps/PaymentSteps/IdealPayment/SavedIdealPaymentStep";
import NewSepaPaymentStep from "../Steps/PaymentSteps/SepaPayment/NewSepaPaymentStep";
import SavedSepaPaymentStep from "../Steps/PaymentSteps/SepaPayment/SavedSepaPaymentStep";
import NewSofortPaymentStep from "../Steps/PaymentSteps/SofortPayment/NewSofortPaymentStep";
import SavedSofortPaymentStep from "../Steps/PaymentSteps/SofortPayment/SavedSofortPaymentStep";
import SwishPaymentStep from "../Steps/PaymentSteps/SwishPayment/SwishPaymentStep";
import { TCheckoutHandlerResult } from "../Steps/PaymentSteps/common";
import ProviderFeeStep from "../Steps/ProviderFee/ProviderFeeStep";
import NewShippingAddressStep from "../Steps/ShippingAddress/NewShippingAddressStep";
import SavedShippingAddressStep from "../Steps/ShippingAddress/SavedShippingAddressStep";
import TeamSupportStep from "../Steps/TeamSupport/TeamSupportStep";
import ZakatInputStep from "../Steps/ZakatInput/ZakatInputStep";
const CheckoutSteps: {
  [key: string]: {
    name: string;
    title: React.FC;
    body: React.FC;
    footer?: boolean;
  };
} = {
  [PaymentOptionStep.name]: PaymentOptionStep,
  [DonationAmountStep.name]: DonationAmountStep,
  [ConfirmationStep.name]: ConfirmationStep,
  [TeamSupportStep.name]: TeamSupportStep,
  [ProviderFeeStep.name]: ProviderFeeStep,
  [MonthlySubscriptionStep.name]: MonthlySubscriptionStep,
  [PaymentMethodStep.name]: PaymentMethodStep,
  [SavedCardPaymentStep.name]: SavedCardPaymentStep,
  [NewCardPaymentStep.name]: NewCardPaymentStep,
  [NewIdealPaymentStep.name]: NewIdealPaymentStep,
  [SavedIdealPaymentStep.name]: SavedIdealPaymentStep,
  [SavedSepaPaymentStep.name]: SavedSepaPaymentStep,
  [NewSepaPaymentStep.name]: NewSepaPaymentStep,
  [NewSofortPaymentStep.name]: NewSofortPaymentStep,
  [SavedSofortPaymentStep.name]: SavedSofortPaymentStep,
  [NewGiropayPaymentStep.name]: NewGiropayPaymentStep,
  [CheckoutEndStep.name]: CheckoutEndStep,
  [SwishPaymentStep.name]: SwishPaymentStep,
  [GeneralDonationStep.name]: GeneralDonationStep,
  [CalculatedZakatStep.name]: CalculatedZakatStep,
  [ZakatInputStep.name]: ZakatInputStep,
  [NewBraintreeCardStep.name]: NewBraintreeCardStep,
  [SavedBraintreeCardStep.name]: SavedBraintreeCardStep,
  [SavedShippingAddressStep.name]: SavedShippingAddressStep,
  [NewShippingAddressStep.name]: NewShippingAddressStep,
};

const resetters: (() => void)[] = [];

const create = (<T extends unknown>(f: StateCreator<T> | undefined) => {
  if (f === undefined) return create;
  const store = _create(f);
  const initialState = store.getState();
  resetters.push(() => {
    store.setState(initialState, true);
  });
  return store;
}) as typeof _create;

export const resetCheckoutStore = () => {
  for (const resetter of resetters) {
    resetter();
  }
};
type CombinedSlices = PaymentOptionSlice &
  ModalSlice &
  CheckoutStepsSlice &
  DonationAmountSlice &
  CheckoutOrderSlice &
  TeamSupportSlice &
  ProviderFeeSlice &
  MonthlySubscriptionSlice &
  CheckoutEndSlice &
  PaymentMethodSlice &
  ZakatSlice &
  GeneralDonationSlice &
  ShippingAddressSlice;
type ImmerStateCreator<CurrentSlice> = StateCreator<
  CombinedSlices,
  [["zustand/immer", never]],
  [],
  CurrentSlice
>;
// payment option slice
type PaymentOptionSlice = {
  selectedPaymentOption: TPaymentOption | undefined;
  setSelectedPaymentOption: (option: TPaymentOption) => void;
  paymentOptions: TPaymentOption[];
  setPaymentOptions: (options: TPaymentOption[]) => void;
  paymentOptionData: {
    targetableType: string | undefined;
    targetableId: string | undefined;
    payment_options_label: string | undefined;

  };
  setPaymentOptionData: (data: {
    targetableType: string | undefined;
    targetableId: string | undefined;
    payment_options_label: string | undefined;
  }) => void;
};
const createPaymentOptionSlice: ImmerStateCreator<PaymentOptionSlice> = (
  set
) => ({
  selectedPaymentOption: undefined,
  setSelectedPaymentOption: (option) =>
    set((state) => {
      state.selectedPaymentOption = option;
    }),
  paymentOptions: [],
  setPaymentOptions: (options) =>
    set((state) => {
      state.paymentOptions = options;
    }),
  paymentOptionData: {
    targetableType: undefined,
    targetableId: undefined,
    payment_options_label: undefined,
  },
  setPaymentOptionData: ({
    targetableType,
    targetableId,
    payment_options_label,
  }) =>
    set((state) => {
      state.paymentOptionData = {
        targetableType,
        targetableId,
        payment_options_label,
      };
    }),
});
// Donation amount slice

type DonationAmountSlice = {
  donationAmountMetadata: Record<string, any>;
  setDonationAmountMetadata: (metadata: Record<string, any>) => void;
  paymentFrequency: number | undefined;
  setPaymentFrequency: (frequency: number) => void;
  donationAmountValue: string | undefined;
  setDonationAmountValue: (value: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};
const createDonationAmountSlice: ImmerStateCreator<DonationAmountSlice> = (
  set
) => ({
  donationAmountMetadata: {},
  setDonationAmountMetadata: (metadata) =>
    set((state) => {
      state.donationAmountMetadata = metadata;
    }),
  paymentFrequency: undefined,
  setPaymentFrequency: (frequency) =>
    set((state) => {
      state.paymentFrequency = frequency;
    }),
  donationAmountValue: undefined,
  setDonationAmountValue: (value) =>
    set((state) => {
      state.donationAmountValue = value;
    }),
  loading: false,
  setLoading: (loading) =>
    set((state) => {
      state.loading = loading;
    }),
});
// team support slice
type TeamSupportSlice = {
  teamSupport: string | undefined;
  setTeamSupport: (teamSupport: string) => void;
  teamSupportCustomAmount: boolean;
  setTeamSupportCustomAmount: (customAmount: boolean) => void;
};
const createTeamSupportSlice: ImmerStateCreator<TeamSupportSlice> = (set) => ({
  teamSupport: undefined,
  setTeamSupport: (teamSupport) =>
    set((state) => {
      state.teamSupport = teamSupport;
    }),
  teamSupportCustomAmount: false,
  setTeamSupportCustomAmount: (customAmount) =>
    set((state) => {
      state.teamSupportCustomAmount = customAmount;
    }),
});

type ProviderFeeSlice = {
  providerFee: boolean;
  setProviderFee: (teamSupport: boolean) => void;
};
const createProviderFeeSlice: ImmerStateCreator<ProviderFeeSlice> = (set) => ({
  providerFee: false,
  setProviderFee: (PF) =>
    set((state) => {
      state.providerFee = PF;
    }),
});
// monthly subscription slice
type MonthlySubscriptionSlice = {
  monthlySubscription:
    | { billing_day: number; billing_method: "automatic" | "manual" }
    | undefined;
  setMonthlySubscription: (monthlySubscription: {
    billing_day: number;
    billing_method: "automatic" | "manual";
  }) => void;
};
const createMonthlySubscriptionSlice: ImmerStateCreator<
  MonthlySubscriptionSlice
> = (set) => ({
  monthlySubscription: undefined,
  setMonthlySubscription: (monthlySubscription) =>
    set((state) => {
      state.monthlySubscription = monthlySubscription;
    }),
});
// checkout end slice
type CheckoutEndSlice = {
  checkoutEnd: TCheckoutHandlerResult | undefined;
  setCheckoutEnd: (order: TCheckoutHandlerResult) => void;
};
const createCheckoutEndSlice: ImmerStateCreator<CheckoutEndSlice> = (set) => ({
  checkoutEnd: undefined,
  setCheckoutEnd: (order) =>
    set((state) => {
      state.checkoutEnd = order;
    }),
});

//checkout order slice
type CheckoutOrderSlice = {
  checkoutOrder: TCheckOutOrder | undefined;
  setCheckoutOrder: (order: TCheckOutOrder) => void;
};
const createCheckoutOrderSlice: ImmerStateCreator<CheckoutOrderSlice> = (
  set
) => ({
  checkoutOrder: undefined,
  setCheckoutOrder: (order) =>
    set((state) => {
      state.checkoutOrder = order;
    }),
});
type GeneralDonationSlice = {
  generalDonationFrequency: string | undefined;
  setGeneralDonationFrequency: (generalDonationFrequency: string) => void;
  quickDonationPaymentOption: TQuickDonationPaymentOption | undefined;
  setQuickDonationPaymentOption: (
    quickDonationPaymentOption: TQuickDonationPaymentOption
  ) => void;
  generalDonationCustomAmount: boolean;
  setGeneralDonationCustomAmount: (customAmount: boolean) => void;
};
const createGeneralDonationSlice: ImmerStateCreator<GeneralDonationSlice> = (
  set
) => ({
  generalDonationFrequency: undefined,
  setGeneralDonationFrequency: (generalDonationFrequency) =>
    set((state) => {
      state.generalDonationFrequency = generalDonationFrequency;
    }),
  quickDonationPaymentOption: undefined,
  setQuickDonationPaymentOption: (quickDonationPaymentOption) =>
    set((state) => {
      state.quickDonationPaymentOption = quickDonationPaymentOption;
    }),
  generalDonationCustomAmount: false,
  setGeneralDonationCustomAmount: (customAmount) =>
    set((state) => {
      state.generalDonationCustomAmount = customAmount;
    }),
});
// modal slice
type ModalSlice = {
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
};
const createModalSlice: ImmerStateCreator<ModalSlice> = (set) => ({
  modalVisible: false,
  setModalVisible: (visible) =>
    set((state) => {
      state.modalVisible = visible;
    }),
});
// payment method slice
type PaymentMethodSlice = {
  selectedPaymentMethod: string;
  setSelectedPaymentMethod: (paymentMethod: string) => void;
};
const createPaymentMethodSlice: ImmerStateCreator<PaymentMethodSlice> = (
  set
) => ({
  selectedPaymentMethod: "",
  setSelectedPaymentMethod: (paymentMethod) =>
    set((state) => {
      state.selectedPaymentMethod = paymentMethod;
    }),
});
// payment method slice
type ZakatSlice = {
  zakatItems: any[];
  setZakatItems: (zakatItems: any[]) => void;
};
const createZakatSlice: ImmerStateCreator<ZakatSlice> = (set) => ({
  zakatItems: [],
  setZakatItems: (zakatItems) =>
    set((state) => {
      state.zakatItems = zakatItems;
    }),
});

// shipping address slice
type ShippingAddressSlice = {
  selectedAddress: number | string | undefined;
  setSelectedAddress: (address: number | string) => void;
};
const createShippingAddressSlice: ImmerStateCreator<ShippingAddressSlice> = (
  set
) => ({
  selectedAddress: undefined,
  setSelectedAddress: (address) =>
    set((state) => {
      state.selectedAddress = address;
    }),
});

// checkout steps slice
type CheckoutStepsSlice = {
  step: string | undefined;
  stepHistory: string[];
  addStepToHistory: (step: string) => void;
  removeStepFromHistory: (step: string) => void;
  replaceStepInHistory: (step: string) => void;
  popStepFromHistory: () => void;
};
const createCheckoutStepsSlice: ImmerStateCreator<CheckoutStepsSlice> = (
  set
) => ({
  step: undefined,
  stepHistory: [],
  addStepToHistory: (step) =>
    set((state) => {
      state.stepHistory.push(step);
    }),
  replaceStepInHistory: (step) =>
    set((state) => {
      state.stepHistory[state.stepHistory.length - 1] = step;
    }),
  removeStepFromHistory: (step) =>
    set((state) => {
      state.stepHistory = state.stepHistory.filter((s) => s !== step);
    }),
  popStepFromHistory: () =>
    set((state) => {
      state.stepHistory.pop();
    }),
});

const useCheckoutStore = create<CombinedSlices>()(
  immer((...a) => ({
    ...createCheckoutStepsSlice(...a),
    ...createModalSlice(...a),
    ...createPaymentOptionSlice(...a),
    ...createDonationAmountSlice(...a),
    ...createCheckoutOrderSlice(...a),
    ...createTeamSupportSlice(...a),
    ...createMonthlySubscriptionSlice(...a),
    ...createProviderFeeSlice(...a),
    ...createCheckoutEndSlice(...a),
    ...createGeneralDonationSlice(...a),
    ...createPaymentMethodSlice(...a),
    ...createZakatSlice(...a),
    ...createShippingAddressSlice(...a),
  }))
);

const useCheckoutCurrentStepName = () =>
  useCheckoutStore((state) => state.stepHistory[state.stepHistory.length - 1]);

const useCheckoutCurrentStep = () =>
  useCheckoutStore(
    (state) => CheckoutSteps[state.stepHistory[state.stepHistory.length - 1]]
  );

const useCheckoutCanGoBack = () =>
  useCheckoutStore(
    (state) =>
      state.stepHistory.length > 1 &&
      state.stepHistory[state.stepHistory.length - 1] !== CheckoutEndStep.name
  );
const useCheckoutShowHeader = () =>
  useCheckoutStore(
    (state) =>
      state.stepHistory[state.stepHistory.length - 1] !== CheckoutEndStep.name
  );

export {
  CheckoutSteps,
  useCheckoutCanGoBack,
  useCheckoutCurrentStep,
  useCheckoutCurrentStepName,
  useCheckoutShowHeader,
  useCheckoutStore,
};
