import { yupResolver } from "@hookform/resolvers/yup";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { getMixpanel } from "config/mixpanel";
import useTranslation from "next-translate/useTranslation";
import { Control, Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../CheckoutState";
import { MolhamResult } from "../../axiosInstance";
import CheckoutEndStep from "../CheckoutEnd/CheckoutEndStep";

export const nameEmailSchema = yup.object({
  name: yup.string(),
});

export type TNameEmailForm = yup.InferType<typeof nameEmailSchema>;

export const useNameEmailForm = () =>
  useForm<TNameEmailForm>({
    resolver: yupResolver(nameEmailSchema),
    defaultValues: {
      name: "",
    },
  });
export const NameEmailFormComponents = ({
  control,
  isLast,
}: {
  control: Control<TNameEmailForm>;
  isLast?: boolean;
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <label htmlFor="name">
        {t("name")} <span className="text-muted">({t("optional")})</span>
      </label>

      <Controller
        name="name"
        control={control}
        render={({
          field: { onBlur, onChange, value, ref },
          fieldState: { error },
        }) => {
          return (
            <>
              <input
                ref={ref}
                placeholder={t("enter_name")}
                style={{
                  textTransform: "uppercase",
                }}
                // dir={dir}
                onChange={(e) => {
                  const text = e.target.value;
                  if (text.length === 0 || /^[A-Za-z '.~`-]+$/.test(text)) {
                    onChange(text);
                  } else {
                    // onChange(value);
                  }
                }}
                value={value}
                onBlur={onBlur}
                className={`form-control rounded smol-input ${
                  isLast ? "" : "mb-3"
                }`}
                type="text"
              />
              {error?.message ? (
                <div style={{ color: "red" }}>{t(error?.message)}</div>
              ) : null}
            </>
          );
        }}
      />
    </>
  );
};

export const SubmitButton = ({
  onSubmit,
  text,
  loading,
}: {
  onSubmit: () => void;
  text?: string;
  loading?: boolean;
}) => {
  const { t } = useTranslation("common");
  return (
    <MolhamButton
      id={`donate-${text ? text : "complete"}-btn`}
      endIcon="fe-credit-card"
      label={text ? text : t(`complete_payment`)}
      className={`w-100`}
      onClick={onSubmit}
      disabled={loading}
      isLoading={loading}
    />
  );
};
export type TCheckoutHandlerResult = {
  message: { title: string; body: string };
  suggest_review: boolean;
  checkout_order: {
    id: number;
    amount: number;
    currency_code: string;
    status: "pending" | "paid" | "processing" | "canceled";
  };
  call_to_action_button: {
    label: string;
    path: {
      mobile: string;
      web: string;
    };
  };
};
export const handleCheckoutEnd = async ({
  res,
  metadata,
}: {
  res: MolhamResult<TCheckoutHandlerResult>;
  metadata?: {
    "Payment Method"?: string;
    "Checkout Order"?: string;
    Items?: any[];
    currency?: string;
    amount?: number;
    saved?: boolean;
    "Payment Provider ID"?: string;
  };
}) => {
  const { addStepToHistory, setCheckoutEnd } = useCheckoutStore.getState();
  let status = "success";
  if (res.ok === true) {
    if (res.data.message) {
      if (
        res.data.checkout_order.status === "paid" ||
        res.data.checkout_order.status === "processing"
      ) {
        setCheckoutEnd(res.data);
        addStepToHistory(CheckoutEndStep.name);
      } else {
        status = "error";
        alert(res.data.message.title + res.data.message.body);
      }
    }
  } else {
    status = "error";
    toast.error(res.error.message);
  }
  const mixpanel = getMixpanel();
  if (mixpanel && typeof metadata === "object") {
    mixpanel.track("Checkout Done", {
      ...metadata,
    });
  }
};

export const SepaMandate = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <hr className="mb-3" />
      <p
        className="text-muted"
        style={{
          fontSize: 12,
          marginBottom: 0,
        }}
      >
        {t("sepa_debit_mandate")}
      </p>
    </>
  );
};
