import { TPaymentMethodItem } from "apiTypes";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { NEXT_URL_PROXY } from "config";
import { useAuthContext } from "context/AuthContext";
import { useInfinitePagination } from "customHooks/useInfinitPagination";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../../CheckoutState";
import braintreeActions from "../../../actions/braintree";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import { PaymentMethodItem } from "../../PaymentMethod/components/PaymentMethodItem";
import { SubmitButton, handleCheckoutEnd } from "../common";
import NewBraintreeCardStep from "./NewBraintreeCardStep";
const SavedBraintreeCardStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_bank_card");
};
const SavedBraintreeCardStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const { donor } = useAuthContext();

  const {
    loading,
    setLoading,
    checkoutOrder,
    addStepToHistory,
    replaceStepInHistory,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    replaceStepInHistory: state.replaceStepInHistory,

    checkoutOrder: state.checkoutOrder,
    setLoading: state.setLoading,
    loading: state.loading,
  }));
  const { formattedData, isLoadingInitialData, getAllPages } =
    useInfinitePagination<TPaymentMethodItem>(
      `${NEXT_URL_PROXY}/auth/saved-payment-methods`
    );

  useEffect(() => {
    if (!isLoadingInitialData) {
      getAllPages();
    }
  }, [isLoadingInitialData]);
  const filteredData = formattedData.filter(
    (item) => item.type === "card" && item.methodable_type === "braintree_card"
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    number | string | null
  >(null);
  const handleNextSlide = () => {
    addStepToHistory(NewBraintreeCardStep.name);
  };
  const onSubmit = async () => {
    setLoading(true);
    try {
      if (selectedPaymentMethod === null) {
        return;
      }
      const transactionRes = await braintreeActions.createTransaction({
        checkout_order_reference: checkoutOrder.reference,
        saved_payment_method_id: selectedPaymentMethod,
      });
      if (transactionRes.ok === true) {
        const res = await checkoutHandlerActions.handleCheckoutSource({
          payment_provider_id:
            donor!.available_payment_methods.braintree_card.payment_provider.id,
          type: checkoutHandlerActions.CheckoutSourceTypes.braintree
            .transaction,
          reference: transactionRes.data.id,
        });
        handleCheckoutEnd({
          res,
          metadata: {
            "Checkout Order": checkoutOrder.reference,
            Items: checkoutOrder.items,
            currency: checkoutOrder.currency.code,
            amount: checkoutOrder.amount,
            "Payment Method": "Braintree Card",
            saved: true,
          },
        });
      } else {
        setLoading(false);
        toast.error(transactionRes.error?.message);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };
  useEffect(() => {
    if (!isLoadingInitialData && filteredData.length === 0) {
      replaceStepInHistory(NewBraintreeCardStep.name);
    }
  }, [isLoadingInitialData, filteredData]);

  if (!isLoadingInitialData && filteredData.length === 0) {
    return null;
  }
  return (
    <ModalBodyElementContainer className="d-flex flex-column">
      {filteredData.length > 0 ? (
        <>
          {filteredData.map((item) => {
            return (
              <>
                <PaymentMethodItem
                  key={item.id}
                  Icon={item.icon_url["apple_style"]}
                  isSelected={selectedPaymentMethod === item.id}
                  onPress={() => setSelectedPaymentMethod(item.id)}
                  label={item.name}
                  description={item.description}
                />
              </>
            );
          })}
          <PaymentMethodItem
            Icon={filteredData?.[0]?.icon_url?.["apple_style"]}
            isSelected={selectedPaymentMethod === "new"}
            onPress={() => setSelectedPaymentMethod("new")}
            label={t("new_payment_method")}
            isLast
          />
        </>
      ) : null}

      <DonationModalFooterPortal id="modal-footer-portal-host">
        {selectedPaymentMethod ? (
          <PaymentSlidesFooterContainer>
            {selectedPaymentMethod === "new" ? (
              <MolhamButton
                id={`donate-saved-braintree-next-btn`}
                endIcon="fe-arrow-right"
                label={t(`next`)}
                className={`w-100`}
                onClick={handleNextSlide}
                disabled={loading}
                isLoading={loading}
              />
            ) : (
              <SubmitButton loading={loading} onSubmit={onSubmit} />
            )}
          </PaymentSlidesFooterContainer>
        ) : null}
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const SavedBraintreeCardStep = {
  name: "SavedBraintreeCardStep",
  title: SavedBraintreeCardStepTitle,
  body: SavedBraintreeCardStepBody,
  footer: true,
};
export default SavedBraintreeCardStep;
