import CloseBtn from "components/ReuseableCompos/CloseBtn";
import useModal from "customHooks/useModal";
import { Modal } from "react-bootstrap";
import { StyledCheckoutModal } from "../DonateModalWrapper";

type Props = {
  children:
    | React.ReactNode
    | ((args: { open: () => void; close: () => void }) => React.ReactNode);

  openingComponent: React.ReactNode | ((onPress: () => void) => void);
  headerLabel?: string;
  headerComponent?: (closeModal: () => void) => React.ReactNode;
};

type IsolatedModal = React.FunctionComponent<Props>;

const IsolatedModal: IsolatedModal = ({
  openingComponent,
  children,
  headerLabel,
  headerComponent,
}) => {
  const modal = useModal();
  return (
    <>
      {typeof openingComponent === "function"
        ? openingComponent(modal.handleShowModal)
        : openingComponent}
      <StyledCheckoutModal
        show={modal.showModal}
        onHide={modal.handleCloseModal}
        fullscreen="md-down"
        centered
        scrollable
        className="z-2002"
      >
        <Modal.Header>
          {headerComponent ? (
            headerComponent(modal.handleCloseModal)
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <span className="modal-title h4">{headerLabel}</span>
              </div>
              <CloseBtn closeModal={modal.handleCloseModal} />
            </>
          )}
        </Modal.Header>
        <Modal.Body className="p-0 rounded-bottom">
          <div style={{ padding: 20, paddingBottom: 20 }}>
            {typeof children === "function"
              ? children({
                  open: modal.handleShowModal,
                  close: modal.handleCloseModal,
                })
              : children}
          </div>
        </Modal.Body>
      </StyledCheckoutModal>
    </>
  );
};
export default IsolatedModal;
