import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { NEXT_URL_PROXY } from "config";
import { useAuthContext } from "context/AuthContext";
import { useInfinitePagination } from "customHooks/useInfinitPagination";
import useTranslation from "next-translate/useTranslation";
import { useEffect } from "react";
import { useCheckoutStore } from "../../CheckoutState";
import DonationModalFooterPortal from "../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../components/PaymentSlidesFooterContainer";
import { PaymentMethodItem } from "../PaymentMethod/components/PaymentMethodItem";
// import { SubmitButton, handleCheckoutEnd } from "../common";
import { TAddress } from "apiTypes/addresses";
import checkoutActions from "../../actions/checkout";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
import NewShippingAddressStep from "./NewShippingAddressStep";
const SavedShippingAddressStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("shipping_address");
};
const SavedShippingAddressStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const {
    loading,
    setLoading,
    checkoutOrder,
    addStepToHistory,
    replaceStepInHistory,
    selectedAddress,
    setSelectedAddress,
    setCheckoutOrder,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    replaceStepInHistory: state.replaceStepInHistory,

    checkoutOrder: state.checkoutOrder,
    setLoading: state.setLoading,
    loading: state.loading,
    selectedAddress: state.selectedAddress,
    setSelectedAddress: state.setSelectedAddress,
    setCheckoutOrder: state.setCheckoutOrder,
  }));
  const { formattedData, isLoadingInitialData, getAllPages } =
    useInfinitePagination<TAddress>(
      `${NEXT_URL_PROXY}/auth/shipping-addresses/`
    );
  const { donor } = useAuthContext();
  useEffect(() => {
    if (
      checkoutOrder?.shipping_address_required === false ||
      (!isLoadingInitialData && formattedData.length === 0)
    ) {
      replaceStepInHistory(NewShippingAddressStep.name);
    }
  }, [checkoutOrder, isLoadingInitialData, formattedData]);
  useEffect(() => {
    if (!isLoadingInitialData) {
      getAllPages();
    }
  }, [isLoadingInitialData]);
  const handleNextSlide = async () => {
    if (selectedAddress === "new") {
      addStepToHistory(NewShippingAddressStep.name);
      return;
    }
    setLoading(true);
    try {
      if (selectedAddress === null) {
        return;
      }
      const res = await checkoutActions.setShippingAddress({
        checkout_order_id: checkoutOrder.id,
        shipping_address_id: selectedAddress,
      });
      if (res.ok === true) {
        setCheckoutOrder(res.data);
        addStepToHistory(ConfirmationStep.name);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };
  if (!isLoadingInitialData && formattedData.length === 0) {
    return null;
  }

  const IconComp = ({ icon }: { icon: string }) => {
    return (
      <span
        className={`bg-secondary btn-icon rounded-circle d-flex justify-content-center align-items-center profile-menu-item-avatar me-2`}
        style={{ width: 44, height: 44 }}
      >
        <i
          className={`fe fe-${icon} 
         fs-xxl
         text-primary`}
        ></i>
      </span>
    );
  };
  return (
    <ModalBodyElementContainer className="d-flex flex-column">
      {formattedData.length > 0 ? (
        <>
          {formattedData.map((item) => {
            return (
              <>
                <PaymentMethodItem
                  key={item.id}
                  isSelected={selectedAddress === item.id}
                  onPress={() => setSelectedAddress(item.id)}
                  label={item.recipient_name}
                  description={`${item.street_address}`}
                  subdescription={`${item.country.name} / ${item.city}`}
                  Icon={<IconComp icon={"map-pin"} />}
                />
              </>
            );
          })}
          <PaymentMethodItem
            isSelected={selectedAddress === "new"}
            onPress={() => setSelectedAddress("new")}
            label={t("add_shipping_address")}
            Icon={<IconComp icon={"plus"} />}
            isLast
          />
        </>
      ) : null}
      <DonationModalFooterPortal id="modal-footer-portal-host">
        {selectedAddress ? (
          <PaymentSlidesFooterContainer hideTotal>
            <MolhamButton
              id="donate-saved-ideal-next-btn"
              endIcon="fe-arrow-right"
              label={t(`next`)}
              className={`w-100`}
              onClick={handleNextSlide}
              disabled={loading}
              isLoading={loading}
            />
          </PaymentSlidesFooterContainer>
        ) : null}
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const SavedShippingAddressStep = {
  name: "SavedShippingAddressStep",
  title: SavedShippingAddressStepTitle,
  body: SavedShippingAddressStepBody,
  footer: true,
};
export default SavedShippingAddressStep;
