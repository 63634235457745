import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";

import Image from "next/legacy/image";
import Link from "next/link";
import { profileAvatar } from "../../config";
import { AuthContext } from "../../context/AuthContext";
import useRTL from "../../customHooks/useRTL";
import AvatarDropdown from "./NavBarDropdowns/AvatarDropdown";

const LoweNavbarEndSideSignedIn = () => {
  const { donor } = useContext(AuthContext);

  const { t } = useTranslation("common");
  const isRTL = useRTL();
  return (
    <div className="d-none d-lg-flex align-items-center order-3 ms-lg-auto mm-1c">
      <div className={`border-left mx-4`} style={{ height: 30 }}></div>
      <Link href={"/profile"} className="text-decoration-none">
        <div className="navbar-tool dropdown">
          <span
            style={{
              width: "2.75rem",
              height: "2.75rem",
              position: "relative",
            }}
            className="cursor"
          >
            <Image
              unoptimized
              className="rounded-circle"
              src={
                donor?.avatar ? donor?.avatar?.thumbnails["1:1"] : profileAvatar
              }
              alt="Avatar"
              layout="fill"
              blurDataURL={
                donor?.avatar ? donor?.avatar?.blurry["1:1"] : profileAvatar
              }
              placeholder="blur"
            />
          </span>
          <span className="navbar-tool-label text-capitalize dropdown-toggle ps-2 cursor">
            <small>
              {t(`hello`)}
              {isRTL ? "،" : ","}
            </small>{" "}
            {donor?.name ? donor?.name.split(" ")[0] : "Guest"}
          </span>
          {/* avatar list dropdown component */}
          <AvatarDropdown />
        </div>
      </Link>
    </div>
  );
};

export default LoweNavbarEndSideSignedIn;
