import styled from "styled-components";
type Props = {
  count: number;
  sticky?: boolean;
  style?: any;
};

const Badge = ({ count, sticky = true, ...props }: Props) => {
  return (
    <StyledBadge
      {...props}
      className={`${sticky ? "navbar-tool-badge" : ""} `}
      dir="auto"
      style={{
        padding: "0.12rem 0.5rem 0.02rem 0.5rem",
        borderRadius: 8,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "var(--white)",
        color: "white",
        fontSize: "0.6rem",
        lineHeight: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...props.style,
      }}
    >
      {count >= 100 ? "+99" : count}
    </StyledBadge>
  );
};

export default Badge;
const StyledBadge = styled.span`
  width: unset !important;
  height: unset !important;
  /* padding: 0 5px !important; */
  font-size: 0.7rem !important;
`;
