import styled from "@emotion/styled";
import useRTL from "customHooks/useRTL";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";

type Option = {
  label: string;
  value: any;
  description?: string;
};
type Props = {
  options: Option[];
  error?: string;
} & ComponentPropsWithoutRef<"select">;
function SelectInput({ options, ...props }: Props, ref: ForwardedRef<HTMLSelectElement>) {
  const isRTL = useRTL();

  return (
    <StylesSelectButton
      {...props}
      ref={ref}
      className={` form-control  form-select w-100 ${
        isRTL ? "text-end" : "text-start"
      } rounded-2 ${props?.className}`}
      onChange={props.onChange}
    >
      {options?.map((option) => {
        return (
          <option key={option.value?.toString()} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </StylesSelectButton>
  )
}

export default forwardRef(SelectInput);
const StylesSelectButton = styled.select`
  height: 50;
  font-size: 16;
  width: 100%;
  border-radius: 0;
  color: var(--dark-gray);
  &:hover {
    border-color: var(--primary2) !important;
    background-color: var(--white) !important;

  color: var(--dark-gray);
  }
`;
