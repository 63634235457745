import useMobile from "customHooks/useMobile";
import CustomContentLoader from "../ReuseableCompos/CustomContentLoader";

const StripeCardElementSkeleton = () => {
  const isMobile = useMobile("md");
  const yCard = "28%" 
  const y = "32%"
  return (
    <CustomContentLoader
      className="position-absolute top-0"
      viewBox={`0 0 ${isMobile ? "330" : "460"} 44`}
    >
      <rect x="18" y={yCard} rx="4" ry="4" width="24" height="16" />
      <rect x="46" y={y} rx="8" ry="8" width="45%" height="12" />
      <rect x="66%" y={y} rx="8" ry="8" width="15%" height="12" />
      <rect x="83%" y={y} rx="8" ry="8" width="10%" height="12" />
    </CustomContentLoader>
  );
};

export default StripeCardElementSkeleton;
