import { useStripe } from "@stripe/react-stripe-js";
import SelectModal from "components/ReuseableCompos/SharedDonatePages/DonateModal/components/Select/SelectModal";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import stripeActions from "../../../actions/stripe";
import Checkbox from "../../../components/Checkbox";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import {
  NameEmailFormComponents,
  SepaMandate,
  SubmitButton,
  TNameEmailForm,
  handleCheckoutEnd,
  useNameEmailForm,
} from "../common";

const SOFORT_COUNTRIES: { [key: string]: string } = {
  DE: "Germany",
  AT: "Austria",
  BE: "Belgium",
  NL: "Netherlands",
  ES: "Spain",
  IT: "Italy",
  CH: "Switzerland",
  PL: "Poland",
};
const NewSofortPaymentStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_sofort");
};
const NewSofortPaymentStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const [saveForLater, setSaveForLater] = useState(false);
  const { data: utilities } = useUtilities();

  const { control, handleSubmit } = useNameEmailForm();
  const [sofortCountry, setSofortCountry] = useState("DE");
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  //   const {data: donor} = useDonor();
  const donor = useCheckoutStore().checkoutOrder?.payer;

  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = async (data: TNameEmailForm) => {
    const paymentProvider =
      donor.available_payment_methods.stripe_sofort.payment_provider;
    setLoading(true);
    try {
      // const element = elements!.getElement(CardElement);
      const pm = await stripe.createPaymentMethod({
        type: "sofort",
        sofort: {
          country: sofortCountry,
        },
        billing_details: {
          name: data.name?.toUpperCase(),
          email: donor.email,
        },
      });
      if (pm.error) {
        toast.error(pm.error.message);
        setErrorMessage(pm.error.message);
        // setLoading(false);

        return;
      }
      const checkoutOrder = useCheckoutStore.getState().checkoutOrder;

      const paymentIntent = await stripeActions.paymentIntent({
        checkout_order_reference: checkoutOrder!.reference,
        save_for_later: saveForLater,
        // return_url: 'molhamteam://stripe-redirect',
        payment_method: pm.paymentMethod.id,
        payment_method_types: ["sofort"],
        confirmation_method: "automatic",
        payment_provider_id: paymentProvider.id,
      });
      if (paymentIntent.ok === false) {
        toast.error(paymentIntent.error.message);
        setErrorMessage(paymentIntent.error ? paymentIntent.error.message : "");
        setLoading(false);

        return;
      }
      const { error } = await stripe.confirmSofortPayment(
        paymentIntent.data.client_secret,
        {
          payment_method: pm.paymentMethod.id,
          return_url: `${
            location.protocol + "//" + location.host + location.pathname
          }?checkout_order_id=${checkoutOrder!.reference}`,
        }
      );
      if (error) {
        toast.error(error.message);
      } else {
        const res = await checkoutHandlerActions.handleCheckoutSource({
          payment_provider_id: paymentProvider.id,
          type: checkoutHandlerActions.CheckoutSourceTypes.stripe
            .payment_intent,
          reference: paymentIntent.data?.id,
        });
        handleCheckoutEnd({
          res,
          metadata: {
            "Checkout Order": checkoutOrder.reference,
            Items: checkoutOrder.items,
            currency: checkoutOrder.currency.code,
            amount: checkoutOrder.amount,
            "Payment Method": "Stripe Sofort",
            saved: false,
          },
        });
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <ModalBodyElementContainer>
      <div className="w-100 mb-3">
        <label className="mb-1">{t("country")}</label>
        <SelectModal
          title={t("country")}
          selectedOption={{
            label: SOFORT_COUNTRIES[sofortCountry],
            value: sofortCountry,
          }}
          onOptionChange={(val) => {
            setSofortCountry(val.value);
          }}
          buttonProps={{
            className: "smol-input",
          }}
          options={
            Object.entries(SOFORT_COUNTRIES).map(([key, value]) => ({
              label: value,
              value: key,
            })) as any
          }
        />
      </div>
      <NameEmailFormComponents control={control} />
      {donor?.type !== "guest" && (
        <Checkbox
          checked={saveForLater}
          style={{ marginTop: 20, marginBottom: 20 }}
          onClick={() => {
            setSaveForLater(!saveForLater);
          }}
        >
          <div>{t("save_payment_method_for_later")}</div>
        </Checkbox>
      )}{" "}
      {errorMessage ? (
        <div style={{ color: "red", marginTop: 10, marginBottom: 10 }}>
          {errorMessage}
        </div>
      ) : null}
      <SepaMandate />
      <DonationModalFooterPortal id="modal-footer-portal-host">
        <PaymentSlidesFooterContainer>
          <SubmitButton onSubmit={handleSubmit(onSubmit)} loading={loading} />
        </PaymentSlidesFooterContainer>
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const NewSofortPaymentStep = {
  name: "NewSofortPaymentStep",
  title: NewSofortPaymentStepTitle,
  body: NewSofortPaymentStepBody,
  footer: true,
};
export default NewSofortPaymentStep;
