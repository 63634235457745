import { createContext } from "react";
import useModal from "../customHooks/useModal";

type TFilterOffcanvasContext = {
  showFilterOffCanvas: boolean;
  handleCloseFilterOffCanvas: () => void;
  handleShowFilterOffCanvas: () => void;
};

const FilterOffcanvasContext = createContext<TFilterOffcanvasContext>(null);

const FilterOffcanvasProvider = ({ children }) => {
  const {
    showModal: showFilterOffCanvas,
    handleCloseModal: handleCloseFilterOffCanvas,
    handleShowModal: handleShowFilterOffCanvas,
  } = useModal();
  return (
    <FilterOffcanvasContext.Provider
      value={{
        showFilterOffCanvas,
        handleCloseFilterOffCanvas,
        handleShowFilterOffCanvas,
      }}
    >
      {children}
    </FilterOffcanvasContext.Provider>
  );
};
export { FilterOffcanvasContext, FilterOffcanvasProvider };
