const SvgComponent = (props:any) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 1000 1000"
  >
    <path
      d="M918 315.08 593.32 34.45c-53.22-45.93-133.32-45.93-186.55 0L82.14 315.08c-29.43 25.47-46.38 62-46.38 100.13v450.23c0 74.22 62.62 134.56 139.57 134.56h649.34c77 0 139.57-60.34 139.57-134.56V415.21A132.67 132.67 0 0 0 918 315.08ZM385.68 928.5h-132l-.09-171.8a91.68 91.68 0 0 1 163.64-56.91 105.16 105.16 0 0 0 9.31 141.32 114 114 0 0 0-40.86 87.39ZM270.8 533.34a74.21 74.21 0 1 1 21.69 52.79 74.39 74.39 0 0 1-21.69-52.79ZM442.69 928.5a57.4 57.4 0 0 1 114.8 0Zm8.61-163.19a48.87 48.87 0 0 1 48.83-48.75 48.59 48.59 0 0 1 48.75 48.75 48.89 48.89 0 0 1-48.75 48.84 49.19 49.19 0 0 1-48.83-48.84Zm122.44 75.8a105.23 105.23 0 0 0 32.26-75.8 103.29 103.29 0 0 0-4.83-31.44 90.2 90.2 0 0 1 53.67-17.31 91.76 91.76 0 0 1 91.69 91.7V928.5h-132a114.2 114.2 0 0 0-40.79-87.39ZM580.15 585a74.57 74.57 0 1 1 149.14 0 74.57 74.57 0 0 1-149.14 0Zm312.68 280.44c0 34.78-30.56 63.06-68.16 63.06h-21.16V808.26A148.49 148.49 0 0 0 739 685.73a131.54 131.54 0 1 0-168.37 0c-.27 0-.36.09-.53.36a105.41 105.41 0 0 0-69.92-26.62 103.06 103.06 0 0 0-36.71 6.68 146.28 146.28 0 0 0-34-32 131.53 131.53 0 1 0-168.37 0 148.6 148.6 0 0 0-64.61 122.55l.27 171.8h-21.43c-37.51 0-68.07-28.28-68.07-63.06V415.21a61.3 61.3 0 0 1 21.6-46.11L453.4 88.47A71.86 71.86 0 0 1 500 71.61c16.77 0 33.55 5.62 46.55 16.86L871.14 369.1a61.09 61.09 0 0 1 21.69 46.11Z"
      className="molham-celebration-icon"
    />
  </svg>
);
export { SvgComponent as ShelterSVG };
