import { Elements } from "@stripe/react-stripe-js";
import { useModeContext } from "context/ModeContext";
import { usePreferenceContext } from "context/PreferencesContext";
import { useStripeContext } from "context/StripeProvider";
import { PropsWithChildren, useEffect } from "react";
type Props = PropsWithChildren & {
  loadStripe?: boolean;
};
const StripeElementsWrapper: React.FC<Props> = ({ children, loadStripe }) => {
  const { stripePromise, setInitLoadStripe } = useStripeContext();
  useEffect(() => {
    if (loadStripe) {
      setInitLoadStripe(true);
    }
  }, [loadStripe]);
  const pref = usePreferenceContext();
  const mode = useModeContext();

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: pref?.lang?.shortcut as any,
        appearance: {
          variables: {
            fontFamily: "bahij",
            colorText:
              mode.theme === "light" ? " #737491" : "rgba(255,255,255,0.87)",
          },
        },

        fonts: [
          {
            cssSrc: "/fonts-f/bahij-fonts.css",
            family: "bahij",
          },
          {
            family: "bahij",
            src: `url("/fonts-f/bahij/Bahij_Black.ttf")`,
            display: "swap",
            weight: "900",
            style: "normal",
          },
          {
            family: "bahij",
            src: `url("/fonts-f/bahij/Bahij_Bold.ttf")`,
            display: "swap",
            weight: "700",
            style: "normal",
          },
          {
            family: "bahij",
            src: `url("/fonts-f/bahij/Bahij_Plain.ttf")`,
            display: "swap",
            weight: "500",
            style: "normal",
          },
        ],
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeElementsWrapper;
