import { useModeContext } from "context/ModeContext";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { NavHeaderContext } from "../../context/NavHeaderContext";
import useLastPath from "../../customHooks/useLastPath";
import useRTL from "../../customHooks/useRTL";
import RtlSvgLogo from "../ReuseableCompos/RtlSvgLogo";
import SvgLogo from "../ReuseableCompos/SvgLogo";
import LogoCreator from "./LogoCreator";
import SvgLogoSm from "components/ReuseableCompos/SvgLogoSm";

type Props = {
  scroll: boolean;
};

const Logo = ({ scroll }: Props) => {
  const isRTL = useRTL();
  const lastPath = useLastPath();
  const { t } = useTranslation("common");
  const { asPath } = useRouter();
  const { headerNavMeta } = useContext(NavHeaderContext);
  const { locale } = useRouter();
  const [headerTitle, setHeaderTitle] = useState("");

  useEffect(() => {
    setHeaderTitle(headerNavMeta?.title ? headerNavMeta?.title : t(lastPath));
  }, [headerNavMeta?.title, lastPath, locale, t]);
  const { theme } = useModeContext();
  const { data: utilities } = useUtilities();
  return (
    <Link
      href="/"
      passHref
      className="navbar-brand m-0 p-0"
      aria-label="Homepage"
    >
      <span className="d-lg-block d-none">
      <LogoCreator hwd={"45px"} hwm={"45px"} />

      </span>
      <div style={{ position: "relative" }}>
        <StyledFlipBox
          className={`d-lg-none d-block animate__animated ${
            scroll && asPath !== "/"
              ? "animate__fadeInUp"
              : "animate__fadeInDown"
          }`}
          // style={{ position: "relative" }}

          // style={{ transform: scroll ? "translateX(180)" : "translateX(0)" }}
        >
          {asPath === "/" ? (
            <span className="">
              {/* <SvgLogoSm /> */}
              <LogoCreator />
            </span>
          ) : !scroll ? (
            <span className="front animate__animated animate__fadeInDown">
              {/* <SvgLogoSm /> */}
              <LogoCreator />
            </span>
          ) : (
            <span className="back fs-lg animate__animated animate__fadeInUp">
              {headerTitle}
            </span>
          )}
        </StyledFlipBox>
      </div>
    </Link>
  );
};

export default memo(Logo);

const StyledFlipBox = styled.div`
  cursor: pointer;
  position: relative;
  animation-duration: 0.5s !important;
  span {
    animation-duration: 0.5s !important;
  }
  /* transform-style: preserve-3d; */
  /* transition: all 0.5s ease-in-out; */
  /* width: 50px;
  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;

    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back {
    transform: rotateX(180deg);
    color: var(--gray);
  } */
`;
