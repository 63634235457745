import { useColors } from "layout/Layout";

type Props = {
  text?: string;
  selected: boolean;

  onClick: () => void;
};
const LiteButton: React.FC<Props> = ({ text, onClick, selected }) => {
  const colors = useColors();
  return (
    <div
      onClick={onClick}
      style={{
        borderColor: selected ? colors.primary2 : "var(--light-gray)",
        color: selected ? colors.primary2 : "var(--dark-gray)",
        height: 46,
        whiteSpace: "normal",
        lineHeight: 1.2,
        fontWeight: 600,
        fontSize: 14,
        textAlign: "center",
      }}
      className="d-flex flex-row align-items-center justify-content-center  btn flex-1 border-1 text-bold rounded-2"
    >
      {text}
    </div>
  );
};

export default LiteButton;
