import { useContext, useState } from "react";

import dynamic from "next/dynamic";
import { FaApple } from "react-icons/fa";
import { AuthContext } from "../../../context/AuthContext";
import MolhamButton from "../MolhamButton";
import LoginLoading from "./LoginLoading";
const AppleSignIn = dynamic(() => import("react-apple-signin-auth"), {
  ssr: false,

  loading: LoginLoading,
});
type Props = {
  theme: string;
};
const LoginWithApple = ({ theme }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { login } = useContext(AuthContext);
  const responseApple = async (response) => {
    if (response?.status === "unknown") {
      return;
    }
    setIsLoading(true);
    await login({
      apple_authorization_code: response.authorization.code,
      apple_auth_full_name:
        response.user?.name?.firstName + " " + response.user?.name?.lastName,
      apple_redirect_uri: "https://molhamteam.com",
      redirectURI: "https://molhamteam.com",

      apple_client_id: "com.molhamteam.signin",
    });
    setIsLoading(false);
  };

  return (
    <AppleSignIn
      //@ts-ignore
      authOptions={{
        clientId: "com.molhamteam.signin",
        apple_redirect_uri: "https://molhamteam.com",
        redirectURI: "https://molhamteam.com",
        scope: "email name",
        state: "state",
        nonce: "nonce",
        usePopup: true,
      }}
      onSuccess={responseApple}
      render={(renderProps) => (
        <MolhamButton
          id="login-apple-btn"
          className={`btn 
        ${theme === "light" ? "bs-apple bg-apple" : "bs-apple-DM bg-apple-DM"}
         rounded-p`}
          onClick={renderProps.onClick}
          style={{ padding: 14, height: "fit-content" }}
          spinnerSize={"32px"}
          variant={null}
          isLoading={isLoading}
          CustomIconButton={<FaApple size={32} />}
        />
      )}
    />
  );
};

export default LoginWithApple;
{
  /* <a
className="btn btn-block bs-facebook mt-0 bg-facebook rounded-1 d-flex align-items-center"
href="#"
>
<i className="ai-facebook fs-xxl me-1"></i>{" "}
<div className="d-flex flex-column">
  <span className="fs-md">{t(`via_facebook`)} </span>
  <span className="fs-xs">{t(`signin_facebook`)}</span>
</div>
</a> */
}
