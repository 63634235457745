import MolhamModal from "components/MolhamModal";
import { useAuthContext } from "context/AuthContext";
import { InAppMsgModalContext } from "context/InAppMsgModalContext";
import useMobile from "customHooks/useMobile";
import useWindowSize from "customHooks/useWindowSize";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useContext, useEffect, useState } from "react";
import Confetti from "react-confetti";
import CustomContentLoader from "./CustomContentLoader";
import MolhamButton from "./MolhamButton";
const Lottie = dynamic(() => import("lottie-react"), {
  loading: () => <CircleLoader />,
});

const InAppMsgModal = () => {
  const { readMsg, showInAppMsgModal, remindMeLater } =
    useContext(InAppMsgModalContext);

  const [animationData, setAnimationData] = useState(null);

  const { t } = useTranslation("common");
  const matches = useMobile("lg");
  const { donor } = useAuthContext();
  const IAM = donor?.unread_inapp_messages?.[0];
  useEffect(() => {
    if (!IAM) return;
    fetch(IAM?.lottie_file?.uri).then((res) => {
      res.json().then((data) => {
        setAnimationData(data);
      });
    });
  }, [IAM?.lottie_file?.uri]);
  const { height, width } = useWindowSize();
  if (!IAM) return null;

  const onReadMsg = () => {
    readMsg(IAM?.id.toString());
  };
  return (
    <>
      {showInAppMsgModal && IAM?.has_confetti && (
        <Confetti
          width={width}
          height={height}
          style={{ zIndex: 9999 }}
          numberOfPieces={50}
        />
      )}
      <MolhamModal
        show={showInAppMsgModal}
        onHide={onReadMsg}
        header={{ label: " ", noBorder: true }}
        fullscreen
        body={{ className: "pt-0" }}
        swipeToClose={false}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          {animationData ? (
            <Lottie
              className="mb-3"
              animationData={animationData}
              loop={true}
              style={{ width: matches ? 300 : 400 }}
            />
          ) : (
            <CircleLoader />
          )}
          <p className="text-center mb-3 fs-xxl">{IAM?.subject}</p>
          <p className="text-center mb-3 fs-lg">{IAM?.body}</p>
          <MolhamButton
            id="callToAction-IAM"
            className="w-100"
            hrefClassName="w-100 mb-3"
            label={IAM?.call_to_action.label}
            href={IAM?.call_to_action.path}
            onClick={onReadMsg}
          />
          <div className="nav-link cursor" onClick={remindMeLater}>
            {t("remind_me_later")}
          </div>
        </div>
      </MolhamModal>
    </>
  );
};

export default InAppMsgModal;
const CircleLoader = () => (
  <CustomContentLoader viewBox={`0 0 300 300`} style={{ width: 300 }}>
    <circle cx="50%" cy="50%" r="136" />
  </CustomContentLoader>
);
