import useTranslation from "next-translate/useTranslation";
import { useModeContext } from "../../context/ModeContext";
import StyledNavbarDropdown from "./StyledNavbarDropdown";

const ThemeChangeBtn = ({ onClick, Icon, className, text, checked }) => {
  const { t } = useTranslation("common");
  const { theme } = useModeContext();
  return (
    <>
      <StyledNavbarDropdown
        className="d-md-flex align-items-center text-nowrap "
        onClick={onClick}
      >
        <button
          className={`btn ${
            className || ""
          } px-1 py-3 d-flex flex-column justify-content-center align-items-center text-wrap`}
          type="button"
          style={{ paddingTop: "12px" }}
        >
          <Icon
            size={40}
            className={`mb-2 ${theme == "dark" ? "text-light" : "text-dark"}`}
          />
          {/* <span>{t("theme_mode_color")}</span> */}
          <h6 className="fs-sm mb-2 fw-normal">{t(text)}</h6>

          <input
            type="radio"
            checked={checked ? true : false}
            className="form-check-input position-relative m-0"
          />
        </button>
      </StyledNavbarDropdown>
    </>
  );
};

export default ThemeChangeBtn;
