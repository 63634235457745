import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "../../../utils/isEmpty";
import MolhamButton from "../MolhamButton";
import MolhamInput from "../MolhamInput";

type Props = {
  handleSubmit: (onSubmit: any) => (e: any) => Promise<void>;
  onSubmit: any;
  isLoading: boolean;
  register: any;
  isDirty: boolean;
  error: any;
  emailErrors: any;
  control: any;
  watch: any;
};

const ForgotPasswordEmailStep = ({
  emailErrors,
  handleSubmit,
  onSubmit,
  isLoading,
  
  control,
  watch,
  isDirty,
  error,
}: Props) => {
  const { t } = useTranslation("common");
  const isMobile = useMobile("md");

  return (
    <form
      className={`w-100 needs-validation ${
        error || isEmpty(emailErrors) ? "invalid" : ""
      }`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-4 pb-1 d-flex flex-column align-items-center w-100">
        <div
          className="text-center p-4 bg-secondary mb-4"
          style={{ borderRadius: "50%" }}
        >
          <i
            className="ai-lock text-primary "
            style={{ fontSize: 80, padding: 12 }}
          ></i>
        </div>
        <p className="h3 mb-4 text-center">{t(`reset_password`)}</p>

        <p className={`${isMobile ? "fs-md" : "fs-lg"} mb-4 text-center`}>
          {t(`reset_password_description`)}
        </p>
      <MolhamInput
      control={control}
      watch={watch}
      name="email"
      placeholder={t(`email`)}
      type="email"

      />
      </div>

      <MolhamButton
        id="FP-send-code-btn"
        label={t(`send_reset_code`)}
        className={`w-100`}
        isLoading={isLoading}
        disabled={!isDirty}
      />
    </form>
  );
};

export default ForgotPasswordEmailStep;
