import { useEffect, useState } from "react";

const useMobile = (
  query: "md" | "lg" | string,
  custom?: boolean,
  readyState?: boolean
): boolean | null => {
  const [matches, setMatches] = useState<boolean | null>(
    readyState ? null : false
  );
  const parsedQuery = parseQuery(query, custom);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(parsedQuery);
    const updateMatches = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    //@ts-ignore
    updateMatches(mediaQueryList);
    const changeListener = (event: MediaQueryListEvent) => updateMatches(event);
    mediaQueryList.addEventListener("change", changeListener);

    return () => {
      mediaQueryList.removeEventListener("change", changeListener);
    };
  }, [parsedQuery]);

  return matches;
};

export default useMobile;
const parseQuery = (query: string, custom?: boolean) => {
  if (custom) {
    return query;
  } else if (query === "md") {
    return "(max-width: 768px)";
  } else if (query === "lg") {
    return "(max-width: 992px)";
  } else {
    return `(max-width: ${query}px)`;
  }
};
