import {
  HTMLMotionProps,
  MotionValue,
  animate,
  m,
  useMotionValue,
} from "framer-motion";

export const SwipeHandlerDiv = ({
  disabled,
  onSwipeDown,
  hght,
  YMotionValue,
  children,
  ...rest
}: HTMLMotionProps<"div"> & {
  disabled: boolean;
  hght: MotionValue<number>;
  onSwipeDown: () => void;
  YMotionValue: MotionValue<number>;
}) => {
  const prevY = useMotionValue(0);
  const props: HTMLMotionProps<"div"> = disabled
    ? {}
    : {
        onPanStart: () => {
          YMotionValue.set(prevY.get());
          hght.set(
            window.document.getElementById("offCanvasMdiv")?.clientHeight
          );
        },
        onPan: (_, pointInfo) => {
          const nextPoint = prevY.get() + pointInfo.offset.y;
          if (nextPoint >= 0) {
            YMotionValue.set(nextPoint);
          }
        },
        onPanEnd: (_, pointInfo) => {
          const nextPoint = prevY.get() + pointInfo.offset.y;
          if (nextPoint >= 0) {
            YMotionValue.set(nextPoint);
            prevY.set(nextPoint);
          }

          if (pointInfo.velocity.y > 1000) {
            onSwipeDown();
          }
          if (YMotionValue.get() <= 0.5 * hght.get()) {
            animate(YMotionValue, 0);
            prevY.set(0);
          } else if (YMotionValue.get() >= 0.5 * hght.get()) {
            onSwipeDown();
          }
        },
        style: {
          touchAction: "none",
          // y: YMotionValue,
        },
      };
  return (
    <m.div {...props} {...rest}>
      {children}
    </m.div>
  );
};
