export const currencyData = [
  { label: "$ Dollar (USD)", symbol: "$", shortcut: "usd" },
  { label: "€ Euro (EUR)", symbol: "€", shortcut: "eur" },
  { label: "₺ Turkish_Lira (TRY)", symbol: "₺", shortcut: "try" },
];

export const langs = [
  { label: "العربية", shortcut: "ar" },
  { label: "English", shortcut: "en" },
  { label: "Français", shortcut: "fr" },
  { label: "Deutsch", shortcut: "de" },
  { label: "Türkçe", shortcut: "tr" },
  { label: "Español", shortcut: "es" },
];
