import { Dispatch, SetStateAction, createContext, useState } from "react";
export type TNavHeaderProps = {
  title?: string;
  section?: string;
  customSearch?: {
    icon: string | JSX.Element;
    onClick: () => void;
    customNavBackBtn?: any;
  };
  setBar?: JSX.Element;
};
type TNavHeaderContext = {
  headerNavMeta: TNavHeaderProps;
  //has prev
  setHeaderNavMeta: Dispatch<SetStateAction<TNavHeaderProps>>;
};
const NavHeaderContext = createContext<TNavHeaderContext>(null);

const NavHeaderProvider = ({ children }) => {
  const [headerNavMeta, setHeaderNavMeta] = useState({});
  return (
    <NavHeaderContext.Provider value={{ headerNavMeta, setHeaderNavMeta }}>
      {children}
    </NavHeaderContext.Provider>
  );
};
export { NavHeaderContext, NavHeaderProvider };
