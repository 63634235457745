import styled from "styled-components";

const StyledNavbarDropdown = ({ children, ...props }) => {
  return (
    <NavbarDropdown
      {...props}
      className="d-flex align-items-center justify-content-center flex-1"
    >
      {children}
    </NavbarDropdown>
  );
};

export default StyledNavbarDropdown;
// nav dropdown styling section
const NavbarDropdown = styled.div`
  cursor: pointer;
  //on hover change span color
  &:hover h6 {
    color: var(--primary2);
    transition: all 0.25s ease;
  }

  .btn {
    direction: ${(props) => (props.theme.rtl ? "rtl" : "ltr")};
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    padding: 5px;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: var(--primary2);
    text-decoration: none;
    /* background-color: rgba(0, 0, 0, 0); */
  }
  .btn:hover {
    color: #fff;
  }
  .dropdown-toggle::after {
    display: none;
  }
`;
