import Badge from "components/ReuseableCompos/Badge";
import CustomContentLoader from "components/ReuseableCompos/CustomContentLoader";
import useMobile from "customHooks/useMobile";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import { useModeContext } from "../../context/ModeContext";
import { NavHeaderContext } from "../../context/NavHeaderContext";
import { NotificationContext } from "../../context/NotificationContext";
import useStickyScroll from "../../customHooks/useStickyScroll";
import NotificationOffCanvas from "../NotificationOffCanvas";
import Logo from "./Logo";
import LoweNavbarEndSideSignedIn from "./LoweNavbarEndSideSignedIn";
import LoweNavbarEndSideSignedOut from "./LoweNavbarEndSideSignedOut";
import NavBackBtn from "./NavBackBtn";
import NavBarSearch from "./NavBarSearch/NavBarSearch";
import NavbarBtns from "./NavbarBtns";
import NavbarMenu from "./NavbarMenu";

const LowerNavbar = () => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showSearchBarRes, setShowSearchBarRes] = useState(false);

  const { donor, isDonorLoggedIn } = useContext(AuthContext);
  const {
    showNotificationOffCanvas,
    handleCloseNotificationOffCanvas,
    handleShowNotificationOffCanvas,
    notifications,
  } = useContext(NotificationContext);
  const { pathname } = useRouter();
  const scroll = useStickyScroll(75);
  const startedScrolling = useStickyScroll(20);
  const { headerNavMeta } = useContext(NavHeaderContext);
  const matches = useMobile("lg");
  const theme = useModeContext();
  const CustomNotifIcon = headerNavMeta.customSearch?.customNavBackBtn;
  const Notif = () => {
    return (
      <span
        style={{ minWidth: 44, justifyContent: "flex-start" }}
        className={`navbar-tool-icon-box cursor d-flex align-items-center d-lg-none`}
        onClick={handleShowNotificationOffCanvas}
      >
        <i className="fe-bell fs-xxl"></i>
        {donor?.unread_notifications_count > 0 ? (
          <Badge
            style={{ right: "-8px" }}
            count={donor?.unread_notifications_count}
          />
        ) : null}
      </span>
    );
  };
  // if (pathname === "/" && !scroll && matches) return null;
  return (
    <>
      <LowerNavbarWrapper
        // condition={pathname === "/" && matches}
        condition={false}
        scroll={startedScrolling}
        className={`navbar navbar-expand-lg navbar-light bg-blur navbar-sticky navbar-floating zindex-7 pt-0 pb-0
        ${scroll ? "zindex-7" : ""} 
        ${
          showSearchBar && showSearchBarRes
            ? theme.theme === "light"
              ? "border-bottom"
              : ""
            : "navbar-box-shadow"
        }

        `}
      >
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className={
              headerNavMeta.setBar && matches ? "" : "dark-border-bottom"
            }
          >
            <NavBarSearch
              setShowSearchBar={setShowSearchBar}
              setShowSearchBarRes={setShowSearchBarRes}
              showSearchBar={showSearchBar}
              showBorderBottom={true}
            />
            <div
              className={`container lower-navbar-padding ${
                matches ? "navbar-paddingTBM" : "navbar-paddingTB"
              }`}
            >
              <NavBackBtn Notif={Notif} Custom={CustomNotifIcon} />

              <Logo scroll={scroll} />

              <div
                className={`d-flex align-items-center order-lg-3 ms-lg-auto mm-1c`}
              >
                {donor ? (
                  <LoweNavbarEndSideSignedIn />
                ) : (
                  <>
                    {isDonorLoggedIn && !matches ? (
                      <NavbarSkeleton />
                    ) : (
                      <LoweNavbarEndSideSignedOut />
                    )}
                  </>
                )}

                <NavbarBtns
                  setShowSearchBar={setShowSearchBar}
                  donor={donor}
                  isDonorLoggedIn={isDonorLoggedIn}
                  customSearch={headerNavMeta.customSearch}
                />
              </div>

              <NavbarMenu />
            </div>
          </div>
          {headerNavMeta.setBar && matches ? headerNavMeta.setBar : null}
        </div>
      </LowerNavbarWrapper>

      <NotificationOffCanvas
        showNotificationOffCanvas={showNotificationOffCanvas}
        handleCloseNotificationOffCanvas={handleCloseNotificationOffCanvas}
        notifications={notifications}
      />
    </>
  );
};

export default LowerNavbar;

const LowerNavbarWrapper = styled.nav<{ scroll: boolean; condition: boolean }>`
  transition: top 0.3s ease-in-out;
  @media (max-width: 991.98px) {
    top: ${(props) => (!props.condition ? "0" : props.scroll ? "0" : "-75px")};
  }

  .home-icon {
    color: #1768b3;
  }

  .navbar-tool-icon-box {
    width: unset;
    height: unset;
  }
`;
const NavbarSkeleton = () => {
  return (
    <div className="d-flex align-items-center order-lg-3 ms-lg-auto mm-1c">
      <div className={`border-left mx-4`} style={{ height: 30 }} />
      <div className="navbar-tool search-bar-icon-wrapper">
        <CustomContentLoader viewBox={`0 0 108 44`} style={{ width: 108 }}>
          <rect
            x="0"
            y="0"
            rx="8"
            ry="8"
            width="44"
            height="44"
            className="donate-card-icon"
          ></rect>
          <rect x="50" y="8" rx="4" ry="4" width="40" height="12"></rect>
          <rect x="50" y="24" rx="4" ry="4" width="58" height="12"></rect>
        </CustomContentLoader>
      </div>
    </div>
  );
};
