import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useTranslation from "next-translate/useTranslation";
import React, { PropsWithChildren } from "react";
import { useCheckoutStore } from "../CheckoutState";
import ModalBodyElementContainer from "./ModalBodyElementContainer";

type Props = {
  startingChildren?: React.ReactNode;
  totalText?: string;
  style?: React.CSSProperties;
  hideTotal?: boolean;
};

const PaymentSlidesFooterContainer: React.FC<PropsWithChildren<Props>> = (
  props
) => {
  const checkoutOrder = useCheckoutStore((state) => state.checkoutOrder);
  const { t } = useTranslation("common");
  const { formatNumbers } = useFormatNumbers();
  const { curr } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  return (
    <ModalBodyElementContainer
      style={{
        width: "100%",
        ...props.style,
      }}
    >
      {props.startingChildren}

      {!props.hideTotal && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 16,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: "bold" }}>{`${
            props.totalText ? props.totalText : t("total_amount")
          }:`}</div>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            {formatNumbers(checkoutOrder?.amount, currencyCode??curr.code, true)}
          </div>
        </div>
      )}
      {props.children}
    </ModalBodyElementContainer>
  );
};

export default PaymentSlidesFooterContainer;
