import { useContext } from "react";
import { AuthModalContext } from "../../context/AuthModalContext";

const LoweNavbarEndSideSignedOut = () => {
  const { handleShowAuthModal, onTabClick } = useContext(AuthModalContext);

  const onSignupBtnClick = () => {
    handleShowAuthModal();
    onTabClick("with_social");
  };
  return (
    <div className="d-none d-lg-flex align-items-center order-3 ms-lg-auto nav-tool">
      <div className={`border-left mx-4`} style={{ height: 30 }}></div>
      <span className={`navbar-tool-icon-box `} onClick={onSignupBtnClick}>
        <i className="ai-user fs-xxl"></i>
      </span>
    </div>
  );
};

export default LoweNavbarEndSideSignedOut;
