import { useRouter } from "next/router";
import { useState } from "react";
import { API_URL, NEXT_URL_PROXY } from "../config";
import useUpdateEffect from "./useUpdateEffect";
import useSWRImmutable from "swr/immutable";
import { fetcher } from "../utils/getData";
import { BareFetcher, PublicConfiguration } from "swr/_internal";

const useCustomSWRImmutable = <Data = any>(endPoint, options?: Partial<PublicConfiguration<Data, any, BareFetcher<Data>>>, proxy: boolean = false) => {
  const [changeLocaleLoading, setChangeLocaleLoading] = useState(false);
  const { locale } = useRouter();
  // const { curr } = usePreferenceContext();

  const { data, error, mutate } = useSWRImmutable<Data>(
    endPoint ? `${proxy ? NEXT_URL_PROXY : API_URL}/${endPoint}` : null,
    (key) => fetcher(key, null, null, { locale }),
    {
      ...options,
      
    }
  );
  useUpdateEffect(() => {
    setChangeLocaleLoading(true);
    const mutatePaginatedData = async () => {
      await mutate();
      setChangeLocaleLoading(false);
    };
    mutatePaginatedData();
  }, [locale]);
  return {
    data,
    mutate,
    error,
    changeLocaleLoading,
  };
};

export default useCustomSWRImmutable;
