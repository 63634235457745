import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FiMoon, FiSun } from "react-icons/fi";
import { MdOutlinePalette } from "react-icons/md";
import { useModeContext } from "../../context/ModeContext";
import useModal from "../../customHooks/useModal";
import useRTL from "../../customHooks/useRTL";
import ThemingModal from "../ThemingModal";
import { StyledColorBullet } from "./StyledColorBullet";
import StyledNavbarDropdown from "./StyledNavbarDropdown";

type Props = {
  className?: string;
  offcanvas?: boolean;
};
const Color = ({ className, offcanvas }: Props) => {
  const { color, theme } = useModeContext();

  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const {
    showModal: showThemingModal,
    handleCloseModal: handleCloseThemingModal,
    handleShowModal: handleShowThemingModal,
  } = useModal();
  const { data: utilities } = useUtilities();
  const colors = utilities?.colors;
  const currentColor = colors?.find((c) => c.key === color);
  const isRTL = useRTL();
  return (
    <>
      <StyledNavbarDropdown
        className="d-md-flex align-items-center text-nowrap"
        onClick={handleShowThemingModal}
      >
        {offcanvas ? (
          <span
            className={` ${
              className || ""
            } p-1 d-flex flex-column justify-content-center align-items-center text-wrap`}
          >
            {theme === "dark" ? (
              <FiMoon size={25} className="" />
            ) : (
              <FiSun size={25} className="" />
            )}
            {/* <span>{t("theme_mode_color")}</span> */}
            <span className="fs-sm mt-1 text-center">
              {t(theme)} {currentColor?.name?.[locale]}
            </span>
          </span>
        ) : (
          <StyledColorBullet
            className="mile-1 cursor"
            style={{
              borderRadius: !isRTL
                ? "0.5rem 0% 0% 0.5rem"
                : "0% 0.5rem 0.5rem 0%",
            }}
          >
            <MdOutlinePalette size={20} />
          </StyledColorBullet>
        )}
      </StyledNavbarDropdown>

      <ThemingModal
        handleCloseThemingModal={handleCloseThemingModal}
        showThemingModal={showThemingModal}
      />
    </>
  );
};

export default Color;
