
import { TFAQSection,MolhamLanguagesObject } from '../apiTypes';
import useCustomSWRImmutable from './useCustomSWRImmutable';
export type TShortcut = {
  contents: {
    description: MolhamLanguagesObject;
    name: MolhamLanguagesObject;
  };
  id: number;
  keywords: string[];
  path: {
    mobile: string;
    web: string;
  };
};

export type TPages = {
  id: number;
  slug: string;
  contents: {
    title: MolhamLanguagesObject;
    description: MolhamLanguagesObject;
    body: MolhamLanguagesObject;
  };
}[];
export type TContentModal = {
  id: number;
  contents: {
    title: MolhamLanguagesObject;
    description: MolhamLanguagesObject;
    body: MolhamLanguagesObject;
  };
  name: string;
};
export type TContents = {
  constants: {
    [s:string]: {
      [key: string]: string;
    };
  };
  pages: TPages;
  faqs: TFAQSection[];
  shortcuts: TShortcut[];
  modals: TContentModal[];
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useContents(run = true) {
  const swr = useCustomSWRImmutable<TContents>(run ? 'contents' : null);
  return swr;
}
