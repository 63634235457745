import { useAuthContext } from "context/AuthContext";
import useMobile from "customHooks/useMobile";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { AuthModalContext } from "../../../context/AuthModalContext";
import MainAuthModalComponent from "./MainAuthModalComponent";

const AuthModal = () => {
  const { donor, finishedLoadingOnce } = useAuthContext();
  const { showAuthModal, handleCloseAuthModal } = useContext(AuthModalContext);
  const isMobile = useMobile("md");
  return (
    <Modal
      show={showAuthModal && !donor && finishedLoadingOnce}
      onHide={handleCloseAuthModal}
      //@ts-ignore
      size={isMobile ? "lg" : "md"}
      centered
      fullscreen={isMobile ? true : undefined}
    >
      <MainAuthModalComponent />
    </Modal>
  );
};

export default AuthModal;
