import useTranslation from "next-translate/useTranslation";
import { useMemo } from "react";
import { useCheckoutStore } from "../../CheckoutState";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import FixedAmountOption from "./FixedAmountOption";
import InputableAmountOption from "./InputableAmountOption";
import QuantifiableAmountOption from "./QuantifiableAmountOption";

const DonationAmountStepTitle: React.FC = () => {
  const { t } = useTranslation("common");
  const { paymentOptionData } = useCheckoutStore((state) => ({
    paymentOptionData: state.paymentOptionData,
  }));
  return paymentOptionData.payment_options_label ?? t("donation_options");
};
const DonationAmountStepBody: React.FC = () => {
  const selectedPaymentOption = useCheckoutStore(
    (state) => state.selectedPaymentOption
  );

  const SelectedVariant = useMemo(() => {
    if (
      selectedPaymentOption &&
      !selectedPaymentOption.quantifiable &&
      selectedPaymentOption.amount
    ) {
      return FixedAmountOption;
    } else if (
      selectedPaymentOption &&
      selectedPaymentOption.quantifiable &&
      selectedPaymentOption.amount
    ) {
      return QuantifiableAmountOption;
    } else if (
      selectedPaymentOption &&
      !selectedPaymentOption.quantifiable &&
      !selectedPaymentOption.amount
    ) {
      return InputableAmountOption;
    } else {
      return InputableAmountOption;
    }
  }, [selectedPaymentOption]);

  return (
    <ModalBodyElementContainer>
      <SelectedVariant />
    </ModalBodyElementContainer>
  );
};
const DonationAmountStep = {
  name: "DonationAmountStep",
  title: DonationAmountStepTitle,
  body: DonationAmountStepBody,
  // footer: DonationAmountStepFooter,
};
export default DonationAmountStep;
