import useTranslation from "next-translate/useTranslation";
import styled from "styled-components";
import ModeSwitcher from "../ModeSwitcher";
import ActiveLink from "../ReuseableCompos/ActiveLink";
import AboutDropdown from "./AboutDropdown";
import ColorBtn from "./ColorBtn";
import CurrencyDropdown from "./CurrencyDropdown";
import HelpDropdown from "./HelpDropdown";
import LanguageDropdown from "./LanguageDropdown";
import StyledNavbarDropdown from "./StyledNavbarDropdown";

const UpperNavbar = () => {
  // getting translations
  const { t } = useTranslation("common");
  const items = [
    { href: "/help/faqs/payment", title: t("how_to_donate") },

    { title: t(`transparency`), href: "/transparency" },
    // { title: t(`blogs`), href: "/blog" },
  ];
  return (
    // upper navbar section
    <StyledUpperNavbar className="topbar topbar-dark d-none d-lg-flex zindex-9">
      <div className="container d-md-flex align-items-center px-0 px-xl-3">
        <div className="d-none d-md-flex align-items-center flex-1">
          <div className="d-none d-md-flex align-items-center text-nowrap mr-3">
            <StyledNavbarDropdown className="d-md-flex align-items-center text-nowrap">
              <AboutDropdown />
            </StyledNavbarDropdown>
          </div>
          <div className="d-none d-md-flex align-items-center text-nowrap mr-3">
            <StyledNavbarDropdown className="d-md-flex align-items-center text-nowrap">
              <HelpDropdown />
            </StyledNavbarDropdown>
          </div>
          {items.map((item, index) => (
            <UpperNavItem key={index} title={item.title} href={item.href} />
          ))}
        </div>

        <div className="d-inline-flex ">
          <div className="d-flex align-items-center">
            <ColorBtn offcanvas={false} />
            <ModeSwitcher isOffcanvas={false} />
          </div>
        </div>

        <div className="d-inline-flex text-md-right align-items-center justify-content-end float-right uppernav-sm flex-1">
          {/* language section dropdown */}
          <div className="d-flex">
            <LanguageDropdown />
            <CurrencyDropdown />
          </div>
        </div>
      </div>
    </StyledUpperNavbar>
  );
};

export default UpperNavbar;
// uppernav styling
const StyledUpperNavbar = styled.nav`
  transition: all 0.3s ease-in-out;
  height: 44px;
  display: flex;
  align-items: center;
  @media (max-width: 767.98px) {
    display: flex;
    .float-right {
      float: unset !important;
    }
    .d-inline-flex {
      display: flex !important;
      justify-content: space-between !important;
    }
  }
`;
const UpperNavItem = ({ title, href }) => {
  return (
    <div className="d-none d-md-flex align-items-center text-nowrap mr-3">
      {/* transparency page router */}

      <ActiveLink prefetch={false} activeClassName="active fw-bold" href={href}>
        <a className="topbar-link mr-1">{title}</a>
      </ActiveLink>
    </div>
  );
};
