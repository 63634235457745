import useRTL from "customHooks/useRTL";
import dayjs from 'dayjs';
import RelativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { useRouter } from "next/router";
import { useEffect } from "react";
dayjs.extend(utc);
dayjs.extend(RelativeTime);


const IntlProvider = ({ children }) => {
  const { locale } = useRouter();
  // const [localMessages, setLocalMessages] = useState(null);
  const isRTL = useRTL();
  // const { data: contents } = useContents();
  // change the direction of the app on locale change
  useEffect(()=>{
    const loadDayjs = async ()=>{
      const currentLocale = locale.replaceAll("ar", "ar-iq")
      await import(`dayjs/locale/${currentLocale}.js`)
      // if the locale is arabic, we need to import the arabic locale but use the iraqi version
      if(locale === 'ar'){
        await import('dayjs/locale/ar.js')
      }
      dayjs.locale(currentLocale)
    }
    loadDayjs()
  },[locale])
  useEffect(() => {
    let dir = isRTL ? "rtl" : "ltr";
    document.querySelector("html").setAttribute("dir", dir);
    const body = document.querySelector("body");
    isRTL ? body.classList.add("rtl") : body.classList.remove("rtl");
  }, [isRTL]);
  // switch the constants from serverside to clientside and store them in locale storage
  // useEffect(() => {
  //   if (localMessages?.error) {
  //     return;
  //   }
  //   if (contents?.constants) {
  //     localStorage.setItem("messages", JSON.stringify(contents?.constants));
  //   }

  //   if (localStorage.getItem("messages")) {
  //     setLocalMessages(JSON.parse(localStorage.getItem("messages")));
  //   }
  // }, [contents?.constants]);
  // const [extraTranslations, setExtraTranslations] = useState({});
  // const getSEOTranslations = async (lang:string)=>{
  //   if(typeof window !== undefined){
  //     return {}
  //   }
  //   const translations = (await import("../constants/contents.json")).constants[lang]
  //   setExtraTranslations(translations)
  // }
  // locale ? getSEOTranslations(locale) : null
  return (
    // <NextIntlClientProvider
    //   onError={(e) => null}
    //   messages={{
    //     ...localMessages?.[locale],
    //     //   ...pageProps?.contents?.constants,
    //     ...extraTranslations,
    //     ...contents?.constants?.[locale]

    //   }}
    // >
    <>
      {children}
      </>
    // </NextIntlClientProvider>
  );
};
export default IntlProvider;
