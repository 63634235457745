import Cookies from "js-cookie";

export const isUndefined = (val: any) => {
    return val === undefined || val === null;
  }

  export const getMoclid = () => {
    const moclid = Cookies.get('moclid');
    if (moclid) return moclid;
    return null;
  };