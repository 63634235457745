type Props = {
  customClassName?: string;
  hasPadding?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;
const OffCanvasFooter: React.FC<Props> = ({
  customClassName,
  hasPadding = true,
  ...props
}) => {
  return (
    props.children ? <div
      className={`border-top dark-border-bottom ${
        hasPadding ? "py-4 px-4" : ""
      } ${customClassName}`}
      {...props}
    >
      {props.children}
    </div> : null
  );
};

export default OffCanvasFooter;
