

type Item = {
  amount:string|number
}
// getting the amount of the array of objects
const amount = (item:Item) => Number(item.amount);
// getting the total of passed values
const sum = (prev:number, next:number) => prev + next;

/**
 * It takes an array of objects and returns the sum of the amounts.
 * @param data - The data to be processed.
 * @returns The total of all the amounts in the data.
 */
export const getTotal = (data:Item[]) => {
  const total = data.map(amount).reduce(sum, 0);
  return total;
};
