import { m } from "framer-motion";
import { useColors } from "layout/Layout";
import React, { ComponentProps } from "react";

type Props = { checked?: boolean; circle?: boolean } & ComponentProps<
  typeof m.div
>;

const CheckboxRoundedIcon: React.FC<Props> = ({ checked, ...props }) => {
  const colors = useColors();
  const color = checked ? colors.primary2 : "var(--light-gray)";
  return (
    <m.div
      {...props}
      style={{
        display: "flex",
        flexDirection: "row",
        width: "22px",
        height: "22px",
        // border: `1px solid ${checked ? colors.primary2 : "var(--light-gray)"}`,
        // backgroundColor: checked ? colors.primary2 : "transparent",
        marginLeft: 0,
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <m.div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
        transition={{
          type: "spring",
          stiffness: 500,
          damping: 30,
        }}
        // animate={{ scale: checked ? 1 : 0 }}
      >
        {checked ? (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 25"
            height={"100%"}
            width={"100%"}
          >
            <path
              fill={color}
              d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M18.2,8l-7.8,9.2c-0.2,0.2-0.4,0.3-0.7,0.3h0
c-0.3,0-0.5-0.1-0.7-0.3l-3.3-3.7c-0.3-0.4-0.3-1,0-1.3c0.4-0.3,1-0.3,1.3,0c0,0,0,0,0,0l2.6,2.9l7.1-8.4c0.3-0.4,0.9-0.4,1.3-0.1
C18.5,7,18.6,7.6,18.2,8z"
            />
          </svg>
        ) : (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            height={"100%"}
            width={"100%"}
          >
            <circle
              fill="none"
              stroke={color}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="12"
              cy="12"
              r="10.9"
            />
          </svg>
        )}
      </m.div>
    </m.div>
  );
};

export default CheckboxRoundedIcon;
