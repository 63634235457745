type Props = {
  toggleShowPass: () => void;
  showPass: boolean;
  isRTL: boolean;
  LR?: number;
  additionalClasses?: string;
};

export default function EyeButton({
  toggleShowPass,
  showPass,
  isRTL,
  LR = 12,
  additionalClasses = "",
}: Props) {
  const sharedStyles = {
    position: "absolute" as const,
    zIndex: 5,
    color: "#b0b3b8",
    top: "22px",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    border: "none",
  };

  return (
    <button
      type="button"
      onClick={toggleShowPass}
      style={
        isRTL ? { ...sharedStyles, left: LR } : { ...sharedStyles, right: LR }
      }
    >
      <i
        className={`ai-eye${
          showPass ? "-off" : ""
        } fs-md mb-0 ${additionalClasses} jambad`}
      ></i>
    </button>
  );
}
