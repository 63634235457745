// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyD4x5uhhX_HAMpZDqqp4UuCVHAMZJHmJ1A",
  authDomain: "molham-volunteer-1571151536473.firebaseapp.com",
  projectId: "molham-volunteer-1571151536473",
  storageBucket: "molham-volunteer-1571151536473.appspot.com",
  messagingSenderId: "1010928718503",
  appId: "1:1010928718503:web:6e144bd631feec46184182",
  measurementId: "G-MVFD49XK91",
};
