import { yupResolver } from "@hookform/resolvers/yup";
import { useStripe } from "@stripe/react-stripe-js";
import useTranslation from "next-translate/useTranslation";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import stripeActions from "../../../actions/stripe";
import Checkbox from "../../../components/Checkbox";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import {
  NameEmailFormComponents,
  SepaMandate,
  SubmitButton,
  handleCheckoutEnd,
} from "../common";

const schema = yup.object({
  name: yup.string().required(),
  iban: yup.string().required(),
});
type TForm = yup.InferType<typeof schema>;

const NewSepaPaymentStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_sepa_debit");
};
const NewSepaPaymentStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const [saveForLater, setSaveForLater] = useState(false);
  const stripe = useStripe();
  const { control, handleSubmit } = useForm<TForm>({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const donor = useCheckoutStore().checkoutOrder?.payer;

  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = async (data: TForm) => {
    const paymentProvider =
      donor!.available_payment_methods.stripe_sepa_debit.payment_provider;
    setLoading(true);
    try {
      // const element = elements!.getElement(CardElement);
      const pm = await stripe.createPaymentMethod({
        type: "sepa_debit",
        sepa_debit: {
          iban: data.iban,
        },
        billing_details: {
          name: data.name?.toUpperCase(),
          email: donor.email,
        },
      });
      if (pm.error) {
        toast.error(pm.error.message);
        setErrorMessage(pm.error.message);
        // setLoading(false);

        return;
      }
      const checkoutOrder = useCheckoutStore.getState().checkoutOrder;

      const paymentIntent = await stripeActions.paymentIntent({
        checkout_order_reference: checkoutOrder!.reference,
        save_for_later: saveForLater,
        // return_url: 'molhamteam://stripe-redirect',
        payment_method: pm.paymentMethod.id,
        payment_method_types: ["sepa_debit"],
        confirmation_method: "automatic",
        payment_provider_id: paymentProvider.id,
      });
      if (paymentIntent.ok === false) {
        toast.error(paymentIntent.error.message);
        setErrorMessage(paymentIntent.error ? paymentIntent.error.message : "");
        setLoading(false);

        return;
      }
      const { error } = await stripe.confirmSepaDebitPayment(
        paymentIntent.data.client_secret,
        {
          payment_method: pm.paymentMethod.id,
          return_url: `${
            location.protocol + "//" + location.host + location.pathname
          }?checkout_order_id=${checkoutOrder!.reference}`,
        }
      );
      if (error) {
        toast.error(error.message);
      } else {
        const res = await checkoutHandlerActions.handleCheckoutSource({
          payment_provider_id: paymentProvider.id,
          type: checkoutHandlerActions.CheckoutSourceTypes.stripe
            .payment_intent,
          reference: paymentIntent.data?.id,
        });
        handleCheckoutEnd({
          res,
          metadata: {
            "Checkout Order": checkoutOrder.reference,
            Items: checkoutOrder.items,
            currency: checkoutOrder.currency.code,
            amount: checkoutOrder.amount,
            "Payment Method": "Stripe Sepa Debit",
            saved: false,
          },
        });
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <ModalBodyElementContainer>
      {donor?.tester ? <p>DE89370400440532013000</p> : null}
      <label>{t("iban")}</label>
      <Controller
        name="iban"
        control={control}
        render={({
          field: { onBlur, onChange, value, ref },
          fieldState: { error },
        }) => {
          return (
            <>
              <input
                ref={ref}
                placeholder={t("enter_iban")}
                // dir={dir}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value}
                onBlur={onBlur}
                className="form-control rounded smol-input mb-2"
                type="text"
              />
              {error?.message ? (
                <div style={{ color: "red" }}>{t(error?.message)}</div>
              ) : null}
            </>
          );
        }}
      />
      <NameEmailFormComponents control={control as any} />
      {donor?.type !== "guest" && (
        <Checkbox
          checked={saveForLater}
          style={{ marginTop: 20, marginBottom: 20 }}
          onClick={() => {
            setSaveForLater(!saveForLater);
          }}
        >
          <div>{t("save_payment_method_for_later")}</div>
        </Checkbox>
      )}
      {errorMessage ? (
        <div style={{ color: "red", marginTop: 10, marginBottom: 10 }}>
          {errorMessage}
        </div>
      ) : null}

      <SepaMandate />
      <DonationModalFooterPortal id="modal-footer-portal-host">
        <PaymentSlidesFooterContainer>
          <SubmitButton onSubmit={handleSubmit(onSubmit)} loading={loading} />
        </PaymentSlidesFooterContainer>
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const NewSepaPaymentStep = {
  name: "NewSepaPaymentStep",
  title: NewSepaPaymentStepTitle,
  body: NewSepaPaymentStepBody,
  footer: true,
};
export default NewSepaPaymentStep;
