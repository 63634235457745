import ContentLoader, { IContentLoaderProps } from "react-content-loader";
import { useModeContext } from "../../context/ModeContext";
import useRTL from "../../customHooks/useRTL";

type Props = {
  children: React.ReactNode;
} & IContentLoaderProps;
const CustomContentLoader = ({ children, ...props }: Props) => {
  const { theme } = useModeContext();
  const isRTL = useRTL();
  return (
    <ContentLoader
      rtl={isRTL}
      speed={2}
      backgroundColor={theme === "light" ? "#e4e6eb" : "#22303c"}
      foregroundColor={
        theme === "light" ? "#ecebeb" : "rgba(161, 161, 170 , 0.01)"
      }
      {...props}
    >
      {children}
    </ContentLoader>
  );
};

export default CustomContentLoader;
