import * as React from "react";
const SvgComponent = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 1000 1000",
    }}
    viewBox="0 0 1000 1000"
    {...props}
  >
    <path
      d="M544.5 55.3 99.8 588.9H500l-44.5 355.8 444.7-533.6H500z"
      style={{
        fill: "none",
        stroke: props.fill,
        strokeWidth: 70,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
  </svg>
);
export { SvgComponent as QuickSVG };
