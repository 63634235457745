import React from "react";
import ErrorBoundaryComponent from "./ErrorBoundaryComponent";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() // error: Error
  {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch() // error: Error, errorInfo: React.ErrorInfo
  {
    // You can use your own error logging service here
  }

  render() {
    // Check if the error is thrown
    if ((this.state as { hasError: boolean }).hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryComponent
          onClick={() => {
            this.setState({ hasError: false });
          }}
        />
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
