import axiosInstance, { MolhamError, MolhamResponse, MolhamResult } from "../axiosInstance";
type PayPalCheckoutOrder = {
    id: string;
    links: { href: string; method: string; rel: string }[];
    status: string;
}
const checkoutOrder = async (data: {
    checkout_order_reference: string;
    return_url: string;
    cancel_uri?: string;
    payment_provider_id: string;
}): Promise<MolhamResult<PayPalCheckoutOrder>> => {
    try {
        const res = await axiosInstance.post(
            `/payment-providers/paypal/checkout-orders`,
            data,
        );
        return res as MolhamResponse<PayPalCheckoutOrder>;
    } catch (err: any) {
        return err as MolhamError;
    }
};

const subscription = async (data: {
    checkout_order_reference: string;
    return_url: string;
    cancel_uri?: string;
    payment_provider_id: string;
}): Promise<MolhamResult<PayPalCheckoutOrder>> => {
    try {
        const res = await axiosInstance.post(
            `/payment-providers/paypal/subscriptions`,
            data,
        );
        return res as MolhamResponse<PayPalCheckoutOrder>;
    } catch (err: any) {
        return err as MolhamError;
    }
};
const paypalActions = { checkoutOrder, subscription };
export default paypalActions;
