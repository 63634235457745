import Cookies from "js-cookie";
import router from "next/router";
export const getMolhamHeaders = (
  overrides?:
    | {
        locale?: string;
        currency?: string;
        sessionReference?: string;
        clickReference?: string;
      }
    | undefined
) => {
  try {
    if (typeof window === "undefined") return {};
    const SessionReference =
      overrides?.sessionReference || Cookies.get("molham-session-reference");
    const ClickReference = overrides?.clickReference || Cookies.get("moclid");
    const Locale = overrides?.locale || router.locale;
    const Currency = overrides?.currency || Cookies.get("currency");
    const XHeaders: {
      "X-Molham-Session-Reference"?: string;
      "X-Molham-Click-Reference"?: string;
      "X-Molham-Locale"?: string;
      "X-Molham-Currency"?: string;
    } = {};
    if (SessionReference)
      XHeaders["X-Molham-Session-Reference"] = SessionReference;
    if (ClickReference) XHeaders["X-Molham-Click-Reference"] = ClickReference;
    if (Locale) XHeaders["X-Molham-Locale"] = Locale;
    if (Currency) XHeaders["X-Molham-Currency"] = Currency;
    return XHeaders;
  } catch (error) {
    return {};
  }
};
