import { Router } from "next/router";
import { useEffect } from "react";
import TwitterConvTrkr from "react-twitter-conversion-tracker";

function TwitterPixel() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      TwitterConvTrkr.init("o4q9d");
      TwitterConvTrkr.pageView();
      Router.events.on("routeChangeComplete", () => {
        TwitterConvTrkr.pageView();
      });
    }
  }, []);
  return null;
}

export default TwitterPixel;
