import Lottie from "lottie-react";
import React, { ComponentProps, useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";

type Props = Omit<ComponentProps<typeof Lottie>, "animationData"> & {
  uri?: string;
  margin_top?: number | string;
  margin_bottom?: number | string;
};

const LottieUri: React.FC<Props> = (props) => {
  // const {data: jsonData} = useSWRImmutable2<string>(props.uri);
  const [jsonData, setJsonData] = useState<string | null>(null);
  //   fetch the json data from the uri
  useEffect(() => {
    if (props.uri) {
      axiosInstance.get(props.uri).then((res) => {
        setJsonData(res.data);
      });
    }
  }, [props.uri]);
  const marginTop = props.margin_top ? Number(props.margin_top) : 0;
  const marginBottom = props.margin_bottom ? Number(props.margin_bottom) : 0;
  return (
    <>
      {jsonData ? (
        <Lottie
          autoPlay={true}
          {...props}
          style={{
            ...{
              marginBottom: marginBottom,
              marginTop: marginTop,
            },
            ...props.style,
          }}
          animationData={jsonData}
        />
      ) : (
        <div
          {...props}
          style={{
            ...{
              marginBottom: marginBottom,
              marginTop: marginTop,
            },
            ...props.style,
          }}
        />
      )}
    </>
  );
};

export default LottieUri;
