import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import { Dropdown } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import ActiveLink from "../ReuseableCompos/ActiveLink";

const AboutDropdown = () => {
  const { t } = useTranslation("common");
  const paths = [
    { path: "/about/about", name: "abouttheteam" },
    { path: "/about/molham", name: "about_molham" },
    { path: "/about/mission", name: "mission" },
    { path: "/about/programs", name: "ourdepsandprojects" },
    { path: "/about/legalnotice", name: "legalnotice" },
    { path: "/about/reports", name: "annual-reports" },

    { name: "blogs", path: "/blog" },
  ];

  const isRTL = useRTL();
  return (
    <ul className="navbar-expand-lg list-unstyled m-0">
      <li className="nav-item dropdown">
        <ActiveLink fuzzy href="/about" activeClassName="active fw-bold">
          <span className="topbar-link dropdown-toggle text-capitalize cursor">
            {t(`header_about`)}
            <MdKeyboardArrowDown />
          </span>
        </ActiveLink>
        <ul
          className={`dropdown-menu dropdown-menu-${
            isRTL ? "end" : "start"
          } test-menu`}
        >
          {paths.map((path, index) => {
            const isLast = index === paths.length - 1;
            return (
              <div key={index}>
                <li>
                  <ActiveLink
                    prefetch={false}
                    href={path.path}
                    activeClassName="active fw-bold"
                  >
                    <a className="dropdown-item">
                      <span className="d-block text-heading ">
                        {t(path.name)}
                      </span>
                    </a>
                  </ActiveLink>
                </li>
                {!isLast && <Dropdown.Divider as="div" />}
              </div>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};

export default AboutDropdown;
