type Props = {
  closeModal: () => void;
  style?: React.CSSProperties;
  className?: string;
  iconStyle?: React.CSSProperties;
  show?: boolean;
};
const CloseBtn: React.FC<Props> = ({
  closeModal,
  style,
  className,
  iconStyle = {},
  show = true,
}) => {
  return show ? (
    <button
      className={`close fs-lg lead ${className ? className : ""}`}
      // type="button"

      onClick={closeModal}
      style={style}
    >
      <i className="fe-x" style={iconStyle}></i>
    </button>
  ) : (
    <div style={{ minWidth: 35 }} />
  );
};

export default CloseBtn;
