import { useStripe } from "@stripe/react-stripe-js";
import { TPaymentMethodItem } from "apiTypes";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { NEXT_URL_PROXY } from "config";
import { useAuthContext } from "context/AuthContext";
import { useInfinitePagination } from "customHooks/useInfinitPagination";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import stripeActions from "../../../actions/stripe";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import { PaymentMethodItem } from "../../PaymentMethod/components/PaymentMethodItem";
import { SubmitButton, handleCheckoutEnd } from "../common";
import NewCardPaymentStep from "./NewCardPaymentStep";
const SavedCardPaymentStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_bank_card");
};
const SavedCardPaymentStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const stripe = useStripe();
  
  const { donor } = useAuthContext();
  const {
    loading,
    setLoading,
    checkoutOrder,
    addStepToHistory,
    replaceStepInHistory,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    replaceStepInHistory: state.replaceStepInHistory,

    checkoutOrder: state.checkoutOrder,
    setLoading: state.setLoading,
    loading: state.loading,
  }));
  const { formattedData, isLoadingInitialData, getAllPages } =
    useInfinitePagination<TPaymentMethodItem>(
      `${NEXT_URL_PROXY}/auth/saved-payment-methods`
    );

  useEffect(() => {
    if (!isLoadingInitialData) {
      getAllPages();
    }
  }, [isLoadingInitialData]);
  const filteredData = formattedData.filter(
    (item) => item.type === "card" && item.methodable_type === "stripe_card"
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    number | string | null
  >(null);
  const handleNextSlide = () => {
    addStepToHistory(NewCardPaymentStep.name);
  };
  const onSubmit = async () => {
    if (stripe) {
      setLoading(true);
      try {
        if (selectedPaymentMethod === null) {
          return;
        }
        const paymentProvider =
          donor.available_payment_methods.stripe_card.payment_provider;
        const paymentIntent = await stripeActions.paymentIntent({
          checkout_order_reference: checkoutOrder!.reference,
          saved_payment_method_id: selectedPaymentMethod,
          payment_method_types: ["card"],
          confirmation_method: "automatic",
          payment_provider_id: paymentProvider.id,
        });

        if (paymentIntent.ok === false) {
          toast.error(paymentIntent.error?.message);
          setLoading(false);

          return;
        }
        const { error } = await stripe.confirmCardPayment(
          paymentIntent.data.client_secret,
          {
            return_url: `${window.location}/?checkout_order_id=${
              checkoutOrder!.reference
            }`,
          }
        );
        if (error) {
          toast.error(error.message);
        } else {
          const res = await checkoutHandlerActions.handleCheckoutSource({
            payment_provider_id: paymentProvider.id,
            type: checkoutHandlerActions.CheckoutSourceTypes.stripe
              .payment_intent,
            reference: paymentIntent?.data.id,
          });
          handleCheckoutEnd({
            res,
            metadata: {
              "Checkout Order": checkoutOrder.reference,
              Items: checkoutOrder.items,
              currency: checkoutOrder.currency.code,
              amount: checkoutOrder.amount,
              "Payment Method": "Stripe Card",
              saved: true,
            },
          });
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }

      setLoading(false);
    }
  };
  useEffect(() => {
    if (!isLoadingInitialData && filteredData.length === 0) {
      replaceStepInHistory(NewCardPaymentStep.name);
    }
  }, [isLoadingInitialData, filteredData]);

  if (!isLoadingInitialData && filteredData.length === 0) {
    return null;
  }
  return (
    <ModalBodyElementContainer className="d-flex flex-column">
      {filteredData.length > 0 ? (
        <>
          {filteredData.map((item) => {
            return (
              <>
                <PaymentMethodItem
                  key={item.id}
                  Icon={item.icon_url["apple_style"]}
                  isSelected={selectedPaymentMethod === item.id}
                  onPress={() => setSelectedPaymentMethod(item.id)}
                  label={item.name}
                  description={item.description}
                />
              </>
            );
          })}
          <PaymentMethodItem
            Icon={`https:\/\/molhamteam.com\/assets\/icons\/payment-methods\/apple-style\/card.svg`}
            isSelected={selectedPaymentMethod === "new"}
            onPress={() => setSelectedPaymentMethod("new")}
            label={t("new_payment_method")}
            isLast
          />
        </>
      ) : null}

      <DonationModalFooterPortal id="modal-footer-portal-host">
        {selectedPaymentMethod ? (
          <PaymentSlidesFooterContainer>
            {selectedPaymentMethod === "new" ? (
              <MolhamButton
                id="donate-saved-next-btn"
                endIcon="fe-arrow-right"
                label={t(`next`)}
                className={`w-100`}
                onClick={handleNextSlide}
                disabled={loading}
                isLoading={loading}
              />
            ) : (
              <SubmitButton loading={loading} onSubmit={onSubmit} />
            )}
          </PaymentSlidesFooterContainer>
        ) : null}
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const SavedCardPaymentStep = {
  name: "SavedCardPaymentStep",
  title: SavedCardPaymentStepTitle,
  body: SavedCardPaymentStepBody,
  footer: true,
};
export default SavedCardPaymentStep;
