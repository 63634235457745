import { EducationSVG } from "../../components/MolhamSvgIcons/EducationSVG";
import { EventsSVG } from "../../components/MolhamSvgIcons/EventsSVG";
import { HumanitarianSVG } from "../../components/MolhamSvgIcons/HumanitarianSVG";
import { MedicalSVG } from "../../components/MolhamSvgIcons/MedicalSVG";
import { OrphansSVG } from "../../components/MolhamSvgIcons/OrphansSVG";
import { QuickSVG } from "../../components/MolhamSvgIcons/QuickSVG";
import { ResponseSVG } from "../../components/MolhamSvgIcons/ResponseSVG";
import { ShelterSVG } from "../../components/MolhamSvgIcons/ShelterSVG";
import { ZakatSVG } from "../../components/MolhamSvgIcons/ZakatSVG";
type Route = {
  icon: (props: any) => JSX.Element;
  path?: string;
};
export const donationCardsRoutes: Record<string, Route> = {
  cases: {
    icon: MedicalSVG,
    path: "/cases",
  },
  campaigns: {
    icon: ResponseSVG,
    path: "/campaigns",
  },

  general: {
    icon: HumanitarianSVG,
    path: "#quick_donate",
  },
  sponsorships: {
    icon: OrphansSVG,
    path: "/sponsorships",
  },
  medical: {
    icon: MedicalSVG,

    path: "/medical-cases",
  },
  orphans: {
    icon: OrphansSVG,

    path: "/orphan-sponsorships",
  },
  shelter: {
    icon: ShelterSVG,

    path: "/shelter",
  },
  humanitarian: {
    icon: HumanitarianSVG,

    path: "/humanitarian-cases",
  },

  response: {
    icon: HumanitarianSVG,

    path: "/response-campaigns",
  },
  education: {
    icon: EducationSVG,

    path: "/students-sponsorships",
  },
  zakat: {
    icon: ZakatSVG,

    path: "/zakat",
  },
  events: {
    icon: EventsSVG,

    path: "/events",
  },
  fundraisers: {
    icon: EventsSVG,

    path: "/fundraisers",
  },
  quick: {
    icon: QuickSVG,
  },
};
