import { yupResolver } from "@hookform/resolvers/yup";
import { NEXT_URL_PROXY } from "config";
import { useModeContext } from "context/ModeContext";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { isRTL } from "utils/isRtl";
import { postData } from "utils/postData";
import * as Yup from "yup";
import { AuthContext } from "../../../context/AuthContext";
import { isEmpty } from "../../../utils/isEmpty";
import MolhamButton from "../MolhamButton";
import MolhamInput from "../MolhamInput";
import checkoutActions from "../SharedDonatePages/DonateModal/actions/checkout";
import { useCheckoutStore } from "../SharedDonatePages/DonateModal/CheckoutState";

const GuestForm = ({ fromCheckoutId }: { fromCheckoutId?: number }) => {
  const { t } = useTranslation("common");
  const isMobile = useMobile("md");

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, t(`invalid_donor_name_length`))
      .required(t(`name_required`)),
    email: Yup.string().required(t(`email_required`)).email(t(`email_invalid`)),
  });

  const { handleSubmit, watch, formState, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });
  const [isConfirm, setIsConfirm] = useState(false);

  const { isDirty, errors } = formState;
  const { theme } = useModeContext();
  const { isLoading, login } = useContext(AuthContext);
  const [alrReg, setAlrReg] = useState(false);
  // const [captcha, setCaptcha] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string | null>("dsafsdaf");
  const { id, setCheckoutOrder } = useCheckoutStore((state) => ({
    id: state?.checkoutOrder?.id,
    setCheckoutOrder: state?.setCheckoutOrder,
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    const { email, name } = data;
    if (!captcha) {
      toast.error(t(`captcha_required`));
      return;
    }
    // await signup(email, password, name);
    const res = await checkoutActions.setGuest({
      email,
      name,
      id: id,
    });
    if (!res.ok) {
      if (res.error?.code == "guest_already_registered_donor") {
        setAlrReg(true);
        // toast.error(t("guest_already_registered_donor"));
        setLoading(false);

        return;
      }
      toast.error(res?.error?.message);
      setLoading(false);

      return;
    }

    setCheckoutOrder(res.data);
    setLoading(false);
    // setGuest(email);
  };

  const [loading, setLoading] = useState(false);
  const setCO = useCheckoutStore().setCheckoutOrder;
  const {
    handleSubmit: handleCodeSubmit,
    control: controlCode,
    formState: { errors: codeErrors },
  } = useForm();
  const onLoginSubmit = async (data: { email: string }) => {
    setLoading(true);
    try {
      await postData(
        `${NEXT_URL_PROXY}/donors/send-one-time-password`,
        data,
        null,
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      ); // TODO: FIX DIS THING

      // setHideModal(true);
      // toast.success(t("code_sent"));
      setIsConfirm(true);

      // router.reload();
    } catch (err) {
      toast.error(err.message);
    }

    setLoading(false);
  };

  const onCodeSubmit = async (data) => {
    setLoading(true);

    try {
      await login({ ...data, email: watch("email") });
      if (fromCheckoutId) {
        const res = await checkoutActions.getCheckoutOrder({
          idOrReference: fromCheckoutId,
        });

        if (res.ok) {
          setCO(res.data);
        }
      }
      toast.success(t("email_verified"));
    } catch (err) {
      toast.error(err.message);
    }

    setLoading(false);
  };

  const onCodeStepComplete = async () => {
    handleCodeSubmit(onCodeSubmit)();
  };

  const onload = () => {
    const doc = document.getElementById("captcha-parent")?.childNodes[0]
      .childNodes[0].childNodes[0] as HTMLDivElement;

    doc.style.width = "302px";
  };
  return (
    <>
      {!isConfirm ? (
        <div className="w-100 d-flex flex-column align-items-center">
          <p
            className={`${
              isMobile ? "fs-md" : "fs-lg"
            } fw-normal text-center mb-4`}
          >
            {t(`continue_as_guest_desc`)}
          </p>
          <form
            className={`w-100 needs-validation ${
              isEmpty(errors) || !isEmpty(errors) ? "invalid" : ""
            }`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <MolhamInput
              control={control}
              watch={watch}
              name="name"
              // label={t(`full_name`)}
              placeholder={t(`full_name`)}
              type="ig"
              icon="ai-user"
            />

            <MolhamInput
              control={control}
              watch={watch}
              name="email"
              customOnChange={(e) => {
                setValue("email", e.target.value);
                setAlrReg(false);
              }}
              placeholder={t(`email`)}
              type="email"
            />

            {/* <div className="mb-3 centered" id="captcha-parent">
              <ReCAPTCHA
                sitekey={"6LcFR8MpAAAAALLLZOkuImCcZ_zcU260rSz-kj4I"}
                onChange={(v) => setCaptcha(v)}
                onErrored={() => setCaptcha(null)}
                onExpired={() => setCaptcha(null)}
                theme={theme === "dark" ? "dark" : "light"}
                asyncScriptOnLoad={onload}
                style={{
                  width: 302,
                  height: 76,
                  borderRadius: 3,
                  overflow: "hidden",
                }}
              />
            </div> */}

            {alrReg ? (
              <div className="">
                <p className="text-center text-info">
                  {t(`guest_already_registered_donor`)}
                </p>
                <MolhamButton
                  id="signup-btn"
                  label={t(`send_code`)}
                  isLoading={loading}
                  startIcon="fe-key"
                  disabled={!isDirty || loading}
                  className=" w-100"
                  onClick={() => {
                    onLoginSubmit({ email: watch("email") });
                  }}
                />
              </div>
            ) : (
              <MolhamButton
                id="signup-btn"
                label={t(`continue`)}
                isLoading={isLoading || loading}
                endIcon="fe-arrow-right"
                disabled={!isDirty}
                className=" w-100"
                type="submit"
              />
            )}
          </form>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center w-100">
          <form
            className={`w-100 needs-validation ${
              !isEmpty(codeErrors) ? "invalid" : ""
            }`}
            onSubmit={handleCodeSubmit(onCodeSubmit)}
          >
            <div className="d-flex flex-column align-items-center w-100">
              <div
                className="text-center p-4 bg-secondary mb-4
"
                style={{ width: "fit-content", borderRadius: "50%" }}
              >
                <i
                  className="ai-mail text-primary "
                  style={{ fontSize: 80, padding: 12 }}
                ></i>
              </div>
              <p className="h3 mb-4  text-center">{t("email_verification")}</p>
              <p className={`fs-md mb-4 text-center`}>
                {t("email_verification_description")}
              </p>
              <p
                className={`h4 ${
                  isMobile ? "fs-md" : "fs-lg"
                } mb-4 text-center`}
              >
                {watch("email")}
              </p>
              <Controller
                name="one_time_password"
                control={controlCode}
                rules={{
                  required: t(`code_required`),
                }}
                render={({ field }) => (
                  <OTPInput
                    numInputs={6}
                    inputType="number"
                    onChange={(otp) => {
                      field.onChange(otp);
                      if (otp.length == 6) onCodeStepComplete();
                    }}
                    containerStyle={{
                      direction: "ltr",
                    }}
                    value={field.value}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          disabled={isLoading}
                          style={{
                            width: "40px",
                            height: "40px",
                            margin: "0rem 5px 1rem 5px",
                            padding: isRTL ? "6px 0px 6px 0px" : 0,
                            color: theme == "light" ? "#000" : "#fff",
                          }}
                          className="form-control-num text-center form-control-active form-control-bg"
                        />
                      );
                    }}
                  />
                )}
              />

              {codeErrors.code?.message && (
                <div className="invalid-feedback text-center ">
                  {/* @ts-ignore */}
                  {codeErrors.code?.message}
                </div>
              )}
            </div>
          </form>
          <MolhamButton
            id="verification-resend-code-btn"
            label={t("resend_verification_code")}
            className="w-100 text-primary"
            variant={null}
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      )}
    </>
  );
};

export default GuestForm;
