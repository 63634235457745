import { TNotificationsItem } from "apiTypes";
import useCustomSWR from "customHooks/useCustomSWR";
import { createContext } from "react";
import useModal from "../customHooks/useModal";
import { useAuthContext } from "./AuthContext";

type TNotificationContext = {
  showNotificationOffCanvas: boolean;
  handleCloseNotificationOffCanvas: () => void;
  handleShowNotificationOffCanvas: () => void;
  notifications: TNotificationsItem[];
};

const NotificationContext = createContext<TNotificationContext>(null);

const NotificationProvider = ({ children }) => {
  const { donor } = useAuthContext();
  const { data: notifications } = useCustomSWR(`auth/notifications`, {
    revalidateOnFocus: !!donor,
    revalidateOnMount: !!donor,
    revalidateIfStale: !!donor,
  });
  const {
    showModal: showNotificationOffCanvas,
    handleCloseModal: handleCloseNotificationOffCanvas,
    handleShowModal: handleShowNotificationOffCanvas,
  } = useModal();

  return (
    <NotificationContext.Provider
      value={{
        showNotificationOffCanvas,
        handleCloseNotificationOffCanvas,
        handleShowNotificationOffCanvas,
        notifications: (notifications?.data as TNotificationsItem[]) || [],
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
