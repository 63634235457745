import axiosInstance, {
  MolhamError,
  MolhamResponse,
  MolhamResult,
} from "../axiosInstance";

const generateClientToken = async (): Promise<
  MolhamResult<{ client_code: string }>
> => {
  try {
    const res = await axiosInstance.post(
      "/payment-providers/braintree/client-token"
    );
    return res as MolhamResponse<{ client_code: string }>;
  } catch (err: any) {
    return err as MolhamError;
  }
};

const createTransaction = async (
  data:
    | {
        braintree_payment_method_nonce: string;
        save_payment_method_for_later?: boolean;
        checkout_order_reference: string | number;
        device_data?: string;
      }
    | {
        saved_payment_method_id: string | number;
        checkout_order_reference: string | number;
        device_data?: string;
      }
): Promise<MolhamResult<{ id: string }>> => {
  try {
    const res = await axiosInstance.post<{ id: string }>(
      "/payment-providers/braintree/transactions",
      data
    );
    return res as MolhamResponse<{ id: string }>;
  } catch (err: any) {
    return err as MolhamError;
  }
};
const createPaymentUrl = async (data: {
  checkout_order_reference: string;
  return_url: string;
  cancel_uri?: string;
  payment_provider_id: string;
}): Promise<
  MolhamResult<{
    url: string;
  }>
> => {
  try {
    const res = await axiosInstance.post(
      `https://checkout.molhamteam.com/${data.checkout_order_reference}/braintree-card`,
      data
    );
    return res as MolhamResponse<{ url: string }>;
  } catch (err: any) {
    return err as MolhamError;
  }
};
const braintreeActions = {
  generateClientToken,
  createTransaction,
  createPaymentUrl,
};
export default braintreeActions;
