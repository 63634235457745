import { PropsWithChildren, RefAttributes } from "react";
import { Offcanvas, OffcanvasHeaderProps } from "react-bootstrap";
import CloseBtn from "./ReuseableCompos/CloseBtn";
type Props = {
  onCloseBtnClick?: () => void;
  customHeaderClassName?: string;
  showButton?: boolean;
  centered?: boolean;
  customButton?: React.ReactNode;
} & OffcanvasHeaderProps &
  RefAttributes<HTMLDivElement>;
const CustomOffCanvasHeader: React.FC<PropsWithChildren<Props>> = ({
  children,
  onCloseBtnClick,
  customHeaderClassName = "",
  showButton,
  customButton,
  centered,
  ...props
}) => {
  return (
    <Offcanvas.Header
      {...props}
      className={
        customHeaderClassName !== ""
          ? customHeaderClassName
          : "offcanvas-header-shadow dark-border-bottom px-3 d-flex"
      }
    >
      {centered &&
        (showButton ? (
          <div style={{ minWidth: 35 }}>{customButton}</div>
        ) : (
          <div />
        ))}
      <Offcanvas.Title className="mb-0">{children}</Offcanvas.Title>
      {showButton && <CloseBtn closeModal={onCloseBtnClick} />}
      {centered && !showButton && <div />}
    </Offcanvas.Header>
  );
};

export default CustomOffCanvasHeader;
