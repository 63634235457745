import { TNotificationsItem } from "apiTypes";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import CustomOffCanvasHeader from "./CustomOffCanvasHeader";
import CustomOffcanvas from "./CustomOffcanvas";
import CustomOffcanvasBody from "./CustomOffcanvasBody";
import NotificationCards from "./NotificationCards";
import OffCanvasFooter from "./OffCanvasFooter";
import MolhamButton from "./ReuseableCompos/MolhamButton";

type Props = {
  showNotificationOffCanvas: boolean;
  handleCloseNotificationOffCanvas: () => void;
  notifications: TNotificationsItem[];
};

const NotificationOffCanvas = ({
  showNotificationOffCanvas,
  handleCloseNotificationOffCanvas,
  notifications,
}: Props) => {
  const { t } = useTranslation("common");
  const matches = useMobile("lg");

  return (
    <CustomOffcanvas
      show={showNotificationOffCanvas}
      onHide={handleCloseNotificationOffCanvas}
      placement={matches ? "bottom" : "end"}
    >
      <CustomOffCanvasHeader
        showButton
        onCloseBtnClick={handleCloseNotificationOffCanvas}
      >
        {t(`notifications`)}
      </CustomOffCanvasHeader>
      <CustomOffcanvasBody id={"scrollableDiv"} className="px-0">
        <NotificationCards
          handleCloseNotificationOffCanvas={handleCloseNotificationOffCanvas}
        />
      </CustomOffcanvasBody>
      {notifications.length > 0 ? (
        <OffCanvasFooter customClassName="border-bottom-0">
          <MolhamButton
            id="show-all-notifications-btn"
            label={t(`show_all_notifications`)}
            className="w-100"
            onClick={handleCloseNotificationOffCanvas}
            endIcon="fe-arrow-right"
            hrefClassName="w-100"
            href={"/profile/notifications"}
          />
        </OffCanvasFooter>
      ) : null}
    </CustomOffcanvas>
  );
};

export default NotificationOffCanvas;
