import useTranslation from "next-translate/useTranslation";

import { useRouter } from "next/router";
import { CiCreditCard1 } from "react-icons/ci";
import { FaDollarSign, FaEuroSign, FaPoundSign } from "react-icons/fa";
import { usePreferenceContext } from "../../context/PreferencesContext";
import StyledNavbarDropdown from "./StyledNavbarDropdown";
type Props = {
  className?: string;
  offcanvas?: boolean;
};
const CurrencyDropdown = ({ className, offcanvas }: Props) => {
  const { locale } = useRouter();
  const { curr, currencySelectModal } = usePreferenceContext();
  const { t } = useTranslation("common");

  const currName = curr?.name?.[locale]
    ? t(`${curr?.name?.[locale]}`)
    : curr?.name?.[locale];
  return (
    <StyledNavbarDropdown
      className="d-md-flex align-items-center text-nowrap"
      onClick={currencySelectModal.handleShowModal}
    >
      {offcanvas ? (
        <span
          className={`d-flex flex-column justify-content-center align-items-center  ${
            className || ""
          }`}
        >
          <CurrSymbol code={curr?.code.toUpperCase()} />
          <span className=" d-inline-block fs-sm word-break mt-1">
            {curr?.code.toUpperCase()}
          </span>
        </span>
      ) : !currName ? (
        <></>
      ) : (
        <button type="button" className={"btn"} style={{ paddingInlineEnd: 0 }}>
          {curr?.symbol ? (
            <span className="d-none d-sm-inline-block">
              {"("}
              <span className="fw-bold">{curr?.symbol}</span>
              {")"}

              {" " + currName}
            </span>
          ) : (
            <span className="d-none d-sm-inline-block">
              {"(" + curr?.code.toUpperCase() + ") " + currName}
            </span>
          )}

          <span className="d-inline-block d-sm-none">{currName}</span>
        </button>
      )}
    </StyledNavbarDropdown>
  );
};

export default CurrencyDropdown;

const CurrSymbol = ({ code }) => {
  switch (code) {
    case "USD":
      return <FaDollarSign size={25} />;
    case "EUR":
      return <FaEuroSign size={25} />;
    case "GBP":
      return <FaPoundSign size={25} />;
    default:
      return <CiCreditCard1 size={25} />;
  }
};
