import InfiniteScroll from "react-infinite-scroll-component";

import useMobile from "customHooks/useMobile";
import SmallSpinner from "./SmallSpinner";
export type CustomInfiniteScrollProps = {
  dataLength: number;
  getMoreData: React.ComponentProps<typeof InfiniteScroll>["next"];
  pageCount?: number;
  customLoader?: React.ComponentProps<typeof InfiniteScroll>["loader"];
  no100vh?: boolean;
  scrollThreshold?: number;
  dontMobile?: boolean;
  customHeight?: string | number;
} & Omit<React.ComponentProps<typeof InfiniteScroll>, "loader" | "next">;
const CustomInfinitScroll = ({
  dataLength,
  getMoreData,
  pageCount = 1,
  children,
  customLoader = false,
  no100vh = false,
  scrollThreshold,
  customHeight,
  dontMobile = false,
  ...props
}: CustomInfiniteScrollProps) => {
  const isMobile = useMobile("md");
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={getMoreData}
      {...props}
      scrollThreshold={scrollThreshold || 0.8}
      scrollableTarget="scrollableDiv"
      style={
        isMobile && !dontMobile
          ? {
              scrollSnapType: "y mandatory",
              height: no100vh ? "unset" : "100vh",
              minHeight: customHeight || "unset",
              overflowY: "scroll",
              overflowX: "hidden",
              scrollBehavior: "smooth",
            }
          : {
              overflow: "hidden",
              minHeight: customHeight || "unset",
            }
      }
      loader={
        customLoader || (
          <div
            className={`d-flex justify-content-center w-100 my-2 align-items-center overflow-none`}
          >
            <SmallSpinner isLoading={true} />{" "}
          </div>
        )
      }
    >
      {children}
    </InfiniteScroll>
  );
};

export default CustomInfinitScroll;
