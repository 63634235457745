import Script from "next/script";

const ClarityScript = () => {
  return (
    <Script strategy="afterInteractive" id="molham-clarity">
      {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "k1akec8n01");`}
    </Script>
  );
};
export default ClarityScript;

export const getClarity = () => {
  if (
    typeof window !== "undefined" &&
    "clarity" in window &&
    typeof window.clarity === "function"
  ) {
    return window.clarity;
  } else {
    return null;
  }
};
