import { TCartItem } from "apiTypes/";
import useCustomSWR from "customHooks/useCustomSWR";
import { useRouter } from "next/router";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { KeyedMutator } from "swr";
import useModal from "../customHooks/useModal";
import { useAuthContext } from "./AuthContext";
import { AuthModalContext } from "./AuthModalContext";
type CartProviderData = {
  carts: TCartItem[];
  showCartOffCanvas: boolean;
  handleCloseCartOffCanavas: () => void;
  handleShowCartOffCanvas: () => void;
  mutateCart: KeyedMutator<TCartItem[]>;
};
const CartContext = createContext<CartProviderData>(null);

const CartProvider = ({ children }: PropsWithChildren) => {
  const { donor, finishedLoadingOnce } = useAuthContext();
  const { data: carts, mutate: mutateCart } = useCustomSWR<TCartItem[]>(
    `auth/cart`,
    {
      revalidateOnFocus: !!donor,
      revalidateOnMount: !!donor,
      revalidateIfStale: !!donor,
    }
  );
  const {
    showModal: showCartOffCanvas,
    handleCloseModal: handleCloseCartOffCanavas,
    handleShowModal: handleShowCartOffCanvas,
  } = useModal();
  const router = useRouter();
  //monitor donor change and mutate cart
  useEffect(() => {
    if (donor) {
      mutateCart();
    }
  }, [donor]);
  useEffect(() => {
    router.events.on("routeChangeStart", handleCloseCartOffCanavas);
    return () => {
      router.events.off("routeChangeStart", handleCloseCartOffCanavas);
    };
  }, []);
  const { handleShowAuthModal } = useContext(AuthModalContext);
  useEffect(() => {
    const handler = () => {
      const url = window.location.href;
      const cartURL = new URL(url).searchParams.get("view_cart");
      if (cartURL) {
        if (!donor && finishedLoadingOnce) {
          router.replace(router.pathname, undefined, {
            shallow: true,
          });
          handleShowAuthModal();
        } else if (donor && finishedLoadingOnce) {
          handleShowCartOffCanvas();
        }
      }
    };
    handler();
  }, [donor, finishedLoadingOnce, router]);
  const handleCloseCart = () => {
    const url = window.location.href;

    const cartURL = new URL(url).searchParams.get("view_cart");
    if (cartURL) {
      router.replace(router.pathname, undefined, {
        shallow: true,
      });
    }
    handleCloseCartOffCanavas();
  };
  const value = useMemo(() => {
    return {
      carts: carts || [],
      showCartOffCanvas,
      handleCloseCartOffCanavas: handleCloseCart,
      handleShowCartOffCanvas,
      mutateCart,
    };
  }, [carts, showCartOffCanvas]);
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
export function useCartContext(): CartProviderData {
  const cartData = useContext(CartContext);
  if (cartData == null) {
    throw new Error(
      "CartContext is not available. Make sure you are rendering the component within the CartProvider."
    );
  }
  return cartData;
}
export { CartContext, CartProvider };
