import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import { ReviewModalContext } from "context/ReviewModalContext";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import ReviewAnim from "../../public/lottie/motivation1.json";
import SmileEmoji from "../../public/lottie/motivation2.json";
import MolhamButton from "./MolhamButton";
import checkoutActions from "./SharedDonatePages/DonateModal/actions/checkout";
const Lottie = dynamic(() => import("lottie-react"), {});

const MotivationModal = () => {
  const {
    handleCloseReviewModal,
    showReviewModal,
    motivationId,
    setMotivationId,
  } = useContext(ReviewModalContext);
  const onModalHide = () => {
    handleCloseReviewModal();
    setMotivationId(null);
  };

  const { t } = useTranslation("common");
  const matches = useMobile("lg");
  const [details, setDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const onSubmitReview = async () => {
    setIsLoading(true);
    try {
      if (motivationId) {
        const resPromise = checkoutActions.sendDonationMotivation({
          id: motivationId,
          message: details,
        });
        const res = await resPromise;
        if (res.ok === true) {
          toast.success(t("sent_successfully"));
          setIsLoading(false);
          setDetails("");
          setDone(true);
          setTimeout(() => {
            setDone(false);
            handleCloseReviewModal();
          }, 10000);
        } else {
          toast.error(res.error.message);
          // navigateToSlide('teamSupport');
        }
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MolhamModalOffcanvas
      show={showReviewModal === "motivation"}
      onHide={onModalHide}
      header={{ label: "motivation_modal_header", borderRadius: false }}
      fullscreen
      body={{ className: "p-0" }}
      swipeToClose={false}
    >
      <div
        className="mx-4 pb-4 pb-md-0 d-flex flex-column justify-content-center align-items-center h-100
      "
      >
        {!done ? (
          <>
            <Lottie
              className=" w-50 mb-3"
              animationData={ReviewAnim}
              loop
              style={{ maxWidth: matches ? 300 : 400 }}
            />
            <p className="text-center mb-3 px-2 fs-lg">
              {t(`motivation_label`)}
            </p>

            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <textarea
                className="form-control mb-3"
                placeholder={t(`motivation_placeholder`)}
                rows={3}
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
              <MolhamButton
                id="review-submit-btn"
                onClick={onSubmitReview}
                label={t(`send`)}
                className={"mb-3 w-100"}
                isLoading={isLoading}
              />
            </div>

            <p className="fs-md mb-3 text-center">
              <div
                onClick={() => {
                  handleCloseReviewModal();
                }}
                className="fw-medium cursor text-decoration-none text-primary"
              >
                {t(`not_now_label`)}
              </div>
            </p>
          </>
        ) : (
          <>
            <Lottie
              className=" w-50 mb-3"
              animationData={SmileEmoji}
              loop
              style={{ maxWidth: matches ? 300 : 400 }}
            />
            <p className="text-center">{t(`sent_motivation_message`)}</p>
            <MolhamButton
              id="review-close-btn"
              onClick={() => {
                setDone(false);
                handleCloseReviewModal();
              }}
              label={t(`close_this_window`)}
              className={"mb-3 w-100"}
            />
          </>
        )}
      </div>
    </MolhamModalOffcanvas>
  );
};

export default MotivationModal;
