import { PropsWithChildren } from "react";
import { Offcanvas, OffcanvasProps } from "react-bootstrap";
import useRTL from "../customHooks/useRTL";
export type CustomOffcanvasProps = PropsWithChildren<
  {
    extraStyles?: React.CSSProperties;
  } & OffcanvasProps
>;
const CustomOffcanvas: React.FC<CustomOffcanvasProps> = ({
  placement,
  extraStyles,
  ...props
}) => {
  const isRTL = useRTL();
  return (
    <Offcanvas
      placement={placement ? placement : isRTL ? "end" : "start"}
      scroll={false}
      {...props}
      style={{
        // paddingBottom: placement === "bottom" ? 8 : 0,
        ...extraStyles,
      }}
    >
      {props.children}
    </Offcanvas>
  );
};

export default CustomOffcanvas;
