import { useCartContext } from "context/CartContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useMobile from "customHooks/useMobile";
import useModal from "customHooks/useModal";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import { sendEvent } from "utils/sendEvent";
import { getTotal } from "../utils/gettingTotal";
import CartItem from "./CartItem";
import CustomOffcanvas from "./CustomOffcanvas";
import CustomOffcanvasBody from "./CustomOffcanvasBody";
import CustomOffCanvasHeader from "./CustomOffCanvasHeader";
import OffCanvasFooter from "./OffCanvasFooter";
import ButtonfulModal from "./ReuseableCompos/ButtonfulModal";
import CloseBtn from "./ReuseableCompos/CloseBtn";
import MolhamButton from "./ReuseableCompos/MolhamButton";
import NoData from "./ReuseableCompos/NoData";
import {
  resetCheckoutStore,
  useCheckoutStore,
} from "./ReuseableCompos/SharedDonatePages/DonateModal/CheckoutState";
import { StyledCheckoutModal } from "./ReuseableCompos/SharedDonatePages/DonateModal/DonateModalWrapper";
import ConfirmationStep from "./ReuseableCompos/SharedDonatePages/DonateModal/Steps/Confirmation/ConfirmationStep";
import TeamSupportStep from "./ReuseableCompos/SharedDonatePages/DonateModal/Steps/TeamSupport/TeamSupportStep";

import cartActions from "./ReuseableCompos/SharedDonatePages/DonateModal/actions/cart";
import ModalBodyElementContainer from "./ReuseableCompos/SharedDonatePages/DonateModal/components/ModalBodyElementContainer";
import ProviderFeeStep from "./ReuseableCompos/SharedDonatePages/DonateModal/Steps/ProviderFee/ProviderFeeStep";
import SavedShippingAddressStep from "./ReuseableCompos/SharedDonatePages/DonateModal/Steps/ShippingAddress/SavedShippingAddressStep";
import MolhamModalOffcanvas from "./MolhamModalOffCanvas";
import MolhamOffcanvas from "./MolhamOffcanvas";

const CartOffCanvas = () => {
  const { carts, handleCloseCartOffCanavas, showCartOffCanvas, mutateCart } =
    useCartContext();
  const onDeleteBtnClick = async (id: number) => {
    const filteredItems = carts.filter((item) => item.id !== id);
    mutateCart(filteredItems, { revalidate: false });
    await cartActions.deleteCartItem({ id });
    mutateCart(filteredItems);
  };
  const router = useRouter();
  const { formatNumbers } = useFormatNumbers();
  const {
    showModal: showActionSheet,
    handleCloseModal: handleCloseActionSheet,
    handleShowModal: handleShowActionSheet,
  } = useModal();
  const [selectedId, setSelectedId] = useState(null);
  const onMenuClick = (id: number) => {
    setSelectedId(id);
    handleShowActionSheet();
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { addStepToHistory, setModalVisible, setCheckoutOrder } =
    useCheckoutStore((state) => ({
      addStepToHistory: state.addStepToHistory,
      setModalVisible: state.setModalVisible,
      setCheckoutOrder: state.setCheckoutOrder,
    }));
  const onCheckoutBtnClick = async () => {
    setLoadingSubmit(true);
    try {
      const res = await cartActions.createCartCheckoutOrder();
      if (res.ok) {
        await sendEvent({
          pixel: "InitiateCheckout",
        });
        resetCheckoutStore();
        setCheckoutOrder(res.data);
        if (res.data.team_support_suggested) {
          addStepToHistory(TeamSupportStep.name);
        } else if (res.data.payment_provider_fees_checkout_suggestion) {
          addStepToHistory(ProviderFeeStep.name);
        } else if (res.data.shipping_address_required) {
          addStepToHistory(SavedShippingAddressStep.name);
        } else {
          addStepToHistory(ConfirmationStep.name);
        }
        setModalVisible(true);
        handleCloseCartOffCanavas();
      }
    } catch (error) {
      setLoadingSubmit(false);
      toast.error(error.message);
    }
    setLoadingSubmit(false);
  };
  const { t } = useTranslation("common");

  const isMobile = useMobile("md");

  const cartNotEmpty = getTotal(carts) != 0;

  return (
    <>
    <MolhamOffcanvas
      show={showCartOffCanvas}
      onHide={handleCloseCartOffCanavas}
      body={{className:"p-0 position-relative",style:{padding:"0"}}}
      header={{borderRadius:false,label:t("cart"),}}
      fullscreen={isMobile}
      swipeToClose={false}
      placement="end"

      >
                    <Modal.Body className="p-0">
              {cartNotEmpty && (
                <div className="">
                  {carts?.map((data, index) => (
                    <div
                      style={{
                        paddingTop: index === 0 ? "0.5rem" : 0,
                        paddingInlineEnd: "0.35rem", // align with close btn
                      }}
                      key={index}
                    >
                      <CartItem
                        data={data}
                        key={data.id}
                        onMenuClick={onMenuClick}
                        isLast={index === carts.length - 1}
                      />
                    </div>
                  ))}
                </div>
              )}
              {carts.length <= 0 && <NoData isOffcanvas />}
            </Modal.Body>
            {cartNotEmpty && (
              <OffCanvasFooter className="p-0 border-top-0 w-100" style={{
                position:'absolute',
                bottom:0,

              }}>
                <ModalBodyElementContainer
                  style={{
                    width: "100%",
                    borderTop: "1px solid var(--light-gray)",
                    padding: 14,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 14,
                      fontWeight: "bold",
                    }}
                  >
                    <span>{t(`total_amount`)}:</span>
                    <span className="h6 mb-0">
                      {formatNumbers(getTotal(carts))}
                    </span>
                  </div>

                  <MolhamButton
                    id="checkout-cart-btn"
                    label={t(`proceed_to_checkout`)}
                    className="w-100"
                    disabled={loadingSubmit}
                    isLoading={loadingSubmit}
                    onClick={onCheckoutBtnClick}
                    startIcon="fe-credit-card"
                  />
                </ModalBodyElementContainer>
              </OffCanvasFooter>
            )}
      </MolhamOffcanvas>
      {/* {isMobile ? (
        <>
          <StyledCheckoutModal
            show={showCartOffCanvas}
            onHide={handleCloseCartOffCanavas}
            scrollable
            fullscreen
          >
            <Modal.Header>
              {isMobile && <div style={{ width: 35 }} />}
              <span className="modal-title h5">{t(`cart`)}</span>
              <CloseBtn closeModal={handleCloseCartOffCanavas} />
            </Modal.Header>

            <Modal.Body className="p-0">
              {cartNotEmpty && (
                <div className="">
                  {carts?.map((data, index) => (
                    <div
                      style={{
                        paddingTop: index === 0 ? "0.5rem" : 0,
                        paddingInlineEnd: "0.35rem", // align with close btn
                      }}
                      key={index}
                    >
                      <CartItem
                        data={data}
                        key={data.id}
                        onMenuClick={onMenuClick}
                        isLast={index === carts.length - 1}
                      />
                    </div>
                  ))}
                </div>
              )}
              {carts.length <= 0 && <NoData isOffcanvas />}
            </Modal.Body>
            {cartNotEmpty && (
              <Modal.Footer className="p-0 border-top-0">
                <ModalBodyElementContainer
                  style={{
                    width: "100%",
                    borderTop: "1px solid var(--light-gray)",
                    padding: 14,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 14,
                      fontWeight: "bold",
                    }}
                  >
                    <span>{t(`total_amount`)}:</span>
                    <span className="h6 mb-0">
                      {formatNumbers(getTotal(carts))}
                    </span>
                  </div>

                  <MolhamButton
                    id="checkout-cart-btn"
                    label={t(`proceed_to_checkout`)}
                    className="w-100"
                    disabled={loadingSubmit}
                    isLoading={loadingSubmit}
                    onClick={onCheckoutBtnClick}
                    startIcon="fe-credit-card"
                  />
                </ModalBodyElementContainer>
              </Modal.Footer>
            )}
          </StyledCheckoutModal>
        </>
      ) : (
        <CustomOffcanvas
          show={showCartOffCanvas}
          onHide={handleCloseCartOffCanavas}
        >
          <CustomOffCanvasHeader
            showButton
            onCloseBtnClick={handleCloseCartOffCanavas}
          >
            {t(`cart`)}
          </CustomOffCanvasHeader>
          <CustomOffcanvasBody className="p-0">
            {carts.length <= 0 && <NoData isOffcanvas />}
            {cartNotEmpty && (
              <div>
                {carts?.map((data, index) => (
                  <div
                    style={{ paddingTop: index === 0 ? "0.5rem" : 0 }}
                    key={index}
                  >
                    <CartItem
                      data={data}
                      key={data.id}
                      onMenuClick={onMenuClick}
                      isLast={index === carts.length - 1}
                    />
                  </div>
                ))}
              </div>
            )}
          </CustomOffcanvasBody>

          {cartNotEmpty && (
            <OffCanvasFooter customClassName="border-bottom-0">
              <div className="d-flex justify-content-between mb-4">
                <span>{t(`total`)}:</span>
                <span className="h6 mb-0">
                  {formatNumbers(getTotal(carts))}
                </span>
              </div>
              <MolhamButton
                id="checkout-cart-btn"
                label={t(`checkout`)}
                className="w-100"
                disabled={loadingSubmit}
                isLoading={loadingSubmit}
                onClick={onCheckoutBtnClick}
                startIcon="fe-credit-card"
              />
            </OffCanvasFooter>
          )}
        </CustomOffcanvas>
      )} */}
      <ButtonfulModal
        handleCloseModal={handleCloseActionSheet}
        showModal={showActionSheet}
        buttons={[
          [
            {
              isLoading: false,

              text: `${t("view")} ${
                carts.find((item) => item.id === selectedId)?.short_name
              }`,
              color: "primary",
              onClick: () => {
                handleCloseActionSheet();
                handleCloseCartOffCanavas();
                const path = carts.find((item) => item.id === selectedId)?.path
                  .web;
                const normalizedPath = path.startsWith("/") ? path : `/${path}`;
                router.push(normalizedPath);
              },
              enabled:
                carts.find((item) => item.id === selectedId)?.path?.web !==
                undefined,
            },
            {
              isLoading: false,

              text: t(`remove_cart_item`),
              color: "danger",
              onClick: () => {
                onDeleteBtnClick(selectedId);
                handleCloseActionSheet();
              },
            },
          ],
          [
            {
              text: t(`cancel`),
              color: "primary",
              fontWeight: "bold",
              isLoading: false,
            },
          ],
        ]}
      />
    </>
  );
};

export default CartOffCanvas;
