import { fromNow } from "utils/fromNow";

type Props = {
  date: string | Date;
  className?: string;
  longFormat?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;
const FormattedDate = ({
  date,
  className,
  longFormat = false,
  ...props
}: Props) => {
  return (
    <div className={`fs-xs text-muted fw-bold ${className || ""}`} {...props}>
      <span>{fromNow(date)}</span>
    </div>
  );
};

export default FormattedDate;
