import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import notFound from "../../public/lottie/notFound.json";
import MolhamButton from "./MolhamButton";

const Lottie = dynamic(() => import("lottie-react"));
type Props = {
  error?: string;
  label?: string;
  lottieFile?: any;
  isOffcanvas?: boolean;
  additionalClassName?: string;
  actionBtn?: {
    label: string;
    icon?: string;
    onClick: () => void;
  };
};
const NoData: React.FC<Props> = ({
  error,
  label = "no_result",
  lottieFile = null,
  additionalClassName = "",
  isOffcanvas = false,
  actionBtn,
}) => {
  const { t } = useTranslation("common");

  return (
    <div
      className={`d-flex justify-content-center align-item-center flex-column h-100 ${
        isOffcanvas ? "" : "my-5"
      } ${additionalClassName}`}
    >
      <p
        className={`text-center text-capitalize text-muted fs-lg m${
          isOffcanvas ? "y" : "b"
        }-5`}
      >
        {label ? t(label) : t("no_result")}
      </p>
      <Lottie
        className="m-auto"
        style={{ width: 300 }}
        animationData={lottieFile || notFound}
        loop={true}
        title={error}
      />
      {error && <h6 className="text-center">{error}</h6>}
      {actionBtn && (
        <div className="w-100 d-flex">
          <MolhamButton
            id={`nodata-${actionBtn.label}-btn`}
            label={t(actionBtn.label)}
            className="text-capitalize mb-2 m-auto"
            onClick={actionBtn.onClick}
            startIcon={actionBtn.icon && `fe-${actionBtn.icon}`}
          />
        </div>
      )}
    </div>
  );
};

export default NoData;
