import { useCartContext } from "context/CartContext";
import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useTranslation from "next-translate/useTranslation";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../CheckoutState";
import { baseOnAddToCart, baseOnProceedToCheckout } from "./common";
import DonationAmountFooter from "./components/DonationAmountFooter";

type Props = {};

const FixedAmountOption: React.FC<Props> = ({}) => {
  const selectedPaymentOption = useCheckoutStore(
    (state) => state.selectedPaymentOption
  );
  const { formatNumbers } = useFormatNumbers();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;
  const { curr } = usePreferenceContext();
  const setModalVisible = useCheckoutStore((state) => state.setModalVisible);
  const { t } = useTranslation("common");
  const { mutateCart } = useCartContext();
  const onAddToCart = baseOnAddToCart({
    onSuccess() {
      mutateCart();
      setModalVisible(false);
      //handleShowCartOffCanvas
      toast.success(t("item_added_to_cart"));
    },
  });
  const onProceedToCheckout = baseOnProceedToCheckout({
    onSuccess() {},

    onFail() {},
  });
  return (
    <div>
      <div className="d-flex flex-column align-items-center justify-content-center w-100">
        <div className="mb-1 ">{selectedPaymentOption.name}</div>
        <div className="mb-1 text-muted text-center">
          {selectedPaymentOption.description}
        </div>
        <div>
          {`(${formatNumbers(
            selectedPaymentOption.amount[currencyCode || curr.code],
            currencyCode || curr.code
          )})`}
        </div>
      </div>
      {/* <Separator  style={[styles.mb,{width:dim.width}]} /> */}
      {/* <Divider className="mb-4" /> */}
      <DonationAmountFooter
        amount={Number(selectedPaymentOption.amount[currencyCode || curr.code])}
        onAddToCart={() =>
          onAddToCart({
            amount:
              selectedPaymentOption.amount[
                currencyCode || curr.code
              ].toString(),
          })
        }
        onProceedToCheckout={() =>
          onProceedToCheckout({
            amount:
              selectedPaymentOption.amount[
                currencyCode || curr.code
              ].toString(),
            currency_code: currencyCode || curr.code,
          })
        }
      />
    </div>
  );
};

export default FixedAmountOption;
