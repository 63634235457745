import { getMixpanel } from "config/mixpanel";
import { getAnalytics, logEvent } from "firebase/analytics";

export const sendEvent = async (
  eventName: {
    firebase?: string;
    pixel?: string;
    mixpanel?: string;
    twitter?: string;
  },
  data?: any
) => {
  const firebaseAnalytics = getAnalytics();
  if (firebaseAnalytics && eventName.firebase) {
    if (data) {
      logEvent(firebaseAnalytics, eventName.firebase, data);
    } else {
      logEvent(firebaseAnalytics, eventName.firebase);
    }
  }
  if (eventName.pixel) {
    return
    if (typeof window !== "undefined") {
      if (data) {
        window.fbq("track", eventName.pixel, data);
      } else {
        window.fbq("track", eventName.pixel);
      }
    }
  }

  if (eventName.mixpanel) {
    const mixpanel = getMixpanel();
    if (data) {
      mixpanel.track(eventName.mixpanel, data);
    } else {
      mixpanel.track(eventName.mixpanel);
    }
  }
};
