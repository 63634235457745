import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import OtpInput from "react-otp-input";
import { ThemeContext } from "styled-components";
import useRTL from "../../customHooks/useRTL";
import { isEmpty } from "../../utils/isEmpty";
import CloseBtn from "./CloseBtn";
import MolhamButton from "./MolhamButton";
import Or from "./Or";

type Props = {
  email: string;
  handleCodeSubmit: (onCodeSubmit: any) => (e: any) => Promise<void>;
  onCodeSubmit: (data: any) => Promise<void>;
  controlCode: any;
  codeErrors: any;
  onCodeStepComplete: () => void;
  resendCode: () => void;
  signOut?: () => void;
  noSignOut: boolean;
  closeButton: boolean;
  onCloseBtnClick: () => void;
  showModalCondition: boolean;
};

export default function VerificationModal({
  email,
  handleCodeSubmit,
  onCodeSubmit,
  controlCode,
  codeErrors,
  onCodeStepComplete,
  resendCode,
  signOut,
  noSignOut,
  closeButton,
  onCloseBtnClick,
  showModalCondition,
}: Props) {
  const { t } = useTranslation("common");
  const { theme } = useContext(ThemeContext);
  const isRTL = useRTL();
  const isMobile = useMobile("md");

  return (
    <Modal show={showModalCondition} centered dialogClassName="w-375">
      {closeButton && (
        <div className="d-flex justify-content-between p-2">
          <div />
          <CloseBtn closeModal={onCloseBtnClick} />
        </div>
      )}
      <Modal.Body>
        <form
          className={`w-100 needs-validation ${
            !isEmpty(codeErrors) ? "invalid" : ""
          }`}
          onSubmit={handleCodeSubmit(onCodeSubmit)}
        >
          <div className="d-flex flex-column align-items-center w-100">
            <div
              className="text-center p-4 bg-secondary mb-4
"
              style={{ width: "fit-content", borderRadius: "50%" }}
            >
              <i
                className="ai-mail text-primary "
                style={{ fontSize: 80, padding: 12 }}
              ></i>
            </div>
            <p className="h3 mb-4  text-center">{t("email_verification")}</p>
            <p className={`fs-md mb-4 text-center`}>
              {t("email_verification_description")}
            </p>
            <p
              className={`h4 ${isMobile ? "fs-md" : "fs-lg"} mb-4 text-center`}
            >
              {email}
            </p>
            <Controller
              name="code"
              control={controlCode}
              rules={{
                required: t(`code_required`),
              }}
              render={({ field }) => (
                <OtpInput
                  numInputs={6}
                  inputType="number"
                  onChange={(otp) => {
                    field.onChange(otp);
                    if (otp.length == 6) onCodeStepComplete();
                  }}
                  containerStyle={{
                    direction: "ltr",
                  }}
                  value={field.value}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        style={{
                          width: "40px",
                          height: "40px",
                          margin: "0rem 5px 1rem 5px",
                          padding: isRTL ? "6px 0px 6px 0px" : 0,
                          color: theme == "light" ? "#000" : "#fff",
                        }}
                        className="form-control-num text-center form-control-active form-control-bg"
                      />
                    );
                  }}
                />
              )}
            />

            {codeErrors.code?.message && (
              <div className="invalid-feedback text-center ">
                {codeErrors.code?.message}
              </div>
            )}
          </div>
        </form>
        <MolhamButton
          id="verification-resend-code-btn"
          label={t("resend_verification_code")}
          className="w-100 text-primary"
          variant={null}
          onClick={resendCode}
        />

        {!noSignOut && <Or m="mb-0" />}
        {!noSignOut && (
          <MolhamButton
            id="verification-signout-btn"
            label={t("current_account_signout")}
            className="w-100 text-primary "
            variant={null}
            type="button"
            onClick={signOut}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
