import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { useAuthContext } from "context/AuthContext";
import { AuthModalContext } from "context/AuthModalContext";
import { usePreferenceContext } from "context/PreferencesContext";
import useContents from "customHooks/useContents";
import useMobile from "customHooks/useMobile";
import { useColors } from "layout/Layout";
import useTranslation from "next-translate/useTranslation";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import checkoutActions from "../../actions/checkout";
import { useCheckoutStore } from "../../CheckoutState";
import DonationModalFooterPortal from "../../components/DonationModalFooterPortal";
import IsolatedModal from "../../components/IsolatedModal";
import PaymentSlidesFooterContainer from "../../components/PaymentSlidesFooterContainer";
import { replaceSubStringsWithComponents } from "../../utils";
import MonthlySubscriptionStep from "../MonthlySubscription/MonthlySubscriptionStep";
import ConfirmationItem from "./components/ConfirmationItem";
const ConfirmationStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("confirmation");
};

const ConfirmationStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const colors = useColors();
  const { lang } = usePreferenceContext();
  const isMobile = useMobile("md");
  const { donor } = useAuthContext();
  const { setStep } = useContext(AuthModalContext);
  const {
    checkoutOrder,
    addStepToHistory,
    replaceStepInHistory,
    hideModal,
    setCheckoutOrder,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    replaceStepInHistory: state.replaceStepInHistory,
    setCheckoutOrder: state.setCheckoutOrder,
    checkoutOrder: state.checkoutOrder,
    hideModal: () => {
      setTimeout(() => setStep(0), 300);
      state.setModalVisible(false);
    },
  }));

  const [loading, setLoading] = useState(false);
  const handleNextSlide = async () => {
    setLoading(true);
    const res = await checkoutActions.getCheckoutOrder({
      idOrReference: checkoutOrder.id,
    });
    if (res.ok) {
      setCheckoutOrder(res.data);
      addStepToHistory(MonthlySubscriptionStep.name);
    } else {
      toast.error(res.error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (checkoutOrder && checkoutOrder.confirmation_required === false) {
      replaceStepInHistory(MonthlySubscriptionStep.name);
    }
  }, [checkoutOrder]);
  const { data: contents } = useContents();
  const termsModalContent = useMemo(
    () => contents?.modals.find((modal) => modal.name === "checkout_terms"),
    [contents]
  );

  if (checkoutOrder && checkoutOrder.confirmation_required === false) {
    return null;
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {checkoutOrder && Array.isArray(checkoutOrder?.items)
        ? checkoutOrder?.items.map((item, itemIdx) => {
            const components = [
              <ConfirmationItem
                key={itemIdx}
                item={item}
                currency={checkoutOrder?.currency?.code}
              />,
              itemIdx === checkoutOrder?.items.length - 1 ? null : (
                <div
                  className="w-100"
                  style={{
                    borderTop: "1px solid var(--light-gray)",
                  }}
                />
              ),
            ];
            return components;
          })
        : null}

      <DonationModalFooterPortal id="modal-footer-portal-host">
        <PaymentSlidesFooterContainer>
          <MolhamButton
            id="donate-confirm-and-proceed-btn"
            endIcon="fe-arrow-right"
            label={t(`confirm_and_proceed`)}
            className={`w-100`}
            isLoading={loading}
            onClick={handleNextSlide}
          />

          <div
            style={{
              marginTop: 16,
              fontSize: isMobile ? 12 : 16,
              textAlign: "center",
            }}
          >
            {replaceSubStringsWithComponents(
              t("payment_process_terms_of_usage_agreement"),
              [
                {
                  component: (
                    <IsolatedModal
                      key={"payment_process_terms_of_use"}
                      headerLabel={
                        termsModalContent?.contents.title[lang.shortcut]
                      }
                      openingComponent={(onPress) => (
                        <span
                          onClick={onPress}
                          style={{
                            color: colors.primary2,
                            cursor: "pointer",
                          }}
                        >
                          {t("payment_process_terms_of_use")}
                        </span>
                      )}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            termsModalContent?.contents.body[lang.shortcut] ??
                            "",
                        }}
                      ></div>
                    </IsolatedModal>
                  ),
                  subStr: t("payment_process_terms_of_use"),
                },
              ]
            )}
          </div>
        </PaymentSlidesFooterContainer>
      </DonationModalFooterPortal>
    </div>
  );
};
const ConfirmationStep = {
  name: "ConfirmationStep",
  title: ConfirmationStepTitle,
  body: ConfirmationStepBody,
  footer: true,
};
export default ConfirmationStep;
