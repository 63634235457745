import { yupResolver } from "@hookform/resolvers/yup";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutActions from "../../actions/checkout";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
import NumericalInput from "../DonationAmount/components/NumericalInput";
import ProviderFeeStep from "../ProviderFee/ProviderFeeStep";
import TeamSupportStep from "../TeamSupport/TeamSupportStep";
import SavedShippingAddressStep from "../ShippingAddress/SavedShippingAddressStep";

const ZakatInputStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("zakat_pay_now");
};
const ZakatInputStepBody: React.FC = () => {
  const [
    checkoutOrder,
    setCheckoutOrder,
    setDonationAmountValue,
    addStepToHistory,
  ] = useCheckoutStore((state) => [
    state.checkoutOrder,
    state.setCheckoutOrder,
    state.setDonationAmountValue,
    state.addStepToHistory,
  ]);
  const { formatNumbers } = useFormatNumbers();

  const { t } = useTranslation("common");
  const { curr, currencies } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  const { data: utilities } = useUtilities();
  const currencyOptions = {
    accept_decimals:
      utilities?.currencies?.find((v) => v.code === currencyCode || curr?.code)
        ?.accept_decimals ?? true,
    min_payable_amount:
      utilities?.currencies?.find((v) => v.code === currencyCode || curr?.code)
        ?.min_payable_amount ?? 1,
  };
  const schema = useMemo(() => {
    return yup.object().shape({
      amount: yup
        .number()
        .min(
          currencyOptions.min_payable_amount,
          t("minimum_is_x") + " " + currencyOptions.min_payable_amount
        )
        .typeError("")
        .required(),
    });
  }, [currencyCode, curr?.code, t]);
  const {
    control,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm<{ amount: string }>({
    resolver: yupResolver(schema),
    defaultValues: { amount: "" },
  });
  const watchAmount = watch("amount");
  const total = watchAmount ? Number(watchAmount) : 0;
  const [loading, setLoading] = useState(false);

  const handleNextSlide = async (data: { amount: string }) => {
    setLoading(true);
    try {
      if (data.amount) {
        const resPromise = !checkoutOrder
          ? checkoutActions.createCheckoutOrder({
              currency_code: currencyCode || curr.code,
              amount: data.amount,
              payment_option_id: 31, // id of the zakat campaign
            })
          : checkoutActions.updateCheckoutOrder({
              currency_code: currencyCode || curr.code,
              amount: data.amount,
              payment_option_id: 31, // id of the zakat campaign
              id: checkoutOrder.id,
            });

        const res = await resPromise;
        if (res.ok === true) {
          setCheckoutOrder(res.data);
          setDonationAmountValue(data.amount);
          if (res.data.team_support_suggested) {
            addStepToHistory(TeamSupportStep.name);
          } else if (res.data.payment_provider_fees_checkout_suggestion) {
            addStepToHistory(ProviderFeeStep.name);
          } else if (res.data.shipping_address_required) {
            addStepToHistory(SavedShippingAddressStep.name);
          } else {
            addStepToHistory(ConfirmationStep.name);
          }
        } else {
          toast.error(res.error.message);
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalBodyElementContainer>
      <form onSubmit={handleSubmit(handleNextSlide)}>
        <div className="mb-3">
          <Controller
            name="amount"
            defaultValue={""}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <NumericalInput
                currencyCode={currencyCode || curr.code}
                currencySymbol={
                  currencies.find((c) => c.code == currencyCode)?.symbol ??
                  curr.symbol
                }
                value={value}
                onChange={(val) => {
                  onChange(val);
                }}
                onBlur={onBlur}
                autoFocus
                error={t(errors.amount?.message)}
                isMoney={true}
                minAmount={
                  currencyOptions.min_payable_amount
                    ? currencyOptions.min_payable_amount
                    : 0
                }
                placeholder={`${t("donation_amount_placeholder_partial")}${t(
                  `${currencyCode || curr.code}_currency_name`
                )}`}
              />
            )}
          />
        </div>
        <MolhamButton
          id="zakat-input-step-btn"
          endIcon="fe-arrow-right"
          label={
            <>
              {t("proceed_to_checkout") + " ("}
              <b>{formatNumbers(total, currencyCode ?? curr?.code, true)}</b>
              {")"}
            </>
          }
          className={`w-100`}
          disabled={loading}
          isLoading={loading}
        />
      </form>
    </ModalBodyElementContainer>
  );
};
const ZakatInputStep = {
  name: "ZakatInputStep",
  title: ZakatInputStepTitle,
  body: ZakatInputStepBody,
  footer: false,
};
export default ZakatInputStep;
