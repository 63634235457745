import axiosInstance, {
  MolhamError,
  MolhamResponse,
  MolhamResult,
} from "../axiosInstance";

const paymentRequest = async (
  checkout_order_reference: string
): Promise<
  MolhamResult<{
    id: string;
    token: string;
  }>
> => {
  try {
    const res = await axiosInstance.post(
      `/payment-providers/swish/payment-requests`,
      {
        checkout_order_reference,
      }
    );
    return res as MolhamResponse<{
      id: string;
      token: string;
    }>;
  } catch (err: any) {
    return err as MolhamError;
  }
};

const swishActions = { paymentRequest };
export default swishActions;
