import { yupResolver } from "@hookform/resolvers/yup";
import { useCartContext } from "context/CartContext";
import { usePreferenceContext } from "context/PreferencesContext";
import { useColors } from "layout/Layout";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useMemo } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../CheckoutState";
import { baseOnAddToCart, baseOnProceedToCheckout } from "./common";
import DonationAmountFooter from "./components/DonationAmountFooter";
import NumericalInput from "./components/NumericalInput";
import { isUndefined } from "./utils";
type Props = {};

const InputableAmountOption: React.FC<Props> = ({}) => {
  const { t } = useTranslation("common");
  const { curr, currencies } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  const selectedPaymentOption = useCheckoutStore(
    (state) => state.selectedPaymentOption
  );
  const donationAmountValue = useCheckoutStore(
    (state) => state.donationAmountValue
  );
  const setDonationAmountValue = useCheckoutStore(
    (state) => state.setDonationAmountValue
  );
  const schema = useMemo(() => {
    const min =
      selectedPaymentOption && selectedPaymentOption.min_amount
        ? selectedPaymentOption.min_amount[currencyCode || curr?.code]
        : 0;
    const max =
      selectedPaymentOption && selectedPaymentOption.max_amount
        ? selectedPaymentOption.max_amount[currencyCode || curr?.code]
        : 0;
    return yup.object().shape({
      amount: yup
        .number()
        .typeError("")
        .min(min, t("minimum_is_x") + " " + min)
        .max(max, t("maximum_is_x") + " " + max)
        .required(),
    });
  }, [selectedPaymentOption, currencyCode, curr, t]);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,

    formState: { errors },
  } = useForm<{ amount: string }>({
    resolver: yupResolver(schema),
    defaultValues: { amount: "" },
  });
  const watchAmount = watch("amount");
  const total = watchAmount ? Number(watchAmount) : 0;
  const colors = useColors();
  useEffect(() => {
    if (donationAmountValue) {
      setValue("amount", donationAmountValue);
    }
  }, [donationAmountValue]);
  const setModalVisible = useCheckoutStore((state) => state.setModalVisible);
  const { mutateCart } = useCartContext();

  const onAddToCart = baseOnAddToCart({
    onSuccess() {
      mutateCart();
      setModalVisible(false);
      toast.success(t("item_added_to_cart"));

      // handleShowCartOffCanvas();
      // setDonationAmount({});
      // mutateCart();
      // closeModal();
      // navigation.navigate('Cart', {screen: 'Home'});
    },
  });
  const onProceedToCheckout = baseOnProceedToCheckout({
    onSuccess() {
      setDonationAmountValue(getValues().amount);
    },

    onFail() {},
  });
  return (
    <div>
      {selectedPaymentOption &&
      selectedPaymentOption.funding_progress_bar &&
      (curr.code || currencyCode)
        ? (() => {
            const recievedPercentage =
              (selectedPaymentOption.funding_progress_bar.received[
                currencyCode || curr.code
              ] /
                selectedPaymentOption.funding_progress_bar.required[
                  currencyCode || curr.code
                ]) *
              100;
            const leftPercentage =
              (selectedPaymentOption.funding_progress_bar.left_to_fund[
                currencyCode || curr.code
              ] /
                selectedPaymentOption.funding_progress_bar.required[
                  currencyCode || curr.code
                ]) *
              100;
            const value = !isUndefined(selectedPaymentOption.max_amount)
              ? recievedPercentage +
                (total /
                  selectedPaymentOption.max_amount[currencyCode || curr.code]) *
                  leftPercentage
              : 0;
            return (
              <div className="mb-4 d-flex justify-content-center align-items-center w-100">
                <div style={{ position: "absolute", width: 200, zIndex: 0 }}>
                  <CircularProgressbar
                    value={value}
                    strokeWidth={5}
                    maxValue={100}
                    styles={buildStyles({
                      trailColor: "var(--separator-mobile)",
                      pathColor: colors.lightPrimary,
                    })}
                  />
                </div>
                <div style={{ width: 200, zIndex: 1 }}>
                  <CircularProgressbar
                    value={recievedPercentage}
                    styles={{
                      ...buildStyles({
                        trailColor: "#00000000",
                        pathColor: colors.primary2,
                      }),
                      ...{
                        text: {
                          fontWeight: "bold",
                          fill: "var(--dark-gray)",
                          fontSize: "1.5rem",
                        },
                      },
                    }}
                    // classes={{
                    //   text:"CircularProgressbar-text bold"
                    // }}
                    strokeWidth={5}
                    text={`%${value?.toFixed(0)}`}
                    maxValue={100}
                  />
                </div>
              </div>
            );
          })()
        : null}
      <Controller
        name="amount"
        defaultValue={""}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <NumericalInput
            currencyCode={currencyCode || curr.code}
            currencySymbol={
              currencies.find((c) => c.code === currencyCode)?.symbol ||
              curr.symbol
            }
            value={value}
            onChange={(val) => {
              onChange(val);
            }}
            onBlur={onBlur}
            autoFocus
            error={t(errors.amount?.message)}
            isMoney={true}
            maxAmount={
              selectedPaymentOption?.max_amount
                ? selectedPaymentOption.max_amount[currencyCode || curr.code]
                : 0
            }
            minAmount={
              selectedPaymentOption?.min_amount
                ? selectedPaymentOption.min_amount[currencyCode || curr.code]
                : 0
            }
            placeholder={`${t("donation_amount_placeholder_partial")}${t(
              `${currencyCode || curr.code}_currency_name`
            )}`}
          />
        )}
      />
      <DonationAmountFooter
        amount={total}
        onAddToCart={handleSubmit(onAddToCart)}
        // onProceedToCheckout={async() => { if (await trigger()) {
        //   onProceedToCheckout(getValues())
        // } }}
        onProceedToCheckout={handleSubmit((e: any) =>
          onProceedToCheckout({
            currency_code: currencyCode ?? curr.code,
            ...e,
          })
        )}
      />
    </div>
  );
};

export default InputableAmountOption;
