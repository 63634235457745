import CustomContentLoader from "../CustomContentLoader";

const LoginLoading = () => {
  return (
    <CustomContentLoader
      viewBox={`0 0 62 62`}
      className=""
      style={{ width: "62px" }}
    >
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%"></rect>
    </CustomContentLoader>
  );
};

export default LoginLoading;
