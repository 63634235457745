import { TAddress } from "apiTypes/addresses";
import { useEditAdressForm } from "components/Profile/AddEditAddressModal";
import { createAddress } from "components/addressesDataManager";
import { NEXT_URL_PROXY } from "config";
import { useAuthContext } from "context/AuthContext";
import { useInfinitePagination } from "customHooks/useInfinitPagination";
import useTranslation from "next-translate/useTranslation";
import React, { useState } from "react";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutActions from "../../actions/checkout";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
// import {
//   NameEmailFormComponents,
//   SepaMandate,
//   SubmitButton,
//   TNameEmailForm,
//   handleCheckoutEnd,
//   useNameEmailForm,
// } from "../common";
const NewShippingAddressStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("shipping_address");
};
const NewShippingAddressStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const { donor } = useAuthContext();

  const [errorMessage, setErrorMessage] = useState("");
  const { mutate } = useInfinitePagination<TAddress>(
    `${NEXT_URL_PROXY}/auth/shipping-addresses/`
  );
  const onSubmit = async (d: any) => {
    setLoading(true);
    try {
      const {
        checkoutOrder,
        setSelectedAddress,
        replaceStepInHistory,
        setCheckoutOrder,
      } = useCheckoutStore.getState();
      const newAddressRes = await createAddress(d);
      if (newAddressRes.status !== 200) {
        setErrorMessage(newAddressRes.error.message);
        return;
      }
      const res = await checkoutActions.setShippingAddress({
        checkout_order_id: checkoutOrder.id,
        shipping_address_id: newAddressRes.data.id,
      });
      mutate();

      if (res.ok === true) {
        setCheckoutOrder(res.data);
        setSelectedAddress(newAddressRes.data.id);
      }
      replaceStepInHistory(ConfirmationStep.name);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const { Form, formHook } = useEditAdressForm(onSubmit, loading, true);
  return (
    <ModalBodyElementContainer>
      {Form}

      {/* <DonationModalFooterPortal id="modal-footer-portal-host"> */}
      {/* <PaymentSlidesFooterContainer> */}
      {/* <SubmitButton onSubmit={handleSubmit(onSubmit)} loading={loading} /> */}
      {/* </PaymentSlidesFooterContainer> */}
      {/* </DonationModalFooterPortal> */}
    </ModalBodyElementContainer>
  );
};
const NewShippingAddressStep = {
  name: "NewShippingAddressStep",
  title: NewShippingAddressStepTitle,
  body: NewShippingAddressStepBody,
  footer: true,
};
export default NewShippingAddressStep;
