import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
export const nextCheckoutItemSourceOptions = {
  donate_navbar: "donate_navbar",
  donate_menu: "donate_menu",
  transparency: "transparency",
  profile: "profile",
  target_cards_widget: "target_cards_widget",

  quick_actions_widget: "quick_actions_widget",
  slider_widget: "slider_widget",

  single_targetable_sidebar: "single_targetable_sidebar",
  blog_post: "blog_post",
  custom_page: "custom_page",
  promo_widget: "promo_widget",
} as const;

type NextCheckoutItemSourceStore = {
  nextCheckoutItemSource: NextCheckoutItemSource | undefined;
  setNextCheckoutItemSource: (
    nextCheckoutItemSource: NextCheckoutItemSource | undefined
  ) => void;
};

export const useNextCheckoutItemSourceStore =
  create<NextCheckoutItemSourceStore>()(
    immer((set) => {
      return {
        nextCheckoutItemSource: undefined,
        setNextCheckoutItemSource: (nextCheckoutItemSource) =>
          set((state) => {
            state.nextCheckoutItemSource = nextCheckoutItemSource;
          }),
      };
    })
  );
export const nextCheckoutItemSourceManager = {
  setNextCheckoutItemSource:
    useNextCheckoutItemSourceStore.getState().setNextCheckoutItemSource,
  options: nextCheckoutItemSourceOptions,
  get: () => useNextCheckoutItemSourceStore.getState().nextCheckoutItemSource,
};
export type NextCheckoutItemSource = keyof typeof nextCheckoutItemSourceOptions;
