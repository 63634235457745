import { m } from "framer-motion";
import { useColors } from "layout/Layout";
import React, { ComponentProps } from "react";

type Props = { checked?: boolean; circle?: boolean } & ComponentProps<
  typeof m.div
>;

const CheckboxIcon: React.FC<Props> = ({ checked, ...props }) => {
  const colors = useColors();
  return (
    <m.div
      {...props}
      style={{
        display: "flex",
        flexDirection: "row",
        width: "20px",
        height: "20px",
        borderRadius: 6,
        borderWidth: 1,
        border: `1px solid var(--gray)`,
        backgroundColor: checked ? colors.primary2 : "transparent",
        marginLeft: 0,
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <m.div
        style={{ justifyContent: "center", alignItems: "center" }}
        transition={{
          type: "spring",
          stiffness: 500,
          damping: 30,
        }}
        animate={{ scale: checked ? 1 : 0 }}
      >
        <i className="fe-check" style={{ fontSize: 14, color: "white" }} />
      </m.div>
    </m.div>
  );
};

export default CheckboxIcon;
