const SvgComponent = (props: any) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 1000 1000"
  >
    <defs></defs>
    <path
      d="M553.49 910.65a35.65 35.65 0 0 1-26.31-11.56L174.45 514.91c-69.31-75.5-86.68-180.88-45.32-275C170 147 257.81 89.35 358.43 89.35c67.08 0 130.13 26.39 177.55 74.33l17.51 17.71L571 163.68c47.39-47.94 110.44-74.33 177.54-74.33C849.17 89.35 937 147 977.85 239.89c41.36 94.14 24 199.52-45.32 275L579.8 899.09a35.65 35.65 0 0 1-26.31 11.56ZM358.43 160.76c-71.87 0-134.67 41.33-163.91 107.85-29.8 67.82-17.33 143.69 32.55 198l326.42 355.54 326.42-355.54c49.88-54.31 62.35-130.18 32.55-198-29.24-66.52-92-107.85-163.91-107.85a177.12 177.12 0 0 0-126.76 53.13l-42.91 43.4a35.71 35.71 0 0 1-25.39 10.6 35.75 35.75 0 0 1-25.4-10.6l-42.9-43.4a177.12 177.12 0 0 0-126.76-53.13Z"
      className="molham-celebration-icon"
    />
    <path
      d="M499.93 660.69a35.71 35.71 0 0 1-32.82-21.64l-73.18-170.73-39.39 98.51a35.74 35.74 0 0 1-33.16 22.44H35.71a35.71 35.71 0 0 1 0-71.42H297.2l62.44-156.1a35.71 35.71 0 0 1 66-.8l73.18 170.73 39.39-98.51a35.72 35.72 0 0 1 67 2l27.57 82.71h45.68a35.71 35.71 0 0 1 0 71.42h-71.41a35.69 35.69 0 0 1-33.87-24.41l-4.89-14.64-35.2 88a35.72 35.72 0 0 1-32.73 22.44Z"
      className="molham-celebration-icon"
    />
  </svg>
);
export { SvgComponent as MedicalSVG };
