type Props = {
  children: any;
  className?: string;
  as?: any;
  isNavLink?: boolean;
  [x: string]: any;
};

const DividedMenuItem = ({
  children,
  className = "",
  as = "a",
  isNavLink = true,
  ...props
}: Props) => {
  const Tag = as;
  return (
    <Tag
      {...props}
      className={`${
        isNavLink ? "nav-link" : ""
      } v-centered justify-content-between px-3 ${className || ""}`}
    >
      {children}
      <i className="fe-chevron-right fs-xxl "></i>
    </Tag>
  );
};

export default DividedMenuItem;
