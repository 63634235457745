import { yupResolver } from "@hookform/resolvers/yup";
import { useModeContext } from "context/ModeContext";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AuthContext } from "../../../context/AuthContext";
import { AuthModalContext } from "../../../context/AuthModalContext";
import { isEmpty } from "../../../utils/isEmpty";
import MolhamButton from "../MolhamButton";
import MolhamInput from "../MolhamInput";
type Props = {
  fromPage?: boolean;
};
const SignUpForm = ({ fromPage }: Props) => {
  const { t } = useTranslation("common");
  const isMobile = useMobile("md");

  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .min(3, t(`invalid_donor_name_length`))
      .required(t(`name_required`)),
    email: Yup.string().required(t(`email_required`)).email(t(`email_invalid`)),
    password: Yup.string()
      .min(8, t(`password_must_be_8`))
      .required(t(`password_required`)),
  });

  const { handleSubmit, watch, formState, control } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });

  const { isDirty, errors } = formState;
  const { theme } = useModeContext();
  const { signup, isLoading } = useContext(AuthContext);
  const { setStep } = useContext(AuthModalContext);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const onSubmit = async (data) => {
    const { email, password, userName } = data;
    if (!captcha) {
      toast.error(t(`captcha_required`));
      return;
    }
    await signup(email, password, userName);
  };
  const onload = () => {
    const doc = document.getElementById("captcha-parent")?.childNodes[0]
      .childNodes[0].childNodes[0] as HTMLDivElement;

    doc.style.width = "302px";
  };
  return (
    <div className=" d-flex flex-column align-items-center">
      <p
        className={`${isMobile ? "fs-md" : "fs-lg"} fw-normal text-center mb-4`}
      >
        {t(`sign_up_description`)}
      </p>
      <form
        className={`w-100 needs-validation ${
          isEmpty(errors) || !isEmpty(errors) ? "invalid" : ""
        }`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <MolhamInput
          control={control}
          watch={watch}
          name="userName"
          // label={t(`full_name`)}
          placeholder={t(`full_name`)}
          type="ig"
          icon="ai-user"
        />

        <MolhamInput
          control={control}
          watch={watch}
          name="email"
          // label={t(`email`)}
          placeholder={t(`email`)}
          type="email"
        />

        <MolhamInput
          control={control}
          watch={watch}
          name="password"
          // label={t(`password`)}
          placeholder={t(`password`)}
          type="password"
        />
        <div className="mb-3 centered" id="captcha-parent">
          <ReCAPTCHA
            sitekey={"6LcFR8MpAAAAALLLZOkuImCcZ_zcU260rSz-kj4I"}
            onChange={(v) => setCaptcha(v)}
            onErrored={() => setCaptcha(null)}
            onExpired={() => setCaptcha(null)}
            theme={theme === "dark" ? "dark" : "light"}
            asyncScriptOnLoad={onload}
            style={{
              width: 302,
              height: 76,
              borderRadius: 3,
              overflow: "hidden",
            }}
          />
        </div>
        <MolhamButton
          id="signup-btn"
          label={t(`sign_up`)}
          isLoading={isLoading}
          disabled={!isDirty}
          className="mb-4 w-100"
          type="submit"
        />

        {fromPage ? (
          <p className="fs-sm mb-0">
            {t(`or`)}
            <Link
              href="login"
              className="fw-medium cursor text-decoration-none"
            >
              {t(`log_in_with`)}
            </Link>
            {t(`email`)}
          </p>
        ) : (
          <p className="fs-md mb-0 text-center">
            {t(`or`)} {t(`log_in_with`)}{" "}
            <a
              onClick={() => setStep(1)}
              className="fw-medium cursor text-decoration-none"
            >
              {t(`email`)}
            </a>
          </p>
        )}
      </form>
    </div>
  );
};

export default SignUpForm;
