import useTranslation from "next-translate/useTranslation";
import MolhamButton from "./ReuseableCompos/MolhamButton";
type Props = {
  onClick: () => void;
};
export default function ErrorBoundaryComponent({ onClick }: Props) {
  const { t } = useTranslation("common");
  return (
    <div className="w-100 text-center p-4">
      <h2>{t("something_went_wrong")}</h2>
      <MolhamButton
        id="try-again-btn"
        label={t("try_again")}
        onClick={onClick}
      />
    </div>
  );
}
