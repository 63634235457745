import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "../../../utils/isEmpty";
import MolhamButton from "../MolhamButton";
import MolhamInput from "../MolhamInput";

type Props = {
  handlePasswordForm: (onPasswordSubmit: any) => (e: any) => Promise<void>;
  onPasswordSubmit: any;
  registerPasswordForm: any;
  isLoading: boolean;
  formErrors: any;
  watch: any;
  control: any;
  fromPasswordDonor: any;
  error: any;
};
const ForgotPasswordResetPasswordStep = ({
  handlePasswordForm,
  formErrors,
  onPasswordSubmit,
  control,
  watch,
  isLoading,
  fromPasswordDonor,
  error,
}: Props) => {
  const { t } = useTranslation("common");

  return (
    <form
      className={`w-100 needs-validation ${
        error || !isEmpty(formErrors) ? "invalid" : ""
      }`}
      onSubmit={handlePasswordForm(onPasswordSubmit)}
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="mb-3 text-center">
            <label
              className="d-flex flex-column justify-content-center align-item-center mb-0 px-0"
              htmlFor="recovery-email"
            >
              <span className="fw-normal fs-xl text-uppercase mb-1">
                {t(`welcome`)}
              </span>{" "}
              <span className="fs-200 text-uppercase fw-bold mb-1">
                {fromPasswordDonor?.name}
              </span>
              <span className="fs-lg">{fromPasswordDonor?.email}</span>
            </label>
          </div>
          <p className="fs-xl mb-3 mt-0  text-center">{t(`reset_password`)}</p>

          <MolhamInput
            type="password"
            control={control}
            name="new_password"
            watch={watch}
            placeholder={t(`password`)}
          />
          <MolhamInput
            type="password"
            control={control}
            name="new_password_confirmation"
            watch={watch}
            placeholder={t(`confirm_password`)}
          />
        </div>
      </div>
      <MolhamButton
        id="FP-change-password-btn"
        label={t(`change_password`)}
        className={`w-100`}
        startIcon="fe-lock fs-lg"
        isLoading={isLoading}
      />
    </form>
  );
};

export default ForgotPasswordResetPasswordStep;
