import { useContext, useState } from "react";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FaFacebookF } from "react-icons/fa";
import { AuthContext } from "../../../context/AuthContext";
import MolhamButton from "../MolhamButton";

const LoginWithFacebook = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { login } = useContext(AuthContext);

  const responseFacebook = async (response) => {
    if (response?.status === "unknown") {
      return;
    }
    setIsLoading(true);
    await login({
      facebook_access_token: response?.accessToken,
      facebook_user_id: response.userID,
    });
    setIsLoading(false);
  };
  return (
    <FacebookLogin
      appId="240557123877022"
      fields="name,email,picture"
      // redirectUri={window.location.href}
      isMobile={false}
      callback={responseFacebook}
      render={(renderProps) => (
        <MolhamButton
          id="login-facebook-btn"
          className="btn bs-facebook mt-0 bg-facebook rounded-p d-flex align-items-center"
          onClick={renderProps.onClick}
          style={{ padding: 14, height: "fit-content" }}
          spinnerSize={"32px"}
          variant={null}
          isLoading={isLoading}
          CustomIconButton={<FaFacebookF size={32} />}
        />
      )}
    />
  );
};

export default LoginWithFacebook;
