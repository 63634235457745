import { getMoclid } from "../Steps/DonationAmount/utils";
import { TCheckoutHandlerResult } from "../Steps/PaymentSteps/common";
import axiosInstance, {
  MolhamError,
  MolhamResponse,
  MolhamResult,
} from "../axiosInstance";
export const CheckoutSourceProviders = {
  stripe: "stripe",
  paypal: "paypal",
  swish: "swish",
  braintree: "braintree",
} as const;
export type TCheckoutSourceProviders =
  (typeof CheckoutSourceProviders)[keyof typeof CheckoutSourceProviders];
export const CheckoutSourceTypes = {
  stripe: {
    payment_intent: "payment_intent",
  },
  paypal: {
    checkout_order: "checkout_order",
    subscription: "subscription",
  },
  swish: {
    payment_request: "payment_request",
  },
  braintree: {
    transaction: "transaction",
  },
} as const;

export type TCheckoutSourceTypes = {
  [K in keyof typeof CheckoutSourceTypes]: (typeof CheckoutSourceTypes)[K][keyof (typeof CheckoutSourceTypes)[K]];
}[keyof typeof CheckoutSourceTypes];

export const handleCheckoutSource = async (data: {
  payment_provider_id: string;
  type: TCheckoutSourceTypes;
  reference: string | number;
  click_reference?: string;
}): Promise<MolhamResult<TCheckoutHandlerResult>> => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...data, click_reference: moclid };

  try {
    const res = await axiosInstance.post(
      `/checkout-sources/handle`,
      referencedData,
      {
        timeout: 30000,
      }
    );

    return res as MolhamResponse<TCheckoutHandlerResult>;
  } catch (err) {
    return err as MolhamError;
  }
};
const checkoutHandlerActions = {
  handleCheckoutSource,
  CheckoutSourceTypes,
  CheckoutSourceProviders,
};
export default checkoutHandlerActions;
