import axios from "axios";
import { getMolhamHeaders } from "./molhamHeaders";

/**
 * It sends a POST request to the specified url with the specified data.
 * @param url - The URL to send the request to.
 * @param data - The data you want to send to the server.
 * @param token - The token to be used for authentication.
 * @param api_url - The URL of the API endpoint.
 * @returns The response from the API call.
 */
export const postData = async <Data = any>(
  url: string,
  data?: any,
  token?: string,
  headers: Record<string, any> = {}
) => {
  const molhamHeaders = getMolhamHeaders({});
  const res = await axios.post(`${url}`, data, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,

          ...headers,
          ...molhamHeaders,
        }
      : { ...headers, ...molhamHeaders },
  });
  return res as Data;
};

// export const postData = async (url, data, token, api_url) => {
//   const res = await axios.post(`${api_url ? api_url : API_URL}${url}`, data, {
//     headers: token
//       ? {
//           Authorization: `Bearer ${token}`,
//         }
//       : {},
//   });
//   return res;
// };
