import axios from "axios";
import { getMolhamHeaders } from "./molhamHeaders";

export const getData = async <Data = any>(
  url: string,
  token?: string,
  header: Record<string, any> = {},
  customURL?: string
) => {
  const molhamHeaders = getMolhamHeaders({});
  const res = await axios.get<Data>(`${customURL ? customURL : url}`, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
          ...header,
          ...molhamHeaders,
        }
      : { ...header, ...molhamHeaders },
  });
  return res;
};

/**
 * It fetches the data from the endpoint and returns the data in JSON format.
 * @param endpoint - The API endpoint to call.
 * @param token - The token to be sent to the API.
 * @param customURL - If you want to use a custom URL, you can do so.
 * mainly used inside useSwr hook
 */
export const fetcher = async <Data = any>(
  endpoint: string,
  token?: string,
  customURL?: string,
  header: Record<string, any> = {}
) => {
  const molhamHeaders = getMolhamHeaders({});
  const res = await fetch(`${customURL ? customURL : endpoint}`, {
    method: "GET",
    headers: token
      ? {
          ...molhamHeaders,
          Authorization: `Bearer ${token}`,
          ...header,
        }
      : { ...molhamHeaders, ...header },
  });
  if (!res.ok) {
    const error = new Error(
      "An error occurred while fetching the data."
    ) as any;
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json() as Promise<Data>;
};
