import { m } from "framer-motion";
import React, { ComponentProps } from "react";
type Props = { checked: boolean; size?: number } & ComponentProps<typeof m.div>;

const RadioIcon: React.FC<Props> = ({ checked, size = 20, ...props }) => {
  const mathRound = Math.round(size / 1.5);
  const widthHeightCalc = checked
    ? mathRound % 2 == 1
      ? mathRound - 1
      : mathRound
    : 0;
  return (
    <m.div
      {...props}
      style={{
        width: size,
        aspectRatio: 1,
        border: `solid 2px ${
          checked ? "var(--primary2)" : "var(--light-gray)"
        }`,
        borderRadius: "50%",
        // padding: 10,
        // backgroundColor: checked ? "var(--primary)" : 'transparent',
        marginInlineStart: 5,
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      // animate={{
      //   borderWidth: checked ? 4 : 1,
      //   ...props.animate,
      // }}
    >
      <m.div
        style={{
          // width: size / 3,
          borderRadius: "50%",

          backgroundColor: "var(--primary2)",
        }}
        transition={{
          type: "timing",
          duration: 0.2,
        }}
        animate={{
          width: widthHeightCalc,
          height: widthHeightCalc,
        }}
      />
    </m.div>
  );
};

export default RadioIcon;
