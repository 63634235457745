import { useStripe } from "@stripe/react-stripe-js";
import type { PaymentRequest } from "@stripe/stripe-js";
import { TPaymentMethodItem } from "apiTypes";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { NEXT_URL_PROXY } from "config";
import { useAuthContext } from "context/AuthContext";
import { usePreferenceContext } from "context/PreferencesContext";
import { useInfinitePagination } from "customHooks/useInfinitPagination";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutHandlerActions from "../../actions/checkout-handler";
import stripeActions from "../../actions/stripe";
import DonationModalFooterPortal from "../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../components/PaymentSlidesFooterContainer";
import ApplePayForm from "../PaymentSteps/ApplePayForm/ApplePayForm";
import SavedBraintreeCardStep from "../PaymentSteps/Braintree/SavedBraintreeCardStep";
import SavedCardPaymentStep from "../PaymentSteps/CardPayment/SavedCardPaymentStep";
import NewGiropayPaymentStep from "../PaymentSteps/GiropayPayment/NewGiropayPaymentStep";
import GooglePayForm from "../PaymentSteps/GooglePayForm/GooglePayForm";
import SavedIdealPaymentStep from "../PaymentSteps/IdealPayment/SavedIdealPaymentStep";
import PaypalForm from "../PaymentSteps/PayPalPayment/PayPalForm";
import SavedSepaPaymentStep from "../PaymentSteps/SepaPayment/SavedSepaPaymentStep";
import SavedSofortPaymentStep from "../PaymentSteps/SofortPayment/SavedSofortPaymentStep";
import SwishPaymentStep from "../PaymentSteps/SwishPayment/SwishPaymentStep";
import { handleCheckoutEnd } from "../PaymentSteps/common";
import { PaymentMethodItem } from "./components/PaymentMethodItem";

export const supportedPaymentMethods = {
  swish: "swish",
  paypal_checkout_order: "paypal_checkout_order",
  paypal_subscription: "paypal_subscription",
  stripe_apple_pay: "stripe_apple_pay",
  stripe_google_pay: "stripe_google_pay",
  stripe_card: "stripe_card",
  stripe_ideal: "stripe_ideal",
  stripe_sepa_debit: "stripe_sepa_debit",
  stripe_sofort: "stripe_sofort",
  stripe_giropay: "stripe_giropay",
  braintree_card: "braintree_card",
} as const;
const PaymentMethodStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("payment_method");
};
const PaymentMethodStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const { lang } = usePreferenceContext();
  const { checkoutOrder, addStepToHistory } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    checkoutOrder: state.checkoutOrder,
  }));
  const [allowedPaymentRequests, setAllowedPaymentRequests] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  );
  const billing_method =
    checkoutOrder?.subscription_setup_details?.billing_method;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);
  //preload payment methods
  useInfinitePagination<TPaymentMethodItem>(
    `${NEXT_URL_PROXY}/auth/saved-payment-methods`
  );

  const stripe = useStripe();
  const isAutoSubscription =
    checkoutOrder?.subscription_setup_details?.frequency !== null &&
    billing_method === "automatic";
  const isManualSubscription =
    checkoutOrder?.subscription_setup_details?.frequency !== null &&
    billing_method === "manual";
  const possiblePaymentMethods = (() => {
    if (!checkoutOrder?.payer?.available_payment_methods) return [];
    const supportedPaymentMethodsData = Object.entries(
      checkoutOrder.payer.available_payment_methods
    )
      .map(([key, data]) => ({
        ...(data as any),
        key,
      }))
      .filter((p) => {
        if (
          p.key === supportedPaymentMethods.stripe_apple_pay &&
          !allowedPaymentRequests?.applePay
        ) {
          return false;
        } else if (
          p.key === supportedPaymentMethods.stripe_google_pay &&
          !allowedPaymentRequests?.googlePay
        ) {
          return false;
        }
        return true;
      });
    if (isAutoSubscription) {
      return supportedPaymentMethodsData.filter(
        (v) => v.automatic_billing_subscription_checkout_supported
      );
    }
    if (isManualSubscription) {
      return supportedPaymentMethodsData.filter(
        (v) => v.manual_billing_subscription_checkout_supported
      );
    }
    // if (isOneTimeCheckout) {
    return supportedPaymentMethodsData.filter(
      (v) => v.one_time_checkout_supported
    );
  })();
  const onProceed = () => {
    switch (selectedPaymentMethod) {
      case supportedPaymentMethods.braintree_card:
        addStepToHistory(SavedBraintreeCardStep.name);
        break;
      case supportedPaymentMethods.swish:
        addStepToHistory(SwishPaymentStep.name);
        break;
      case supportedPaymentMethods.stripe_card:
        addStepToHistory(SavedCardPaymentStep.name);
        break;

      case supportedPaymentMethods.stripe_sofort:
        addStepToHistory(SavedSofortPaymentStep.name);
        break;

      case supportedPaymentMethods.stripe_sepa_debit:
        addStepToHistory(SavedSepaPaymentStep.name);
        break;

      case supportedPaymentMethods.stripe_ideal:
        addStepToHistory(SavedIdealPaymentStep.name);
        break;
      case supportedPaymentMethods.stripe_giropay:
        addStepToHistory(NewGiropayPaymentStep.name);
        break;

      default:
        break;
    }
  };
  const grandpaEl =
    document.getElementById("paymentEl")?.parentElement.parentElement;
  const isMobile = useMobile("md");
  useEffect(() => {
    if (grandpaEl && isMobile) {
      grandpaEl.style.height = "100vh";
    } else if (grandpaEl && !isMobile) {
      grandpaEl.style.height = "auto";
    }
  }, [grandpaEl, isMobile]);
  useEffect(() => {
    if (
      stripe &&
      checkoutOrder &&
      checkoutOrder?.payer?.available_payment_methods.stripe_card
    ) {
      const paymentProvider =
        checkoutOrder?.payer?.available_payment_methods.stripe_card
          .payment_provider;
      const pr = stripe.paymentRequest({
        country: paymentProvider.country_code.toUpperCase(),
        currency: paymentProvider.currency,
        total: {
          label: `Molham Checkout Order ${checkoutOrder?.reference}`,
          amount: parseInt(
            (
              checkoutOrder.withdrawal_amount[paymentProvider.currency] * 100
            ).toFixed(2)
          ),
        },
        requestPayerName: false,
        requestPayerEmail: false,
        requestShipping: false,
        requestPayerPhone: false,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setAllowedPaymentRequests(result);
          setPaymentRequest(pr);
        }
      });
      pr.on("paymentmethod", async (ev) => {
        const paymentIntentRes = await stripeActions.paymentIntent({
          checkout_order_reference: checkoutOrder!.reference,
          // return_url: 'molhamteam://stripe-redirect',
          payment_method_types: ["card"],
          confirmation_method: "automatic",
          payment_provider_id: paymentProvider.id,
        });
        if (paymentIntentRes.ok === false) {
          return;
        }
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            paymentIntentRes.data.client_secret,
            {
              payment_method: ev.paymentMethod.id,
              return_url: `${window.location}?checkout_order_id=${
                checkoutOrder!.reference
              }`,
            },
            { handleActions: false }
          );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(
              paymentIntentRes.data.client_secret
            );
            if (error) {
              toast.error(error.message);
            } else {
              const res = await checkoutHandlerActions.handleCheckoutSource({
                payment_provider_id: paymentProvider.id,
                type: checkoutHandlerActions.CheckoutSourceTypes.stripe
                  .payment_intent,
                reference: paymentIntentRes.data?.id,
              });
              handleCheckoutEnd({
                res,
                metadata: {
                  "Checkout Order": checkoutOrder.reference,
                  Items: checkoutOrder.items,
                  currency: checkoutOrder.currency.code,
                  amount: checkoutOrder.amount,
                  "Payment Method": "Stripe Apple|Google Pay",
                  saved: false,
                },
              });
            }
          } else {
            const res = await checkoutHandlerActions.handleCheckoutSource({
              payment_provider_id: paymentProvider.id,
              type: checkoutHandlerActions.CheckoutSourceTypes.stripe
                .payment_intent,
              reference: paymentIntentRes.data?.id,
            });
            handleCheckoutEnd({
              res,
              metadata: {
                "Checkout Order": checkoutOrder.reference,
                Items: checkoutOrder.items,
                currency: checkoutOrder.currency.code,
                amount: checkoutOrder.amount,
                "Payment Method": "Stripe Apple|Google Pay",
                saved: false,
              },
            });
          }
        }
      });
      return () => {
        pr.off("paymentmethod");
      };
    }
  }, [stripe]);

  return (
    <ModalBodyElementContainer
      className="d-flex flex-column align-items-center justify-content-center"
      id="paymentEl"
    >
      <div
        style={{
          flexDirection: "column",
          width: "100%",
        }}
      >
        {possiblePaymentMethods.map((item, idx) => {
          return (
            <PaymentMethodItem
              key={item.key}
              isLast={idx === possiblePaymentMethods.length - 1}
              Icon={item.icon_url["apple-style"]}
              description={item.description[lang.shortcut]}
              label={item.label[lang.shortcut]}
              onPress={() => {
                setSelectedPaymentMethod(item.key);
              }}
              isSelected={selectedPaymentMethod === item.key}
            />
          );
        })}
      </div>
      <DonationModalFooterPortal id="modal-footer-portal-host">
        {selectedPaymentMethod === supportedPaymentMethods.stripe_apple_pay &&
        allowedPaymentRequests?.applePay ? (
          <ApplePayForm paymentRequest={paymentRequest} />
        ) : selectedPaymentMethod ===
            supportedPaymentMethods.stripe_google_pay &&
          allowedPaymentRequests?.googlePay ? (
          <GooglePayForm paymentRequest={paymentRequest} />
        ) : selectedPaymentMethod ===
          supportedPaymentMethods.paypal_subscription ? (
          <PaypalForm isSubscription={true} />
        ) : selectedPaymentMethod ===
          supportedPaymentMethods.paypal_checkout_order ? (
          <PaypalForm isSubscription={false} />
        ) : selectedPaymentMethod ? (
          <PaymentSlidesFooterContainer>
            <MolhamButton
              id="donate-payment-method-next-btn"
              endIcon="fe-arrow-right"
              label={t(`proceed`)}
              className={`w-100`}
              onClick={onProceed}
              disabled={!selectedPaymentMethod}
            />
          </PaymentSlidesFooterContainer>
        ) : null}
      </DonationModalFooterPortal>
      {/* ) : null} */}
    </ModalBodyElementContainer>
  );
};
const PaymentMethodStep = {
  name: "PaymentMethodStep",
  title: PaymentMethodStepTitle,
  body: PaymentMethodStepBody,
  footer: true,
};
export default PaymentMethodStep;
