import useTranslation from "next-translate/useTranslation";
import React, { useContext, useState } from "react";
import { avatarDropdownMeta } from "../../../constants/profileMeta";
import { AuthContext } from "../../../context/AuthContext";
import { usePreferenceContext } from "../../../context/PreferencesContext";
import useFormatNumbers from "../../../customHooks/useFormatNumbers";
import ActiveLink from "../../ReuseableCompos/ActiveLink";
import SmallSpinner from "../../ReuseableCompos/SmallSpinner";

const AvatarDropdown = () => {
  const [isMenuItemCLicked, setIsMenuItemCLick] = useState(false);
  const { signOut, isLoading, donor } = useContext(AuthContext);

  const { formatNumbers } = useFormatNumbers();
  const { curr } = usePreferenceContext();
  const { t } = useTranslation("common");

  const onSubMenuClick = () => {
    setIsMenuItemCLick(true);
    setTimeout(() => setIsMenuItemCLick(false), 100);
  };

  return (
    <div
      className={`dropdown-menu dropdown-menu-right text-capitalize ${
        isMenuItemCLicked ? "d-none" : ""
      }`}
      style={{ width: "fit-content" }}
    >
      {avatarDropdownMeta.map((item, idx) => (
        <React.Fragment key={item.title}>
          {item.isSignOut ? (
            <li
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                signOut();
                onSubMenuClick();
              }}
            >
              <a className="dropdown-item d-flex align-items-center cursor">
                <i
                  className={`${item.icon} font-size-base opacity-60 mr-2`}
                ></i>
                <span className="text-danger">{t(item.title)}</span>
                <SmallSpinner isLoading={isLoading} className="mx-2" />
              </a>
            </li>
          ) : (
            <li onClick={onSubMenuClick}>
              <ActiveLink activeClassName="active" href={item.link}>
                <div className="dropdown-item d-flex align-items-center">
                  <i
                    className={`${item.icon} font-size-base opacity-60 mr-2`}
                  ></i>
                  {t(item.title)}
                  {item.badge && item.link.includes("donations") && (
                    <span
                      style={{ marginTop: 1 }}
                      className="ml-auto font-size-xs text-muted"
                      dir="auto"
                    >
                      <>{formatNumbers(donor?.donations_total?.[curr?.code])}</>
                    </span>
                  )}
                </div>
              </ActiveLink>
            </li>
          )}
          {avatarDropdownMeta.length - 1 !== idx && (
            <li className="dropdown-divider"></li>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AvatarDropdown;
