import UpperNavbar from "./UpperNavbar";

const Navbar = () => {
  return (
    <header>
      <UpperNavbar />
    </header>
  );
};

export default Navbar;
