import { AUTH_URL } from "config";
import Cookies from "js-cookie";
import { createContext, useContext, useEffect } from "react";
import { postData } from "utils/postData";
import useModal from "../customHooks/useModal";
import { useAuthContext } from "./AuthContext";
type InAppMsgModalContextType = {
  showInAppMsgModal: boolean;
  handleCloseInAppMsgModal: () => void;
  handleShowInAppMsgModal: () => void;
  readMsg: (id: string) => void;
  remindMeLater: () => void;
};
const InAppMsgModalContext = createContext<InAppMsgModalContextType>(null);

const InAppMsgModalProvider = ({ children }) => {
  const {
    showModal: showInAppMsgModal,
    handleCloseModal: handleCloseInAppMsgModal,
    handleShowModal: handleShowInAppMsgModal,
  } = useModal();
  const { donor } = useAuthContext();
  const setShown = () => {
    Cookies.set("shown", "true");
  };
  const shown = Cookies.get("shown");
  const disabled = true;
  useEffect(() => {
    if (
      donor &&
      Array.isArray(donor?.unread_inapp_messages) &&
      donor?.unread_inapp_messages?.length > 0 &&
      shown !== "true" &&
      !disabled
    ) {
      handleShowInAppMsgModal();
    }
  }, [donor, shown]);
  const readMsg = async (id: string) => {
    postData(`${AUTH_URL}/inapp-messages/${id}/mark-as-read`);
    setShown();
    handleCloseInAppMsgModal();
  };
  const onRemindMeLater = () => {
    setShown();
    handleCloseInAppMsgModal();
  };
  return (
    <InAppMsgModalContext.Provider
      value={{
        readMsg,
        remindMeLater: onRemindMeLater,
        showInAppMsgModal,
        handleCloseInAppMsgModal,
        handleShowInAppMsgModal,
      }}
    >
      {children}
    </InAppMsgModalContext.Provider>
  );
};
export const useInAppMsgModalContext = () => {
  const context = useContext(InAppMsgModalContext);
  if (context === undefined) {
    throw new Error(
      "useInAppMsgModalContext must be used within a InAppMsgModalProvider"
    );
  }
  return context;
};
export { InAppMsgModalContext, InAppMsgModalProvider };
