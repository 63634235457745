import useMobile from "customHooks/useMobile";
import useUtilities from "customHooks/useUtilities";
import { m } from "framer-motion";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import styled from "styled-components";
import { useModeContext } from "../context/ModeContext";
import { colorDetector, useAllTextColors } from "../layout/Layout";
import MolhamModalOffcanvas from "./MolhamModalOffCanvas";
import ThemeActionBar from "./ThemeActionBar";

type Props = {
  showThemingModal: boolean;
  handleCloseThemingModal: () => void;
};

const ThemingModal = ({ showThemingModal, handleCloseThemingModal }: Props) => {
  const { setColor, color: ctxColor, theme } = useModeContext();
  const { locale } = useRouter();

  const { data: utilities } = useUtilities();
  const onTabClick = (color) => {
    setColor(color);
  };
  const allColors = useAllTextColors();
  const { t } = useTranslation("common");
  const selectedColor = utilities?.colors?.find(
    (color) => color?.key === ctxColor
  );
  const pickForMe = () => {
    const col =
      utilities?.colors[Math.floor(Math.random() * utilities?.colors?.length)]
        ?.key;
    if (col === ctxColor || col === "default") {
      return pickForMe();
    }
    onTabClick(col);
  };
  const isMobile = useMobile("md");

  return (
    <MolhamModalOffcanvas
      onHide={handleCloseThemingModal}
      show={showThemingModal}
      body={{
        className: "p-0",
      }}
      fullscreen
      header={{
        label: "theme_mode_color",
        labelClassName: "text-primary",
        borderRadius: false,
      }}
      swipeToClose={false}
    >
      <div className={`border-bottom `}>
        <ThemeActionBar closeTab={handleCloseThemingModal} />
      </div>

      <div
        className="m-0 px-0 px-md-3 py-3 border-bottom"
        style={
          isMobile
            ? {
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, 75px)",
                gridGap: "1rem",
                alignContent: "center",
                justifyContent: "center",
              }
            : {
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, 75px)",
                gridAutoRows: "1fr",
                gridGap: "1rem",
                justifyContent: "space-between",
              }
        }
      >
        {utilities?.colors?.map((color) => {
          if (color?.key === "default") {
            return null;
          }
          return (
            <div
              key={color?.key}
              className="d-flex align-items-center justify-content-center"
            >
              <ColorPalette
                onClick={() => onTabClick(color?.key)}
                idx={color?.key}
                className={`cursor color donate-card-icon position-relative`}
                theme={theme}
                isActive={color?.key === ctxColor}
              >
                {color?.key === ctxColor && (
                  <i
                    style={{
                      fontSize: 40,
                      right: "22.5%",
                      top: "25%",
                      color: "#fff",
                    }}
                    className="fe-check position-absolute"
                  ></i>
                )}
              </ColorPalette>
            </div>
          );
        })}
        <div className="d-flex align-items-center justify-content-center">
          <RandomBlock
            onClick={pickForMe}
            className={`p-3 cursor color donate-card-icon d-flex align-items-center justify-content-center`}
            theme={theme}
          >
            <i
              style={{ fontSize: 40 }}
              className="fe-shuffle text-contrast"
            ></i>
          </RandomBlock>
        </div>
      </div>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="p-3"
        key={selectedColor?.key}
      >
        <h6
          key={selectedColor?.key}
          className={`mb-0 fs-xl text-capitalize text-center mb-2`}
          style={{
            color: allColors[selectedColor?.key],
            transition: "all 0.2 ease-in-out",
          }}
        >
          {selectedColor?.name[locale]}
        </h6>
        <h6 className="mb-0 fw-medium text-center">
          {t(selectedColor?.description[locale])}
        </h6>
      </m.div>
    </MolhamModalOffcanvas>
  );
};

export default ThemingModal;
const ColorPalette = styled.span<{ idx: string; isActive: boolean }>`
  display: block;
  width: 75px;
  height: 75px;
  /* border: ${(props) =>
    props.isActive ? `8px solid var(--text-white)` : "none"}; */
  background: ${(props) =>
    colorDetector({ color: props.idx, theme: props.theme })?.primary};
`;
const RandomBlock = styled.span`
  display: block;
  width: 75px;
  height: 75px;
  background: ${(props) =>
    props.theme === "dark" ? "var(--light)" : "var(--dark)"};
`;
