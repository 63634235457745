import { useEffect, useRef } from "react";

const useFocus = (showElement: boolean) => {
  const ref = useRef(null);
  useEffect(() => {
    if (showElement) ref.current?.focus();
  }, [showElement]);
  return { ref };
};

export default useFocus;
