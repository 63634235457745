import { useCheckoutStore } from "components/ReuseableCompos/SharedDonatePages/DonateModal/CheckoutState";
import CheckoutEndStep from "components/ReuseableCompos/SharedDonatePages/DonateModal/Steps/CheckoutEnd/CheckoutEndStep";
import { handleCheckoutEnd } from "components/ReuseableCompos/SharedDonatePages/DonateModal/Steps/PaymentSteps/common";
import checkoutHandlerActions from "components/ReuseableCompos/SharedDonatePages/DonateModal/actions/checkout-handler";
import useUtilities from "customHooks/useUtilities";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import BodyLock from "utils/bodyLock";
//http://localhost:3000/humanitarian-cases/21617?checkout_order_id=hsCpXEtxTaTuBL6Ff&payment_intent=pi_3NrjKcHChY2eOuBQ16YuzxFR&payment_intent_client_secret=pi_3NrjKcHChY2eOuBQ16YuzxFR_secret_0lnERqwIw1FDW7uqnS3maiIID&redirect_status=succeeded
const CheckoutHandler = ({ children }) => {
  const router = useRouter();
  const { data: utilities } = useUtilities();
  const {
    checkout_order_id,
    payment_intent,
    payment_intent_client_secret,
    redirect_status,
    swish_payment_request_id,
    payment_provider_id,
  } = router.query;
  const setModalVisible = useCheckoutStore((state) => state.setModalVisible);
  const handleStripeCheckout = async () => {
    if (
      checkout_order_id &&
      payment_intent &&
      payment_intent_client_secret &&
      redirect_status &&
      utilities
    ) {
      try {
        const checkoutOrderId = Array.isArray(checkout_order_id)
          ? checkout_order_id[0]
          : checkout_order_id;
        const paymentIntent = Array.isArray(payment_intent)
          ? payment_intent[0]
          : payment_intent;
        const paymentProvider = payment_provider_id
          ? Array.isArray(payment_provider_id)
            ? payment_provider_id[0]
            : payment_provider_id
          : null;
        const checkoutSourceRes =
          await checkoutHandlerActions.handleCheckoutSource({
            payment_provider_id: utilities!.payment_providers.stripe.id,
            type: checkoutHandlerActions.CheckoutSourceTypes.stripe
              .payment_intent,
            reference: paymentIntent,
          });
        if (checkoutSourceRes.ok === true) {
          handleCheckoutEnd({
            res: checkoutSourceRes,
            metadata: {
              "Checkout Order": checkoutOrderId,
              "Payment Method": "Stripe",
              saved: false,
              "Payment Provider ID": paymentProvider,
            },
          });
          setModalVisible(true);

          router.replace(window.location.pathname, undefined, {
            shallow: true,
          });
        }
      } catch (error) {}
    }
  };
  const [loading, setLoading] = useState(false);
  const changeLoading = (value: boolean) => {
    if (value) {
      setLoading(true);
      BodyLock.lock();
    } else {
      setLoading(false);
      BodyLock.unlock();
    }
  };

  const handleSwishCheckout = async () => {
    if (payment_provider_id && swish_payment_request_id) {
      changeLoading(true);
      try {
        const swishPaymentRequestId = Array.isArray(swish_payment_request_id)
          ? swish_payment_request_id[0]
          : swish_payment_request_id;
        const paymentProviderId = Array.isArray(payment_provider_id)
          ? payment_provider_id[0]
          : payment_provider_id;
        router.replace(router.pathname);
        const request = () => {
          return checkoutHandlerActions.handleCheckoutSource({
            payment_provider_id: paymentProviderId,
            type: checkoutHandlerActions.CheckoutSourceTypes.swish
              .payment_request,
            reference: swishPaymentRequestId,
          });
        };

        let retries = 0;
        const maxRetries = 3;
        const delay = 5;

        let checkoutEndRes = await request();
        changeLoading(false);

        while (!checkoutEndRes.ok && retries < maxRetries) {
          await new Promise<void>((resolve) => {
            setTimeout(async () => {
              checkoutEndRes = await request();
              retries++;
              resolve();
            }, delay);
          });
        }
        if (checkoutEndRes.ok) {
          const checkoutModalState = useCheckoutStore.getState();

          if (
            checkoutEndRes.data.checkout_order.status === "paid" ||
            checkoutEndRes.data.checkout_order.status === "processing"
          ) {
            if (checkoutModalState.modalVisible) {
              checkoutModalState.setCheckoutEnd(checkoutEndRes.data);
              checkoutModalState.addStepToHistory(CheckoutEndStep.name);
            } else {
              checkoutModalState.setCheckoutEnd(checkoutEndRes.data);
              checkoutModalState.addStepToHistory(CheckoutEndStep.name);

              checkoutModalState.setModalVisible(true);
            }
            router.replace(window.location.pathname, undefined, {
              shallow: true,
            });
          } else {
            toast.error(
              checkoutEndRes.data.message.title +
                "\n" +
                checkoutEndRes.data.message.body
            );
          }
        }
      } catch (error) {
        toast.error(error?.error?.message);
      }
    }
  };
  useEffect(() => {
    handleStripeCheckout();
  }, [
    checkout_order_id,
    payment_intent,
    payment_intent_client_secret,
    redirect_status,
    utilities?.payment_providers,
  ]);
  useEffect(() => {
    handleSwishCheckout();
  }, [
    checkout_order_id,
    utilities?.payment_providers,
    swish_payment_request_id,
  ]);
  return (
    <>
      {children}
      {loading && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            opacity: 0.5,
            backgroundColor: "#000",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default CheckoutHandler;
