import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import { usePreferenceContext } from "context/PreferencesContext";
import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
const CurrencySelectModal: React.FC = () => {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const { curr, currencies, onCurrencyDropdownChange, currencySelectModal } =
    usePreferenceContext();
  const isRTL = useRTL();
  return (
    <MolhamModalOffcanvas
      show={currencySelectModal.showModal}
      onHide={currencySelectModal.handleCloseModal}
      body={{ className: "p-0" }}
      fullscreen
      header={{
        label: t("preferred_currency"),
        borderRadius: false,
      }}
      swipeToClose={false}
    >
      {currencies?.map((option, optionIdx) => (
        <div
          className={`d-flex align-items-center justify-content-between w-100 py-3 cursor ${
            optionIdx === currencies.length - 1
              ? "border-bottom-0"
              : "border-bottom"
          } ${isRTL ? "pr-3 pl-16" : "ps-3 pe-4"}`}
          key={option.name[locale]}
          onClick={() => {
            onCurrencyDropdownChange(
              option,
              currencySelectModal.handleCloseModal
            );
            // handleCloseModalOffCanvas();
          }}
        >
          <label className={`mb-0 d-block cursor fs-base`}>
            {`${t(`${option.name[locale]}`)} (${option.code.toUpperCase()})`}
          </label>
          <input
            type="radio"
            className="form-check-input mt-0"
            checked={curr?.code === option.code}
            defaultChecked={curr?.name[locale] === option?.name[locale]}
            id={option.name[locale]}
          />
        </div>
      ))}
    </MolhamModalOffcanvas>
  );
};

export default CurrencySelectModal;
