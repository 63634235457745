import { useStripe } from "@stripe/react-stripe-js";
import { TPaymentMethodItem } from "apiTypes";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { NEXT_URL_PROXY } from "config";
import { useAuthContext } from "context/AuthContext";
import useClientInfintePagination from "customHooks/useClientInfintePagination";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import stripeActions from "../../../actions/stripe";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import { PaymentMethodItem } from "../../PaymentMethod/components/PaymentMethodItem";
import { SubmitButton, handleCheckoutEnd } from "../common";
import NewSofortPaymentStep from "./NewSofortPaymentStep";
const SavedSofortPaymentStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_sofort");
};
const SavedSofortPaymentStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const stripe = useStripe();
  const { donor } = useAuthContext();
  const {
    loading,
    setLoading,
    checkoutOrder,
    addStepToHistory,
    replaceStepInHistory,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    replaceStepInHistory: state.replaceStepInHistory,

    checkoutOrder: state.checkoutOrder,
    setLoading: state.setLoading,
    loading: state.loading,
  }));
  const { data: utilities } = useUtilities();

  const { formattedData, isLoadingInitialData, getAllPages } =
    useClientInfintePagination<TPaymentMethodItem>(
      `${NEXT_URL_PROXY}/auth/saved-payment-methods`
    );

  useEffect(() => {
    if (!isLoadingInitialData) {
      getAllPages();
    }
  }, [isLoadingInitialData]);
  const filteredData = formattedData.filter((item) => item.type === "sofort");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    number | string | null
  >(null);
  const handleNextSlide = () => {
    addStepToHistory(NewSofortPaymentStep.name);
  };
  const onSubmit = async () => {
    const paymentProvider =
      donor.available_payment_methods.stripe_sofort.payment_provider;
    if (stripe) {
      setLoading(true);
      try {
        if (selectedPaymentMethod === null) {
          return;
        }
        const paymentIntent = await stripeActions.paymentIntent({
          checkout_order_reference: checkoutOrder!.reference,
          saved_payment_method_id: selectedPaymentMethod,
          payment_provider_id: paymentProvider.id,
        });

        if (paymentIntent.ok === false) {
          toast.error(paymentIntent.error?.message);
          setLoading(false);

          return;
        }

        const res = await checkoutHandlerActions.handleCheckoutSource({
          payment_provider_id: paymentProvider.id,
          type: checkoutHandlerActions.CheckoutSourceTypes.stripe
            .payment_intent,
          reference: paymentIntent?.data.id,
        });
        handleCheckoutEnd({
          res,
          metadata: {
            "Checkout Order": checkoutOrder.reference,
            Items: checkoutOrder.items,
            currency: checkoutOrder.currency.code,
            amount: checkoutOrder.amount,
            "Payment Method": "Stripe Sofort",
            saved: true,
            "Payment Provider ID": paymentProvider.id,
          },
        });
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }

      setLoading(false);
    }
  };
  useEffect(() => {
    if (!isLoadingInitialData && filteredData.length === 0) {
      replaceStepInHistory(NewSofortPaymentStep.name);
    }
  }, [isLoadingInitialData, filteredData]);

  if (!isLoadingInitialData && filteredData.length === 0) {
    return null;
  }
  return (
    <ModalBodyElementContainer className="d-flex flex-column">
      {filteredData.length > 0 ? (
        <>
          {filteredData.map((item) => {
            return (
              <>
                <PaymentMethodItem
                  key={item.id}
                  Icon={item.icon_url["apple_style"]}
                  isSelected={selectedPaymentMethod === item.id}
                  onPress={() => setSelectedPaymentMethod(item.id)}
                  label={item.name}
                  description={item.description}
                />
              </>
            );
          })}
          <PaymentMethodItem
            Icon={filteredData?.[0]?.icon_url?.["apple_style"]}
            isSelected={selectedPaymentMethod === "new"}
            onPress={() => setSelectedPaymentMethod("new")}
            label={t("new_payment_method")}
            isLast
          />
        </>
      ) : null}
      <DonationModalFooterPortal id="modal-footer-portal-host">
        {selectedPaymentMethod ? (
          <PaymentSlidesFooterContainer>
            {selectedPaymentMethod === "new" ? (
              <MolhamButton
                id="donate-saved-sofort-next-btn"
                endIcon="fe-arrow-right"
                label={t(`next`)}
                className={`w-100`}
                onClick={handleNextSlide}
                disabled={loading}
                isLoading={loading}
              />
            ) : (
              <SubmitButton loading={loading} onSubmit={onSubmit} />
            )}
          </PaymentSlidesFooterContainer>
        ) : null}
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const SavedSofortPaymentStep = {
  name: "SavedSofortPaymentStep",
  title: SavedSofortPaymentStepTitle,
  body: SavedSofortPaymentStepBody,
  footer: true,
};
export default SavedSofortPaymentStep;
