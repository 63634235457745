import { usePreferenceContext } from "context/PreferencesContext";
import dayjs from "dayjs";

import MolhamButton from "components/ReuseableCompos/MolhamButton";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutActions from "../../actions/checkout";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import SelectModal from "../../components/Select/SelectModal";
import PaymentMethodStep from "../PaymentMethod/PaymentMethodStep";

const monthlySubscriptionData = {
  billingDay: Array(28)
    .fill(0)
    .map((_, index) => {
      return { label: (index + 1).toString(), value: index + 1 };
    }),
  totalPayments: Array(11)
    .fill(0)
    .map((_, index) => {
      if (index === 0) {
        return { label: "until_subscription_cancellation", value: 0 };
      }
      return { label: (index + 2).toString(), value: index + 2 };
    }),
  automaticBilling: [
    {
      label: "auto",
      value: "automatic",
      description: "automatic_billing_description",
    },
    {
      label: "manual",
      value: "manual",
      description: "manual_billing_description",
    },
  ],
};
const MonthlySubscriptionStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("monthly_subscription_settings");
};
const MonthlySubscriptionStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const { lang, curr } = usePreferenceContext();

  const {
    loading,
    setCheckoutOrder,
    setLoading,
    monthlySubscription,
    setMonthlySubscription,
    checkoutOrder,
    addStepToHistory,
    replaceStepInHistory,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    checkoutOrder: state.checkoutOrder,
    monthlySubscription: state.monthlySubscription,
    setMonthlySubscription: state.setMonthlySubscription,
    setLoading: state.setLoading,
    setCheckoutOrder: state.setCheckoutOrder,
    loading: state.loading,
    replaceStepInHistory: state.replaceStepInHistory,
  }));
  const DateNow = dayjs();
  const [subscriptionSettings, setSubscriptionSettings] = useState<{
    billing_day: number;
    billing_method: "automatic" | "manual";
  }>({
    billing_day:
      DateNow.date() > 28 && DateNow.date() <= 31 ? 1 : DateNow.date(),
    billing_method: "automatic",
  });
  useEffect(() => {
    if (monthlySubscription) {
      setSubscriptionSettings(monthlySubscription);
    }
  }, []);
  useEffect(() => {
    if (checkoutOrder?.subscription_setup_details?.frequency !== "month") {
      replaceStepInHistory(PaymentMethodStep.name);
    }
  }, [checkoutOrder]);

  const handleNextSlide = async () => {
    setLoading(true);
    try {
      const { billing_day, billing_method } = subscriptionSettings;
      if (checkoutOrder) {
        const resPromise = checkoutActions.setSubscriptionSettings({
          currency_code: checkoutOrder?.currency?.code || curr?.code,
          billing_day: subscriptionSettings.billing_day,
          billing_method: subscriptionSettings.billing_method,
          id: checkoutOrder?.id,
        });
        const res = await resPromise;
        if (res.ok === true) {
          if (billing_method === "automatic") {
            setMonthlySubscription({
              billing_day,
              billing_method,
            });
          } else {
            setMonthlySubscription({
              billing_method,
              billing_day,
            });
          }
          //TODO: add the monthly subscription to the checkout order

          if (res.data) setCheckoutOrder(res.data);
          addStepToHistory(PaymentMethodStep.name);
        } else {
          toast.error(res.error.message);
          // navigateToSlide('monthlySubscriptionSettings');
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  /**
   * we check if the end of the month minus 4 days is before the current day
   * then it means we are in the last 4 days of the month
   * and we should set the next billing after 2 months in number
   */
  const nextSubscriptionStartsNextMonth = dayjs()
    .endOf("month")
    .subtract(4, "days")
    .isBefore(dayjs());

  const labels = {
    billing_day: t("day_x_of_each_month", {
      x: subscriptionSettings.billing_day,
    }),
    billing_method: t(
      monthlySubscriptionData.automaticBilling.find(
        (val) => val.value === subscriptionSettings.billing_method
      )!.label
    ),
  };
  return (
    <ModalBodyElementContainer className="d-flex flex-column align-items-center justify-content-center px-3">
      {subscriptionSettings.billing_method === "automatic" ? (
        <div className="w-100 mb-3">
          <div className="mb-3">{t("billing_day")}</div>
          <SelectModal
            title={t("billing_day")}
            swipeToClose={false}
            selectedOption={{
              label: labels.billing_day,
              value: subscriptionSettings.billing_day,
            }}
            onOptionChange={(val) => {
              setSubscriptionSettings({
                ...subscriptionSettings,
                billing_day: val.value,
              });
            }}
            options={monthlySubscriptionData.billingDay.map((item) => ({
              label: t("day_x_of_each_month", {
                x: item.label,
              }),
              value: item.value,
              description: t("next_billing_is_in_x", {
                x: dayjs()
                  .add(nextSubscriptionStartsNextMonth ? 2 : 1, "month")
                  .set("date", item.value)
                  .locale(lang.shortcut.replaceAll("ar", "ar-iq"))
                  .format(
                    `DD ${
                      lang.shortcut === "ar"
                        ? `(MMMM/${dayjs()
                            .add(
                              nextSubscriptionStartsNextMonth ? 2 : 1,
                              "month"
                            )
                            .set("date", item.value)
                            .locale("ar")
                            .format("MMMM")})`
                        : "MMMM"
                    } YYYY`
                  ),
              }),
            }))}
          />
        </div>
      ) : null}
      <MolhamButton
        id="donate-monthly-next-btn"
        endIcon="fe-arrow-right"
        label={t(`next`)}
        className={`w-100`}
        onClick={handleNextSlide}
        disabled={loading}
        isLoading={loading}
      />
    </ModalBodyElementContainer>
  );
};
const MonthlySubscriptionStep = {
  name: "MonthlySubscriptionStep",
  title: MonthlySubscriptionStepTitle,
  body: MonthlySubscriptionStepBody,
  // footer: MonthlySubscriptionStepFooter,
};
export default MonthlySubscriptionStep;
