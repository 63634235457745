import { CardElement } from "@stripe/react-stripe-js";
import StripeCardElementSkeleton from "components/Skeletons/StripeCardElementSkeleton";
import { useModeContext } from "context/ModeContext";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";

const StripeCardElement = ({}) => {
  const [elementReady, setElementReady] = useState(false);
  const { theme } = useModeContext();
  const { t } = useTranslation("common");
  return (
    <>
      <label className="form-label px-0">{t("card_info")}</label>
      <div className="position-relative">
        <CardElement
          onReady={() => {
            setElementReady(true);
          }}
          id="card-element"
          className="form-control d-flex flex-column justify-content-center py-0 smol-input mb-3"
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: "14px",
                fontFamily: "bahij",
                color:
                  theme === "light" ? " #737491" : "rgba(255,255,255,0.87)",
                "::placeholder": {
                  color: "#b0b3b8",
                },
              },
            },
          }}
        />
        {!elementReady && <StripeCardElementSkeleton />}
      </div>
    </>
  );
};

export default StripeCardElement;
