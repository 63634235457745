import { TCheckOutOrder } from "apiTypes";
import { getMixpanel } from "config/mixpanel";
import { toast } from "react-toastify";
import { sendEvent } from "utils/sendEvent";
import { useCheckoutStore } from "../../CheckoutState";
import cartActions from "../../actions/cart";
import checkoutActions from "../../actions/checkout";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
import ProviderFeeStep from "../ProviderFee/ProviderFeeStep";
import TeamSupportStep from "../TeamSupport/TeamSupportStep";
import SavedShippingAddressStep from "../ShippingAddress/SavedShippingAddressStep";

export const baseOnProceedToCheckout =
  ({
    onFail,
    onSuccess,
  }: {
    onSuccess: (
      checkoutOrder: TCheckOutOrder,
      data?: { amount?: string; quantity?: string }
    ) => void;
    onFail: (data?: { amount?: string; quantity?: string }) => void;
  }) =>
  async (data?: {
    amount?: string;
    quantity?: string;
    currency_code: string;
  }) => {
    const state = useCheckoutStore.getState();
    // const { curr } = usePreferenceContext();

    const {
      selectedPaymentOption,
      setLoading,
      donationAmountMetadata,
      paymentFrequency,
      checkoutOrder,
      setCheckoutOrder,
      addStepToHistory,
    } = state;
    setLoading(true);
    try {
      if (selectedPaymentOption?.id) {
        const res = !checkoutOrder
          ? await checkoutActions.createCheckoutOrder({
              ...data,
              currency_code: data.currency_code || "usd",
              payment_option_id: selectedPaymentOption.id,
              frequency_cycles: paymentFrequency,
              metadata: donationAmountMetadata,
            })
          : await checkoutActions.updateCheckoutOrder({
              ...data,
              currency_code: data.currency_code || "usd",
              payment_option_id: selectedPaymentOption.id,
              id: checkoutOrder?.id,
              frequency_cycles: paymentFrequency,
              metadata: donationAmountMetadata,
            });

        if (res.ok === true) {
          setCheckoutOrder(res.data);
          onSuccess(res.data, data);
          if (res.data.team_support_suggested) {
            addStepToHistory(TeamSupportStep.name);
          } else if (res.data.payment_provider_fees_checkout_suggestion) {
            addStepToHistory(ProviderFeeStep.name);
          } else if (res.data.shipping_address_required) {
            addStepToHistory(SavedShippingAddressStep.name);
          } else {
            addStepToHistory(ConfirmationStep.name);
          }
        } else {
          toast.error(res.error.message);
          onFail(data);
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };
export const baseOnAddToCart =
  ({
    onFail,
    onSuccess,
  }: {
    onSuccess: (data?: { amount?: string; quantity?: string }) => void;
    onFail?: (data?: { amount?: string; quantity?: string }) => void;
  }) =>
  async (data: { amount?: string; quantity?: string }) => {
    const state = useCheckoutStore.getState();
    const {
      selectedPaymentOption,
      setLoading,
      donationAmountMetadata,
      setModalVisible,
    } = state;
    if (selectedPaymentOption?.id) {
      setLoading(true);
      try {
        const res = await cartActions.createCartItem({
          ...data,
          payment_option_id: selectedPaymentOption.id,
          metadata: donationAmountMetadata,
        });
        const mixpanel = getMixpanel();
        mixpanel.track("Add to cart", {
          amount: data.amount,
          quantity: data.quantity,
          payment_option_id: selectedPaymentOption.id,
          name: selectedPaymentOption.name,
          ...donationAmountMetadata,
        });
        if (res.ok === true) {
          await sendEvent({
            pixel: "AddToCart",
          });
          onSuccess(data);
          setModalVisible(false);
        } else {
          toast.error(res.error ? res.error.message : "");
          onFail ? onFail(data) : null;
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    }
  };
