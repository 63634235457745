/* eslint-disable @next/next/no-img-element */
import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import LogoCreator from "components/NavBar/LogoCreator";
import { ReviewModalContext } from "context/ReviewModalContext";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import CloseBtn from "./CloseBtn";

const GetAppModal = () => {
  const { handleCloseReviewModal, showReviewModal } =
    useContext(ReviewModalContext);
  const onModalHide = () => {
    handleCloseReviewModal();
  };

  const { t } = useTranslation("common");
  const { data: utilities } = useUtilities();
  const playstoreBadge = "/assets/app-badges/playstore.png";
  const appstoreBadge = "/assets/app-badges/appstore.png";
  if (!utilities) return null;
  return (
    <MolhamModalOffcanvas
      show={showReviewModal === "app"}
      fullscreen
      onHide={onModalHide}
      body={{ className: "p-0" }}
      swipeToClose={false}
    >
      <div className="px-3 py-4 py-md-6 user-select-none d-flex flex-column justify-content-center align-items-center h-100 position-relative">
        <div
          className="position-absolute"
          style={{ top: 16, insetInlineEnd: 16 }}
        >
          <CloseBtn closeModal={onModalHide} />
        </div>
        <div className="p-3 rounded-circle bg-squircle">
          <LogoCreator hwm="80px" hwd="100px" />
        </div>
        <div className="centered my-4 text-center flex-column">
          <div className="h2 mb-3">
            {t("download_banner") + " " + t("our_app_now_banner")}
          </div>
          <div className="fs-md mb-0 text-center lh17">
            {t("for_a_better_exp")}
          </div>
        </div>
        <div className="centered d-none d-md-flex ">
          <a
            className=""
            href={utilities?.mobile_app?.appstore?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={appstoreBadge}
              alt="Playstore"
              style={{ height: 50 }}
              className="img-fluid"
            />
          </a>
          <a
            className="ms-3"
            href={utilities?.mobile_app?.playstore?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={playstoreBadge}
              alt="Appstore"
              style={{ height: 50 }}
              className="img-fluid"
            />
          </a>
        </div>
        <div className="centered flex-column d-md-none ">
          <a
            className=""
            href={utilities?.mobile_app?.appstore?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={appstoreBadge}
              alt="Playstore"
              style={{ width: 140 }}
              className="img-fluid"
            />
          </a>

          <a
            className={"mt-3"}
            href={utilities?.mobile_app?.playstore?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={playstoreBadge}
              alt="Appstore"
              style={{ width: 140 }}
              className="img-fluid"
            />
          </a>
        </div>

        {/* <div
          className="nav-link fs-sm cursor mt-5 mt-md-4 p-0"
          onClick={onModalHide}
        >
          {t("remind_me_later")}
        </div> */}
      </div>
    </MolhamModalOffcanvas>
  );
};

export default GetAppModal;
