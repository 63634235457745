import { PayPalButtons } from "@paypal/react-paypal-js";
import { usePaypalEnableContext } from "context/PayPalProvider";
import { useEffect, useState } from "react";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import paypalActions from "../../../actions/paypal";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import { handleCheckoutEnd } from "../common";
type Props = {
  isSubscription?: boolean;
};

const PaypalForm: React.FC<Props> = ({ isSubscription }) => {
  const [loading, setLoading] = useState(false);
  const checkoutOrder = useCheckoutStore((state) => state.checkoutOrder);
  const enablePaypal = usePaypalEnableContext();
  useEffect(() => {
    enablePaypal.setEnable(true);
  }, []);

  // const { donor } = useAuthContext();
  const donor = useCheckoutStore().checkoutOrder?.payer;
  const createOrderSubscription = async () => {
    const paymentProvider =
      donor?.available_payment_methods.paypal_subscription.payment_provider;

    setLoading(true);
    const paypalSubscriptionResult = await paypalActions.subscription({
      checkout_order_reference: checkoutOrder!.reference,
      return_url: "molhamteam://paypal-redirect",
      payment_provider_id: paymentProvider.id,
    });
    setLoading(false);

    return paypalSubscriptionResult.ok === true
      ? paypalSubscriptionResult.data.id
      : "";
  };
  const onApproveSubscription = async (data: any) => {
    const paymentProvider =
      donor?.available_payment_methods.paypal_subscription.payment_provider;

    const res = await checkoutHandlerActions.handleCheckoutSource({
      payment_provider_id: paymentProvider.id,
      type: checkoutHandlerActions.CheckoutSourceTypes.paypal.subscription,
      reference: data.subscriptionID,
    });
    handleCheckoutEnd({
      res,
      metadata: {
        "Checkout Order": checkoutOrder.reference,
        Items: checkoutOrder.items,
        currency: checkoutOrder.currency.code,
        amount: checkoutOrder.amount,
        "Payment Method": "Paypal Subscription",
        saved: false,
      },
    });
  };
  const createOrderOneTime = async () => {
    const paymentProvider =
      donor?.available_payment_methods.paypal_checkout_order.payment_provider;

    setLoading(true);
    const paypalCheckoutOrderResult = await paypalActions.checkoutOrder({
      checkout_order_reference: checkoutOrder!.reference,
      return_url: "molhamteam://paypal-redirect",
      payment_provider_id: paymentProvider.id,
    });
    setLoading(false);

    return paypalCheckoutOrderResult.ok === true
      ? paypalCheckoutOrderResult.data.id
      : "";
  };
  const onApproveOneTime = async (data: any) => {
    const paymentProvider =
      donor?.available_payment_methods.paypal_checkout_order.payment_provider;

    const res = await checkoutHandlerActions.handleCheckoutSource({
      payment_provider_id: paymentProvider.id,
      type: checkoutHandlerActions.CheckoutSourceTypes.paypal.checkout_order,
      reference: data.orderID,
    });
    handleCheckoutEnd({
      res,
      metadata: {
        "Checkout Order": checkoutOrder.reference,
        Items: checkoutOrder.items,
        currency: checkoutOrder.currency.code,
        amount: checkoutOrder.amount,
        "Payment Method": "Paypal Checkout Order",
        saved: false,
      },
    });
  };

  return (
    <PaymentSlidesFooterContainer>
      <div
        style={{
          minHeight: 44,
          maxHeight: 44,
        }}
      >
        {isSubscription ? (
          <PayPalButtons
            // className="w-100"
            disabled={loading}
            style={{
              height: 44,
              layout: "vertical",
              shape: "rect",
              tagline: false,
            }}
            createSubscription={createOrderSubscription}
            onApprove={onApproveSubscription}
          />
        ) : (
          <PayPalButtons
            // className="w-100"
            disabled={loading}
            style={{
              height: 44,
              layout: "vertical",
              shape: "rect",
              tagline: false,
            }}
            createOrder={createOrderOneTime}
            onApprove={onApproveOneTime}
          />
        )}
      </div>
    </PaymentSlidesFooterContainer>
  );
};

export default PaypalForm;
