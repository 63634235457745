const SvgComponent = (props:any) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 1000 1000"
  >
    <defs></defs>
    <path
      d="M594.74 187a89.68 89.68 0 0 0-30.8-5.6c-22.09 0-43.87 8.09-63.79 23.65-20.22-15.56-42-23.65-64.09-23.65a89.68 89.68 0 0 0-30.8 5.6c-41.07 15.87-65.65 59.12-65.65 111.7 0 46.36 19.6 88.68 47.6 123.21 28.32 34.54 65 61.61 100.81 77.48a26.86 26.86 0 0 0 12.13 2.8 26.54 26.54 0 0 0 11.83-2.8c35.78-15.87 72.49-42.94 100.81-77.48 28-34.53 47.6-76.85 47.6-123.21 0-52.59-24.58-95.84-65.65-111.7Zm-31.11 53.52a26.81 26.81 0 0 1 10 1.87c15.25 5.6 27.69 27.07 27.69 56.31 0 32.36-14.62 61.92-35.15 86.81-19.29 23.34-44.5 42-66 54.14-21.46-11.82-46.67-30.8-66.27-54.14-20.53-24.89-35.15-54.45-35.15-86.81 0-29.24 12.44-50.71 27.69-56.31a26.81 26.81 0 0 1 9.95-1.87c11.83 0 27.07 7.78 41.38 24.27 5.92 6.53 14.32 9.33 22.4 9.33 7.78 0 16.18-2.8 22.1-9.33 14.6-16.79 29.53-24.28 41.36-24.28ZM603.45 822.65a138.33 138.33 0 0 0 35.16-91.79c0-76.54-62.23-138.45-138.46-138.45-76.53 0-138.76 61.91-138.76 138.45a138.33 138.33 0 0 0 35.16 91.79c-58.81 23.65-100.5 80.58-100.5 147.79a29.55 29.55 0 0 0 29.56 29.56h348.78A29.55 29.55 0 0 0 704 970.44c0-67.21-41.74-124.14-100.55-147.79Zm-103.3-171.13c43.56 0 79 35.47 79.35 79.34-.31 43.87-35.79 79.34-79.35 79.34a79.34 79.34 0 1 1 0-158.68ZM359.83 940.88a101.12 101.12 0 0 1 96.46-71.56h87.42a100.83 100.83 0 0 1 96.46 71.56ZM111.85 766.65c-.62 2.48-1.24 5.28-1.86 7.77v-7.77ZM110 774.42h7.78c0 .63-.32.94-.32 1.56ZM171 651.52l-5.29 5.6-2.8-2.8c2.46-.93 5.26-1.86 8.09-2.8ZM168.48 644.06l2.49 7.46a55.21 55.21 0 0 1-5.91-6.22 11.17 11.17 0 0 1 3.42-1.24Z"
      className="molham-celebration-icon"
    />
    <path
      d="M398.73 556.63a135.92 135.92 0 0 1-7.47 43.24 29.61 29.61 0 0 1-28 20.54 32.67 32.67 0 0 1-9.33-1.55 29.89 29.89 0 0 1-20.22-28 31 31 0 0 1 1.55-9.33 78.7 78.7 0 0 0-75-104.54c-43.56.31-79.34 35.78-79.34 79.65 0 43.56 35.78 79.34 79.34 79.34H316a29.56 29.56 0 1 1 0 59.11h-99.29a101.5 101.5 0 0 0-96.77 71.57H110v7.77l7.46 1.56c0-.62.32-.93.32-1.56H316a21.78 21.78 0 1 1 0 43.57H86a21.65 21.65 0 0 1-21.75-21.8c0-65 41.07-119.78 98.63-141.88l2.8 2.8 5.29-5.6-2.49-7.46a11.17 11.17 0 0 0-3.42 1.24c-2.8.94-5.6 1.87-8.09 2.8a136.57 136.57 0 0 1-35.16-91.47c0-76.54 62.23-138.77 138.46-138.77 76.54 0 138.46 62.23 138.46 138.77Z"
      className="molham-celebration-icon"
    />
    <path
      d="M345.52 796.2A29.55 29.55 0 0 1 316 825.76H86a29.35 29.35 0 0 1-29.53-29.56c0-67.2 41.69-124.14 100.5-148.1 2.49-.93 5.29-1.86 8.09-2.8a11.17 11.17 0 0 1 3.42-1.24l2.49 7.46-5.29 5.6-2.8-2.8c-57.56 22.1-98.63 76.86-98.63 141.88A21.65 21.65 0 0 0 86 818h230a21.78 21.78 0 1 0 0-43.56H117.77c0 .63-.32.94-.32 1.56l-7.45-1.58v-7.77h206a29.74 29.74 0 0 1 29.52 29.55ZM834.94 645.3a55.21 55.21 0 0 1-5.91 6.22l2.49-7.46a11.17 11.17 0 0 1 3.42 1.24ZM837.12 654.32l-2.8 2.8-5.29-5.6c2.8.94 5.6 1.87 8.09 2.8Z"
      className="molham-celebration-icon"
    />
    <path
      d="M943.53 796.2A29.35 29.35 0 0 1 914 825.76H684a29.56 29.56 0 1 1 0-59.11h196a101.21 101.21 0 0 0-96.77-71.57H684a29.56 29.56 0 1 1 0-59.08h55.69a79.5 79.5 0 1 0-79.34-79.34 77.84 77.84 0 0 0 4.36 24.89 23.64 23.64 0 0 1 1.55 9.33 29.89 29.89 0 0 1-20.22 28 32.67 32.67 0 0 1-9.33 1.55 29.61 29.61 0 0 1-28-20.54 135.92 135.92 0 0 1-7.47-43.24c0-76.54 61.92-138.77 138.46-138.77s138.46 62.23 138.46 138.77A136.57 136.57 0 0 1 843 648.1c-2.49-.93-5.29-1.86-8.09-2.8a11.17 11.17 0 0 0-3.42-1.24l-2.49 7.46 5.29 5.6 2.8-2.8a55.21 55.21 0 0 0 5.91-6.22c58.84 23.96 100.53 80.9 100.53 148.1Z"
      className="molham-celebration-icon"
    />
    <path
      d="M843 648.1a55.21 55.21 0 0 1-5.91 6.22l-2.8 2.8-5.29-5.6 2.49-7.46a11.17 11.17 0 0 1 3.42 1.24c2.83.94 5.63 1.87 8.09 2.8ZM533.76 33.6c.31 16.8.62 33.92.62 51 0 16.49-.31 33.29-.31 49.78a33.49 33.49 0 0 1-34.22 33.92 33.36 33.36 0 0 1-24-9.65 34.31 34.31 0 0 1-10-24.27V78.72c0-12.45 0-24.58-.31-37v-2.2c0-8.41 1.55-16.5 5.6-23.34S482.11 4 490.51.62l1.25-.62h17.11l2.18 1.24c3.11 2.18 9.33 4.05 14.62 10.58 4.04 5.92 7.78 13.07 8.09 21.78ZM784.85 106.72V108a33.66 33.66 0 0 1-10.58 24.27c-23.34 23.34-46.67 47-70.32 70.32a34.18 34.18 0 0 1-24.27 10.27 34.76 34.76 0 0 1-24.58-10.58 33.86 33.86 0 0 1 1.25-48.54c23-23.33 46.36-46.67 69.69-70a34.71 34.71 0 0 1 24.58-10.58 37.84 37.84 0 0 1 14 3.11 33.66 33.66 0 0 1 20.23 30.45ZM345.21 201.62l-5.6-5.29 5.6 5.6a34.39 34.39 0 0 1-24.89 10.58 33.78 33.78 0 0 1-21.16-7.51h-.31l-.31-.31-4.05-3.42V201c-22.71-22.72-45.42-45.43-68.14-67.83-6.22-6.54-11.2-14.94-11.2-25.21v-.62A33 33 0 0 1 236 76.23a36.81 36.81 0 0 1 14.31-3.11 30.88 30.88 0 0 1 22.09 9.33q36.87 35.94 72.81 72.81a32 32 0 0 1 9.64 23 34.11 34.11 0 0 1-9.64 23.36Z"
      className="molham-celebration-icon"
    />
  </svg>
);
export { SvgComponent as ResponseSVG };
