import Link from "next/link";
import { useRouter } from "next/router";

type Props = {
  data: any;
  isLast: boolean;
};

const NavBarSearchResult = ({ data, isLast }: Props) => {
  const { contents, path } = data;
  const { name, description } = contents;
  const { locale } = useRouter();
  return (
    <Link
      className={`d-flex align-items-center justify-content-between py-3 text-decoration-0 w-100 ${
        !isLast && "border-bottom"
      }`}
      href={path.web}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="d-flex flex-column px-3">
        <span className=" text-white fs-md fw-medium">{name?.[locale]}</span>
        <span className=" text-muted text-truncate fs-sm">
          {description?.[locale]}
        </span>
      </div>
      <span>
        <i className="fe fe-chevron-right fs-xxl text-white px-3"></i>{" "}
      </span>
    </Link>
  );
};

export default NavBarSearchResult;
