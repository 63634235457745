import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import React, { ComponentProps } from "react";
import styled, { StyledComponent } from "styled-components";
import useRTL from "../../../../../../customHooks/useRTL";
import CheckboxRoundedIcon from "../CheckboxRoundedIcon";
import RadioIcon from "../RadioIcon";
type Option = {
  label: string;
  value: any;
  description?: string;
};
type Props =
  | {
      options: Option[];
      selectedOption: Option;
      onOptionChange: (option: Option) => void;
      title?: string;
      buttonProps?: ComponentProps<StyledComponent<"button", any, {}, never>>;
      swipeToClose?: boolean;
      openingComponent?: React.ReactNode | ((onPress: () => void) => void);
      multiple?: false;
      name?: string;
    }
  | {
      options: Option[];
      selectedOption: Option[];
      onOptionChange: (option: Option[]) => void;
      title?: string;
      buttonProps?: ComponentProps<StyledComponent<"button", any, {}, never>>;
      swipeToClose?: boolean;
      openingComponent?: React.ReactNode | ((onPress: () => void) => void);
      multiple: true;
      name?: string;
    };
const SelectModal: React.FC<Props> = ({
  options,
  selectedOption,
  swipeToClose = true,
  onOptionChange,
  title,
  buttonProps,
  openingComponent,
  multiple,
  name,
}) => {
  const [showModalOffCanvas, setShowModalOffCanvas] = React.useState(false);
  const handleCloseModalOffCanvas = () => setShowModalOffCanvas(false);
  const handleShowModalOffCanvas = () => setShowModalOffCanvas(true);

  const isRTL = useRTL();
  const onOptionClick = (option: Option) => {
    if (!multiple) {
      onOptionChange(option as any);
      handleCloseModalOffCanvas();
    } else {
      const newSelectedOption = !Array.isArray(selectedOption)
        ? [option]
        : selectedOption?.map((op) => op?.value).includes(option?.value)
        ? selectedOption?.filter((op) => op?.value !== option?.value)
        : [...selectedOption, option];
      onOptionChange(newSelectedOption);
    }
  };
  return (
    <>
      {openingComponent ? (
        typeof openingComponent === "function" ? (
          openingComponent(handleShowModalOffCanvas)
        ) : (
          openingComponent
        )
      ) : (
        <StylesSelectButton
          name={name ?? ""}
          onClick={handleShowModalOffCanvas}
          {...buttonProps}
          className={` form-control mb-3 form-select w-100 ${
            isRTL ? "text-end" : "text-start"
          } rounded-2 ${buttonProps?.className}`}
        >
          {multiple === true
            ? selectedOption.map((op) => op?.label).join(", ")
            : selectedOption?.label}
        </StylesSelectButton>
      )}

      <MolhamModalOffcanvas
        show={showModalOffCanvas}
        onHide={handleCloseModalOffCanvas}
        swipeToClose={swipeToClose}
        fullscreen
        header={{ label: title }}
        modalClassName="p-0 z-2002"
        offCanvasClassname="p-0 z-2002"
        body={{
          style: {
            height: "100%",
          },
          className: "p-0 z-2002",
        }}
      >
        <div className="h-100">
          {options.map((option, optionIdx) => (
            <div
              onClick={() => {
                onOptionClick(option);
              }}
              className={`d-flex align-items-center justify-content-between w-100 py-0 ${
                optionIdx === options.length - 1
                  ? "border-bottom-0"
                  : "border-bottom"
              }`}
              style={{
                paddingInlineStart: "1rem",
                paddingInlineEnd: "1.23rem",
              }}
              key={option.label}
            >
              <div className="d-flex flex-column py-3">
                <label
                  htmlFor={option.label}
                  className={`mb-0 d-block cursor fs-base`}
                >
                  {option.label}
                </label>
                <label
                  htmlFor={option.description}
                  className={`mb-0 d-block cursor fs-sm text-muted  `}
                >
                  {option.description}
                </label>
              </div>
              <div>
                {multiple === true ? (
                  <CheckboxRoundedIcon
                    checked={selectedOption
                      ?.map((op) => op.value)
                      .includes(option.value)}
                    onClick={() => {
                      onOptionClick(option);
                    }}
                    id={option.label}
                  />
                ) : (
                  <RadioIcon
                    checked={selectedOption?.value === option.value}
                    onClick={() => {
                      onOptionClick(option);
                    }}
                    id={option.label}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </MolhamModalOffcanvas>
    </>
  );
};

export default SelectModal;

const StylesSelectButton = styled.button`
  height: 50;
  font-size: 16;
  width: 100%;
  border-radius: 0;
  color: var(--dark-gray);
  &:hover {
    border-color: var(--primary2) !important;
    background-color: var(--white) !important;

    color: var(--dark-gray);
  }
`;
