const SvgComponent = (props:any) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 1000 1000"
  >
    <defs></defs>
    <path
      d="M597.12 666.74c-31.57-12-66.61-4-97.12 21.52-30.54-25.48-65.62-33.49-97.12-21.52-39.59 15-64.18 57.25-64.18 110.18 0 93.83 78.71 172 151.82 205a23.23 23.23 0 0 0 19 0c73.11-32.94 151.83-111.12 151.83-205-.05-52.92-24.65-95.15-64.23-110.18ZM500 935.29c-58.71-30-115.22-91.86-115.22-158.37 0-32.81 13.53-59.14 34.48-67.11a37.7 37.7 0 0 1 13.39-2.43c16.09 0 33.73 9.77 50.11 28.22 8.78 9.81 25.7 9.81 34.47 0 21.07-23.76 44.13-33.12 63.51-25.79 21 8 34.47 34.3 34.47 67.11 0 66.51-56.5 128.4-115.21 158.37Z"
      className="molham-celebration-icon"
    />
    <path
      d="M500 1000a39.6 39.6 0 0 1-16-3.42c-77.75-35-161.37-118.7-161.37-219.66 0-59.78 28.57-107.77 74.57-125.24 33.18-12.6 70.06-6.63 102.84 16.31 32.71-22.91 69.58-28.91 102.83-16.31 46 17.47 74.56 65.46 74.56 125.24 0 101-83.62 184.62-161.32 219.64A39.62 39.62 0 0 1 500 1000Zm-67.33-322.63a67.55 67.55 0 0 0-24.07 4.42c-33.18 12.6-53.8 49.05-53.8 95.13 0 86.65 73.79 159.37 142.34 190.27a7.25 7.25 0 0 0 5.76 0c68.51-30.88 142.29-103.6 142.29-190.25 0-46.08-20.61-82.53-53.79-95.12-26-9.86-54.75-3.15-81.07 18.82l-10.33 8.6-10.33-8.62c-18.41-15.37-38.02-23.25-57-23.25Zm67.33 276-7.32-3.74c-61.73-31.5-124-97.13-124-172.71 0-39.55 17.6-71.8 44.85-82.16 25.88-9.79 55.69 1.32 81.27 30.15 2.16 2.4 8.21 2.44 10.43 0 25.65-28.94 55.29-40 81.21-30.12 27.27 10.37 44.87 42.62 44.87 82.17 0 75.58-62.27 141.21-124 172.71Zm-67.36-229.88a21.91 21.91 0 0 0-7.7 1.39c-14.37 5.47-24.05 26.39-24.05 52 0 58.7 50.05 112.5 99.11 140.09 49-27.59 99.1-81.39 99.1-140.09 0-25.65-9.68-46.57-24.08-52-12.71-4.78-29.4 3-45.73 21.41-14.56 16.29-44.07 16.24-58.54.06-12.9-14.55-26.75-22.86-38.11-22.86Z"
      className="molham-celebration-icon"
    />
    <path
      d="M685.22 497.33c55.91-50.63 91.29-123.52 91.29-204.72 0-152.47-124-276.51-276.51-276.51s-276.51 124-276.51 276.51c0 81.2 35.38 154.09 91.28 204.72C179.44 545.77 85.23 675.65 85.23 822.6a69.2 69.2 0 0 0 69.13 69.12h92.17a23 23 0 0 0 0-46.08h-92.17a23.07 23.07 0 0 1-23.05-23c0-138.42 96.57-259.14 230.44-290.88a274.14 274.14 0 0 0 276.49 0c133.87 31.7 230.44 152.42 230.44 290.84a23.07 23.07 0 0 1-23 23h-92.21a23 23 0 1 0 0 46.08h92.17a69.2 69.2 0 0 0 69.13-69.12c0-146.91-94.22-276.79-229.55-325.23ZM269.57 292.61C269.57 165.56 373 62.19 500 62.19s230.42 103.37 230.42 230.42S627.05 523 500 523 269.57 419.66 269.57 292.61Z"
      className="molham-celebration-icon"
    />
    <path
      d="M845.64 907.83h-92.17a39.15 39.15 0 1 1 0-78.3h92.17a6.94 6.94 0 0 0 6.93-6.94c0-127.43-88.78-241.53-212-273.69-86.37 47.58-194.81 47.57-281.17 0-123.2 32.16-212 146.26-212 273.69a6.94 6.94 0 0 0 6.94 6.94h92.17a39.15 39.15 0 1 1 0 78.3h-92.15a85.32 85.32 0 0 1-85.23-85.24c0-143.77 86.15-273.81 216.81-331.11a292.79 292.79 0 0 1-78.56-198.86C207.38 131.27 338.64 0 500 0s292.62 131.27 292.62 292.62a292.8 292.8 0 0 1-78.56 198.86c130.67 57.3 216.81 187.34 216.81 331.11a85.32 85.32 0 0 1-85.23 85.24Zm-92.17-46.09a6.94 6.94 0 1 0 0 13.88h92.17a53.09 53.09 0 0 0 53-53c0-138.62-87.95-263.24-218.86-310.09l-25.31-9.06 19.92-18c54.65-49.5 86-119.77 86-192.78C760.4 149 643.59 32.21 500 32.21S239.59 149 239.59 292.62c0 73 31.34 143.27 86 192.78l19.92 18-25.31 9.1C189.29 559.35 101.34 684 101.34 822.59a53.08 53.08 0 0 0 53 53h92.17a6.94 6.94 0 1 0 0-13.88h-92.15a39.19 39.19 0 0 1-39.15-39.15c0-144.26 102.12-273.19 242.83-306.54l6.24-1.48 5.55 3.23c79.41 46.08 180.91 46.08 260.33 0l5.55-3.23 6.25 1.48c140.7 33.35 242.82 162.28 242.82 306.54a39.19 39.19 0 0 1-39.14 39.15ZM500 539.15c-135.94 0-246.53-110.6-246.53-246.53S364.06 46.08 500 46.08s246.53 110.6 246.53 246.54S635.94 539.15 500 539.15Zm0-460.85c-118.17 0-214.32 96.14-214.32 214.32S381.83 506.94 500 506.94s214.32-96.15 214.32-214.32S618.17 78.3 500 78.3Z"
      className="molham-celebration-icon"
    />
  </svg>
);
export { SvgComponent as OrphansSVG };
