import { memo } from "react";
type Props = {
  color?: string;
  isLoading: boolean;
  className?: string;
} & React.ComponentProps<'div'>;

const SmallSpinner = ({ color, isLoading, className, ...props }:Props) => {
  return (
    <>
      {isLoading && (
        <div
          className={`spinner-border text-${
            color ? color : "primary"
          } spinner-border-sm
          } ${className ? className : ""}`}
          {...props}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
};

export default memo(SmallSpinner);
