import { Children } from "react";

import { memo, useContext } from "react";

import CustomContentLoader from "components/ReuseableCompos/CustomContentLoader";
import useMobile from "customHooks/useMobile";
import { useRouter } from "next/router";
import { useCartContext } from "../../context/CartContext";
import { FilterOffcanvasContext } from "../../context/FilterOffcanvasContext";
import { NavHeaderContext } from "../../context/NavHeaderContext";
import { NotificationContext } from "../../context/NotificationContext";
import useRTL from "../../customHooks/useRTL";
import Badge from "../ReuseableCompos/Badge";

type Props = {
  setShowSearchBar: (show: boolean) => void;
  donor: any;
  isDonorLoggedIn: boolean;
  customSearch?: {
    onClick: () => void;
    icon: string | JSX.Element;
  };
};
type ActionNavButtonProps = {
  children: any;
  onClick: () => void;
  isDonorLoggedIn: boolean;
  className?: string;
  props?: any;
};

const ActionNavButton = ({
  children,
  onClick,
  isDonorLoggedIn,
  className,
  ...props
}: ActionNavButtonProps) => {
  const isMobile = useMobile("lg");
  if (typeof isDonorLoggedIn === "undefined") {
    return (
      <span
        {...props}
        style={
          className && className.includes("d-lg-block") ? {} : { minWidth: 44 }
        }
        className={`navbar-tool-icon-box cursor ${className || ""}`}
        onClick={onClick}
      >
        <>
          {Children.map(children, (child) => (
            <>{child}</>
          ))}
        </>
      </span>
    );
  } else {
    return (
      <span
        {...props}
        style={
          className && className.includes("d-lg-block")
            ? { marginInlineStart: "1rem" }
            : { minWidth: 44 }
        }
        className={`navbar-tool-icon-box cursor ${className || ""}`}
        onClick={onClick}
      >
        {/* search icon */}
        {isDonorLoggedIn && !isMobile ? (
          <CustomContentLoader viewBox={`0 0 22 22`} style={{ width: 22 }}>
            <rect x="0" y="0" rx="4" ry="4" width="22" height="22"></rect>
          </CustomContentLoader>
        ) : (
          <>
            {Children.map(children, (child) => (
              <>{child}</>
            ))}
          </>
        )}
      </span>
    );
  }
};

const NavbarBtns = ({
  setShowSearchBar,
  donor,
  isDonorLoggedIn,
  customSearch,
}: Props) => {
  const { handleShowNotificationOffCanvas } = useContext(NotificationContext);
  const { handleShowCartOffCanvas, carts } = useCartContext();
  const { handleShowFilterOffCanvas } = useContext(FilterOffcanvasContext);
  const { headerNavMeta } = useContext(NavHeaderContext);
  const { asPath } = useRouter();
  const isRTL = useRTL();
  return (
    <div className="navbar-tool">
      {donor && (
        <ActionNavButton
          className="d-none d-lg-block"
          onClick={handleShowCartOffCanvas}
          isDonorLoggedIn={isDonorLoggedIn}
        >
          <i className="fe-shopping-cart"></i>
          {carts?.length ? (
            <Badge style={{ right: "-14px" }} count={carts?.length || 0} />
          ) : null}
        </ActionNavButton>
      )}

      {donor && (
        // <div className="navbar-tool d-none d-lg-block">
        <ActionNavButton
          className="d-none d-lg-block"
          onClick={handleShowNotificationOffCanvas}
          isDonorLoggedIn={isDonorLoggedIn}
        >
          <i className="fe-bell"></i>
          {donor?.unread_notifications_count ? (
            <Badge
              style={{ right: "-8px" }}
              count={donor?.unread_notifications_count}
            />
          ) : null}
        </ActionNavButton>
        // </div>
      )}

      <ActionNavButton
        className={`search-bar-icon d-none d-lg-block`}
        onClick={() => setShowSearchBar(true)}
        isDonorLoggedIn={isDonorLoggedIn}
      >
        <i className="fe-search search-icon"></i>
      </ActionNavButton>

      {(!headerNavMeta?.section ||
        (headerNavMeta.section === "profile" && customSearch)) &&
        (!customSearch ? (
          <ActionNavButton
            className="search-bar-icon d-flex align-items-center justify-content-end d-lg-none "
            onClick={() => setShowSearchBar(true)}
            isDonorLoggedIn={isDonorLoggedIn}
          >
            <i className="fe-search search-icon"></i>
          </ActionNavButton>
        ) : (
          <ActionNavButton
            className="search-bar-icon d-flex align-items-center justify-content-end d-lg-none "
            onClick={customSearch?.onClick}
            isDonorLoggedIn={isDonorLoggedIn}
          >
            {typeof customSearch?.icon == "string" ? (
              <i className={`${customSearch?.icon} search-icon`}></i>
            ) : (
              customSearch?.icon
            )}
          </ActionNavButton>
        ))}

      {headerNavMeta?.section === "donate" && (
        <>
          <ActionNavButton
            isDonorLoggedIn={isDonorLoggedIn}
            onClick={handleShowFilterOffCanvas}
            className="d-flex align-items-center justify-content-end d-lg-none "
          >
            <i className="fe-sliders fs-xxl"></i>
          </ActionNavButton>
        </>
      )}
      {headerNavMeta?.section === "profile" && !customSearch && (
        <ActionNavButton
          isDonorLoggedIn={isDonorLoggedIn}
          className="d-flex align-items-center justify-content-end d-lg-none  "
          onClick={handleShowNotificationOffCanvas}
        >
          <i className="fe-bell fs-xxl"></i>
          {donor?.unread_notifications_count ? (
            <Badge
              style={{ right: "0px" }}
              count={donor?.unread_notifications_count}
            />
          ) : null}
        </ActionNavButton>
      )}
    </div>
  );
};

export default memo(NavbarBtns);
