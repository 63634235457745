import useMobile from "customHooks/useMobile";
import React from "react";
type Props = {
  children: React.ReactNode;
  className?: string;
  customPy?: string;
  customPxPhone?: string;
  customPxWeb?: string;
  as?: string;
  onClick?: () => void;
};
const ProfileMenuItemContainer: React.FC<Props> = ({
  children,
  className,
  customPy,
  customPxPhone,
  customPxWeb,
  as = "div",
  ...props
}) => {
  const matches = useMobile("md");
  const Tag: any = as;
  return (
    <Tag
      {...props}
      className={`${customPy ? customPy : "py-4"} ${
        !matches ? customPxWeb || "px-0" : customPxPhone || "px-3"
      } mt-0 cc ${className}`}
    >
      {children}
    </Tag>
  );
};

export default ProfileMenuItemContainer;
