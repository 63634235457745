import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import type { PaymentRequest } from "@stripe/stripe-js";
import { useModeContext } from "context/ModeContext";
import useTranslation from "next-translate/useTranslation";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";

type Props = {
  paymentRequest: PaymentRequest;
};

const ApplePayForm: React.FC<Props> = ({ paymentRequest }) => {
  const { t } = useTranslation("common");
  const { theme } = useModeContext();
  return (
    <>
      <PaymentSlidesFooterContainer>
        {paymentRequest ? (
          <PaymentRequestButtonElement
            options={{
              paymentRequest,

              disableMultipleButtons: true,
              style: {
                paymentRequestButton: {
                  theme: theme === "light" ? "dark" : "light",
                },
              },
            }}
          />
        ) : (
          <p>{t("apple_pay_not_supported")}</p>
        )}
      </PaymentSlidesFooterContainer>
    </>
  );
};

export default ApplePayForm;
