import SvgCreator from "components/ReuseableCompos/SvgCreator";
import useMobile from "customHooks/useMobile";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { donationCardsRoutes } from "public/static-data/routesArray";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import CustomOffCanvasHeader from "../CustomOffCanvasHeader";
import CustomOffcanvas from "../CustomOffcanvas";
import ActiveLink from "../ReuseableCompos/ActiveLink";
import NavbarMenuActionBar from "./NavbarMenuActionBar";

type Props = {
  showNavbarMenuOffCanvas: boolean;
  handleCloseNavbarMenuOffCanvas: () => void;
};

const NavbarMenuOffCanvas = ({
  showNavbarMenuOffCanvas,
  handleCloseNavbarMenuOffCanvas,
}: Props) => {
  const closeSideBar = () => {
    handleCloseNavbarMenuOffCanvas();
  };
  const { locale, asPath } = useRouter();
  const { donor } = useContext(AuthContext);
  const isSmallMobile = useMobile("420");
  const isMobile = useMobile("md");
  const { t } = useTranslation("common");
  const { data: utilities } = useUtilities();
  const donSec = utilities?.donation_sections.filter((item) => {
    return item.appears_in_menu == true;
  });
  const targetTypes = utilities?.target_types;

  const donSecItems = donSec?.map((item) => {
    const route = item.target_types
      ? targetTypes?.[item.target_types[0]].page_path
      : donationCardsRoutes?.[item.key]?.path;
    return {
      link: route.includes("#") || route.includes("/") ? route : "/" + route,
      title: item.name[locale],
      type: "item",
      fuzzy: true,
      icon: {
        type: "svg",
        src: item.icon.uri,
      },
    };
  });
  const menuOffcanvasMeta = [
    {
      link: "/help/faqs/payment",
      title: "donation_payment_methods",
      type: "item",
      fuzzy: false,
      icon: {
        type: "feather",
        src: "credit-card",
      },
    },

    // {
    //   link: "/donate",
    //   title: "donation_sections",
    //   type: "item",
    //   icon: {
    //     type: "feather",
    //     src: "gift",
    //   },
    // },
    {
      link: "#chat-with-support",
      title: "chat_with_support",
      fuzzy: true,
      type: "item",
      icon: {
        type: "feather",
        src: "message-square",
      },
    },

    {
      link: "/transparency",
      title: "transparency_file",
      type: "item",
      fuzzy: true,

      icon: {
        type: "feather",
        src: "file-text",
      },
    },

    // {
    //   link: "/blog",
    //   title: "blogs",
    //   type: "item",
    //   icon: {
    //     type: "feather",
    //     src: "book",
    //   },
    // },
    {
      link: "/",
      type: "section",
      title: "donation_sections",
      icon: null,
      fuzzy: false,
    },

    ...(donSecItems || []),

    {
      link: "/",
      type: "section",
      title: "donation_sections",
      icon: null,
      fuzzy: false,
    },
    {
      link: "/events/create",
      title: "add_new_envents",
      type: "item",
      fuzzy: true,

      icon: {
        type: "feather",
        src: "plus",
      },
    },
    {
      link: "/blog",
      title: "blogs",
      type: "item",
      fuzzy: false,
      icon: {
        type: "feather",
        src: "book",
      },
    },
    {
      link: "/",
      type: "section",
      title: "help_center",
      icon: null,
      fuzzy: false,
    },

    {
      link: "/help/faqs",
      title: "faqs",
      type: "item",
      fuzzy: false,

      icon: {
        type: "feather",
        src: "help-circle",
      },
    },
    {
      link: "/about/about",
      title: "about_us",
      type: "item",
      fuzzy: false,

      icon: {
        type: "feather",
        src: "info",
      },
    },

    {
      link: "/help/contact-us",
      title: "contact_us",
      type: "item",
      fuzzy: false,
      icon: {
        type: "feather",
        src: "phone",
      },
    },
    {
      link: "/help/terms",
      title: "terms_and_conditions",
      type: "item",
      fuzzy: false,

      icon: {
        type: "feather",
        src: "file-text",
      },
    },
    {
      link: "/help/privacy",
      title: "privacy_policy",
      type: "item",
      fuzzy: false,

      icon: {
        type: "feather",
        src: "file-text",
      },
    },
  ];
  // const ver = process.env.BUILD_ID || "0.1.1";
  return (
    <CustomOffcanvas
      show={showNavbarMenuOffCanvas}
      onHide={handleCloseNavbarMenuOffCanvas}
      extraStyles={isSmallMobile ? { width: "80%" } : {}}
    >
      {!isMobile && (
        <CustomOffCanvasHeader
          showButton
          onCloseBtnClick={handleCloseNavbarMenuOffCanvas}
        >
          {t(`menu`)}
        </CustomOffCanvasHeader>
      )}
      <div className="px-0 position-relative h-100 overflow-auto">
        {/* <CustomSimpleBar> */}
        {isMobile && <div className="border-bottom" />}
        <NavbarMenuActionBar />
        <ul className="navbar-nav pt-0 text-capitalize">
          {/* {donor && (
            <li
              className="nav-item cursor py-0 border-top-0"
              onClick={closeSideBar}
            >
              <ActiveLink
                prefetch={false}
                activeClassName="active fw-bold"
                href="/profile"
              >
                <DividedMenuItem className="py-075 border-bottom">
                  <div className="v-centered">
                    <img
                      src={
                        donor?.avatar
                          ? donor?.avatar?.thumbnails["1:1"]
                          : profileAvatar
                      }
                      className="object-fit-cover rounded-circle"
                      alt=""
                      style={{ width: 48, height: 48 }}
                    />
                    <div className="d-flex flex-column mx-2 align-items-start text-capitalize">
                      <span className="">{donor?.name || "guest"}</span>
                      <span className="fs-xs text-muted pt-1">
                        {t("profile")}
                      </span>
                    </div>
                  </div>
                </DividedMenuItem>
              </ActiveLink>
            </li>
          )} */}
          {menuOffcanvasMeta.map((item, idx) => {
            const lastItem = idx == menuOffcanvasMeta.length - 1;
            if (item.title == "add_new_envents" && !donor) {
              return null;
            }
            if (item.type == "item") {
              return (
                <li
                  className="nav-item py-0 border-top"
                  key={item.title}
                  onClick={closeSideBar}
                >
                  <ActiveLink
                    prefetch={false}
                    activeClassName="active fw-bold"
                    href={item?.link || "/"}
                    passHref
                    fuzzy={item?.fuzzy}
                  >
                    <div
                      onClick={() => {
                        nextCheckoutItemSourceManager.setNextCheckoutItemSource(
                          nextCheckoutItemSourceManager.options.donate_menu
                        );
                      }}
                      className={`nav-link  v-centered justify-content-between px-3 py-25`}
                    >
                      <div className="v-centered">
                        <div
                          className="rounded-circle bg-squircle p-1 d-flex align-items-center justify-content-center"
                          style={{ minWidth: "34px", minHeight: "34px" }}
                        >
                          {item.icon && item.icon?.type == "feather" && (
                            <i className={`fe-${item?.icon.src} fs-lg`}></i>
                          )}
                          {item.icon && item.icon?.type == "svg" && (
                            <SvgCreator
                              color={svgColor(item?.link, asPath)}
                              uri={item?.icon.src}
                              hwd="20"
                              hwm="20"
                              p="p-0"
                            />
                          )}
                        </div>
                        <span className="ms-2 fs-sm fw-bold">
                          {t(item.title)}
                        </span>
                      </div>
                      <i className="fe fe-chevron-right fs-xl fw-bold " />
                    </div>
                  </ActiveLink>
                </li>
              );
            } else if (item.type == "section") {
              return (
                <div
                  className=" border-top"
                  key={item.title}
                  style={{ height: 64 }}
                >
                  {/* <span className="fw-bold fs-18 text-white text-uppercase">
                    {t(item.title)}
                  </span> */}
                </div>
              );
            }
          })}
        </ul>
        <div
          className="position-absolute w-100 text-center"
          style={{
            bottom: 16,
          }}
        ></div>
      </div>
    </CustomOffcanvas>
  );
};

export default NavbarMenuOffCanvas;

const svgColor = (href, asPath) => {
  const hrefTokens = href?.substr(1)?.split("/");
  const pathTokens = asPath?.substr(1)?.split("/");
  let matched = false;
  for (let i = 0; i < hrefTokens?.length; i++) {
    if (hrefTokens[i] === pathTokens[i]) {
      matched = true;
      break;
    }
  }
  if (matched) {
    return "var(--primary2)";
  } else {
    return "var(--gray)";
  }
};
