import { useRouter } from "next/router";

// this hook returns if the direction is rtl or not
const useRTL = () => {
  const { locale } = useRouter();
  const isRTL = locale === "ar";
  return isRTL;
};

export default useRTL;
