import { m, useMotionValue } from "framer-motion";
import { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import BodyLock from "utils/bodyLock";
import MolhamMOCHeader from "./MolhamMOCHeader";
import { SwipeHandlerDiv } from "./SwipeHandlerDiv";
import { OffcanvasPlacement } from "react-bootstrap/esm/Offcanvas";

const MolhamOffcanvas: React.FC<Props> = ({
  children,
  onHide,
  show,
  header,
  footer,
  swipeToClose = true,
  body,
  fullscreen,
  className = "",
  placement = "bottom",
}) => {
  const YMotionValue = useMotionValue(0);
  const hght = useMotionValue(0);
  useEffect(() => {
    if (show) YMotionValue.set(0);
  }, [show]);

  const sharedProps = {
    onEnter: BodyLock.lock,
    onExit: BodyLock.unlock,
    show,
    onHide,
  };
  const TitleNode = () =>
    header?.label && (
      <MolhamMOCHeader
        onCloseBtnClick={onHide}
        centered={header?.centered}
        label={header?.label}
        noBorder={header?.noBorder}
        labelClassName={header?.labelClassName}
        customButton={header?.customButton}
        showCustomBtnCond={header?.showCustomBtnCond}
        noCloseBtn={header?.noCloseBtn}
      />
    );
  return (
    <Offcanvas
      placement={placement}
      scroll={false}
      bsPrefix={
        fullscreen
          ? `${className} offcanvas offcanvas-bottom h-100`
          : `${className} offcanvas`
      }
      style={{
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
      }}
      {...sharedProps}
    >
      <m.div
        style={{
          borderRadius:
            header?.borderRadius !== false ? "1.25rem 1.25rem 0 0" : "0rem",
          y: YMotionValue,
        }}
        id="offCanvasMdiv"
        className="d-flex flex-column h-100 bg-white"
      >
        {swipeToClose ? (
          <div className="position-relative">
            <SwipeHandlerDiv
              className={`zindex9999 d-flex flex-column pb-1 minHfit w-100 bg-trans ${
                !header?.label && "position-absolute"
              } swiper-handler-min-height`}
              disabled={!swipeToClose}
              style={{ touchAction: "none" }}
              onSwipeDown={swipeToClose && onHide}
              hght={hght}
              YMotionValue={YMotionValue}
            >
              {swipeToClose && (
                <div className="w-100 d-flex align-items-center justify-content-center ">
                  <div
                    className="my-1 bg-swipedown-pill rounded-pill"
                    style={{ width: 50, height: 6 }}
                  />
                </div>
              )}
              <TitleNode />
            </SwipeHandlerDiv>
          </div>
        ) : (
          <TitleNode />
        )}

        <Offcanvas.Body className={`${body?.className}`} style={body?.style}>
          {children}
        </Offcanvas.Body>
        {footer?.content && (
          <div
            className={`border-top 
         ${footer?.padding ? "p-4" : ""} 
         ${footer?.className ?? ""}
         `}
          >
            {footer.content}
          </div>
        )}
      </m.div>
    </Offcanvas>
  );
};

export default MolhamOffcanvas;
type Props = {
  show: boolean;
  placement?: OffcanvasPlacement;
  onHide: () => void;
  header?: {
    label?: string;
    labelClassName?: string;
    centered?: boolean;
    customButton?: React.ReactNode;
    showCustomBtnCond?: boolean;
    borderRadius?: boolean;
    noCloseBtn?: boolean;
    noBorder?: boolean;
  };
  footer?: {
    content?: React.ReactNode;
    padding?: boolean;
    className?: string;
  };
  children: React.ReactNode;
  swipeToClose?: boolean;
  body: {
    style?: React.CSSProperties;
    className?: string;
  };
  fullscreen?: boolean;
  className?: string;
};
