import { SplashLogo } from "config";
import useMobile from "customHooks/useMobile";
import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";
import styled from "styled-components";
import ActiveLink from "./ReuseableCompos/ActiveLink";

type Props = {};

function Footer({}: Props) {
  const isRTL = useRTL();
  const { t } = useTranslation("common");
  const { pathname } = useRouter();
  const isMobile = useMobile("lg");
  const Item = ({
    path,
    index,
  }: {
    path: {
      path: string;
      name: string;
    };
    index: number;
  }) => {
    return (
      <div key={index}>
        <ActiveLink
          prefetch={false}
          href={path.path}
          activeClassName="active fw-bold"
        >
          <a className="dropdown-item">
            <span style={{ color: "#fff" }} className={`d-block`}>
              {t(path.name)}
            </span>
          </a>
        </ActiveLink>
      </div>
    );
  };

  if (isMobile && pathname !== "/") {
    return null;
  }
  return (
    <FooterWrapper className="cs-footer bg-dark py-3" dir="ltr">
      <div
        className={`container d-flex justify-content-between align-items-center px-0 ${
          isRTL && "flex-row-reverse"
        }`}
      >
        <div
          className="d-flex flex-column align-items-center justify-content-center h-100 "
          style={{
            padding: 24,
            minHeight: "fit-content",
          }}
        >
          <Image
            alt="splash-logo"
            className="mt-0 mt-md-3"
            width={150}
            height={150}
            src={SplashLogo}
          />
          <div
            style={{ color: "#fff" }}
            className={`row mt-3 text-center text-wrap `}
          >
            {t("all_rights_res")}
          </div>
        </div>
        <div className="d-none d-md-flex align-items-start">
          <div className={`d-flex ${isRTL && "flex-row-reverse"} `}>
            <div className="d-flex flex-column">
              {helpPaths.map((path, index) => {
                return <Item path={path} index={index} key={index} />;
              })}
            </div>
            <div className="d-flex flex-column">
              {aboutPaths.map((path, index) => {
                return <Item path={path} index={index} key={index} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
const FooterWrapper = styled.footer`
  border-top: 2px solid var(--light-gray);
`;
const aboutPaths = [
  { path: "/about/about", name: "abouttheteam" },
  { path: "/about/molham", name: "about_molham" },
  { path: "/about/programs", name: "ourdepsandprojects" },
  { path: "/about/legalnotice", name: "legalnotice" },
  { path: "/about/reports", name: "annual-reports" },
];
const helpPaths = [
  { path: "/help/faqs/payment", name: "donation_payment_methods" },
  { path: "/help/faqs", name: "faqs" },
  { path: "/help/terms", name: "payment_process_terms_of_use" },
  { path: "/help/privacy", name: "privacy_policy" },
  { path: "/help/contact-us", name: "contact_us" },
  { path: "#chat-with-support", name: "chat_with_support" },
  { path: "/feedback", name: "send_feedback" },

];
