import SvgCreator from "components/ReuseableCompos/SvgCreator";
import { useModeContext } from "context/ModeContext";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import useRTL from "customHooks/useRTL";
import useUtilities from "customHooks/useUtilities";
import { useAllTextColors } from "layout/Layout";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { donationCardsRoutes } from "public/static-data/routesArray";
import { Dropdown } from "react-bootstrap";
import ActiveLink from "../ReuseableCompos/ActiveLink";
const onLinkClick = () => {
  nextCheckoutItemSourceManager.setNextCheckoutItemSource(
    nextCheckoutItemSourceManager.options.donate_navbar
  );
};
const NavbarMenu = () => {
  const { t } = useTranslation("common");
  const { data: utilities } = useUtilities();
  const { locale, asPath } = useRouter();
  const router = useRouter();
  const isRTL = useRTL();
  const targetTypes = utilities?.target_types;
  const items = utilities?.donation_sections?.map((item) => {
    const path =
      targetTypes[item?.target_types?.[0]]?.page_path ||
      donationCardsRoutes?.[item.key]?.path;
    return {
      path: path.includes("#") || path.includes("/") ? path : "/" + path,
      name: item?.short_name,
      appears: item?.appears_in_navbar,
      target_types: item?.target_types?.map((x) => targetTypes[x].page_path),
      icon: item?.icon,
    };
  });

  const paths = items?.filter((item) => !item?.appears);
  const isActiveLink = (href: string) => {
    const hrefTokens = href?.substr(1)?.split("/");
    const pathTokens = router.asPath?.substr(1)?.split("/");
    let matched = false;
    for (let i = 0; i < hrefTokens?.length; i++) {
      if (hrefTokens[i] === pathTokens[i]) {
        matched = true;
        break;
      }
    }

    return matched;
  };
  const allColors = useAllTextColors();
  const { theme } = useModeContext();
  if (!utilities) return null;
  return (
    <div
      className="cs-offcanvas-collapse order-lg-2 d-lg-block d-none"
      style={{ width: "100px !important" }}
    >
      <div className="cs-offcanvas-body">
        <div className="navbar-nav d-flex align-items-center">
          <div
            className={`border-left`}
            style={{ height: 30, marginInlineStart: 24, marginInlineEnd: 12 }}
          ></div>

          {/* <div className="nav-item  text-capitalize">
            <span className="d-lg-none">{t(`home`)}</span>
            <ActiveLink fuzzy activeClassName="active" href="/" prefetch>
              <a
                onClick={onLinkClick}
                className="nav-link text-capitalize whitespace-nowrap px-3"
                aria-label={t(`home`)}
              >
                <span className="d-lg-none">{t(`home`)}</span>
                <i className="fe-home d-none d-lg-block"></i>
              </a>
            </ActiveLink>
          </li> */}
          {items?.map((item, index) => {
            if (!item?.appears) return null;
            const isActive = item?.target_types?.some((type) =>
              isActiveLink("/" + type)
            );

            return (
              <div
                className="nav-item dropdown whitespace-nowrap px-3 nav-item-padding"
                style={{ paddingBlock: 12 }}
                key={index}
                id={"nav-item-" + index}
              >
                <ActiveLink
                  activeClassName="active fw-bold "
                  fuzzy
                  href={item?.path}
                >
                  <a
                    onClick={onLinkClick}
                    style={{ fontWeight: 500, fontSize: 17 }}
                    className="nav-link  dropdown-toggle text-capitalize p-0 cursor d-flex align-items-center"
                  >
                    <SvgCreator
                      // color={
                      //   isActive
                      //     ? allColors[item?.icon?.color]
                      //     : theme === "dark"
                      //     ? "#fff"
                      //     : "#5a5b75"
                      // }
                      color={allColors[item?.icon?.color]}
                      uri={item.icon?.uri}
                      hwd="22"
                      hwm="22"
                      p="p-0"
                    />
                    <span
                      className="ps-2 fw-bold"
                      style={
                        isActive
                          ? {
                              color: allColors[item?.icon?.color],
                              fontSize: 16,
                            }
                          : { fontSize: 16, color: "var(--dark-gray)" }
                      }
                    >
                      {item?.name?.[locale]}
                    </span>
                  </a>
                </ActiveLink>
                <span
                  className="nav-ind"
                  style={
                    isActive
                      ? {
                          backgroundColor: allColors[item?.icon?.color],
                          width: "100%",
                        }
                      : {}
                  }
                ></span>
                <span
                  className="nav-ind"
                  style={
                    isActive
                      ? {
                          backgroundColor: allColors[item?.icon?.color],
                          width: "100%",
                        }
                      : {}
                  }
                ></span>
              </div>
            );
          })}
          <div className="navbar-expand-lg list-unstyled m-0">
            <div className="nav-item dropdown cursor">
              <ActiveLink fuzzy href="/donate" activeClassName="active fw-bold">
                <span
                  onClick={onLinkClick}
                  className="nav-link whitespace-nowrap"
                >
                  <i className="fe-menu d-none d-lg-block"></i>
                </span>
              </ActiveLink>

              <div
                className={`dropdown-menu dropdown-menu-${
                  isRTL ? "end" : "start"
                } test-menu`}
              >
                {paths?.map((path, index) => {
                  const isLast = index === paths.length - 1;
                  return (
                    <div key={index}>
                      <div>
                        <ActiveLink
                          prefetch={false}
                          href={path.path}
                          activeClassName="active fw-bold"
                          fuzzy
                        >
                          <a
                            onClick={onLinkClick}
                            className="dropdown-item d-flex align-items-center "
                          >
                            <SvgCreator
                              color={svgColor(path.path, asPath)}
                              uri={path.icon?.uri}
                              hwd="20"
                              hwm="20"
                              p="p-0"
                            />
                            <span className="fs-16 ps-2 py-1 d-block text-heading ">
                              {path.name[locale]}
                            </span>
                          </a>
                        </ActiveLink>
                      </div>
                      <Dropdown.Divider as="div" />
                    </div>
                  );
                })}
                <div>
                  <div>
                    <ActiveLink
                      prefetch={false}
                      href={"/blog"}
                      activeClassName="active fw-bold"
                      fuzzy
                    >
                      <a
                        onClick={onLinkClick}
                        className="dropdown-item d-flex align-items-center "
                      >
                        <i
                          style={{ color: svgColor("/blog", asPath) }}
                          className="fe-book d-none d-lg-block fs-18"
                        />
                        <span className="fs-16 ps-2 py-1 d-block text-heading ">
                          {t("blogs")}
                        </span>
                      </a>
                    </ActiveLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMenu;
const NavbarMenuItem = ({ href, title, icon, asPath }) => {
  return (
    <div className="nav-item dropdown whitespace-nowrap">
      <ActiveLink activeClassName="active fw-bold" fuzzy href={href}>
        <a
          onClick={onLinkClick}
          style={{ fontSize: 17 }}
          className="nav-link dropdown-toggle text-capitalize px-3 cursor d-flex align-items-center gap-2"
        >
          <div className="d-block w-100 h-100 flex-1">
            <SvgCreator
              color={"var(--gray)"}
              uri={icon?.uri}
              hwd="24"
              hwm="24"
              p="p-0"
            />
          </div>
          {title}
        </a>
      </ActiveLink>
    </div>
  );
};
const svgColor = (href, asPath) => {
  const hrefTokens = href?.substr(1)?.split("/");
  const pathTokens = asPath?.substr(1)?.split("/");
  let matched = false;
  for (let i = 0; i < hrefTokens?.length; i++) {
    if (hrefTokens[i] === pathTokens[i]) {
      matched = true;
      break;
    }
  }
  if (matched) {
    return "var(--primary2)";
  } else {
    return "#b0b3b8";
  }
};
