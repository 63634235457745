import { createContext, useState } from "react";
import useModal from "../customHooks/useModal";
import { useAuthContext } from "./AuthContext";
type AuthModalContextType = {
  showAuthModal: boolean;
  handleCloseAuthModal: () => void;
  handleShowAuthModal: () => void;
  onTabClick: (tabName: string) => void;
  tab: string;
  nextStep: () => void;
  prevStep: () => void;
  step: number;
  setStep: (step: number) => void;
};
const AuthModalContext = createContext<AuthModalContextType>(null);

const AuthModalProvider = ({ children }) => {
  const [tab, setTab] = useState("with_social");
  const [step, setStep] = useState(0);

  const {
    showModal: showAuthModal,
    handleCloseModal: handleCloseAuthModal,
    handleShowModal: handleShowAuthModal,
  } = useModal();

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };
  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const onTabClick = (tabName) => {
    setTab(tabName);
  };
  return (
    <AuthModalContext.Provider
      value={{
        showAuthModal,
        handleCloseAuthModal,
        handleShowAuthModal,
        onTabClick,
        tab,
        nextStep,
        prevStep,
        step,
        setStep,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};

export { AuthModalContext, AuthModalProvider };
