import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

type TReviewModal = "review" | "app" | "motivation" | null;
type ReviewModalContextType = {
  showReviewModal: TReviewModal;
  handleCloseReviewModal: () => void;
  handleShowReviewModal: (t: TReviewModal) => void;
  motivationId?: number;
  setMotivationId?: Dispatch<SetStateAction<number | null>>;
};
const ReviewModalContext = createContext<ReviewModalContextType>(null);

const ReviewModalProvider = ({ children }) => {
  const [showReviewModal, setModalType] = useState<TReviewModal>(null);
  const handleShowReviewModal = (type: TReviewModal) => {
    setModalType(type);
  };
  const handleCloseReviewModal = () => {
    setModalType(null);
  };

  const [motivationId, setMotivationId] = useState<number | null>(null);
  return (
    <ReviewModalContext.Provider
      value={{
        showReviewModal,
        motivationId,
        setMotivationId,
        handleCloseReviewModal,
        handleShowReviewModal,
      }}
    >
      {children}
    </ReviewModalContext.Provider>
  );
};
export const useReviewModalContext = () => {
  const context = useContext(ReviewModalContext);
  if (context === undefined) {
    throw new Error(
      "useReviewModalContext must be used within a ReviewModalProvider"
    );
  }
  return context;
};
export { ReviewModalContext, ReviewModalProvider };
