import useSWRInfinite from "swr/infinite";
import { PaginatableList } from "../apiTypes";
import { fetcher } from "../utils/getData";

export default function useClientInfintePagination<Data extends any>(
  endpoint: string
) {
  /**
   * Given a page index and the previous page's data, return the key for the next page
   * @param pageIndex - The page index of the current request.
   * @param previousPageData - The previous page's data.
   * @returns The `getKey` function returns a string that represents the next page to fetch. If there is
   * no next page, it returns `null`.
   */
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.data?.length) return null; // reached the end
    return `${endpoint}?page=${pageIndex + 1}`;
  };

  const { data, size, setSize, error, mutate, isValidating } = useSWRInfinite<
    PaginatableList<Data>
  >(getKey, fetcher);

  /* Reducing the data array to a single array of data. */
  const formattedData = data?.reduce<Data[]>(
    (prev, currVal) => prev.concat(currVal.data),
    []
  ) ?? [];
  /* It's checking if data is undefined and if error is undefined. */
  const isLoadingInitialData = !data && !error;
  /* If the initial data is still loading, or if the data array is not empty and the last item in the
  array is undefined, then the loading indicator should be shown. */
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");
  /* The above code is checking if the first element of the array is empty. */
  // TODO: isEmpty check by mohammed may be wrong here needs testing

  const isEmpty = data?.[0]?.data?.length === 0;
  /* This code is checking if the data is empty or if the size of the data is equal to the last page
  number. */

  const hasReachedEnd = isEmpty || size === data?.[0]?.last_page + 1;
  /**
   * GetMoreData is a function that sets the size state to the current size plus one.
   */
  const getMoreData = () => setSize(size + 1);

  const getAllPages = () => setSize(data?.[0]?.last_page);

  return {
    isLoadingInitialData,
    isLoadingMore,
    hasReachedEnd,
    size,
    setSize,
    mutate,
    data,
    formattedData,
    getMoreData,
    getAllPages,
    error,
    isValidating,
  };
}
