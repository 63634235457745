/** @type {import('next-translate').I18nConfig} */
module.exports = {
  locales: ["ar", "en", "fr", "de", "es", "tr"],
  defaultLocale: "ar",
  logBuild: false,
  pages: {
    "*": ["common"],
  },
  defaultNS: "common",
  localeDetection: false,
  loadLocaleFrom: (lang, ns) =>
    // You can use a dynamic import, fetch, whatever. You should
    // return a Promise with the JSON file.
    import(`./constants/locales/${lang}/${ns}.json`).then((m) => m.default),
};
