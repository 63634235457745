import styled from "@emotion/styled";
import braintreeClient from "braintree-web/client";
import braintreeDataCollector from "braintree-web/data-collector";
import braintreeHostedFields from "braintree-web/hosted-fields";
import braintreeThreeDSecure from "braintree-web/three-d-secure";
import InputSkeleton from "components/Skeletons/InputSkeleton";
import { useModeContext } from "context/ModeContext";
import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useRef, useState } from "react";
import braintreeActions from "../actions/braintree";

type BraintreeFieldsProps = {
  nameRef: any;
  numberRef: any;
  expirationDateRef: any;
  cvvRef: any;
  elementsAreReady?: boolean;
};
const BraintreeFields = ({
  nameRef,
  numberRef,
  expirationDateRef,
  cvvRef,
  elementsAreReady,
}: BraintreeFieldsProps) => {
  const { t } = useTranslation("common");
  const isRTL = useRTL();
  return (
    <>
      <label className="px-0" style={{ fontSize: "1rem" }}>
        {t("name")}
      </label>
      <div className="position-relative">
        <StyledBraintreeContainer ref={nameRef} className="form-control mb-2" />

        {!elementsAreReady && <InputSkeleton />}
      </div>

      <label className="px-0" style={{ fontSize: "1rem" }}>
        {t("card_info")}
      </label>

      <div className="position-relative">
        <StyledBraintreeContainer
          ref={numberRef}
          className="form-control"
          data-format="card"
          style={{
            marginBottom: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
        {!elementsAreReady && <InputSkeleton />}
      </div>
      <div className={`d-flex ${isRTL ? "flex-row-reverse" : "flex-row"} mb-3`}>
        <div className="position-relative flex-1">
          <StyledBraintreeContainer
            ref={expirationDateRef}
            style={{
              flex: 1,
              borderRightWidth: 1,
              borderRightStyle: "solid",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            className="form-control"
          />

          {!elementsAreReady && <InputSkeleton />}
        </div>

        <div className="position-relative flex-1">
          <StyledBraintreeContainer
            ref={cvvRef}
            style={{
              flex: 1,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            className="form-control"
          />

          {!elementsAreReady && <InputSkeleton />}
        </div>
      </div>
    </>
  );
};
export default BraintreeFields;

/**
    because sometimes a modal is not rendered in the DOM
    we need to set enable to false to prevent braintree from
    trying to render the fields in a non existing DOM
    element
 * @param enabled 
    @default true
 * @returns 
 */
export const useBraintree = (enabled: boolean = true) => {
  const { t } = useTranslation("common");

  const [hostedFieldsInstance, setHostedFieldsInstance] =
    useState<braintreeHostedFields.HostedFields | null>(null);

  const [threeDSecureInstance, setThreeDSecureInstance] =
    useState<braintreeThreeDSecure.ThreeDSecure | null>(null);
  const [dataCollectorInstance, setDataCollectorInstance] =
    useState<braintreeDataCollector.DataCollector | null>(null);
  const [elementsAreReady, setElementsAreReady] = useState(false);
  const nameRef = useRef();
  const numberRef = useRef();
  const expirationDateRef = useRef();
  const cvvRef = useRef();
  const mode = useModeContext();
  useEffect(() => {
    const createFields = async () => {
      const token = await braintreeActions.generateClientToken();
      if (token.ok) {
        try {
          const client = await braintreeClient.create({
            authorization: token.data.client_code,
          });
          const textColor = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue("--form-control-color");
          const placeholderColor =
            mode.theme === "dark" ? "#9e9fb4" : "rgb(115, 116, 145)";
          const hostedFieldsPromise = braintreeHostedFields.create({
            client,
            styles: {
              input: {
                "font-size": "16px",
                color: textColor,
                // "background-color": window
                //   .getComputedStyle(document.documentElement)
                //   .getPropertyValue("--white"),
                // background: "red !important",
              },
              "input.invalid": {
                color: textColor,
              },
              "input.valid": {
                color: textColor,
              },
              "::-webkit-input-placeholder": {
                color: placeholderColor,
              },
              ":-moz-placeholder": {
                color: placeholderColor,
              },
              "::-moz-placeholder": {
                color: placeholderColor,
              },
              ":-ms-input-placeholder": {
                color: placeholderColor,
              },
              // prevent IE 11 and Edge from
              // displaying the clear button
              // over the card brand icon
              "input::-ms-clear": {
                opacity: "0",
              },
            },
            fields: {
              cardholderName: {
                container: nameRef.current as HTMLElement,

                placeholder: t("full_name_on_card"),
              },
              number: {
                container: numberRef.current,

                placeholder: "1234 1234 1234 1234",
              },
              expirationDate: {
                container: expirationDateRef.current,
                placeholder: "MM/YY",
              },
              cvv: {
                container: cvvRef.current,

                placeholder: "CVC",
              },
            },
          });
          const threeDSecurePromise = braintreeThreeDSecure.create({
            client,
            version: 2,
          });
          const dataCollectorPromise = braintreeDataCollector.create({
            client,
          });
          const [hostedFields, threeDSecure, dataCollector] = await Promise.all(
            [hostedFieldsPromise, threeDSecurePromise, dataCollectorPromise]
          );

          setHostedFieldsInstance(hostedFields);
          setThreeDSecureInstance(threeDSecure);
          setDataCollectorInstance(dataCollector);
        } catch (error) {}
      }
    };
    if (enabled) {
      createFields().then(() => {
        setElementsAreReady(true);
      });
    }
    return () => {
      setElementsAreReady(false);
    };
  }, [enabled]);
  return {
    hostedFieldsInstance,
    threeDSecureInstance,
    dataCollectorInstance,
    nameRef,
    numberRef,
    expirationDateRef,
    cvvRef,
    elementsAreReady,
  };
};

export const StyledBraintreeContainer = styled.div`
  background-color: red;
  color-scheme: none;

  .braintree-hosted-fields-focused {
    border-color: #777;
  }

  .braintree-hosted-fields-invalid {
    border-color: tomato;
  }

  .braintree-hosted-fields-valid {
    border-color: limegreen;
  }
`;
