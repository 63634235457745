import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useTranslation from "next-translate/useTranslation";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutActions from "../../actions/checkout";
import DonationModalFooterPortal from "../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
import ProviderFeeStep from "../ProviderFee/ProviderFeeStep";
import SavedShippingAddressStep from "../ShippingAddress/SavedShippingAddressStep";
import TeamSupportStep from "../TeamSupport/TeamSupportStep";
const CalculatedZakatStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("zakat_details");
};
const CalculatedZakatStepBody: React.FC = () => {
  const [
    checkoutOrder,
    zakatItems,
    setCheckoutOrder,
    setDonationAmountValue,
    addStepToHistory,
  ] = useCheckoutStore((state) => [
    state.checkoutOrder,
    state.zakatItems,
    state.setCheckoutOrder,
    state.setDonationAmountValue,
    state.addStepToHistory,
  ]);
  const { t } = useTranslation("common");
  const { formatNumbers } = useFormatNumbers();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  const [loading, setLoading] = useState(false);
  const fieldTypes = ["money", "gold", "silver", "stock"];

  const calculateTotalForType = (type) => {
    return zakatItems
      .filter((f) => f.type === type)
      .reduce((acc, field) => {
        return acc + Number(field.amount) * Number(field.price);
      }, 0);
  };
  const calculateZakatForType = (type) => {
    const zakatTotal = zakatItems
      .filter((f) => f.type === type)
      .reduce(
        (acc, field) =>
          acc + Number(field.price) * Number(field.amount) * 0.025,
        0
      );
    return zakatTotal >= 85 ? zakatTotal : 0;
  };
  const zakatTotal = useMemo(() => {
    return (
      fieldTypes.reduce((acc, type) => {
        const fieldsOfType = zakatItems.filter((f) => f.type === type);
        const totalOfType = fieldsOfType.reduce((acc, field) => {
          return acc + Number(field.amount) * Number(field.price);
        }, 0);
        return acc + (totalOfType >= 85 ? totalOfType : 0);
      }, 0) * 0.025
    );
  }, [zakatItems]);
  const typesExistingInItems = Array.from(
    new Set(
      zakatItems.map((item) => {
        return item.type;
      })
    )
  );
  const { curr } = usePreferenceContext();
  const handleNextSlide = async () => {
    setLoading(true);
    try {
      if (zakatTotal) {
        const resPromise = !checkoutOrder
          ? checkoutActions.createCheckoutOrder({
              currency_code: curr?.code,
              amount: zakatTotal,
              payment_option_id: 31, // id of the zakat campaign
            })
          : checkoutActions.updateCheckoutOrder({
              currency_code: curr?.code,
              amount: zakatTotal,
              payment_option_id: 31, // id of the zakat campaign
              id: checkoutOrder?.id,
            });

        const res = await resPromise;
        if (res.ok === true) {
          setCheckoutOrder(res.data);
          setDonationAmountValue(zakatTotal.toString());
          if (res.data.team_support_suggested) {
            addStepToHistory(TeamSupportStep.name);
          } else if (res.data.payment_provider_fees_checkout_suggestion) {
            addStepToHistory(ProviderFeeStep.name);
          } else if (res.data.shipping_address_required) {
            addStepToHistory(SavedShippingAddressStep.name);
          } else {
            addStepToHistory(ConfirmationStep.name);
          }
        } else {
          toast.error(res.error.message);
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <ModalBodyElementContainer>
        {typesExistingInItems.map((type, i) => {
          return (
            <div key={i}>
              <div className="fw-bold">{t(`${type}_total_label`)}:</div>
              <div>
                <span className="">- {t("total")}:</span>
                <span className="text-primary">
                  {" "}
                  {formatNumbers(
                    calculateTotalForType(type),
                    currencyCode ?? curr?.code,
                    true
                  )}{" "}
                </span>
                <span
                  className={
                    calculateZakatForType(type) !== 0
                      ? `text-success`
                      : `text-danger`
                  }
                >
                  {calculateZakatForType(type) !== 0
                    ? "(" + t("reached_zakat_limit") + ")"
                    : "(" + t("not_reached_zakat_limit") + ")"}{" "}
                </span>
              </div>
              <div>
                <span>- {t("zakat_amount")}: </span>
                <span className="text-primary">
                  {formatNumbers(
                    calculateZakatForType(type),
                    currencyCode ?? curr?.code,
                    true
                  )}
                </span>
              </div>
            </div>
          );
        })}
      </ModalBodyElementContainer>
      <DonationModalFooterPortal id="modal-footer-portal-host">
        <ModalBodyElementContainer>
          <div className="d-flex flex-row justify-content-between align-items-center mb-3 ">
            {" "}
            <span className="fw-bold">{t("zakat_amount")} </span>
            <span className="text-primary fw-bold">
              {formatNumbers(zakatTotal, currencyCode ?? curr?.code, true)}
            </span>
          </div>
          <MolhamButton
            id="zakat-now-btn"
            endIcon="fe-arrow-right"
            label={
              t("zakat_now") +
              " (" +
              formatNumbers(zakatTotal, currencyCode ?? curr?.code, true) +
              ")"
            }
            className={`w-100`}
            onClick={handleNextSlide}
            disabled={loading}
            isLoading={loading}
          />
        </ModalBodyElementContainer>
      </DonationModalFooterPortal>
    </>
  );
};
const CalculatedZakatStep = {
  name: "CalculatedZakatStep",
  title: CalculatedZakatStepTitle,
  body: CalculatedZakatStepBody,
  footer: true,
};
export default CalculatedZakatStep;
