import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { AuthModalContext } from "../../../context/AuthModalContext";
import useUpdateEffect from "../../../customHooks/useUpdateEffect";
import BackBtn from "../BackBtn";
import CloseBtn from "../CloseBtn";

import useMobile from "customHooks/useMobile";
import { useCheckoutStore } from "../SharedDonatePages/DonateModal/CheckoutState";
import ContinueWith from "./ContinueWith";
import ForgotPassword from "./ForgotPassword";
import GuestForm from "./GuestForm";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";

type Props = {
  additionalOnclose?: () => void;
  fromCheckout?: boolean;
  checkoutId?: number;
  authReq?: boolean;
};
const MainAuthModalComponent = (props: Props) => {
  const {
    showAuthModal,
    handleCloseAuthModal,
    step,
    prevStep,
    nextStep,
    setStep,
  } = useContext(AuthModalContext);
  const { t } = useTranslation("common");
  const { goBack } = useCheckoutStore((state) => ({
    goBack: state.popStepFromHistory,
  }));
  const onBackBtnClick = () => {
    if (step === 3) {
      setStep(1);
      return;
    } else if (step === 4) {
      setStep(1);
    }
    prevStep();
  };

  useUpdateEffect(() => {
    if (!showAuthModal) {
      setTimeout(() => setStep(0), 300);
    }
  }, [showAuthModal]);

  const isMobile = useMobile("md");
  const onC = () => {
    handleCloseAuthModal();
    props.additionalOnclose?.();
  };
  return (
    <>
      <Modal.Header closeButton={false}>
        {step !== 0 ? (
          <BackBtn onBackBtnClick={onBackBtnClick} />
        ) : props.fromCheckout ? (
          <BackBtn onBackBtnClick={goBack} />
        ) : (
          <div style={{ padding: "inherit" }} />
        )}
        <Modal.Title>
          {step === 0 && `${t(`sign_in`)}`}
          {step === 1 && `${t(`sign_in`)}`}
          {step === 2 && `${t(`sign_up`)}`}
          {step === 3 && `${t(`reset_password`)}`}
          {step === 4 && `${t(`continue_as_guest`)}`}
        </Modal.Title>
        <CloseBtn closeModal={onC} />
      </Modal.Header>
      <div className="p-4 h-100">
        {step === 0 && (
          <ContinueWith
            nextStep={nextStep}
            fromCheckout={props.fromCheckout}
            authReq={props.authReq}
          />
        )}
        <div
          className={
            isMobile && step > 0
              ? "h-100 d-flex justify-content-center align-items-center pb-6"
              : ""
          }
        >
          {step === 1 && (
            <SignInForm fromPage={false} fromCheckoutId={props.checkoutId} />
            // <OldSignInForm fromPage={false} />
          )}
          {step === 2 && <SignUpForm fromPage={false} />}
          {step === 3 && <ForgotPassword fromPage={false} />}
          {step === 4 && <GuestForm fromCheckoutId={props.checkoutId} />}
        </div>
      </div>
    </>
  );
};

export default MainAuthModalComponent;
