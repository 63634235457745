import SvgCreator from "components/ReuseableCompos/SvgCreator";
import { useModeContext } from "context/ModeContext";
import useUtilities from "customHooks/useUtilities";
import { memo } from "react";

type Props = {
  hwm?: string;
  hwd?: string;
};

const LogoCreator = ({ hwm, hwd }: Props) => {
  const { theme } = useModeContext();
  const { data: utilities } = useUtilities();
  const uri =
    utilities?.assets?.home_page_logo_url || "/assets/logo/logo-main.svg";
  return (
    <SvgCreator
      color={theme === "dark" ? "#fff" : "var(--primary2)"}
      uri={uri}
      hwm={hwm || "40px"}
      hwd={hwd || "40px"}
      p={"p-0"}
      noStroke={uri.includes("logo-main")}
    />
  );
};

export default memo(LogoCreator);
