import dayjs from "dayjs";

export const fromNow = (
  date: Date | dayjs.Dayjs | string | number | undefined
) => {
  if (!date) return "";
  return dayjs
    .utc(date)
    .subtract(1, "second")
    .fromNow();
};
