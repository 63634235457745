import useContents from "customHooks/useContents";
import useTranslation from "next-translate/useTranslation";

import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import useFocus from "../../../customHooks/useFocus";

import CloseBtn from "../../ReuseableCompos/CloseBtn";

import NavBarSearchResults from "./NavBarSearchResults";

type Props = {
  showSearchBar: boolean;
  setShowSearchBar: (val: boolean) => void;
  setShowSearchBarRes: (val: boolean) => void;
  showBorderBottom: boolean;
};
const NavBarSearch = ({
  showSearchBar,
  setShowSearchBar,
  setShowSearchBarRes,
  showBorderBottom,
}: Props) => {
  // adding focus on search bar when it appears
  const [showResultBar, setShowResultBar] = useState(false);
  const [query, setQuery] = useState("");
  const [innerSearchData, setInnerSearchData] = useState([]);
  const { ref: searchInputRef } = useFocus(showSearchBar);

  const onInputChange = (e) => {
    if (e.target.value) {
      setShowResultBar(true);
    } else {
      setShowResultBar(false);
    }
    setQuery(e.target.value);
    setInnerSearchData(() => {
      const res = shortcuts?.filter(
        (val) =>
          val.keywords.join(" ").toLowerCase().indexOf(query.toLowerCase()) !==
          -1
      );
      if (res?.length > 0 && e.target.value?.length !== 0) {
        setShowSearchBarRes(true);
      } else {
        setShowSearchBarRes(false);
      }
      return res;
    });
  };
  const shortcuts = useContents().data?.shortcuts;

  useEffect(() => {
    // handling toggling on body click and donate button click
    document.body.addEventListener("click", (e) => {
      const target = e.target as Element;
      if (
        !target.classList.contains("navbar-search-field") &&
        !target.classList.contains("navbar-search-field") &&
        !target.classList.contains("navbar-search-wrapper") &&
        !target.classList.contains("search-bar-icon") &&
        !target.classList.contains("search-bar-icon-wrapper") &&
        !target.classList.contains("search-icon")
      ) {
        setShowSearchBar(false);
      }
    });
  });

  // // getting translations
  const { t } = useTranslation("common");

  return (
    <>
      <div
        style={showBorderBottom ? { height: "76px" } : {}}
        className={`navbar-search pt30 bg-light  ${
          showSearchBar ? "show zindex-7" : "hide zindex-7"
        } ${!showBorderBottom ? "dark-border-bottom" : ""}`}
      >
        <div className="container d-flex navbar-search-wrapper flex-nowrap align-items-center h-100">
          {/* search icon */}
          <SearchLoaderWrapper className="centered">
            <i className="fe-search font-size-xl"></i>
          </SearchLoaderWrapper>
          <input
            ref={searchInputRef}
            className="form-control form-control-xl navbar-search-field"
            type="text"
            placeholder={t(`search`)}
            value={query}
            onChange={onInputChange}
          />

          <div className="d-flex align-items-center pl-2 ">
            <CloseBtn
              className="mx-1"
              closeModal={() => setShowSearchBar(false)}
            />
          </div>
        </div>
        <NavBarSearchResults
          showResultBar={showResultBar}
          data={innerSearchData}
        />
      </div>
    </>
  );
};

export default memo(NavBarSearch);
const SearchLoaderWrapper = styled.span`
  width: 40px;
`;
