import useTranslation from "next-translate/useTranslation";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import Checkbox from "../../../components/Checkbox";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import { SubmitButton, handleCheckoutEnd } from "../common";

import braintreeThreeDSecure from "braintree-web/three-d-secure";
import braintreeActions from "../../../actions/braintree";
import BraintreeFields, {
  useBraintree,
} from "../../../components/BraintreeFields";
const NewBraintreeCardStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_bank_card");
};
const NewBraintreeCardStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const [saveForLater, setSaveForLater] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  // const numberRef = useMask({ mask: "____ ____ ____ ____", replacement: "_" });
  const donor = useCheckoutStore().checkoutOrder?.payer;
  const {
    cvvRef,
    dataCollectorInstance,
    expirationDateRef,
    hostedFieldsInstance,
    nameRef,
    numberRef,
    threeDSecureInstance,
    elementsAreReady,
  } = useBraintree(true);
  const onSubmit = async () => {
    setLoading(true);
    try {
      const paymentProvider =
        donor.available_payment_methods.braintree_card.payment_provider;
      const tokenizedCard = await hostedFieldsInstance.tokenize();
      const checkoutOrder = useCheckoutStore.getState().checkoutOrder;

      const tokenizedThreeDSecure = await threeDSecureInstance.verifyCard({
        onLookupComplete: function (data, next) {
          next();
        },
        amount:
          checkoutOrder.withdrawal_amount[paymentProvider.currency]?.toString(),
        nonce: tokenizedCard.nonce,
        bin: tokenizedCard.details.bin,
        email: donor.email,
        collectDeviceData: true,
      } as braintreeThreeDSecure.ThreeDSecureVerifyOptions & {
        onLookupComplete: any;
      });
      if (
        !tokenizedThreeDSecure.threeDSecureInfo.liabilityShiftPossible ||
        !tokenizedThreeDSecure.threeDSecureInfo.liabilityShifted
      ) {
        toast.error(t("something_went_wrong"));
      } else {
        const transactionRes = await braintreeActions.createTransaction({
          checkout_order_reference: checkoutOrder.reference,
          braintree_payment_method_nonce: tokenizedThreeDSecure.nonce,
          device_data: dataCollectorInstance.deviceData,
          save_payment_method_for_later: saveForLater,
        });
        if (transactionRes.ok === true) {
          const res = await checkoutHandlerActions.handleCheckoutSource({
            payment_provider_id: paymentProvider.id,
            type: checkoutHandlerActions.CheckoutSourceTypes.braintree
              .transaction,
            reference: transactionRes.data.id,
          });
          handleCheckoutEnd({
            res,
            metadata: {
              "Checkout Order": checkoutOrder.reference,
              Items: checkoutOrder.items,
              currency: checkoutOrder.currency.code,
              amount: checkoutOrder.amount,
              "Payment Method": "Braintree Card",
              saved: false,
            },
          });
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <ModalBodyElementContainer>
      <BraintreeFields
        nameRef={nameRef}
        numberRef={numberRef}
        expirationDateRef={expirationDateRef}
        cvvRef={cvvRef}
        elementsAreReady={elementsAreReady}
      />
      {donor?.type !== "guest" && (
        <Checkbox
          checked={saveForLater}
          onClick={() => {
            setSaveForLater(!saveForLater);
          }}
        >
          <div>{t("save_payment_method_for_later")}</div>
        </Checkbox>
      )}
      {errorMessage ? (
        <div style={{ color: "red", marginTop: 10, marginBottom: 10 }}>
          {errorMessage}
        </div>
      ) : null}
      <DonationModalFooterPortal id="modal-footer-portal-host">
        <PaymentSlidesFooterContainer>
          <SubmitButton onSubmit={onSubmit} loading={loading} />
        </PaymentSlidesFooterContainer>
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const NewBraintreeCardStep = {
  name: "NewBraintreeCardStep",
  title: NewBraintreeCardStepTitle,
  body: NewBraintreeCardStepBody,
  footer: true,
};
export default NewBraintreeCardStep;
