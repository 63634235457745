import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import { AuthModalContext } from "../../../context/AuthModalContext";
import { useModeContext } from "../../../context/ModeContext";
import useRTL from "../../../customHooks/useRTL";
import MolhamButton from "../MolhamButton";
import Or from "../Or";
import LoginWithApple from "./LoginWithApple";
import LoginWithFacebook from "./LoginWithFacebook";
import LoginWithGoogle from "./LoginWithGoogle";
import DarkImage from "./assets/auth-logo-dark.png";
import LightImage from "./assets/auth-logo-light.png";
const ContinueWith = ({ nextStep, fromCheckout, authReq }) => {
  const { setStep } = useContext(AuthModalContext);
  const onEmailClick = () => {
    nextStep();
  };

  const isRTL = useRTL();

  const { t } = useTranslation("common");
  const { theme } = useModeContext();
  const isMobile = useMobile("md");
  const srcImg = theme === "dark" ? DarkImage.src : LightImage.src;

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      {isMobile && (
        <div>
          {/*  eslint-disable-next-line @next/next/no-img-element */}
          <img src={srcImg} alt="logo" className="img-fluid px-55 mb-3" />
        </div>
      )}
      <div className="">
        <p
          className={`mb-4 ${
            isMobile ? "fs-md" : "fs-lg"
          } fw-normal text-center`}
        >
          {t("continue_on_social")}
        </p>
        <div
          className={`mb-4 d-flex justify-content-center px-5 ${
            !isRTL ? "d-row" : "d-row-reverse"
          } gap-4`}
        >
          <LoginWithGoogle />
          {/* <LoginWithGoogleV2 /> */}
          <LoginWithFacebook />
          <LoginWithApple theme={theme} />
        </div>
        <Or m="mb-4" />

        <MolhamButton
          id="login-cont-email-btn"
          endIcon={`ai-log-in fs-xxl ${isRTL && "transform-scale-invert"}`}
          label={t("login_with_email2")}
          onClick={onEmailClick}
          className="w-100 mb-4"
        />

        <p className="fs-md mb-0 text-center">
          {t(`dont_have_account`)}{" "}
          <a onClick={() => setStep(2)} className="fw-medium cursor">
            {t(`sign_up`)}
          </a>
        </p>
      </div>
      {fromCheckout && !authReq && <Or m="my-4" />}
      {fromCheckout && !authReq && (
        <p className="fs-md mb-0 text-center">
          <a onClick={() => setStep(4)} className="fw-medium cursor">
            {t(`continue_as_guest`)}
          </a>
        </p>
      )}
      {isMobile && (
        <div>
          <p className="fs-xs pt-3 mb-0 mt-2 text-center text-muted">
            Powered By
          </p>
          <p className="fs-sm  mb-0  text-center fw-medium">Molham Tech</p>
        </div>
      )}
    </div>
  );
};

export default ContinueWith;
