import { yupResolver } from "@hookform/resolvers/yup";
import { useCartContext } from "context/CartContext";
import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../CheckoutState";
import { baseOnAddToCart, baseOnProceedToCheckout } from "./common";
import DonationAmountFooter from "./components/DonationAmountFooter";
import QuantityCounter from "./components/QuantityInput";
import { isUndefined } from "./utils";

type Props = {};

const QuantifiableAmountOption: React.FC<Props> = ({}) => {
  const selectedPaymentOption = useCheckoutStore(
    (state) => state.selectedPaymentOption
  );
  const { formatNumbers } = useFormatNumbers();
  const { curr } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  const donationAmountValue = useCheckoutStore(
    (state) => state.donationAmountValue
  );
  const setDonationAmountValue = useCheckoutStore(
    (state) => state.setDonationAmountValue
  );
  const { t } = useTranslation("common");
  const schema = useMemo(() => {
    const min =
      selectedPaymentOption && selectedPaymentOption.min_quantity
        ? selectedPaymentOption.min_quantity
        : 0;
    const max =
      selectedPaymentOption && selectedPaymentOption.max_quantity
        ? selectedPaymentOption.max_quantity
        : 0;
    return yup.object().shape({
      quantity: yup
        .number()
        .typeError("")
        .min(min, t("minimum_is_x") + " " + min)
        .max(max, t("maximum_is_x") + " " + max)
        .required(),
    });
  }, [selectedPaymentOption, currencyCode || curr.code, t]);
  const { control, handleSubmit, setValue, watch, getValues } = useForm<{
    quantity: string;
  }>({
    resolver: yupResolver(schema),
    defaultValues: { quantity: "1" },
  });
  const watchAmount = watch("quantity");
  useEffect(() => {
    if (donationAmountValue && donationAmountValue !== watchAmount) {
      setValue("quantity", donationAmountValue);
    }
  }, [donationAmountValue]);
  const total: number =
    Number(watch("quantity")) *
    (selectedPaymentOption?.amount
      ? selectedPaymentOption.amount[currencyCode || curr.code]
      : 0);
  const setModalVisible = useCheckoutStore((state) => state.setModalVisible);
  const { mutateCart } = useCartContext();

  const onAddToCart = baseOnAddToCart({
    onSuccess() {
      mutateCart();
      setModalVisible(false);
      toast.success(t("item_added_to_cart"));

      // handleShowCartOffCanvas();
    },
  });
  const onProceedToCheckout = baseOnProceedToCheckout({
    onSuccess() {
      setDonationAmountValue(getValues().quantity);
    },
    onFail() {},
  });
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center  mb-4 pb-3 position-relative border-bottom">
        {selectedPaymentOption?.preview_image?.thumbnails["1:1"] ? (
          <Image
            width={160}
            height={160}
            style={{
              width: "160px",
              // objectFit: "cover",
            }}
            className="rounded-circle mb-3 mt-2"
            src={selectedPaymentOption?.preview_image?.thumbnails["1:1"]}
            alt={selectedPaymentOption.name}
          />
        ) : null}
        <div className="text-center mb-1 fw-bold">{selectedPaymentOption.name}</div>
        <div className="text-muted text-center mb-1 lh17">
          {selectedPaymentOption.description}
        </div>
        <div className="text-primary text-center d-flex">
          {`(`}
          <div className="fw-bold mx-1">
            {formatNumbers(
              selectedPaymentOption.amount[currencyCode || curr.code],
              currencyCode || curr.code
            )}
          </div>{" "}
          {`)`}
          {/* {'('+curr.code.toUpperCase() +
            " " +
            selectedPaymentOption.amount[curr.code] + ')'} */}
        </div>
      </div>

      <Controller
        name="quantity"
        defaultValue={"1"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <QuantityCounter
            onChange={(val) => {
              if (
                !isUndefined(selectedPaymentOption.max_quantity) &&
                !isUndefined(selectedPaymentOption.min_quantity) &&
                val <= selectedPaymentOption.max_quantity &&
                val >= selectedPaymentOption.min_quantity
              ) {
                onChange(val.toString());
              }
            }}
            value={Number(value)}
          />
        )}
      />
      <DonationAmountFooter
        amount={total}
        onAddToCart={handleSubmit(onAddToCart)}
        onProceedToCheckout={handleSubmit((e: any) =>
          onProceedToCheckout({
            currency_code: currencyCode || curr.code,
            ...e,
          })
        )}
      />
    </div>
  );
};

export default QuantifiableAmountOption;
