import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import { Dropdown } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import ActiveLink from "../ReuseableCompos/ActiveLink";

const HelpDropdown = () => {
  const { t } = useTranslation("common");
  const paths = [
    { path: "#chat-with-support", name: "chat_with_support" },
    { path: "/help/faqs", name: "faqs" },
    { path: "/help/terms", name: "payment_process_terms_of_use" },
    { path: "/help/privacy", name: "privacy_policy" },
    { path: "/help/contact-us", name: "contact_us" },
    { path: "/feedback", name: "send_feedback" },
  ];

  const isRTL = useRTL();
  return (
    <ul className="navbar-expand-lg list-unstyled m-0">
      <li className="nav-item dropdown">
        {/*TODO: REMOVED FUZZY FOR HOW TO DONATE ISSUES - MUST ADD LATER  */}
        <ActiveLink href="/help" activeClassName="active fw-bold">
          <span className="topbar-link dropdown-toggle text-capitalize cursor">
            {t(`help`)}
            <MdKeyboardArrowDown />
          </span>
        </ActiveLink>
        <ul
          className={`dropdown-menu dropdown-menu-${
            isRTL ? "end" : "start"
          } test-menu`}
        >
          {paths.map((path, index) => {
            const isLast = index === paths.length - 1;

            return (
              <div key={index}>
                <li>
                  <ActiveLink
                    prefetch={false}
                    href={path.path}
                    activeClassName="active"
                    passHref
                  >
                    <a className="dropdown-item">
                      <span className="d-block text-heading ">
                        {t(path.name)}
                      </span>
                    </a>
                  </ActiveLink>
                </li>
                {!isLast && <Dropdown.Divider as="div" />}
              </div>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};

export default HelpDropdown;
