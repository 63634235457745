import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useModeContext } from "context/ModeContext";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import stripeActions from "../../../actions/stripe";
import Checkbox from "../../../components/Checkbox";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import StripeCardElement from "../../../components/StripeCardElement";
import {
  NameEmailFormComponents,
  SubmitButton,
  TNameEmailForm,
  handleCheckoutEnd,
  useNameEmailForm,
} from "../common";
const NewCardPaymentStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_bank_card");
};
const NewCardPaymentStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const [saveForLater, setSaveForLater] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { control, handleSubmit } = useNameEmailForm();
  const { data: utilities } = useUtilities();
  const donor = useCheckoutStore().checkoutOrder?.payer;
  const [loading, setLoading] = useState(false);
  //   const {data: donor} = useDonor();
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = async (data: TNameEmailForm) => {
    if (elements && stripe) {
      setLoading(true);
      try {
        const paymentProvider =
          donor!.available_payment_methods.stripe_card.payment_provider;
        const element = elements!.getElement(CardElement);
        const pm = await stripe.createPaymentMethod({
          type: "card",
          card: element!,
          billing_details: {
            name: data.name?.toUpperCase(),
          },
        });
        if (pm.error) {
          toast.error(pm.error.message);
          setErrorMessage(pm.error.message);
          // setLoading(false);

          return;
        }
        const checkoutOrder = useCheckoutStore.getState().checkoutOrder;
        const paymentIntent = await stripeActions.paymentIntent({
          checkout_order_reference: checkoutOrder!.reference,
          save_for_later: saveForLater,
          // return_url: 'molhamteam://stripe-redirect',
          payment_method: pm.paymentMethod.id,
          payment_method_types: ["card"],
          confirmation_method: "automatic",
          payment_provider_id: paymentProvider.id,
        });
        if (paymentIntent.ok === false) {
          toast.error(paymentIntent.error.message);
          setErrorMessage(
            paymentIntent.error ? paymentIntent.error.message : ""
          );
          // setLoading(false);

          return;
        }
        //   clientSecret: ,
        //   confirmParams: {
        //     return_url: `${window.location}?checkout_order_id=${
        //       checkoutOrder!.reference
        //     }`,
        //     ...stripeParams,
        //   },
        //   elements,
        const { error } = await stripe.confirmCardPayment(
          paymentIntent.data.client_secret,
          {
            payment_method: pm.paymentMethod.id,
            return_url: `${window.location}?checkout_order_id=${
              checkoutOrder!.reference
            }`,
          }
        );
        if (error) {
          toast.error(error.message);
        } else {
          const res = await checkoutHandlerActions.handleCheckoutSource({
            payment_provider_id: paymentProvider.id,
            type: checkoutHandlerActions.CheckoutSourceTypes.stripe
              .payment_intent,
            reference: paymentIntent.data?.id,
          });
          handleCheckoutEnd({
            res,
            metadata: {
              "Checkout Order": checkoutOrder.reference,
              Items: checkoutOrder.items,
              currency: checkoutOrder.currency.code,
              amount: checkoutOrder.amount,
              "Payment Method": "Stripe Card",
              saved: false,
            },
          });
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const mode = useModeContext();

  return (
    <ModalBodyElementContainer>
      <NameEmailFormComponents control={control} />

      <StripeCardElement />

      {donor?.type !== "guest" && (
        <Checkbox
          checked={saveForLater}
          onClick={() => {
            setSaveForLater(!saveForLater);
          }}
        >
          <div>{t("save_payment_method_for_later")}</div>
        </Checkbox>
      )}
      {errorMessage ? (
        <div style={{ color: "red", marginTop: 10, marginBottom: 10 }}>
          {errorMessage}
        </div>
      ) : null}
      <DonationModalFooterPortal id="modal-footer-portal-host">
        <PaymentSlidesFooterContainer>
          <SubmitButton onSubmit={handleSubmit(onSubmit)} loading={loading} />
        </PaymentSlidesFooterContainer>
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const NewCardPaymentStep = {
  name: "NewCardPaymentStep",
  title: NewCardPaymentStepTitle,
  body: NewCardPaymentStepBody,
  footer: true,
};
export default NewCardPaymentStep;
