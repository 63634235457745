import { yupResolver } from "@hookform/resolvers/yup";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { usePreferenceContext } from "context/PreferencesContext";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutActions from "../../actions/checkout";
import LiteButton from "../../components/LiteButton";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
import SavedShippingAddressStep from "../ShippingAddress/SavedShippingAddressStep";

const ProviderFeeStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("PF_coverage");
};
const Fee_Rate = 0.02;
const ProviderFeeStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const {
    loading,
    setLoading,
    checkoutOrder,
    setCheckoutOrder,
    addStepToHistory,
    providerFee,
    setProviderFee,
    amount,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    setLoading: state.setLoading,
    checkoutOrder: state.checkoutOrder,
    setCheckoutOrder: state.setCheckoutOrder,
    setProviderFee: state.setProviderFee,
    providerFee: state.providerFee,
    amount: state.donationAmountValue,
    loading: state.loading,
  }));
  const schema = useMemo(
    () =>
      yup.object().shape({
        providerFee: yup.boolean(),
      }),
    [t]
  );
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{ providerFee: boolean }>({
    resolver: yupResolver(schema),
    defaultValues: {
      providerFee: providerFee,
    },
  });
  const { curr } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  const handleNextSlide = async (data: { providerFee: boolean }) => {
    setLoading(true);

    try {
      if (checkoutOrder) {
        const resPromise = checkoutActions.setProviderFee({
          currency_code: currencyCode || curr.code,
          payment_provider_fees_checkout: data.providerFee,
          id: checkoutOrder.id,
        });
        const res = await resPromise;
        if (res.ok === true) {
          setCheckoutOrder(res.data);
          setProviderFee(data.providerFee);
          if (res.data.shipping_address_required) {
            addStepToHistory(SavedShippingAddressStep.name);
          } else {
            addStepToHistory(ConfirmationStep.name);
          }
        } else {
          toast.error(res.error.message);
          // navigateToSlide('teamSupport');
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (providerFee) {
      setValue("providerFee", providerFee);
    }
  }, [providerFee]);

  const familierPFMap = [
    { value: false, label: t(`no`) },
    { value: true, label: t(`yes`) },
  ];
  const watchPF = watch("providerFee");

  return (
    <ModalBodyElementContainer className="d-flex flex-column align-items-center justify-content-center">
      <Controller
        name="providerFee"
        defaultValue={providerFee}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <span className="mb-3 text-center lh17">
              {t("provider_fees_desc")}
            </span>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 16,
              }}
            >
              {familierPFMap.map((amountRow, amountIdx) => {
                const isLast = amountIdx === familierPFMap.length - 1;
                return (
                  <>
                    <LiteButton
                      key={amountIdx}
                      text={amountRow.label}
                      selected={amountRow.value === value}
                      onClick={() => {
                        setProviderFee(amountRow.value);
                        onChange(amountRow.value);
                      }}
                      // style={{ width: 10,}}
                    />
                    {!isLast && <div style={{ width: 10 }} />}
                  </>
                );
              })}
            </div>
            <span className="mb-3 text-center lh17">
              {watchPF ? t("with_PF") : t("without_PF")}
            </span>
            <MolhamButton
              id="donate-team-support-btn"
              endIcon="fe-arrow-right"
              label={t("continue")}
              className={`w-100`}
              onClick={handleSubmit(handleNextSlide)}
              disabled={loading || checkoutOrder === null}
              isLoading={loading}
            />
          </>
        )}
      />
    </ModalBodyElementContainer>
  );
};
const ProviderFeeStep = {
  name: "ProviderFeeStep",
  title: ProviderFeeStepTitle,
  body: ProviderFeeStepBody,
  // footer: ProviderFeeStepFooter,
};
export default ProviderFeeStep;
