import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import OtpInput from "react-otp-input";
import { useModeContext } from "../../../context/ModeContext";
import useRTL from "../../../customHooks/useRTL";
import { isEmpty } from "../../../utils/isEmpty";

type Props = {
  handleCodeSubmit: (onCodeSubmit: any) => (e: any) => Promise<void>;
  onCodeSubmit: any;
  Controller: any;
  controlCode: any;
  onCodeStepComplete: () => void;
  error: any;
  codeErrors: any;
  email: any;
};

const ForgotPasswordCodeStep = ({
  handleCodeSubmit,
  onCodeSubmit,
  Controller,
  controlCode,
  onCodeStepComplete,
  error,
  codeErrors,
  email,
}: Props) => {
  const { t } = useTranslation("common");
  const isMobile = useMobile("md");
  const isRTL = useRTL();
  const { theme } = useModeContext();
  return (
    <form
      className={`w-100 needs-validation ${
        error || !isEmpty(codeErrors) ? "invalid" : ""
      }`}
      onSubmit={handleCodeSubmit(onCodeSubmit)}
    >
      <div className="d-flex flex-column align-items-center ">
        <div
          className="text-center p-4 bg-secondary mb-4"
          style={{ width: "fit-content", borderRadius: "50%" }}
        >
          <i
            className="ai-lock text-primary "
            style={{ fontSize: 80, padding: 12 }}
          ></i>
        </div>
        <p className="h3 mb-4 text-center">{t(`reset_password`)}</p>

        <p className={`${isMobile ? "fs-md" : "fs-lg"} mb-4 text-center`}>
          {t(`reset_code_description`)}
        </p>
        <p className={`${isMobile ? "fs-md" : "fs-lg"} h4 mb-4 text-center`}>
          {email}
        </p>

        <Controller
          name="code"
          control={controlCode}
          rules={{
            required: t(`code_required`),
          }}
          // defaultValue={donor?.phone}
          render={({ field }) => (
            <OtpInput
              numInputs={6}
              inputType="number"
              onChange={(otp) => {
                field.onChange(otp);
                if (otp.length == 6) onCodeStepComplete();
              }}
              value={field.value}
              containerStyle={{
                direction: "ltr",
              }}
              shouldAutoFocus
              renderInput={(props) => {
                return (
                  <input
                    {...props}
                    style={{
                      width: "40px",
                      height: "40px",
                      margin: 5,
                      padding: isRTL ? "6px 0px 6px 0px" : 0,
                      color: theme == "light" ? "#000" : "#fff",
                    }}
                    className="form-control-num text-center form-control-active form-control-bg"
                  />
                );
              }}
            />
          )}
        />

        {codeErrors.code?.message && (
          <div className="invalid-feedback text-center ">
            {codeErrors.code?.message as string}
          </div>
        )}
      </div>
    </form>
  );
};

export default ForgotPasswordCodeStep;
