import { yupResolver } from "@hookform/resolvers/yup";
import { TAddress } from "apiTypes/addresses";
import MolhamModal from "components/MolhamModal";
import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import MolhamInput from "components/ReuseableCompos/MolhamInput";
import useModal from "customHooks/useModal";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { isEmpty } from "utils/isEmpty";
import * as Yup from "yup";

export const useEditAdressForm = (
  onSubmit: any,
  isLoading: boolean,
  isAdd: boolean,
  content?: TAddress
) => {
  const { t } = useTranslation("common");
  const validationSchema = Yup.object().shape({
    street_address: Yup.string()
      .min(15, t("min_x_invalid", { x: t("15") }))
      .max(200, t("max_x_invalid", { x: t("200") }))
      .required(t(`field_required`)),

    recipient_name: Yup.string()
      .min(10, t("min_x_invalid", { x: t("10") }))
      .max(50, t("max_x_invalid", { x: t("50") }))
      .required(t(`field_required`)),

    recipient_phone: Yup.string()
      .matches(/^(\+?)[0-9]{9,14}$/g, t(`recipient_phone_invalid`))
      .required(t(`field_required`)),

    city: Yup.string()
      .required(t(`field_required`))
      .min(3, t("min_x_invalid", { x: t("3") })),

    state: Yup.string()
      .required(t(`field_required`))
      .min(3, t("min_x_invalid", { x: t("3") })),

    country_code: Yup.string().required(t(`field_required`)),

    postal_code: Yup.string()
      .matches(/^[0-9]{5,6}$/g, t(`postal_code_invalid`))
      .required(t(`field_required`)),
  });
  const formHook = useForm<{
    street_address: string;
    recipient_name: string;
    recipient_phone: string;
    city: string;
    state: string;
    country_code: string;
    postal_code: string;
  }>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    control,
    watch,
    setFocus,
  } = formHook;
  useEffect(() => {
    if (content) {
      reset({
        street_address: content?.street_address,
        recipient_name: content?.recipient_name,
        recipient_phone: content?.recipient_phone,
        city: content?.city,
        state: content?.state,
        country_code: content?.country?.code,
        postal_code: content?.postal_code,
      });
    } else {
      reset({
        street_address: "",
        recipient_name: "",
        recipient_phone: "",
        city: "",
        state: "",
        country_code: "",
        postal_code: "",
      });
    }
  }, [content, reset]);
  const { data: utilities } = useUtilities();
  const { locale } = useRouter();

  const Form = (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`needs-validation ${!isEmpty(errors) ? "invalid" : ""}`}
    >
      <MolhamInput
        control={control}
        name="street_address"
        label="street_address"
        watch={watch}
        placeholder={t(`street_address`)}
      />
      <MolhamInput
        label="recipient_name"
        control={control}
        name="recipient_name"
        watch={watch}
        placeholder={t(`recipient_name`)}
      />
      <MolhamInput
        control={control}
        type="phone"
        name="recipient_phone"
        watch={watch}
        placeholder={t(`recipient_phone`)}
        label="recipient_phone"
      />
      <div className="d-flex " style={{ height: "fit-content" }}>
        <MolhamInput
          control={control}
          name="country_code"
          type="country"
          label="country"
          watch={watch}
          countryProps={{
            locale,
            utilities,
          }}
          placeholder={t(`country`)}
          fullWidth
        />
        <div style={{ width: 16 }} />

        <MolhamInput
          control={control}
          name="city"
          label="city"
          watch={watch}
          fullWidth
          placeholder={t(`city`)}
        />
      </div>
      <div className="d-flex " style={{ height: "fit-content" }}>
        <MolhamInput
          control={control}
          name="state"
          watch={watch}
          fullWidth
          label="state"
          placeholder={t(`state`)}
        />
        <div style={{ width: 16 }} />

        <MolhamInput
          control={control}
          name="postal_code"
          fullWidth
          watch={watch}
          label="postal_code"
          placeholder={t(`postal_code`)}
        />
      </div>

      <MolhamButton
        id={`address-${isAdd ? "add" : "edit"}-btn`}
        label={isAdd ? t(`add_address`) : t(`edit_address`)}
        className="w-100"
        startIcon={isAdd ? "fe-plus" : "fe-edit"}
        isLoading={isLoading}
        disabled={!isDirty || !isValid}
      />
    </form>
  );
  return { Form, formHook };
};

type Props = {
  handleCloseAddEditAddressModal: () => void;
  showAddEditAddressModal: boolean;
  content?: TAddress;
  onEditAddressSubmit: (data) => Promise<void>;
  isLoading: boolean;
  isAdd?: boolean;
};

const AddEditAddressModal = ({
  handleCloseAddEditAddressModal,
  showAddEditAddressModal,
  content,
  onEditAddressSubmit,
  isLoading,
  isAdd,
}: Props) => {
  const { t } = useTranslation("common");
  const onSubmit = async (data) => {
    try {
      await onEditAddressSubmit(data);
      reset();
    } catch (err) {}
  };
  const { Form, formHook } = useEditAdressForm(
    onSubmit,
    isLoading,
    isAdd,
    content
  );
  const {
    reset,
    formState: { isDirty },
    setFocus,
  } = formHook;

  useEffect(() => {
    if (showAddEditAddressModal) {
      setFocus("street_address");
    }
  }, [showAddEditAddressModal, setFocus]);
  const closeModal = () => {
    reset();
    handleCloseAddEditAddressModal();
  };
  const modal = useModal();
  const onHide = () => {
    if (isDirty) {
      modal.handleShowModal();
    } else {
      closeModal();
    }
  };
  return (
    <MolhamModalOffcanvas
      show={showAddEditAddressModal}
      onHide={onHide}
      fullscreen
    >
      <MolhamModal
        size="sm"
        show={modal.showModal}
        onHide={modal.handleCloseModal}
      >
        {/* are you sure you want to close create modal*/}
        <div className="mb-3 fs-18 text-center">
          {t(`are_you_sure_close_create`)}
        </div>
        <MolhamButton
          id="close-create-address-btn"
          label={t(`close`)}
          isLoading={isLoading}
          variant="danger"
          className="w-100"
          onClick={() => {
            modal.handleCloseModal();
            closeModal();
          }}
        />
      </MolhamModal>
      {Form}
    </MolhamModalOffcanvas>
  );
};

export default AddEditAddressModal;
