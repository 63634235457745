import { useEffect } from "react";
import { useCheckoutStore } from "../../../CheckoutState";
import SelectInput from "../../../components/Select/SelectInput";

const SubscriptionSettings: React.FC = () => {
  const selectedPaymentOption = useCheckoutStore(
    (state) => state.selectedPaymentOption
  );
  const paymentFrequency = useCheckoutStore((state) => state.paymentFrequency);
  const setPaymentFrequency = useCheckoutStore(
    (state) => state.setPaymentFrequency
  );
  useEffect(() => {
    if (selectedPaymentOption?.frequency_cycles && !paymentFrequency) {
      setPaymentFrequency(
        selectedPaymentOption.frequency_cycles?.options[0]?.value
      );
    }
  }, [selectedPaymentOption]);
  return selectedPaymentOption && selectedPaymentOption.frequency_cycles ? (
    <>
      <div className="mb-2">
        {selectedPaymentOption.frequency_cycles?.label}
      </div>
      <div className="mb-4">
        <SelectInput
          options={selectedPaymentOption.frequency_cycles?.options.map(
            (item) => {
              return {
                label: item.name,
                value: item.value,
                description: item.description,
              };
            }
          )}
          onChange={(e) => {
            setPaymentFrequency(Number(e.target.value));
          }}
          value={paymentFrequency}
        />
      </div>
    </>
  ) : null;
};

export default SubscriptionSettings;
