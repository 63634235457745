import Cookies from "js-cookie";

export const manageSessionReference = () => {
  const cookieName = "molham-session-reference";
  const getSessionReference = () => {
    return Cookies.get(cookieName);
  };
  const setSessionReference = (value: string) => {
    const isCookieSet = getSessionReference();
    if (!isCookieSet) {
      Cookies.set(cookieName, value, {
        expires: (1 / 24 / 60) * 25, // 25 minutes
      });
    }
  };
  const removeSessionReference = () => {
    Cookies.remove(cookieName);
  };
  return { getSessionReference, setSessionReference, removeSessionReference };
};
