type Meta = {
  link: null | string;
  icon: string;
  title: string;
  desc?: string;
  badge?: boolean;
  key?: string;
  isSignOut?: boolean;
};
export const profileMeta: Meta[] = [
  {
    link: "/profile/overview",
    icon: "fe-eye",
    title: "overview",
    desc: "profile_overview_desc",
    key: "overview",
  },
  {
    link: "/profile/info",
    icon: "fe-info",
    title: "account_info",
    desc: "account_info_desc",
    key: "info",
  },
  {
    link: "/profile/account",
    icon: "fe-settings",
    title: "account_settings",
    desc: "account_settings_desc",
    key: "settings",
  },

  {
    link: "/profile/donations",
    icon: "fe-dollar-sign",
    title: "my_donations",
    desc: "profile_sections_donations_desc",
    badge: true,
    key: "donations",
  },
  {
    link: "/profile/subscriptions",
    icon: "fe-repeat",
    title: "profile-payments-subscribtions",
    desc: "my_subscriptions_desc",
    key: "subscriptions",
  },
  {
    link: "/profile/sponsorships",
    icon: "fe-heart",
    title: "my_sponsorships",
    desc: "my_sponsorships_desc",
    key: "sponsorships",
  },
  {
    link: "/profile/outstanding",
    icon: "fe-calendar",
    title: "profile-payments-outstanding",
    desc: "profile_payments_outstanding_desc",
    key: "outstanding",
  },
  {
    link: "/profile/saved-methods",
    icon: "fe-credit-card",
    title: "saved_payment_methods",
    desc: "profile_saved_method_desc",
    key: "saved_methods",
  },
  {
    link: "/profile/documentations",
    icon: "fe-file-text",
    title: "my_documentations",
    desc: "my_documentations_desc",
    key: "documentations",
  },
  {
    link: "/profile/addresses",
    icon: "fe-map-pin",
    title: "addresses",
    desc: "addresses_desc",
    key: "shipping_addresses",
  },
  {
    link: "/profile/store-orders",
    title: "molham_store_orders",
    desc: "molham_store_orders_desc",
    icon: "fe-shopping-cart",
    key: "store_orders",
  },

  {
    link: "/profile/likes",
    icon: "fe-thumbs-up",
    title: "profile_likes",
    desc: "profile_likes_desc",
    key: "likes",
  },

  {
    link: "/profile/comments",
    icon: "fe-message-circle",
    title: "profile_comments",
    desc: "profile_comments_desc",
    key: "comments",
  },
  {
    link: "/profile/shares",
    icon: "fe-share-2",
    title: "the_shares",
    desc: "profile_shares_desc",
    key: "shares",
  },
  {
    link: "/profile/events",
    icon: "fe-calendar",
    title: "profile_events",
    desc: "profile_events_desc",
    key: "events",
  },

  {
    link: "/profile/support-issues",
    icon: "fe-flag",
    title: "help_and_support_issues",
    desc: "help_and_support_issues_desc",
    key: "support_issues",
  },
  {
    link: null,
    icon: "fe-log-out text-danger",
    title: "sign_out",
    isSignOut: true,
    key: "logout",
  },
];

export const profileSideBarContent = [
  {
    link: "/profile/overview",
    icon: "fe-eye",
    title: "overview",
    desc: "profile_overview_desc",
  },
  {
    link: "/profile/info",
    icon: "fe-info",
    title: "account_info",
    desc: "account_info_desc",
  },
  {
    link: "/profile/account",
    icon: "fe-settings",
    title: "account_settings",
    desc: "account_settings_desc",
  },
  {
    link: "/profile",
    icon: "fe-credit-card",
    title: "donations_and_payments",
    desc: "profile_payments_desc",
    badge: true,
  },
  {
    link: "/profile",
    icon: "fe-bar-chart",
    title: "activity_log",
    desc: "activities_desc",
  },
  {
    link: "/profile/support-issues",
    icon: "fe-flag",
    title: "help_and_support_issues",
    desc: "help_and_support_issues_desc",
  },
  {
    link: null,
    icon: "fe-log-out text-danger",
    title: "sign_out",
    isSignOut: true,
  },
];
export const avatarDropdownMeta: Meta[] = [
  {
    link: "/profile/info",
    icon: "fe-info",
    title: "account_info",
    desc: "account_info_desc",
  },
  {
    link: "/profile/account",
    icon: "fe-settings",
    title: "account_settings",
    desc: "account",
  },
  {
    link: "/profile/donations",
    icon: "fe-dollar-sign",
    title: "my_donations",
    desc: "profile_sections_donations_desc",
    badge: true,
  },
  {
    link: "/profile/subscriptions",
    icon: "fe-repeat",
    title: "profile-payments-subscribtions",
    desc: "my_subscriptions_desc",
  },
  {
    link: "/profile/sponsorships",
    icon: "fe-heart",
    title: "my_sponsorships",
    desc: "my_sponsorships_desc",
  },
  {
    link: "/profile/outstanding",
    icon: "fe-calendar",
    title: "profile-payments-outstanding",
    desc: "profile_payments_outstanding_desc",
  },
  {
    link: "/profile/support-issues",
    icon: "fe-message-square",
    title: "help_and_support_issues",
    desc: "support",
  },
  {
    link: "/profile/account/signout",
    icon: "fe-log-out text-danger",
    title: "sign_out",
    desc: "sign_out",
    isSignOut: true,
  },
];
