import { yupResolver } from "@hookform/resolvers/yup";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { NEXT_URL_PROXY } from "../../../config";
import { AuthModalContext } from "../../../context/AuthModalContext";
import { postData } from "../../../utils/postData";
import ForgotPasswordCodeStep from "./ForgotPasswordCodeStep";
import ForgotPasswordEmailStep from "./ForgotPasswordEmailStep";
import ForgotPasswordResetPasswordStep from "./ForgotPasswordResetPasswordStep";
type Props = {
  fromPage?: boolean;
};
const ForgotPassword = ({ fromPage }: Props) => {
  const [isLoading, setIslaoding] = useState(false);
  const [token, setToken] = useState(null);
  const [code, setCode] = useState(null);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [fromPasswordDonor, setFromPasswordDonor] = useState(null);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const { setStep } = useContext(AuthModalContext);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(8, t(`password_must_be_8`))
      .required(t(`password_required`)),
    new_password_confirmation: Yup.string()
      .oneOf([Yup.ref("new_password"), null], t(`passwords_must_match`))
      .required(t(`confirm_password_required`)),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const validationSchemaForEmail = Yup.object().shape({
    email: Yup.string().required(t(`email_required`)).email(t(`email_invalid`)),
  });
  const formOptionsForEmail = {
    resolver: yupResolver(validationSchemaForEmail),
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors: emailErrors, isDirty },
  } = useForm(formOptionsForEmail);

  const {
    handleSubmit: handleCodeSubmit,
    control: controlCode,
    formState: { errors: codeErrors },
  } = useForm();

  const {
    register: registerPasswordForm,
    handleSubmit: handlePasswordForm,
    control: controlPasswordForm,
    watch: watchPasswordForm,
    formState: { errors: formErrors },
  } = useForm(formOptions);

  const route = useRouter();

  const onSubmit = async (data) => {
    const { email } = data;
    setEmail(email);
    setIslaoding(true);

    try {
      const res = await postData(
        `${NEXT_URL_PROXY}/donors/password-reset-requests`,
        {
          email,
        }
      );
      setToken(res.data.token + "");

      if (!res.data.token) {
        if (res.data === "donor_exceeded_password_reset_requests_limit") {
          toast.error("Too many requests, please try again later!");
        } else {
          toast.error("Something went wrong!");
        }
        setIslaoding(false);
        return;
      }
      toast.success("Check out your email!");
      setIslaoding(false);
    } catch (err) {
      setError(err.response?.data.errors);
      setIslaoding(false);
    }
  };

  const onCodeSubmit = async (data) => {
    const { code } = data;

    setIslaoding(true);

    try {
      const res = await postData(
        `${NEXT_URL_PROXY}/donors/password-reset-requests/${token}`,
        { code }
      );

      setCode(code);
      setShowPasswordForm(true);
      setIslaoding(false);
      // toast.success(`Welcome ${res.data.name}`);
      setFromPasswordDonor(res.data);
    } catch (err) {
      //
      toast.error(err.message);

      // toast.error(err?.response?.data?.error?.code);
      setError(err.response?.data?.error?.code);

      setIslaoding(false);
      setCode(null);
      setShowPasswordForm(null);
    }
  };

  const onCodeStepComplete = async () => {
    handleCodeSubmit(onCodeSubmit)();
  };

  const onPasswordSubmit = async (data) => {
    const { new_password, new_password_confirmation } = data;

    setIslaoding(true);

    try {
      await postData(
        `${NEXT_URL_PROXY}/donors/password-reset-requests/${token}/confirm`,
        {
          code,
          new_password,
          new_password_confirmation,
        }
      );

      setIslaoding(false);
      toast.success(t("password_has_been_reset"));
      // onTabClick("sign_in");
      fromPage ? route.push("/login") : setStep(1);
    } catch (err) {
      toast.error(err.message);
      setError(err.response?.data);
      // toast.error(err?.response?.data?.error?.code);
      setIslaoding(false);
    }
  };

  return (
    <div className="w-100">
      {!token && (
        <ForgotPasswordEmailStep
          error={error}
          emailErrors={emailErrors}
          control={control}
          watch={watch}
          register={register}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          onSubmit={onSubmit}
          isDirty={isDirty}
        />
      )}
      {token && !showPasswordForm && (
        <ForgotPasswordCodeStep
          Controller={Controller}
          controlCode={controlCode}
          handleCodeSubmit={handleCodeSubmit}
          onCodeSubmit={onCodeSubmit}
          onCodeStepComplete={onCodeStepComplete}
          error={error}
          codeErrors={codeErrors}
          email={email}
        />
      )}
      {showPasswordForm && (
        <ForgotPasswordResetPasswordStep
          formErrors={formErrors}
          control={controlPasswordForm}
          watch={watchPasswordForm}
          isLoading={isLoading}
          registerPasswordForm={registerPasswordForm}
          onPasswordSubmit={onPasswordSubmit}
          handlePasswordForm={handlePasswordForm}
          fromPasswordDonor={fromPasswordDonor}
          error={error}
        />
      )}
      {fromPage ? (
        <p className="fs-sm pt-3 mb-0">
          {t(`would_you_go_to`)}{" "}
          <Link href="/login" className="fw-medium cursor">
            {t(`login`)}
          </Link>
        </p>
      ) : null}
    </div>
  );
};

export default ForgotPassword;
