import { useRouter } from "next/router";

const useLastPath = () => {
  const router = useRouter();

  const lastPath = router.asPath
    .split("?")[0]
    .split("/")
    [router.asPath.split("/").length - 1]?.split("#")[0];

  return lastPath;
};

export default useLastPath;
