import useMobile from "customHooks/useMobile";
import CustomContentLoader from "../ReuseableCompos/CustomContentLoader";

const InputSkeleton = () => {
  const isMobile = useMobile("md");

  return (
    <CustomContentLoader
      className="position-absolute top-0 h-100"
      viewBox={`0 0 ${isMobile ? "4" : "8"}00 71`}
    >
      <rect x="16" y="22" rx="12" ry="12" width="95%" height="24" />
      <rect x="0" y="70" rx="0" ry="0" width="100%" height="1" />
    </CustomContentLoader>
  );
};

export default InputSkeleton;
