import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
type FooterProviderData = {
  noBlur: boolean;
  setNoBlur: Dispatch<SetStateAction<boolean>>;
};
const FooterContext = createContext<FooterProviderData>(null);

const FooterProvider = ({ children }: PropsWithChildren) => {
  const [noBlur, setNoBlur] = useState(false);

  const value = useMemo(() => {
    return {
      noBlur,
      setNoBlur,
    };
  }, [noBlur, setNoBlur]);
  return (
    <FooterContext.Provider value={value}>{children}</FooterContext.Provider>
  );
};
export function useFooterContext(): FooterProviderData {
  const footerData = useContext(FooterContext);
  if (footerData == null) {
    throw new Error(
      "FooterContext is not available. Make sure you are rendering the component within the FooterProvider."
    );
  }
  return footerData;
}
export { FooterContext, FooterProvider };
