import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import RadioIcon from "components/ReuseableCompos/SharedDonatePages/DonateModal/components/RadioIcon";
import useRTL from "customHooks/useRTL";
import { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  showModalOffCanvas: boolean;
  handleCloseModalOffCanvas: () => void;
  placeHolder: string;
  options: {
    label: string;
    value: any;
  }[];
  selectedOption: {
    label: string;
    value: any;
  };
  onOptionChange: (option: { label: string; value: any }) => void;
};

function SelectCountryModal({
  showModalOffCanvas,
  handleCloseModalOffCanvas,
  options,
  selectedOption,
  onOptionChange,
  placeHolder,
}: Props) {
  const isRTL = useRTL();
  const [customOptions, setCustomOptions] = useState(options);
  useEffect(() => {
    setCustomOptions(options);
  }, [options]);
  const onSearchChange = (e: any) => {
    setCustomOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  return (
    <MolhamModalOffcanvas
      show={showModalOffCanvas}
      onHide={handleCloseModalOffCanvas}
      fullscreen
      body={{
        className: "p-0 pt-3 pt-md-0",
        style: {
          height: "100%",
          position: "relative",
          overflowY: "hidden",
        },
      }}
      modalStyle={{
        overflowY: "hidden",
        zIndex: 2002,
      }}
      offCanvasClassname="impt-zindex-2003"
    >
      <DivBody>
        <div className="position-sticky top-0 bg-white  px-3 pb-3 pt-2 pt-lg-3">
          {/* //input group with search icon */}
          <div className="position-relative d-flex w-100">
            <input
              className={`form-control text-capitalize w-100`}
              placeholder={placeHolder}
              aria-label="search"
              onChange={onSearchChange}
              style={{ paddingInlineStart: "2rem" }}
            ></input>
            <span
              className={"position-absolute"}
              id="basic-addon1"
              style={
                isRTL
                  ? {
                      right: "3%",
                      top: "20%",
                      color: "#b0b3b8",
                    }
                  : {
                      color: "#b0b3b8",

                      top: "20%",
                      left: "3%",
                    }
              }
            >
              <i className="fe-search"></i>
            </span>
          </div>
        </div>
        <div className="">
          {customOptions?.map((option, optionIdx) => (
            <div
              onClick={() => {
                onOptionChange(option);
                handleCloseModalOffCanvas();
              }}
              className={`d-flex align-items-center justify-content-between px-3 w-100 py-0 cursor ${
                optionIdx === customOptions.length - 1
                  ? "border-bottom-0"
                  : "border-bottom"
              }`}
              key={option.label}
            >
              <div className="d-flex flex-column py-3">
                <label
                  htmlFor={option.label}
                  className={`mb-0 d-block fs-base`}
                >
                  {option.label}
                </label>
              </div>
              <div>
                <RadioIcon
                  checked={selectedOption?.value === option.value}
                  onClick={() => {
                    onOptionChange(option);
                    handleCloseModalOffCanvas();
                  }}
                  id={option.label}
                />
              </div>
            </div>
          ))}
        </div>
      </DivBody>
    </MolhamModalOffcanvas>
  );
}

export default SelectCountryModal;
const DivBody = styled.div`
  overflow-y: auto;
  height: 100%;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  scrollbar-width: none;
`;
