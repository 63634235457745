import { ComponentProps, ForwardedRef, forwardRef } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const CustomPhoneInput = ((props:ComponentProps<typeof PhoneInput>, ref:ForwardedRef<any>) => {
  return (
    <PhoneInput
      dir="ltr"
      ref={ref}
      {...props}
      flags={{
        SY: () => (
          // eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
          <img
            src="/SY.svg"
            width="30"
            height="20"
            style={{
              height: 21,
              width: 35,
              position: "absolute",
              top: -2,
              left: 0,
            }}
          />
        ),
      }}
    />
  );
});

export default forwardRef(CustomPhoneInput);
