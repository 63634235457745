import { TPaymentOption } from "apiTypes";
import { NEXT_URL_PROXY } from "config";
import { usePreferenceContext } from "context/PreferencesContext";
import { useInfinitePagination } from "customHooks/useInfinitPagination";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";
import ProfileMenuItem from "../../../../../Profile/ProfileMenuItem";
import { useCheckoutStore } from "../../CheckoutState";
import DonationAmountStep from "../DonationAmount/DonationAmountStep";
const PaymentOptionStepTitle: React.FC = () => {
  const { t } = useTranslation("common");
  const { paymentOptionData } = useCheckoutStore((state) => ({
    paymentOptionData: state.paymentOptionData,
  }));
  return paymentOptionData.payment_options_label ?? t("donation_options");
};
const PaymentOptionStepBody: React.FC = () => {
  const {
    paymentOptions,
    paymentOptionData,
    setSelectedPaymentOption,
    addStepToHistory,
    setModalVisible,
    replaceStepInHistory,
  } = useCheckoutStore((state) => ({
    paymentOptions: state.paymentOptions,
    paymentOptionData: state.paymentOptionData,
    setSelectedPaymentOption: state.setSelectedPaymentOption,
    addStepToHistory: state.addStepToHistory,
    replaceStepInHistory: state.replaceStepInHistory,

    setModalVisible: state.setModalVisible,
  }));
  const {
    data: targetablePaymentOptions,
    error: targetablePaymentOptionsError,
    formattedData,
    isLoadingInitialData,
    getAllPages,
  } = useInfinitePagination<TPaymentOption>(
    `${NEXT_URL_PROXY}/targetables/${paymentOptionData.targetableType}/${paymentOptionData.targetableId}/payment-options`
  );
  const options =
    formattedData.length >= paymentOptions.length
      ? formattedData
      : paymentOptions;
  const { curr, currencies } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  useEffect(() => {
    if (!isLoadingInitialData) {
      getAllPages();
    }
  }, [isLoadingInitialData]);
  useEffect(() => {
    if (options.length == 0) {
      // TODO: delete the order
      setModalVisible(false);
    } else if (options.length == 1) {
      setSelectedPaymentOption(formattedData[0]);
      replaceStepInHistory(DonationAmountStep.name);
    }
  }, [targetablePaymentOptions, targetablePaymentOptionsError, formattedData]);
  const handleNextSlide = (option: TPaymentOption) => {
    setSelectedPaymentOption(option);
    addStepToHistory(DonationAmountStep.name);
  };
  const formatNumbers = (n: Number) => {
    return Number(n).toString().split(".")[0];
  };
  const { locale } = useRouter();
  const hasPreviewImage = options?.some((option) => !!option.preview_image);
  const isMobile = useMobile("md");
  return (
    <div className="d-flex flex-column align-items-center justify-content-center list-group list-group-flush py-0 ">
      {options.length > 1 ? (
        options?.map((option, key) => {
          const EL = () => (
            <div className="d-flex flex-column">
              <span>{option.description}</span>
              {option.amount?.[currencyCode ?? curr?.code] && (
                <span className="fw-bold mt-1 mt-md-2">
                  <span className="text-primary">
                    {formatNumbers(option.amount?.[currencyCode ?? curr?.code])}
                  </span>{" "}
                  <span>
                    {currencies.find((c) => c.code == currencyCode)?.name?.[
                      locale
                    ] || curr?.name?.[locale]}
                  </span>
                </span>
              )}
            </div>
          );
          return (
            <ProfileMenuItem
              key={key}
              title={option.name}
              CustomCont={EL}
              noIcon={!hasPreviewImage}
              customIcon={hasPreviewImage}
              iconContainerStyle={{
                padding: 0,
              }}
              customIconSize={isMobile ? 54 : 60}
              icon={() => (
                <Image
                  width={50}
                  height={50}
                  style={{
                    width: "100%",
                    height: "100%",
                    // objectFit: "cover",
                  }}
                  src={
                    option?.preview_image?.thumbnails["1:1"] ??
                    "/img/white-splash-smallerer.png"
                  }
                  alt={option.name}
                />
              )}
              customPy={"p-3"}
              customPxPhone="px-3"
              customPxWeb="px-3"
              onClick={() => {
                handleNextSlide(option);
              }}
            />
          );
        })
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};
const PaymentOptionStep = {
  name: "PaymentOptionStep",
  title: PaymentOptionStepTitle,
  body: PaymentOptionStepBody,
  // footer: PaymentOptionStepFooter,
};
export default PaymentOptionStep;
