import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import VerificationModal from "../components/ReuseableCompos/VerificationModal";
import { NEXT_URL_PROXY } from "../config/index";
import { AuthContext } from "../context/AuthContext";
import { postData } from "../utils/postData";
export default function CheckVerificationWrapper({ children }) {
  const { donor, signOut } = useContext(AuthContext);

  const [hideModal, setHideModal] = useState(false);

  const { t } = useTranslation("common");
  const {
    handleSubmit: handleCodeSubmit,
    control: controlCode,
    formState: { errors: codeErrors },
  } = useForm();
  const router = useRouter();
  const onCodeSubmit = async (props) => {
    const { code } = props;
    const data = JSON.stringify({
      verification_code: code,
    });
    try {
      await postData(
        `${NEXT_URL_PROXY}/auth/verify-email`,
        data,
        null,
        { "Content-Type": "application/json", Accept: "application/json" }
      );
      setHideModal(true);
      toast.success(t("email_verified"));
      router.reload();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const resendCode = async () => {
    await postData(`${NEXT_URL_PROXY}/auth/resend-email-verification`);
  };
  const onCodeStepComplete = async () => {
    handleCodeSubmit(onCodeSubmit)();
  };
  const showModalCondition =
    Boolean(donor?.request_email_verification_code) && !hideModal;

  return (
    <div>
      <VerificationModal
        email={donor?.email}
        
        handleCodeSubmit={handleCodeSubmit}
        onCodeSubmit={onCodeSubmit}
        controlCode={controlCode}
        codeErrors={codeErrors}
        onCodeStepComplete={onCodeStepComplete}
        resendCode={resendCode}
        signOut={signOut}
        closeButton={false}
        noSignOut={false}
        onCloseBtnClick={() => setHideModal(true)}
        showModalCondition={showModalCondition}
      />
      {children}
    </div>
  );
}
