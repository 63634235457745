import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React from "react";
type Props = {
  fuzzy?: boolean;
  href?: string;
  children: React.ReactElement;
  activeClassName?: string;
  pathHrefTokenIdx?: number;
} & LinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;
const ActiveLink: React.FC<Props> = ({
  fuzzy = false,
  href,
  children,
  activeClassName,
  pathHrefTokenIdx = 0,
  ...props
}) => {
  const router = useRouter();

  if (!href) {
    return <>{React.cloneElement(children)}</>;
  }
  let className = children.props.className || "";

  const hrefTokens = href?.substr(1)?.split("/");
  const pathTokens = router.asPath?.substr(1)?.split("/");
  let matched = false;
  for (let i = pathHrefTokenIdx; i < hrefTokens?.length; i++) {
    if (hrefTokens[i] === pathTokens[i]) {
      matched = true;
      break;
    }
  }

  if ((!fuzzy && router.asPath === href) || (fuzzy && matched)) {
    className = `${className} ${activeClassName}`.trim();
  }
  return (
    <Link legacyBehavior {...props} href={href || ""}>
      {React.cloneElement(children, { className })}
    </Link>
  );
};

export default ActiveLink;
