import { useMemo } from "react";
import RadioIcon from "../../../components/RadioIcon";
import styles from "./PaymentMethodItem.module.css";
export const PaymentMethodItem: React.FC<{
  label: string;
  description?: string;
  onPress: () => void;
  Icon?: string | React.ReactNode;
  isSelected: boolean;
  subdescription?: string;
  isLast?: boolean;
}> = ({ label, description, onPress, Icon, isSelected, isLast,subdescription }) => {
  const IconComponent = useMemo(() => {
    return typeof Icon === "string"
      ? () => <img className={styles["payment-method-item"]} src={Icon} />
      : Icon;
  }, [Icon]);

  return (
    <>
      <div
        style={{
          borderRadius: 10,
          border: `1px solid ${
            isSelected ? "var(--primary2)" : "var(--light-gray)"
          }`,
          // flex: 1,
          // marginRight: 15,
          // marginLeft: 15,
          marginBottom: isLast ? 0 : 15,
          overflow: "hidden",
          //   ...(isSelected ? theme.theme.shadowSettings : {}),
        }}
        className="cursor"
      >
        <div
          onClick={onPress}
          // android_ripple={{borderless: true}}
          style={{
            display: "flex",
            padding: 10,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            // height: theme.theme.rectComponentHeight + 5,
          }}
        >
          {typeof IconComponent === "function" ? (
            <IconComponent />
          ) : (
            IconComponent
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{
                fontSize: 16,
                color: "var(--dark-gray)",
              }}
            >
              {label}
            </div>
            {description ? (
              <span
                className="text-muted"
                style={{
                  fontSize: 14,
                  textTransform: "capitalize",
                }}
              >
                {description}
              </span>
            ) : null}
            {subdescription ? (
              <span
                className="text-muted"
                style={{
                  fontSize: 14,
                  textTransform: "capitalize",
                }}
              >
                {subdescription}
              </span>
            ) : null}
          </div>
          <RadioIcon checked={isSelected} />
        </div>
      </div>
    </>
  );
};
