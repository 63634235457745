import { TCartItem } from "apiTypes";
import { genericFallbackImg } from "config";
import useFormatNumbers from "customHooks/useFormatNumbers";
import Image from "next/image";
type Props = {
  data: TCartItem;
  isLast?: boolean;
  onMenuClick: (id: string | number) => void;
};
const Cart: React.FC<Props> = ({ data, onMenuClick, isLast }) => {
  const { title, preview_image, amount, description, id, short_name } = data;

  const { formatNumbers } = useFormatNumbers();

  const shortNameNumbers = short_name.split(/\D+/).filter(Boolean).join(" ");
  const shortNameStrings = short_name.split(/\d+/).filter(Boolean).join(" ");
  const amountStrings = formatNumbers(amount).split(" ")[0];
  const amountNumbers = formatNumbers(amount).split(" ")[1];
  return (
    <>
      <div
        onClick={() => onMenuClick(id)}
        className="media cursor  mb-2  w-100 px-3 py-2 "
      >
        <div className="d-block">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <Image
            unoptimized
            blurDataURL={preview_image?.blurry?.["1:1"]}
            placeholder="blur"
            className="rounded"
            width={60}
            height={60}
            src={preview_image?.thumbnails["1:1"] ?? genericFallbackImg}
            alt={title}
          />
        </div>
        <div className="media-body ps-4 ml-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className="mr-3">
              <div
                style={{
                  lineHeight: 1.5,
                }}
                className="h4 nav-heading font-size-md mb-1 text-capitalize font-weight-bold"
              >
                {title}
              </div>
              <div className="d-flex align-items-start flex-column  ">
                <span className="mr-2 mb-1 fs-md">
                  {shortNameStrings}{" "}
                  <span className="text-primary">{shortNameNumbers}</span>
                </span>
                <span className="mr-2 mb-1 fs-md text-muted text-uppercase">
                  {amountStrings}{" "}
                  <span className="text-primary">{amountNumbers}</span>
                </span>
                <span className="fs-md text-muted ">{description}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="align-self-stretch d-flex flex-column justify-content-center">
          <a
            className="d-block cursor text-decoration-none  text-heading"
            data-toggle="tooltip"
            title="Remove"
            onClick={() => {
              onMenuClick(id);
            }}
          >
            <i className="fe-more-vertical " style={{ fontSize: "1.5rem" }}></i>
          </a>
        </div>
      </div>
      {!isLast && (
        <div
          className="w-100 my-2 "
          style={{
            borderTop: "1px solid var(--light-gray)",
          }}
        ></div>
      )}
    </>
  );
};

export default Cart;
