import useRTL from "customHooks/useRTL";

type Props = {
  onBackBtnClick: () => void;
};

const BackBtn = ({ onBackBtnClick, ...props }: Props) => {
  const rtl = useRTL();
  return (
    <button className="back" {...props} onClick={onBackBtnClick}>
      <i className={`fe-arrow-${rtl ? "right" : "left"}`}></i>
    </button>
  );
};

export default BackBtn;
