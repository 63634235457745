import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { NEXT_URL_PROXY } from "config";
import Cookies from "js-cookie";
import { getMolhamHeaders } from "utils/molhamHeaders";

// export interface MolhamError extends AxiosError {
//     ok: false;
//     error?: { code: string; message: string };
//     errors?: { [KEY in string]: string[] | { message: string }[] };
// }
export type MolhamError = AxiosError<{
  error?: { code: string; message: string };
  errors?: { [KEY in string]: string[] | { message: string }[] };
}> & {
  error?: { code: string; message: string };
  errors?: { [KEY in string]: string[] | { message: string }[] };
} & {
  ok: false;
};
// export interface MolhamResponse<T> extends AxiosResponse<T> {
//     ok: true;
// }
export type MolhamResponse<T> = AxiosResponse<T> & {
  ok: true;
  error?: { code: string; message: string };
};
export type MolhamResult<T> = MolhamResponse<T> | MolhamError;
const axiosInstance = axios.create({
  baseURL: NEXT_URL_PROXY,
});

axiosInstance.interceptors.request.use(function (request) {
  const molhamHeaders = getMolhamHeaders();
  const locale = Cookies.get("locale") ?? "ar";
  const currency = Cookies.get("currency");
  request.headers.locale = locale;
  request.headers.currency = currency;
  request.headers = {
    ...request.headers,
    ...molhamHeaders,
  };
  return request;
});
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const res = { ...response, ok: true };
    return res;
  },
  function (
    error: AxiosError<{
      error?: { code: string; message: string };
      errors?: { [KEY in string]: string[] | { message: string }[] };
    }>
  ) {
    const err: MolhamError = {
      ...error,
      ...error.response?.data,
      ok: false,
    };
    // if (err.error) {
    //   showMessage({
    //     type: 'danger',
    //     icon: 'auto',
    //     // description: err.error.message,
    //     // message: i18n.t('error'),
    //     message: err.error.message,
    //   });
    // }
    return Promise.reject(err);
    // return err;
  }
);

export const fetcher = (url: string) =>
  axiosInstance.get(url).then((res) => {
    return res.data;
  });

export default axiosInstance as AxiosInstance;
