import useTranslation from "next-translate/useTranslation";
import { Modal, Offcanvas } from "react-bootstrap";
import CloseBtn from "./ReuseableCompos/CloseBtn";
type Props = {
  onCloseBtnClick: () => void;
  customButton?: React.ReactNode;
  showCustomBtnCond?: boolean;
  label?: string;
  labelClassName?: string;
  centered?: boolean;
  noCloseBtn?: boolean; 
  noBorder?: boolean; 
};
const MolhamMOCHeader: React.FC<Props> = ({
  onCloseBtnClick,
  customButton,
  showCustomBtnCond = true,
  centered,
  labelClassName,
  noCloseBtn,
  noBorder,
  label,
  ...props
}) => {
  const { t } = useTranslation("common");
  return (
    <Modal.Header {...props} className={noBorder ? "border-0" : ""}>
      {(centered || (customButton && showCustomBtnCond)) && (
        <div style={{ minWidth: 35 }}>{customButton}</div>
      )}

      <Offcanvas.Title className={"mb-0 " + labelClassName}>
        {t(label)}
      </Offcanvas.Title>

      {!noCloseBtn ? (
        <CloseBtn closeModal={onCloseBtnClick} />
      ) : (
        <div style={{ minWidth: 35 }}></div>
      )}
    </Modal.Header>
  );
};

export default MolhamMOCHeader;
