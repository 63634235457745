import useTranslation from "next-translate/useTranslation";
import NotificationCard from "./NotificationCard";
import NotificationSkeleton from "./NotificationSkeleton";
import InfinteDataSoruceHandler from "./SharedLayoutComponents/InfinteDataSoruceHandler";

type Props = {
  handleCloseNotificationOffCanvas: () => void;
};

const NotificationCards = ({ handleCloseNotificationOffCanvas }: Props) => {
  const { t } = useTranslation("common");
  return (
    <InfinteDataSoruceHandler
      isOffcanvas
      skeleton={NotificationSkeleton}
      endPoint={"auth/notifications"}
      resourceName="item"
      skeletonAmount={3}
      getMoreSkeletonAmount={4}
      customNoDataLabel={t("no_x_found", { x: t("notifications") })}
    >
      <NotificationCard
        handleCloseNotificationOffCanvas={handleCloseNotificationOffCanvas}
      />
    </InfinteDataSoruceHandler>
  );
};

export default NotificationCards;
