import useMobile from "customHooks/useMobile";
import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { PropsWithChildren, createContext, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useModeContext } from "./ModeContext";
const CrispModalContext = createContext(null);
const CrispModalProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const mode = useModeContext();
  const router = useRouter();
  //remove #chat-with-support from url

  const onClose = () => {
    router.replace(router.asPath.replace("#chat-with-support", ""));
  };
  const isMobile = useMobile("md");
  const isSmallMobile = useMobile("(max-height: 600px)",true);
  const isRTL = useRTL();
  const selectedColor = {
    default: "default",
    blue: "blue",
    teal: "teal",
    purple: "purple",
    rose: "red",
    green: "green",
    orange: "orange",
    phosphoric: "light_green",
    fuchsia: "purple",
    pink: "purple",
  }[mode.color];
  const xStyle = {
    position: "absolute" as const,
    top: 0,
    zIndex: 999,
    padding: 10,
  };
  return (
    <>
      <CrispModalContext.Provider value={null}>
        {children}
      </CrispModalContext.Provider>
      <Modal
        show={router.asPath.includes("#chat-with-support")}
        fullscreen="md-down"
        contentClassName="border-none"
        centered
      >
        <Modal.Body
          className={`p-0 ${
            !isMobile && "rounded"
          } overflow-hidden user-select-none`}
        >
          <div
            style={{
              minHeight: isSmallMobile ? 300 : 600,
              height: "100%",
              display: "flex",
            }}
          >
            <div
              style={!isRTL ? { ...xStyle, right: 0 } : { ...xStyle, left: 0 }}
            >
              <button
                // type="button"
                onClick={onClose}
                className="crisp-modal-close-btn"
              >
                <i className="fe-x" style={{ color: "#fff" }}></i>
              </button>
            </div>

            <iframe
              src={`/api/support-chat?theme=${selectedColor}`}
              style={{
                // flex-grow: 1; border: none; margin: 0; padding: 0;
                flexGrow: 1,
                border: "none",
                margin: 0,
                padding: 0,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CrispModalProvider;

export const useCrispModalContext = () => {
  return useContext(CrispModalContext);
};
