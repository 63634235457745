import {
  MolhamApiCurrency,
  MolhamCountry,
  MolhamCurrencies,
  MolhamLanguagesObject,
  TMolhamImage,
} from "../apiTypes";
import useCustomSWRImmutable from "./useCustomSWRImmutable";

export type TOption = {
  label: MolhamLanguagesObject;
  value: null | number | string;
  options?: TOption[];
};
export type TSortingMethod = {
  label: MolhamLanguagesObject;
  name: string | null;
};
export type TFilter = {
  name: string;
  type: "radio" | "checkbox";
  label: MolhamLanguagesObject;
  options: TOption[];
};
export type TPaymentOption = {
  id: number;
  name: string;
  description: string;
  preview_image: null | TMolhamImage;
  duplicatable: boolean;
  suggested_reccuring_payment_option_donation: boolean;
  exists_in_cart: boolean;
  addible_to_cart: boolean;
  frequency: null | "month" | "week" | "day";
  frequency_cycles: {
    label: string;
    options: { name: string; value: number; description: string }[];
  } | null;
  note: null | string;
  metadata_fields?: {
    required: boolean;
    name: string;
    type: "short_text" | "long_text";
    label: MolhamLanguagesObject;
    placeholder: MolhamLanguagesObject;
    default_value?: string;
  }[];
} & (
  | {
      //inputable
      quantifiable: false;
      amount: null;
      min_amount: MolhamCurrencies;
      max_amount: MolhamCurrencies;
      min_quantity: null;
      max_quantity: null;

      funding_progress_bar: {
        required: MolhamCurrencies;
        received: MolhamCurrencies;
        left_to_fund: MolhamCurrencies;
        funding_percentage: number;
      } | null;
    }
  | {
      //quantifiable
      quantifiable: true;
      amount: MolhamCurrencies;
      min_amount: null;
      max_amount: null;
      min_quantity: number;
      max_quantity: number;
      funding_progress_bar: null;
    }
  | {
      // fixed
      quantifiable: false;
      amount: MolhamCurrencies;
      min_amount: null;
      max_amount: null;
      min_quantity: null;
      max_quantity: null;
      funding_progress_bar: null;
    }
);
export type TQuickDonationPaymentOption = {
  name: MolhamLanguagesObject;
  description: MolhamLanguagesObject;
  id: {
    [S in string]: number;
  };
};
export type TTargetType = {
  name: string;
  targetable_type: string;
  program_id: number | string;
  filters: TFilter[];
  // page_path: string;
  // page_api_endpoint_path: string;
  categories?: { id: number; name: MolhamLanguagesObject }[];
  purposes?: { id: number; name: MolhamLanguagesObject }[];
  sorting_methods: TSortingMethod[];
  page_api_endpoint_path: string;
  page_path: string;
  page_metadata: {
    title: MolhamLanguagesObject;
    description: MolhamLanguagesObject;
    preview_image: null;
    path: string;
    endpoint_path: string;
  };
};
export type TDonationSection = {
  appears_in_navbar: boolean;
  appears_in_menu: boolean;
  key: string;
  name: MolhamLanguagesObject;
  short_name: MolhamLanguagesObject;
  description: MolhamLanguagesObject;
  icon: {
    uri: string;
    color: string;
  };
  target_types: string[] | null;
};
export type TMolhamLottieAnimation = {
  uri: string;
  loop: boolean;
  margin_bottom?: number;
  margin_top?: number;
};
export type TQuickAction = {
  name: MolhamLanguagesObject;
  icon: {
    uri: string;
    color: string;
  };
  path: {
    mobile: string;
    web: string;
  };
};
export type TTypography = {
  type: "heading" | "paragraph";
  font_weight: "bold" | "normal" | "light";
  text: MolhamLanguagesObject;
};
export type TIntroSlide = {
  photo_url: string;
  contents: TTypography[];
};
export const supportedPaymentMethods = {
  swish: "swish",
  paypal_checkout_order: "paypal_checkout_order",
  paypal_subscription: "paypal_subscription",
  stripe_apple_pay: "stripe_apple_pay",
  stripe_google_pay: "stripe_google_pay",
  stripe_card: "stripe_card",
  stripe_ideal: "stripe_ideal",
  stripe_sepa_debit: "stripe_sepa_debit",
  stripe_sofort: "stripe_sofort",
  stripe_giropay: "stripe_giropay",
} as const;
export type TSupportedPaymentMethodValues =
  (typeof supportedPaymentMethods)[keyof typeof supportedPaymentMethods];
export type TSupportedPaymentMethod = {
  payment_provider: string;
  name: string;
  one_time_checkout_supported: boolean;
  manual_billing_subscription_checkout_supported: boolean;
  automatic_billing_subscription_checkout_supported: boolean;
  label: MolhamLanguagesObject;
  description: MolhamLanguagesObject;
  icon_url: {
    "apple-style": string;
    "google-style": string;
  };
};
export type feedback_option = {
  label: MolhamLanguagesObject;
  description: MolhamLanguagesObject;
  rateable: boolean;
  id: number;
};
export type TUtilities = {
  home_page_force_redirection_path: null | string;
  countries: MolhamCountry[];
  currencies: MolhamApiCurrency[];
  // events_categories: {id: number; name: string}[];
  // fundraisers_purposes: {id: number; name: string}[];
  feedback_subjects: feedback_option[];
  target_types: {
    [S in string]: TTargetType;
  };
  colors: {
    key: string;
    name: MolhamLanguagesObject;
    description: MolhamLanguagesObject;
  }[];
  // general_donation_options: TPaymentOption[];
  general_quick_donation_payment_options: TQuickDonationPaymentOption[];
  mobile_app: {
    appstore: {
      url: string;
      latest_version: string;
      last_required_version: string;
    };
    playstore: {
      url: string;
      latest_version: string;
      last_required_version: string;
    };
  };
  donation_sections: TDonationSection[];
  events_intro: MolhamLanguagesObject;
  transparency_intro: MolhamLanguagesObject;
  animation_icons: {
    share: TMolhamLottieAnimation;
    zakat: TMolhamLottieAnimation;
    cart: TMolhamLottieAnimation;
    app_update: TMolhamLottieAnimation;
    successful_payment: TMolhamLottieAnimation;
    new_event: TMolhamLottieAnimation;
    no_results: TMolhamLottieAnimation;
    not_found: TMolhamLottieAnimation;
  };
  gold_price: {
    "24_carat": MolhamCurrencies;
    "22_carat": MolhamCurrencies;
    "21_carat": MolhamCurrencies;
    "18_carat": MolhamCurrencies;
  };
  silver_price: MolhamCurrencies;
  support_team_chat_url: string;
  quick_actions: TQuickAction[];
  intro_slides: TIntroSlide[];
  assets: {
    home_page_logo_url: string;
  };
  payment_providers: {
    paypal: {
      currency: "eur" | "usd" | "sek";
      id: string;
      country_code: string;
      client_id: string;
    };
    stripe: {
      currency: "eur" | "usd" | "sek";
      id: string;
      public_key: {
        live_mode: string;
        test_mode: string;
      };
      country_code: string;
    };
    swish: {
      currency: "eur" | "usd" | "sek";
      id: string;
      country_code: string;
    };
  };
  supported_payment_methods: {
    [key in TSupportedPaymentMethodValues]: TSupportedPaymentMethod;
  };
  external_supported_authentication_providers?: (
    | "facebook"
    | "google"
    | "apple"
    | "twitter"
  )[];
};

export default function useUtilities(run = true) {
  const swr = useCustomSWRImmutable<TUtilities>(
    run ? "utilities" : null,
    undefined,
    false
  );

  return swr;
}
