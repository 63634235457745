import useRTL from "customHooks/useRTL";
import { useEffect, useState } from "react";
export const arabicPattern = /[\u0600-\u06FF\u0750-\u077F]/;

const useInputRTL = (
    value?: string | number,
    defaultValue?: string | number,
  ) => {
    const isRTL = useRTL();

    const [rtl, setRtl] = useState(isRTL);
    useEffect(() => {
      if (!value) {
        setRtl(isRTL);
      } else if (defaultValue) {
        const result = arabicPattern.test(defaultValue.toString().charAt(0));
        setRtl(result);
      } else {
        const result = arabicPattern.test(value.toString().charAt(0));
        setRtl(result);
      }
    }, [value, defaultValue]);
    return rtl;
  };
  export default useInputRTL;
  