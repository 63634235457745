import { TCheckOutOrderItem } from "apiTypes";
import { genericFallbackImg } from "config";
import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";

type Props = {
  item: TCheckOutOrderItem;
  currency: string;
};

const ConfirmationItem: React.FC<Props> = ({ item, currency }) => {
  const { amount, description, preview_image, short_name, title } = item;
  const { formatNumbers } = useFormatNumbers();
  const { curr } = usePreferenceContext();
  const shortNameNumbers = short_name.split(/\D+/).filter(Boolean).join(" ");
  const shortNameStrings = short_name.split(/\d+/).filter(Boolean).join(" ");
  const amountStrings = formatNumbers(
    amount,
    currency ?? curr.code,
    true
  ).split(" ")[0];
  const amountNumbers = formatNumbers(
    amount,
    currency ?? curr.code,
    true
  ).split(" ")[1];
  return (
    <ModalBodyElementContainer className="media  w-100">
      <div className="d-block">
        <img
          className="rounded"
          width="60"
          height="60"
          style={{
            width: "60px",
            height: "60px",
            objectFit: "cover",
          }}
          src={preview_image?.thumbnails["1:1"] ?? genericFallbackImg}
          alt={title}
        />
      </div>
      <div className="media-body ps-4 ml-1">
        <div className="d-flex align-items-center justify-content-between">
          <div className="mr-3">
            <h4 className="nav-heading font-size-md mb-1 text-capitalize font-weight-bold">
              {title}
            </h4>
            <div className="d-flex align-items-start flex-column  ">
              <span className="mr-2 mb-1 fs-md">
                {shortNameStrings}{" "}
                <b className="text-primary">{shortNameNumbers}</b>
              </span>
              <span className="mr-2 mb-1 fs-md text-muted text-uppercase">
                {amountStrings} <b className="text-primary">{amountNumbers}</b>
              </span>
              <span className="fs-md text-muted ">{description}</span>
            </div>
          </div>
        </div>
      </div>
    </ModalBodyElementContainer>
  );
};

export default ConfirmationItem;
