import useMobile from "customHooks/useMobile";
import Link from "next/link";
import { Modal, ModalProps } from "react-bootstrap";
import styled from "styled-components";
import { useModeContext } from "../../context/ModeContext";
export type ButtonfulModalButton = {
  text?: string;
  color?: "primary" | "danger";
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customEl?: React.ReactNode;
  isLoading?: boolean;
  fontWeight?: string;
  btnFntSz?: string;
  enabled?: boolean;
  href?: string;
}[][];
type Props = {
  showModal: boolean;
  handleCloseModal: () => void;
  buttons: ButtonfulModalButton;
  extraProps?: ModalProps;
};
const ButtonfulModal: React.FC<Props> = ({
  showModal,
  handleCloseModal,
  buttons,
  extraProps = {
    // style={{ zIndex: 2002 }}
    // backdropClassName="z-index-2001"
  },
}) => {
  const { theme } = useModeContext();
  const isMobile = useMobile("md");
  const ButtonComp = ({
    btn,
    ind,
  }: {
    btn: ButtonfulModalButton[0][0];
    ind: number;
  }) => {
    return (
      <button
        className={`btn btn-${btn.color}-outline`}
        onClick={(e) => (btn.onClick ? btn.onClick(e) : handleCloseModal())}
        disabled={btn.isLoading}
        style={{
          height: 46,
          fontSize: 20,
          width: "100%",
          fontWeight: btn.fontWeight,
          borderTop: ind > 0 ? "1px solid var(--light-gray)" : "none",
          borderRadius: 0,
        }}
      >
        <span className={btn?.btnFntSz}>{btn.text}</span>
      </button>
    );
  };
  const ButtonsComps = () => {
    return (
      <div>
        {buttons.map((buttonGroup, i) => {
          return (
            <div
              key={i}
              className={`rounded ${theme == "dark" ? "bg-dark" : "bg-white"} ${
                i > 0 ? "mt-2" : "mt-0"
              }`}
            >
              {extraProps.title && (
                <div
                  className="text-center  p-2 "
                  style={{
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                >
                  <span className="text-muted">{extraProps.title}</span>
                </div>
              )}

              {buttonGroup
                .filter((btn) => btn?.enabled !== false)
                .filter((btn) => btn !== null)
                .map((btn, ind) => {
                  if (btn.customEl) return btn.customEl;
                  if (btn.href) {
                    return (
                      <Link href={btn.href} key={ind}>
                        <ButtonComp btn={btn} ind={ind} />
                      </Link>
                    );
                  }
                  return <ButtonComp key={ind} ind={ind} btn={btn} />;
                })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <StyledModal
      show={showModal}
      dialogClassName={
        isMobile ? "modal-dialog-bottom " : "modal-dialog-centered "
      }
      onHide={handleCloseModal}
      contentClassName={"bg-transparent borderless shadowless"}
      backdropClassName="zindex9998"
      className="zindex9999"
      {...extraProps}
    >
      <ButtonsComps />
    </StyledModal>
  );
};

export default ButtonfulModal;
const StyledModal = styled(Modal)`
  .modal-dialog {
    @media (max-width: 768px) {
      max-width: 100%;
      margin: 0.5rem;
    }
  }
`;
