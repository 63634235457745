import styled from "styled-components";

export const StyledColorBullet = styled.div`
  width: 30px;
  height: 30px;
  background: var(--white-trans);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--mainTrans);
  color: "#fff";
  svg {
    transition: var(--mainTrans);
    color: #fff;
  }
  &:hover {
    background: #fff;

    svg {
      color: var(--yellow);
    }
  }
`;
