import useRTL from "customHooks/useRTL";
import React from "react";
import useInputRTL from "./useInputRTL";
const persianNumbers = [
    /۰/g,
    /۱/g,
    /۲/g,
    /۳/g,
    /۴/g,
    /۵/g,
    /۶/g,
    /۷/g,
    /۸/g,
    /۹/g,
  ],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
export const toEnglishNumbers = function (str: string) {
  if (typeof str === "string") {
    for (let i = 0; i < 10; i++) {
      str = str
        .replace(persianNumbers[i], i.toString())
        .replace(arabicNumbers[i], i.toString());
    }
  }
  return str;
};
export function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
type Props = {
  currencySymbol?: string;
  currencyCode?: string;
  value?: number | string;
  defaultValue?: number | string;
  onChange?: (text: string | number) => void;
  error?: string;
  maxAmount?: number;
  minAmount?: number;
  isMoney?: boolean;
  acceptDecimals?: boolean;
  minPayableAmount?: number;
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "value" | "defaultValue" | "onChange" | "error"
>;

const numericPatternWithDecimal = /^[a-z\d,]*\.?[a-z\d,]*$/;
const numericPatternWithoutDecimal = /^[a-z\d,]*$/;
const NumericalInput = React.forwardRef<HTMLInputElement, Props>(
  function NumericalInput(props, ref) {
    const isInputRTL = useInputRTL(props.value, props.defaultValue);
    const currencyOptions = {
      accept_decimals: props.acceptDecimals ?? true,
      min_payable_amount: props.minPayableAmount ?? 1,
    };
    const isRTL = useRTL();
    const parseType = currencyOptions.accept_decimals ? parseFloat : parseInt;
    const maxAmount = props.maxAmount
      ? parseType(props.maxAmount.toString())
      : undefined;
    const isMoney = props.isMoney ?? true;
    const numericPattern = isMoney
      ? currencyOptions.accept_decimals
        ? numericPatternWithDecimal
        : numericPatternWithoutDecimal
      : numericPatternWithDecimal;
    const currencySymbol = props.currencySymbol ?? props.currencyCode ?? "";

    return (
      <>
        <div className="input-group">
          <input
            {...props}
            id="amount"
            ref={ref}
            style={{
              direction: isInputRTL ? "rtl" : "ltr",
            }}
            value={props.value}
            onChange={(e) => {
              if (props.onChange) {
                let val = e.target.value;
                val = val.replace(",", ".");
                val = val.replace("٫", ".");
                val = toEnglishNumbers(val);

                if (numericPattern.test(val)) {
                  if (maxAmount && val !== "") {
                    val =
                      maxAmount < parseType(val) ? maxAmount.toString() : val;
                  }
                  if (val !== "") {
                    val = 0 > parseType(val) ? "0" : val;
                  }

                  if (isNumeric(val) || val === "") {
                    props.onChange(val);
                  }
                }
              }
            }}
            type="text"
            className="form-control"
          />
          <span
            className="input-group-text"
            style={
              isRTL
                ? {
                    borderLeft: "1px solid var(--white)",
                  }
                : {
                    borderRight: "1px solid var(--white)",
                  }
            }
          >
            {currencySymbol?.toUpperCase()}
          </span>
        </div>
        {props.error ? (
          <div className={`invalid-feedback`}>{props.error}</div>
        ) : null}
      </>
    );
  }
);

NumericalInput.displayName = "NumericalInput";
export default NumericalInput;
