import { useRouter } from "next/router";
import { useState } from "react";
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
} from "swr/infinite";
import { PaginatableList } from "../apiTypes";
import { fetcher } from "../utils/getData";
import useUpdateEffect from "./useUpdateEffect";

// this hook is a wrapper of useSWRInfinte
export const useInfinitePagination = <Data extends any>(
  url: string,
  options: SWRInfiniteConfiguration<PaginatableList<Data>> = {},
  headers: any = {},
  refetchOnLocaleChange = true
) => {
  const [changeLocaleLoading, setChangeLocaleLoading] = useState(false);

  const { locale } = useRouter();

  const getKey: SWRInfiniteKeyLoader = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.data?.length) return null; // reached the end
    return `${url}${url.includes("?") ? "&" : "?"}page=${pageIndex + 1
      }#${locale}`;
  };

  const { data, size, setSize, error, mutate, isValidating } = useSWRInfinite<
    PaginatableList<Data>
  >(
    getKey,
    (key) => {
      return fetcher(key, null, null, { locale, ...headers } || null);
    },
    { revalidateFirstPage: true, ...options }
  );

  // if the language is changed mutate the data and refetch it again and set a loading state while it's being fetched

  useUpdateEffect(() => {
    if (refetchOnLocaleChange) {
      setChangeLocaleLoading(true);
      const mutatePaginatedData = async () => {
        await mutate();

        setChangeLocaleLoading(false);
      };
      mutatePaginatedData();
    }
  }, [locale]);
  const formattedData =
    data?.reduce((prev, currVal) => prev.concat(currVal?.data), [] as Data[]) ??
    [];
  const dataLength = formattedData.length;
  //   const formattedData = data?.flatMap((page) => page.data) ?? [];
  /* It's checking if data is undefined and if error is undefined. */
  const isLoadingInitialData = !data && !error;
  /* If the initial data is still loading, or if the data array is not empty and the last item in the
  array is undefined, then the loading indicator should be shown. */
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");
  /* The above code is checking if the first element of the array is empty. */
  const isEmpty = data?.[0]?.data?.length === 0;
  /* This code is checking if the data is empty or if the size of the data is equal to the last page
  number. */

  const hasReachedEnd = isEmpty || size === data?.[0]?.last_page;

  /**
   * GetMoreData is a function that sets the size state to the current size plus one.
   */
  const getMoreData = () => {
    if (data?.[0]?.last_page === 1) {
      setSize(1);
      return;
    }
    setSize(size + 1);
  };
  const getAllPages = () => {
    if (data) {
      setSize(data?.[0]?.last_page + 1);
    }
  };

  return {
    isLoadingInitialData,
    isLoadingMore,
    hasReachedEnd,
    size,
    setSize,
    mutate,
    data,
    formattedData,
    getMoreData,
    getAllPages,
    isValidating,
    error,
    changeLocaleLoading,
    dataLength,
  };
};
