import CustomContentLoader from "./ReuseableCompos/CustomContentLoader";

const NotificationSkeleton = () => {
  return (
    <CustomContentLoader viewBox="0 0 400 170">
      <rect x="19" y="16" rx="7" ry="7" width="291" height="34" />
      <rect x="16" y="61" rx="7" ry="7" width="328" height="75" />
      <rect x="18" y="145" rx="4" ry="4" width="241" height="14" />
    </CustomContentLoader>
  );
};

export default NotificationSkeleton;
