import { PaginatableList } from "apiTypes";
import ProfileMenuItem from "components/Profile/ProfileMenuItem";
import { useIsomorphicLayoutEffect } from "customHooks/useIsomorphicLayoutEffect";
import useMobile from "customHooks/useMobile";
import useUpdateEffect from "customHooks/useUpdateEffect";
import React, { createRef, useEffect, useImperativeHandle } from "react";
import { SWRInfiniteConfiguration } from "swr/infinite";
import { arrayFromNum } from "utils/arrayFromNum";
import { API_URL, NEXT_URL_PROXY } from "../../config";
import { useInfinitePagination } from "../../customHooks/useInfinitPagination";
import CustomInfinitScroll, {
  CustomInfiniteScrollProps,
} from "../ReuseableCompos/CustomInfinitScroll";
import NoData from "../ReuseableCompos/NoData";

type Props = {
  resourceName?: any;
  endPoint: string;
  isOffcanvas?: boolean;
  swrOptions?: SWRInfiniteConfiguration<PaginatableList<any>>;
  skeleton?: any;
  skeletonAmount?: number;
  getMoreSkeletonAmount?: number;
  inifiteScrollClassName?: string;
  skeletonContainerClassName?: string;
  skeletonProps?: any;
  inifiteScrollProps?: Partial<CustomInfiniteScrollProps>;
  customNoDataLabel?: string;
  customActionBtn?: any;
  action?: any;
  children: any;
  bottomActionButton?: {
    onClick: () => void;
    icon: string;
    title: string;
  };
  proxy?: boolean;
  sendDataToParent?: (data: any) => void;
  isLoadingInitialDataState?: (bool: boolean) => void;
};

const InfinteDataSoruceHandler = ({
  resourceName = "item",
  endPoint,
  isOffcanvas = false,
  swrOptions = {},
  skeleton: Skeleton,
  skeletonAmount = 2,
  getMoreSkeletonAmount = 2,
  inifiteScrollClassName = "",
  skeletonContainerClassName = "",
  skeletonProps = {},
  inifiteScrollProps = {},
  customNoDataLabel = null,
  customActionBtn = null,
  action = createRef(),
  isLoadingInitialDataState,
  sendDataToParent,
  bottomActionButton,
  proxy = true,
  children,
}: Props) => {
  const {
    hasReachedEnd,
    mutate,
    data,
    formattedData,
    getMoreData,
    error,
    isLoadingInitialData,
    changeLocaleLoading,
  } = useInfinitePagination(
    endPoint ? `${proxy ? NEXT_URL_PROXY : API_URL}/${endPoint}` : null,
    {
      revalidateOnFocus: true,
      // revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,

      ...swrOptions,
    }
  );
  isLoadingInitialDataState && isLoadingInitialDataState(isLoadingInitialData);
  const isMobile = useMobile("md");
  const isSmallMobile = useMobile("(max-height: 650px)", true);

  useUpdateEffect(() => {
    mutate();
  }, [endPoint]);
  useImperativeHandle(
    action,
    () => ({
      mutate,
    }),

    [mutate]
  );
  useIsomorphicLayoutEffect(() => {
    const isScrollable =
      document.body.scrollHeight > document.body.clientHeight;

    if (
      data &&
      formattedData.length === 12 &&
      !hasReachedEnd &&
      !isScrollable
    ) {
      getMoreData();
    }
  }, [formattedData.length]);
  useEffect(() => {
    if (data && sendDataToParent) {
      const temp = [];
      data.map((item) => {
        item.data.map((i) => {
          temp.push(i);
        });
      });
      sendDataToParent(temp);
    }
  }, [data]);
  if (!endPoint) {
    return <NoData isOffcanvas={isOffcanvas} error={""} />;
  }
  if (error) {
    return (
      <NoData isOffcanvas={isOffcanvas} error={JSON.stringify(error.info)} />
    );
  }
  if (!data && !error) {
    return (
      <div className={skeletonContainerClassName}>
        {arrayFromNum(skeletonAmount).map((item) => (
          <Skeleton {...skeletonProps} key={item} />
        ))}
      </div>
    );
  }
  if (formattedData.length <= 0 || !formattedData) {
    return (
      <NoData
        additionalClassName={isMobile && !isSmallMobile ? "pt-5" : ""}
        isOffcanvas={isOffcanvas}
        label={customNoDataLabel}
        actionBtn={customActionBtn}
      />
    );
  }
  if (changeLocaleLoading) {
    return (
      <div className={skeletonContainerClassName}>
        {arrayFromNum(formattedData?.length).map((item) => (
          <Skeleton {...skeletonProps} key={item} />
        ))}
      </div>
    );
  }
  return (
    <CustomInfinitScroll
      getMoreData={getMoreData}
      dataLength={formattedData?.length}
      hasMore={!hasReachedEnd}
      className={inifiteScrollClassName}
      customLoader={arrayFromNum(getMoreSkeletonAmount).map((item) => (
        <Skeleton {...skeletonProps} key={item} />
      ))}
      {...inifiteScrollProps}
    >
      {React.Children.map(
        children,
        (child) =>
          React.isValidElement(child) &&
          formattedData?.map((item, index, array) =>
            React.cloneElement(child, {
              [resourceName]: item,

              // @ts-ignore
              isLast: index === array.length - 1,
              index,
              isFirst: index === 0,
            })
          )
      )}
      {bottomActionButton && (
        <div className="list-group-flush px-3 ">
          <ProfileMenuItem
            customPxPhone="px-0"
            onClick={bottomActionButton.onClick}
            icon={"fe-" + bottomActionButton.icon}
            title={bottomActionButton.title}
            noDesc
          />
        </div>
      )}
    </CustomInfinitScroll>
  );
};

export default InfinteDataSoruceHandler;
