import { useStripe } from "@stripe/react-stripe-js";
import { useAuthContext } from "context/AuthContext";
import useTranslation from "next-translate/useTranslation";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCheckoutStore } from "../../../CheckoutState";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import stripeActions from "../../../actions/stripe";
import DonationModalFooterPortal from "../../../components/DonationModalFooterPortal";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import {
  NameEmailFormComponents,
  SepaMandate,
  SubmitButton,
  TNameEmailForm,
  handleCheckoutEnd,
  useNameEmailForm,
} from "../common";
const NewGiropayPaymentStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("pay_with_giropay");
};
const NewGiropayPaymentStepBody: React.FC = () => {
  const stripe = useStripe();
  const { control, handleSubmit } = useNameEmailForm();

  const [loading, setLoading] = useState(false);
  const { donor } = useAuthContext();

  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = async (data: TNameEmailForm) => {
    setLoading(true);
    try {
      const paymentProvider =
        donor!.available_payment_methods.stripe_giropay.payment_provider;
      // const element = elements!.getElement(CardElement);
      const pm = await stripe.createPaymentMethod({
        type: "giropay",
        billing_details: {
          name: data.name?.toUpperCase(),
          email: donor.email,
        },
      });
      if (pm.error) {
        toast.error(pm.error.message);
        setErrorMessage(pm.error.message);
        // setLoading(false);

        return;
      }
      const checkoutOrder = useCheckoutStore.getState().checkoutOrder;
      const paymentIntent = await stripeActions.paymentIntent({
        checkout_order_reference: checkoutOrder!.reference,
        // save_for_later: saveForLater,
        // return_url: 'molhamteam://stripe-redirect',
        payment_method: pm.paymentMethod.id,
        payment_method_types: ["giropay"],
        confirmation_method: "automatic",
        payment_provider_id: paymentProvider.id,
      });
      if (paymentIntent.ok === false) {
        toast.error(paymentIntent.error.message);
        setErrorMessage(paymentIntent.error ? paymentIntent.error.message : "");
        setLoading(false);

        return;
      }
      const { error } = await stripe.confirmGiropayPayment(
        paymentIntent.data.client_secret,
        {
          payment_method: pm.paymentMethod.id,
          return_url: `${
            location.protocol + "//" + location.host + location.pathname
          }?checkout_order_id=${checkoutOrder!.reference}`,
        }
      );
      if (error) {
        toast.error(error.message);
      } else {
        const res = await checkoutHandlerActions.handleCheckoutSource({
          payment_provider_id: paymentProvider.id,
          type: checkoutHandlerActions.CheckoutSourceTypes.stripe
            .payment_intent,
          reference: paymentIntent.data?.id,
        });
        handleCheckoutEnd({
          res,
          metadata: {
            "Checkout Order": checkoutOrder.reference,
            Items: checkoutOrder.items,
            currency: checkoutOrder.currency.code,
            amount: checkoutOrder.amount,
            "Payment Method": "Stripe Giropay",
            saved: false,
          },
        });
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <ModalBodyElementContainer>
      <div style={{ marginBottom: 20 }}>
        <NameEmailFormComponents control={control} />

        {errorMessage ? (
          <div style={{ color: "red", marginTop: 10, marginBottom: 10 }}>
            {errorMessage}
          </div>
        ) : null}
      </div>

      <SepaMandate />
      <DonationModalFooterPortal id="modal-footer-portal-host">
        <PaymentSlidesFooterContainer>
          <SubmitButton onSubmit={handleSubmit(onSubmit)} loading={loading} />
        </PaymentSlidesFooterContainer>
      </DonationModalFooterPortal>
    </ModalBodyElementContainer>
  );
};
const NewGiropayPaymentStep = {
  name: "NewGiropayPaymentStep",
  title: NewGiropayPaymentStepTitle,
  body: NewGiropayPaymentStepBody,
  footer: true,
};
export default NewGiropayPaymentStep;
