import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import { useCheckoutStore } from "../../../CheckoutState";
import swishActions from "../../../actions/swish";
import PaymentSlidesFooterContainer from "../../../components/PaymentSlidesFooterContainer";
import { SubmitButton } from "../common";

import { useAuthContext } from "context/AuthContext";
import { toast } from "react-toastify";
type Props = {};
const SwishMobileForm: React.FC<Props> = () => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [checkoutOrder] = useCheckoutStore((state) => [state.checkoutOrder]);
  const { donor } = useAuthContext();
  const payer = useCheckoutStore().checkoutOrder?.payer;

  const onSubmit = async () => {
    const paymentProvider =
      payer.available_payment_methods.swish.payment_provider ??
      donor.available_payment_methods.swish.payment_provider;
    setLoading(true);
    try {
      const swishPaymentRequestResult = await swishActions.paymentRequest(
        checkoutOrder!.reference
      );

      if (
        swishPaymentRequestResult.ok &&
        swishPaymentRequestResult.data &&
        swishPaymentRequestResult.data.token &&
        swishPaymentRequestResult.data.id
      ) {
        const calbackUrlSearchParams = new URLSearchParams({
          swish_payment_request_id: swishPaymentRequestResult.data.id,
          payment_provider_id: paymentProvider.id,
        });
        const callbackurl =
          location.protocol +
          "//" +
          location.host +
          location.pathname +
          "?" +
          calbackUrlSearchParams.toString();
        const swishSearchParams = new URLSearchParams({
          token: swishPaymentRequestResult.data.token,
          callbackurl,
        });
        const url = "swish://paymentrequest?" + swishSearchParams.toString();
        if (typeof window !== "undefined") {
          if (!donor?.tester) {
            window.location.href = url;
          } else {
            window.location.href = callbackurl;
          }
        }
      } else if (swishPaymentRequestResult.ok === false) {
        toast.error(
          swishPaymentRequestResult.error?.message ?? t("something_went_wrong")
        );
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <>
      <PaymentSlidesFooterContainer>
        <SubmitButton loading={loading} onSubmit={onSubmit} />
      </PaymentSlidesFooterContainer>
    </>
  );
};

export default SwishMobileForm;
