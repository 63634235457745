import ColorBtn from "./ColorBtn";
import CurrencyDropdown from "./CurrencyDropdown";
import LanguageDropdown from "./LanguageDropdown";

const NavbarMenuActionBar = () => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div />
        <LanguageDropdown className=" cursor py-25" offcanvas />
        <div className="one-sided-border"></div>

        <CurrencyDropdown className=" cursor py-25" offcanvas />

        <div className="one-sided-border"></div>

        <ColorBtn className=" cursor py-25" offcanvas />
        <div />
      </div>
    </>
  );
};

export default NavbarMenuActionBar;
