import { langs } from "config/preferences";
import { useRouter } from "next/router";
import { usePreferenceContext } from "../../context/PreferencesContext";
import StyledNavbarDropdown from "./StyledNavbarDropdown";

type Props = {
  className?: string;
  offcanvas?: boolean;
};

const LanguageDropdown = ({ className, offcanvas }: Props) => {
  // const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const { handleShowLang } = usePreferenceContext();
  const { locale } = useRouter();
  const lang = langs.find((item) => item.shortcut === locale);
  return (
    <StyledNavbarDropdown onClick={handleShowLang}>
      {offcanvas ? (
        <span
          className={`d-flex flex-column justify-content-center align-items-center ${
            className || ""
          }`}
        >
          <i className="fe-globe fs-xxl "></i>
          <span className="fs-sm mt-1 ">{lang?.label}</span>
        </span>
      ) : lang?.label ? (
        <button
          type="button"
          className={`${className ? `btn` + className : "btn"}`}
        >
          <i className="fe-globe"></i> {lang?.label}
        </button>
      ) : (
        <></>
      )}
    </StyledNavbarDropdown>
  );
};

export default LanguageDropdown;
