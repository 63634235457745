import SelectModal from "components/ReuseableCompos/SharedDonatePages/DonateModal/components/Select/SelectModal";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import isMobileOrTablet from "utils/isMobileOrTablet";
import SwishDesktopForm from "./SwishDesktopForm";
import SwishMobileForm from "./SwishMobileForm";

const SwishPaymentStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("swish");
};
const SwishPaymentStepBody: React.FC = () => {
  const [mCommerce, setMCommerce] = useState(false);
  const { t } = useTranslation("common");
  const options = [
    { label: t("pay_with_swish_on_this_device"), value: true },
    { label: t("pay_with_swish_on_another_device"), value: false },
  ];
  return (
    <>
      {isMobileOrTablet() ? <div className="p-4 pb-2"><SelectModal
        options={options}
        
        onOptionChange={(value) => setMCommerce(value.value)}
        selectedOption={options.find((option) => option.value === mCommerce)}
        title={t("swish")}
      /> </div>: null}

      {isMobileOrTablet() ? (
        mCommerce ? (
          <SwishMobileForm />
        ) : (
          <SwishDesktopForm />
        )
      ) : (
        <SwishDesktopForm />
      )}
    </>
  );
};

const SwishPaymentStep = {
  name: "SwishPaymentStep",
  title: SwishPaymentStepTitle,
  body: SwishPaymentStepBody,
  footer: false,
};
export default SwishPaymentStep;
