import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import { CSSProperties, createElement } from "react";
import { IconType } from "react-icons/lib";
import useRTL from "../../customHooks/useRTL";
import ActiveLink from "../ReuseableCompos/ActiveLink";
import ProfileMenuItemContainer from "./ProfileMenuItemContainer";
type Props = {
  title: string;
  href?: string;
  desc?: string;
  icon?: string | IconType;
  iconContainerStyle?: CSSProperties;
  badge?: string;
  customIconSize?: number;
  noDesc?: boolean;
  CustomCont?: React.FC;
  customPy?: string;
  noRTL?: boolean;
  forceLTR?: boolean;
  customPxWeb?: string;
  customPxPhone?: string;
  onClick?: () => void;
  noIcon?: boolean;
  customIcon?: boolean;
};
const ProfileMenuItem: React.FC<Props> = ({
  title,
  href,
  desc,
  icon = "fe-heart",
  badge,
  noIcon = false,
  noDesc,
  CustomCont = () => null,
  customPy = "py-3",
  customPxPhone,
  customPxWeb,
  noRTL,
  forceLTR,
  customIcon = false,
  onClick = () => null,
  iconContainerStyle,
  customIconSize,
}) => {
  const { t } = useTranslation("common");
  const isRTL = useRTL();
  const isMobile = useMobile("md");

  // const isRTL = true;
  const badgeStyle = isRTL ? { paddingBottom: 5 } : {};
  return (
    <ActiveLink href={href} passHref>
      <ProfileMenuItemContainer
        customPxPhone={customPxPhone}
        customPxWeb={customPxWeb}
        onClick={onClick}
        as="a"
        customPy={customPy}
        className={`list-group-item cursor transition-none list-group-item-action focus-color-unset hover-color-unset d-flex justify-content-between align-items-center profile-menu-item`}
      >
        <div className="flex-1 d-flex align-items-center">
          {!noIcon && (
            <span
              className={`bg-secondary btn-icon rounded-circle d-flex justify-content-center align-items-center profile-menu-item-avatar ${isMobile ? "me-1" : ""}`}
              style={
                isMobile
                  ? {
                      width: customIconSize || 36,
                      height: customIconSize || 36,
                      ...iconContainerStyle,
                    }
                  : {
                      width: customIconSize || 44,
                      height: customIconSize || 44,
                      ...iconContainerStyle,
                    }
              }
            >
              <i
                className={`fe ${!customIcon && icon} ${
                  isMobile ? "fs-lg" : "fs-xxl"
                } text-primary`}
              >
                {customIcon && createElement(icon)}
              </i>
            </span>
          )}

          <div
            className={`flex-1 d-flex justify-content-between align-items-center ${
              !noIcon && ` ${isMobile ? "ms-2" : "ms-3"}`
            }`}
          >
            <div className="cursor">
              <h5
                className={`${
                  isMobile ? "fs-sm" : "h5"
                } fw-bold mb-0 align-items-center d-flex`}
              >
                {t(`${title}`)}
                {badge && (
                  <span
                    style={badgeStyle}
                    className="font-size-xs badge badge-primary ms-2 rounded-pill"
                    dir="auto"
                  >
                    {badge}
                  </span>
                )}
              </h5>{" "}
              {noDesc ? null : (
                <h6
                  className={`lead mt-1 mt-md-2 mb-0 text-muted ${
                    !desc && "text-warning"
                  } ${isMobile ? "fs-xs" : "fs-md"}`}
                  dir={
                    noRTL ? "auto" : forceLTR ? "ltr" : isRTL ? "rtl" : "ltr"
                  }
                >
                  {!desc ? <CustomCont /> || t("unset_item") : t(desc)}
                </h6>
              )}
            </div>

            <i className="fe fe-chevron-right fs-xxl"></i>
          </div>
        </div>
      </ProfileMenuItemContainer>
    </ActiveLink>
  );
};

export default ProfileMenuItem;
