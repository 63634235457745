import MolhamButton from "components/ReuseableCompos/MolhamButton";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCheckoutStore } from "../../../CheckoutState";
import SelectModal from "../../../components/Select/SelectModal";
import SubscriptionSettings from "./SubscriptionSettings";
const RefWrapper = ({ children }) => {
  const ref = useRef();
  return children({ ref });
};
export type VariantFooterProps = {
  onProceedToCheckout: () => void;
  onAddToCart: () => void;
  amount?: number;
};
const DonationAmountFooter: React.FC<VariantFooterProps> = ({
  onAddToCart,
  onProceedToCheckout,
  amount,
}) => {
  const { t } = useTranslation("common");
  const { locale: lang } = useRouter();
  const loading = useCheckoutStore((state) => state.loading);
  const { formatNumbers } = useFormatNumbers();
  const paymentFrequency = useCheckoutStore((state) => state.paymentFrequency);
  const setPaymentFrequency = useCheckoutStore(
    (state) => state.setPaymentFrequency
  );
  const donationAmountMetadata = useCheckoutStore(
    (state) => state.donationAmountMetadata
  );
  const setDonationAmountMetadata = useCheckoutStore(
    (state) => state.setDonationAmountMetadata
  );
  const selectedPaymentOption = useCheckoutStore(
    (state) => state.selectedPaymentOption
  );
  const itemCanBeAdded =
    selectedPaymentOption?.duplicatable ||
    !selectedPaymentOption?.exists_in_cart;

  useEffect(() => {
    if (
      selectedPaymentOption &&
      selectedPaymentOption.frequency_cycles &&
      paymentFrequency === null
    ) {
      setPaymentFrequency(
        selectedPaymentOption.frequency_cycles?.options[0]?.value
      );
    }
  }, [paymentFrequency, selectedPaymentOption]);
  const {
    control: metadataControl,
    getValues,
    setValue,
    trigger,
  } = useForm({});
  useEffect(() => {
    if (donationAmountMetadata) {
      Object.entries(donationAmountMetadata).forEach(([key, value]) => {
        if (value !== undefined) {
          setValue(key, value);
        }
      });
    }
  }, []);
  const canAddToCart = (() => {
    if (selectedPaymentOption?.addible_to_cart) {
      if (selectedPaymentOption.duplicatable) {
        return true;
      } else {
        return !selectedPaymentOption?.exists_in_cart;
      }
    }
    return false;
  })();
  return (
    <>
      <SubscriptionSettings />
      {selectedPaymentOption?.metadata_fields ? (
        <div className="mb-3 mt-3">
          {selectedPaymentOption.metadata_fields.map(
            (
              item: (typeof selectedPaymentOption.metadata_fields)[0],
              index
            ) => {
              return (
                <Controller
                  name={item.name}
                  control={metadataControl}
                  key={index}
                  defaultValue={item.default_value}
                  rules={{
                    required: {
                      value: item.required,
                      message: t("field_req_msg"),
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <label
                          className="form-label"
                          style={{
                            cursor: "pointer",
                          }}
                          htmlFor={item.name}
                        >
                          {item.label[lang]}
                          <span className="text-muted fs-xs">{` (${t(
                            item.required ? "required" : "optional"
                          )})`}</span>
                        </label>
                        {item.type === "short_text" ? (
                          <input
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            className="form-control rounded mb-3"
                            type="text"
                            key={index}
                            id={item.name}
                            placeholder={item.placeholder[lang] + " .."}
                            // multiline={item.type === 'long_text'}
                          />
                        ) : item.type === "long_text" ? (
                          <textarea
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            className="form-control lh17 mb-3"
                            rows={5}
                            id={item.name}
                            placeholder={item.placeholder[lang] + " .."}
                          ></textarea>
                        ) : item.type === "single_option_picker" ? (
                          <select
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            className="form-select rounded mb-3"
                            id={item.name}
                            disabled={item?.disabled}
                            name={item.name}
                          >
                            {item?.options?.map((op) => (
                              <option
                                key={op?.value}
                                value={op?.value}
                                selected={op?.value === value}
                              >
                                {op?.label?.[lang]}
                              </option>
                            ))}
                          </select>
                        ) : item.type === "multiple_option_picker" ? (
                          <SelectModal
                            multiple
                            name={item.name}
                            options={item.options.map((op) => ({
                              ...op,
                              label: op?.label?.[lang],
                              description: op?.description?.[lang],
                            }))}
                            onOptionChange={(option) => {
                              onChange(option?.map((op) => op?.value));
                            }}
                            selectedOption={item?.options
                              ?.filter((op) => value?.includes(op?.value))
                              ?.map((op) => ({
                                ...op,
                                label: op?.label?.[lang],
                                description: op?.description?.[lang],
                              }))}
                            title={item?.label?.[lang]}
                            buttonProps={{
                              disabled: item?.disabled,
                            }}
                          />
                        ) : null}
                        {error ? (
                          <div className={`invalid-feedback`}>
                            {t(error.message)}
                          </div>
                        ) : null}
                      </>
                    );
                  }}
                />
              );
            }
          )}
        </div>
      ) : null}
      <div className="w-100">
        <MolhamButton
          id="donate-proceed-to-checkout-btn"
          endIcon="fe-arrow-right"
          label={
            <>
              {t("proceed_to_checkout") + " ("}
              <b>{formatNumbers(amount, undefined, true)}</b>
              {")"}
            </>
          }
          className={`w-100`}
          onClick={async () => {
            if (await trigger()) {
              setDonationAmountMetadata(getValues());

              onProceedToCheckout();
            }
          }}
          disabled={loading}
          isLoading={loading}
        />

        {canAddToCart ? (
          <MolhamButton
            id={`donate-${itemCanBeAdded ? "add-to" : "update-in"}-cart-btn}`}
            startIcon="fe-plus"
            label={
              <>
                {t(itemCanBeAdded ? "add_to_cart" : "update_in_cart") + " ("}
                <b>{formatNumbers(amount, undefined, true)}</b>
                {")"}
              </>
            }
            className={`w-100 mt-3`}
            onClick={async () => {
              {
                if (await trigger()) {
                  setDonationAmountMetadata(getValues());

                  onAddToCart();
                }
              }
            }}
            disabled={loading}
            isLoading={loading}
          />
        ) : null}

        {selectedPaymentOption.note ? (
          <div className="mt-3">
            <small className="text-center">
              {t(selectedPaymentOption.note)}
            </small>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DonationAmountFooter;
