import { TCheckOutOrder } from "apiTypes";
import {
  NextCheckoutItemSource,
  nextCheckoutItemSourceManager,
} from "customHooks/useNextCheckoutItemSource";
import { getMoclid } from "../Steps/DonationAmount/utils";
import axiosInstance, { MolhamError, MolhamResponse } from "../axiosInstance";

const createCartItem = async (data: {
  payment_option_id: number;
  quantity?: number | string;
  amount?: number | string;
  click_reference?: string;
  source?: NextCheckoutItemSource;
  metadata?: Record<string, any>;
}) => {
  const moclid = getMoclid();
  let referencedData = data;
  if (moclid) referencedData = { ...referencedData, click_reference: moclid };
  referencedData = {
    ...referencedData,
    source: nextCheckoutItemSourceManager.get(),
  };

  try {
    const res = await axiosInstance.post(`/auth/cart`, referencedData);
    return res as MolhamResponse<unknown>;
  } catch (err) {
    return err as MolhamError;
  }
};
const createCartCheckoutOrder = async () => {
  const moclid = getMoclid();
  let referencedData = {};
  if (moclid) referencedData = { click_reference: moclid };
  try {
    const res = await axiosInstance.post(
      `/auth/cart/checkout-order`,
      referencedData
    );
    return res as MolhamResponse<TCheckOutOrder>;
  } catch (err) {
    return err as MolhamError;
  }
};
const clearCart = async () => {
  try {
    const res = await axiosInstance.delete(`/auth/cart`);
    return res as MolhamResponse<unknown>;
  } catch (err) {
    return err as MolhamError;
  }
};
const deleteCartItem = async (data: { id: number }) => {
  try {
    const res = await axiosInstance.delete(`/auth/cart/${data.id}`);
    return res as MolhamResponse<unknown>;
  } catch (err) {
    return err as MolhamError;
  }
};
const cartActions = {
  createCartItem,
  createCartCheckoutOrder,
  clearCart,
  deleteCartItem,
};
export default cartActions;
