import { PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const DonationModalFooterPortal: React.FC<
  PropsWithChildren<{ id: string }>
> = ({ children, id }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted ? createPortal(children ? <div style={{
    borderTop:"1px solid var(--light-gray)"
  }}>{children}</div>: null, document.getElementById(id)) : null;
};

export default DonationModalFooterPortal;
