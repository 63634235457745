import { Modal } from "react-bootstrap";
import BodyLock from "utils/bodyLock";
import MolhamMOCHeader from "./MolhamMOCHeader";

const MolhamModal: React.FC<Props> = (props) => {
  const modalProps = {
    onEnter: BodyLock.lock,
    onExit: BodyLock.unlock,
    show: props.show,
    onHide: props.onHide,
    size: props.size,
    style: props.style,
    contentClassName: props.className,
  };

  return (
    //@ts-ignore
    <Modal centered scrollable {...modalProps}>
      {props?.header?.label && (
        <MolhamMOCHeader
          onCloseBtnClick={props.onHide}
          noBorder={props?.header.noBorder}
          centered={props?.header.centered}
          label={props?.header.label}
          labelClassName={props?.header.labelClassName}
          customButton={props?.header.customButton}
          showCustomBtnCond={props?.header.showCustomBtnCond}
          noCloseBtn={props?.header.noCloseBtn}
        />
      )}
      <Modal.Body className={props?.body?.className} style={props?.body?.style}>
        {props.children}
      </Modal.Body>
      {props?.footer?.content && (
        <Modal.Footer className={props?.footer?.className}>
          {props?.footer?.content}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MolhamModal;

type Props = {
  show: boolean;
  onHide: () => void;
  header?: {
    label?: string;
    labelClassName?: string;
    centered?: boolean;
    showCustomBtnCond?: boolean;
    customButton?: React.ReactNode;
    borderRadius?: boolean;
    noCloseBtn?: boolean;
    noBorder?: boolean;
  };
  footer?: {
    content?: React.ReactNode;
    className?: string;
  };
  children: React.ReactNode;
  swipeToClose?: boolean;
  body?: {
    style?: React.CSSProperties;
    className?: string;
  };
  style?: React.CSSProperties;
  className?: string;

  fullscreen?: boolean;
  size?: "sm" | "md" | "lg" | "xl" | undefined;
};
