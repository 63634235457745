import { type Stripe } from "@stripe/stripe-js";
import { useCheckoutStore } from "components/ReuseableCompos/SharedDonatePages/DonateModal/CheckoutState";
import useUtilities from "customHooks/useUtilities";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
export type StripeContextType = {
  stripePromise: Promise<Stripe> | null;
  setInitLoadStripe: Dispatch<SetStateAction<boolean>>;
  initLoadStripe: boolean;
};

const StripeContext = createContext<StripeContextType | null>(null);

const StripeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe> | null>(
    null
  );
  const [initLoadStripe, setInitLoadStripe] = useState(false);
  const { data: utilities } = useUtilities();
  // const { donor } = useAuthContext();
  const donor = useCheckoutStore()?.checkoutOrder?.payer;

  useEffect(() => {
    const lazyLoadStripe = async (isTester: boolean) => {
      const loadStripe = (await import("@stripe/stripe-js")).loadStripe;
      setStripePromise(
        loadStripe(
          //@ts-ignore
          donor?.payment_providers.stripe.public_key[
            isTester ? "test_mode" : "live_mode"
          ]
        )
      );
    };
    if (utilities && donor && initLoadStripe && !stripePromise) {
      lazyLoadStripe(donor.tester);
    }
  }, [
    donor,
    donor?.tester,
    utilities?.payment_providers.stripe.public_key,
    initLoadStripe,
    utilities,
    stripePromise,
  ]);
  const value = useMemo(
    () => ({
      stripePromise,
      setInitLoadStripe,
      initLoadStripe,
    }),
    [stripePromise, setInitLoadStripe, initLoadStripe]
  );
  return (
    <StripeContext.Provider value={value}>{children}</StripeContext.Provider>
  );
};
export default StripeProvider;

export function useStripeContext(): {
  stripePromise: Promise<Stripe>;
  setInitLoadStripe: Dispatch<SetStateAction<boolean>>;
} {
  const stripeContextData = useContext(StripeContext);
  if (stripeContextData == null) {
    throw new Error(
      "StripeContext is not available. Make sure you are rendering the component within the ChatSupportProvider."
    );
  }
  // useEffect(() => {
  //   if (!stripeContextData.initLoadStripe) {
  //     stripeContextData.setInitLoadStripe(true);
  //   }
  // }, [stripeContextData.initLoadStripe]);

  return {
    stripePromise: stripeContextData.stripePromise,
    setInitLoadStripe: stripeContextData.setInitLoadStripe,
  };
}
