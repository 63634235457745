import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import { langs } from "config/preferences";
import { usePreferenceContext } from "context/PreferencesContext";
import useContents from "customHooks/useContents";
import useRTL from "customHooks/useRTL";
import { useRouter } from "next/router";

export default function LanguageSelectModal({}) {
  const conts = useContents();
  const consts = conts?.data?.constants;
  const { locale } = useRouter();
  const lang = langs.find((item) => item.shortcut === locale);
  const { onLangDropdownChange, showLang, handleCloseLang } =
    usePreferenceContext();
  const isRTL = useRTL();

  return (
    <MolhamModalOffcanvas
      show={showLang}
      onHide={handleCloseLang}
      body={{ className: "p-0" }}
      fullscreen
      header={{
        label: "display_language",
        borderRadius: false,
      }}
      swipeToClose={false}
    >
      {langs.map((option, optionIdx) => {
        const insp = consts?.[option.shortcut]?.["inspire_humanity"];
        return (
          <div
            className={`d-flex align-items-center justify-content-between w-100 py-0 cursor ${
              optionIdx === langs.length - 1
                ? "border-bottom-0"
                : "border-bottom"
            } ${isRTL ? "pr-3 pl-16" : "ps-3 pe-4"}`}
            key={optionIdx}
            onClick={() => {
              onLangDropdownChange(option);
              handleCloseLang();
            }}
          >
            <div className="d-flex flex-column py-2">
              <label className={`mb-0 d-block cursor fs-base`}>
                {option.label}
              </label>
              <span className="text-muted fs-sm">{insp}</span>
            </div>
            <input
              type="radio"
              className="form-check-input mt-0"
              checked={lang?.label === option.label}
              defaultChecked={lang?.label === option?.label}
              id={option.label}
            />
          </div>
        );
      })}
    </MolhamModalOffcanvas>
  );
}
