import { useState } from "react";
import { FiMoon, FiSun } from "react-icons/fi";
import { PiCircleHalfFill } from "react-icons/pi";
import { useModeContext } from "../context/ModeContext";
import ThemeChangeBtn from "./NavBar/ThemeChangeBtn";

type Props = {
  closeTab: () => void;
};
const ThemeActionBar = ({ closeTab }: Props) => {
  const { theme, setTheme, isAuto, setIsAuto } = useModeContext();
  const [tab, onTabClick] = useState(theme);
  const mediaMatch = window.matchMedia("(prefers-color-scheme: dark)");
  const onAutoClick = () => {
    if (mediaMatch.matches) {
      setTheme("dark");
      onTabClick("dark");
      setIsAuto("true");
      localStorage.setItem("auto", "true");
    } else {
      setTheme("light");
      onTabClick("light");
      setIsAuto("true");
      localStorage.setItem("auto", "true");
    }
    // closeTab();
  };
  return (
    <>
      <div className="d-flex justify-content-between ">
        <div />
        <ThemeChangeBtn
          className="btn-text-color"
          Icon={FiSun}
          text="light"
          onClick={() => {
            // closeTab();
            onTabClick("light");
            setTheme("light");
            setIsAuto("false");
          }}
          checked={tab === "light" && isAuto === "false"}
        />
        <div className="one-sided-border"></div>
        <ThemeChangeBtn
          className="btn-text-color"
          Icon={FiMoon}
          text="dark"
          onClick={() => {
            // closeTab();
            onTabClick("dark");
            setTheme("dark");
            setIsAuto("false");
          }}
          checked={tab === "dark" && isAuto === "false"}
        />

        <div className="one-sided-border"></div>

        <ThemeChangeBtn
          className="btn-text-color"
          Icon={PiCircleHalfFill}
          text="auto"
          onClick={onAutoClick}
          checked={isAuto === "true"}
        />
        <div />
      </div>
    </>
  );
};

export default ThemeActionBar;
