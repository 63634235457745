import dynamic from "next/dynamic";
import { useContext, useState } from "react";
import { useGoogleLogin } from "react-google-login";
import { FaGoogle } from "react-icons/fa";
import { AuthContext } from "../../../context/AuthContext";
import MolhamButton from "../MolhamButton";
import LoginLoading from "./LoginLoading";
const GoogleLogin = dynamic(() => import("react-google-login"), {
  ssr: false,
  loading: LoginLoading,
});
const LoginWithGoogle = () => {
  const responseGoogle = async (response) => {
    if (response?.error) {
      return;
    }
    setIsLoading(true);
    await login({
      google_id_token: response?.tokenObj?.id_token,
    });
    setIsLoading(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const loginG = useGoogleLogin({
    onSuccess: responseGoogle,
    clientId:
      "1010928718503-7s64vifqb6s708od2dlagb31s932g1cf.apps.googleusercontent.com",

    cookiePolicy: "single_host_origin",

    redirectUri: "https://molhamteam.com",
  });

  return (
    <MolhamButton
      id="login-google-btn"
      className="btn bs-google bg-google rounded-p"
      onClick={() => loginG.signIn()}
      disabled={isLoading || !loginG.loaded}
      style={{ padding: 14, height: "fit-content" }}
      spinnerSize={"32px"}
      variant={null}
      isLoading={isLoading}
      CustomIconButton={<FaGoogle size={32} />}
    />
    // <GoogleLogin
    //   render={(renderProps) => (

    //   )}
    //   buttonText="Login"
    //   onSuccess={responseGoogle}
    //   onFailure={responseGoogle}
    //   cookiePolicy={"single_host_origin"}
    // />
  );
};

export default LoginWithGoogle;
