const MobileFooterPush = () => {
  return (
    <div
      className=" cs-footer bg-trans pb-5 pb-lg-0"
      style={{ paddingTop: 14 }}
    />
  );
};

export default MobileFooterPush;
