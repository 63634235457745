import { getFingerprint } from "@thumbmarkjs/thumbmarkjs";
import { createContext, useContext, useEffect, useState } from "react";
type ThumbmarkContextType = {
  thumbmark: string | null;
};
const ThumbmarkContext = createContext<ThumbmarkContextType>(null);
const ThumbmarkProvider = ({ children }) => {
  const [thumbmark, setThumbmark] = useState<null | string>(null);
  useEffect(() => {
    getFingerprint(false).then((fingerprint) => {
      setThumbmark(fingerprint as string);
    });
  });
  return (
    <ThumbmarkContext.Provider value={{ thumbmark }}>
      {children}
    </ThumbmarkContext.Provider>
  );
};

export { ThumbmarkContext, ThumbmarkProvider };
export function useThumbmark(): ThumbmarkContextType {
  const thumbData = useContext(ThumbmarkContext);
  if (thumbData == null) {
    throw new Error(
      "Thumbdata is not available. Make sure you are rendering the component within the ChatSupportProvider."
    );
  }
  return thumbData;
}
