import useMobile from "customHooks/useMobile";
import BodyLock from "utils/bodyLock";
import MolhamModal from "./MolhamModal";
import MolhamOffcanvas from "./MolhamOffcanvas";

const MolhamModalOffcanvas: React.FC<Props> = (props: Props) => {
  const matches = useMobile("lg");
  const sharedProps = {
    onEnter: BodyLock.lock,
    onExit: BodyLock.unlock,
    show: props.show,
    onHide: props.onHide,
    header: props.header,
    body: props.body,
    footer: props.footer,
    showButton: props.showButton,
  };
  const OffCanvasProps = {
    ...sharedProps,
    fullscreen: props.fullscreen,
    swipeToClose: props.swipeToClose,
    className: props.offCanvasClassname,
  };
  const ModalProps = {
    ...sharedProps,
    className: props.modalClassName,
    style: props.modalStyle,
    size: props.size,
  };
  if (matches) {
    return (
      <MolhamOffcanvas {...OffCanvasProps}>{props.children}</MolhamOffcanvas>
    );  
  } else {
    return <MolhamModal {...ModalProps}>{props.children}</MolhamModal>;
  }
};

export default MolhamModalOffcanvas;
type Props = {
  show: boolean;
  onHide: () => void;
  header?: {
    label?: string;
    labelClassName?: string;
    centered?: boolean;
    showCustomBtnCond?: boolean;
    borderRadius?: boolean;
    customButton?: React.ReactNode;
    noCloseBtn?: boolean;
  };
  body?: {
    className?: string;
    style?: React.CSSProperties;
  };
  footer?: {
    action?: React.ReactNode;
    padding?: boolean;
    className?: string;
  };
  swipeToClose?: boolean;
  modalStyle?: React.CSSProperties;
  showButton?: boolean;
  modalClassName?: string;
  offCanvasClassname?: string;
  fullscreen?: boolean;
  size?: "sm" | "md" | "lg" | "xl" | undefined;
  children: React.ReactNode;
};
