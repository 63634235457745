import { TAddress } from "apiTypes/addresses";
import { MolhamError } from "components/ReuseableCompos/SharedDonatePages/DonateModal/axiosInstance";
import { NEXT_URL_PROXY } from "config";
import { deleteData } from "utils/deleteData";
import { postData } from "utils/postData";

export const deleteAddress = async (data: { id: number }) => {
  try {
    const res = await deleteData(
      `${NEXT_URL_PROXY}/auth/shipping-addresses/${data.id}`
    );

    return res as any;
  } catch (err) {
    return err as MolhamError;
  }
};
export const createAddress = async (data: Omit<TAddress, "id">) => {
  try {
    const res = await postData<{ data: TAddress }>(
      `${NEXT_URL_PROXY}/shipping-addresses`,
      data
    );

    return res as any;
  } catch (err) {
    return err as MolhamError;
  }
};

export const updateAddress = async (data: TAddress) => {
  try {
    const res = await postData(
      `${NEXT_URL_PROXY}/auth/shipping-addresses/${data.id}`,
      data
    );

    return res as any;
  } catch (err) {
    return err as MolhamError;
  }
};
