import axios from "axios";

/**
 * It deletes data from the API.
 * @param url - The URL of the API endpoint you want to call.
 * @param token - The token to be used for authentication.
 * @param api_url - The URL of the API endpoint.
 * @returns The response from the API.
 */
export const deleteData = async (url:string, token?:string) => {
  const res = await axios.delete(`${url}`, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
  return res;
};
