import { useRouter } from "next/router";
import { useState } from "react";
import useSWR from "swr";
import type { BareFetcher, PublicConfiguration } from "swr/_internal";
import { NEXT_URL_PROXY } from "../config";
import { usePreferenceContext } from "../context/PreferencesContext";
import { fetcher } from "../utils/getData";
import useUpdateEffect from "./useUpdateEffect";

const useCustomSWR = <Data = any>(
  endPoint: string,
  options?: Partial<PublicConfiguration<Data, any, BareFetcher<Data>>>
) => {
  const [changeLocaleLoading, setChangeLocaleLoading] = useState(false);
  const { locale } = useRouter();
  const { curr } = usePreferenceContext();
  const { data, error, mutate } = useSWR<Data>(
    endPoint ? `${NEXT_URL_PROXY}/${endPoint}` : null,
    (key) => fetcher(key, null, null, { currency: curr?.code, locale }),
    {
      revalidateOnFocus: false,
      // revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      ...options,
    }
  );
  useUpdateEffect(() => {
    setChangeLocaleLoading(true);
    const mutatePaginatedData = async () => {
      await mutate();
      setChangeLocaleLoading(false);
    };
    mutatePaginatedData();
  }, [locale]);
  return {
    data,
    mutate,
    error,
    changeLocaleLoading,
  };
};

export default useCustomSWR;
