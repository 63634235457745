import { FaRegMoon } from "react-icons/fa";
import { FiSun } from "react-icons/fi";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useModeContext } from "../context/ModeContext";

import useTranslation from "next-translate/useTranslation";
import useRTL from "../customHooks/useRTL";

type Props = {
  isOffcanvas?: boolean;
  className?: string;
};

const ModeSwitcher = ({ isOffcanvas = true, className }: Props) => {
  const { switchModeBtn, setSwitchModeBtn, setTheme, theme } = useModeContext();

  const { t } = useTranslation("common");
  const modeHandler = () => {
    setSwitchModeBtn((preSwitchModeBtn) => !preSwitchModeBtn);
    switchModeBtn ? setTheme("light") : setTheme("dark");
    toast(
      `${t(`mode_changed_toast`)} ${switchModeBtn ? t(`light`) : t(`dark`)}`
    );
  };
  const isRTL = useRTL();
  if (isOffcanvas) {
    return (
      <button
        type="button"
        onClick={modeHandler}
        className={`btn p-1 ${className || ""}`}
      >
        {switchModeBtn ? <FiSun size={20} /> : <FaRegMoon size={20} />}
        &nbsp; {t(theme)}
      </button>
    );
  }
  return (
    <ModeBtnWrapper onClick={modeHandler} dark={!switchModeBtn} isRTL={isRTL}>
      {switchModeBtn ? <FiSun size={20} /> : <FaRegMoon size={20} />}
    </ModeBtnWrapper>
  );
};

export default ModeSwitcher;
const ModeBtnWrapper = styled.div<{
  dark: boolean;
  isRTL: boolean;
}>`
  width: 30px;
  height: 30px;
  border-radius: ${(props) =>
    !props.isRTL ? "0 0.5rem 0.5rem 0" : "0.5rem 0 0 0.5rem"};
  background: ${(props) =>
    props.dark ? "var(--white-trans)" : "var(--white-trans)"};
  color: ${(props) => (props.dark ? "var(--white)" : "var(--yellow)")};
  transition: var(--mainTrans);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: ${(props) => (props.dark ? "var(--yellow)" : "var(--yellow)")};
  }
`;
