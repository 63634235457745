import { firebaseConfig } from "config/firebase";
import { Analytics } from "firebase/analytics";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { getAnalytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
// Initialize Firebase
export const FirebaseContext = createContext<{
  analytics: Analytics | null;
  app: FirebaseApp | null;
} | null>(null);

export const FirebaseTrackingProvider = (props: { children: ReactNode }) => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);
  const [app, setApp] = useState<FirebaseApp | null>(null);
  useEffect(() => {
    setApp(initializeApp(firebaseConfig));
    if (app) setAnalytics(getAnalytics(app));
  }, [app]);

  return (
    <FirebaseContext.Provider value={{ analytics, app }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};
export const useFirebaseAnalytics = () => {
  return useContext(FirebaseContext);
};
export default FirebaseTrackingProvider;
