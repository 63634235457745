import { TNotificationsItem } from "apiTypes";
import Link from "next/link";
import FormattedDate from "./FormattedDate";
import DividedMenuItem from "./SharedLayoutComponents/DividedMenuItem";

type Props = {
  item?: TNotificationsItem;
  handleCloseNotificationOffCanvas: () => void;
};

const NotificationCard = ({
  item,
  handleCloseNotificationOffCanvas,
}: Props) => {
  const { title, body, created_at, path } = item;
  return (
    <Link
      href={path.web}
      onClick={() => {
        handleCloseNotificationOffCanvas();
      }}
      className="media align-items-center py-3 border-bottom cursor hover-color-unset"
    >
      <div className="media-body">
        <div className="d-flex justify-content-between align-items-center">
          <DividedMenuItem
            onClick={handleCloseNotificationOffCanvas}
            isNavLink={false}
            as="div"
          >
            <div className="d-flex justify-content-between">
              <i className="fe-zap mx-2 mt-1 fs-lg"></i>
              <div className="mr-3">
                <h4 className="h4 font-size-md text-capitalize mb-0">
                  {title}
                </h4>
                <div className="d-flex align-items-start flex-column font-size-lg">
                  <p className="my-2 font-size-sm">{body}</p>
                  <FormattedDate date={created_at} className="fw-bold" />
                </div>
              </div>
            </div>
          </DividedMenuItem>
        </div>
      </div>
    </Link>
  );
};

export default NotificationCard;
