import { Offcanvas } from "react-bootstrap";

type Props = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const CustomOffcanvasBody = ({ children, ...props }: Props) => {
  return <Offcanvas.Body {...props}>{children}</Offcanvas.Body>;
};

export default CustomOffcanvasBody;
