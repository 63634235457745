import axiosInstance, {
  MolhamError,
  MolhamResponse,
  MolhamResult,
} from "../axiosInstance";
const setupIntent = async (
  paymentMethodId: string,
  payment_provider_id: string
): Promise<MolhamResult<{ client_secret: string }>> => {
  try {
    const res = await axiosInstance.post(
      `/payment-providers/stripe/setup-intents`,
      {
        stripe_payment_method_id: paymentMethodId,
        payment_provider_id,
      }
    );
    return res as MolhamResponse<{ client_secret: string }>;
  } catch (err: any) {
    return err as MolhamError;
  }
};

type PaymentIntentResult = {
  id: string;
  client_secret: string;
};
const paymentIntent = async (data: {
  checkout_order_reference: string;
  save_for_later?: boolean;
  payment_method?: string;
  saved_payment_method_id?: string | number;
  payment_method_types?: (
    | "card"
    | "sofort"
    | "ideal"
    | "giropay"
    | "sepa_debit"
  )[];
  return_url?: string;
  confirmation_method?: "automatic" | "manual";
  confirm?: boolean;
  payment_provider_id: string;
}): Promise<MolhamResult<NonNullable<PaymentIntentResult>>> => {
  try {
    const res = await axiosInstance.post(
      `/payment-providers/stripe/payment-intents`,
      data
    );
    return res as MolhamResponse<NonNullable<PaymentIntentResult>>;
  } catch (err: any) {
    return err as MolhamError;
  }
};
const createSource = async (data: {
  country_code: string;
  type: "sofort" | "ideal" | "giropay" | "sepa_debit";
  checkout_order_reference: string;
}): Promise<
  MolhamResult<{
    redirect: {
      failure_reason: null;
      return_url: string;
      status: string;
      url: string;
    };
  }>
> => {
  try {
    const res = await axiosInstance.post(
      `/payment-providers/stripe/sources`,
      data
    );
    return res as MolhamResponse<{
      redirect: {
        failure_reason: null;
        return_url: string;
        status: string;
        url: string;
      };
    }>;
  } catch (err: any) {
    return err as MolhamError;
  }
};
const stripeActions = {
  setupIntent,
  paymentIntent,
  createSource,
};
export default stripeActions;
