import mixpanel from "mixpanel-browser";
import { Router } from "next/router";
import { useEffect } from "react";

let initialized = false;
export const getMixpanel = () => {
  if (typeof window !== "undefined") {
    if (!initialized) {
      mixpanel.init("6427e82c27cd5e09017e0426879b0cd6");
      initialized = true;
    }
    return mixpanel;
  } else {
    return null;
  }
};

export function Mixpanel() {
  useEffect(() => {
    getMixpanel().track_pageview();
    const handleRouteChange = () => {
      getMixpanel().track_pageview();
    };
    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);
  return null;
}
