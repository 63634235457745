import { usePreferenceContext } from "../context/PreferencesContext";

const useFormatNumbers = () => {
  const { curr } = usePreferenceContext();

  const formatNumbers = (
    num: number | string,
    currency?: string,
    digits?: boolean
  ) => {
    const number = Number(num);
    const processedString = number.toLocaleString("en", {
      currency: currency || curr?.code || "usd",
      currencyDisplay: "symbol",
      maximumFractionDigits: digits ? 2 : 0,
      minimumFractionDigits: 0,
      style: "currency",
    });

    return `‎${processedString.split(/(\d+)/)[0]} ${processedString
      .split(/(\d+)/)
      .slice(1)
      .join("")}`;
    // : processedString;
  };
  return { formatNumbers };
};

export default useFormatNumbers;
