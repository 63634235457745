import NavBarSearchResult from "./NavBarSearchResult";

type Props = {
  showResultBar: boolean;
  data: any;
};

const NavBarSearchResults = ({ showResultBar, data }: Props) => {
  if (!showResultBar) return null;
  return (
    <div
      className={`${
        data?.length == 0 ? "d-none" : "d-flex"
      } container-xl flex-column align-items-center  flex-nowrap bg-white px-0 zindex-7 shadow-lg`}
      style={{
        justifyContent: "start",

        marginTop: "1px",
      }}
    >
      <>
        {data?.map((result, idx) => {
          const isLast = idx === data.length - 1;
          return (
            <NavBarSearchResult key={result.id} data={result} isLast={isLast} />
          );
        })}
      </>
      {/* )} */}
    </div>
  );
};

export default NavBarSearchResults;
