import { useModeContext } from "context/ModeContext";
import { useColors } from "layout/Layout";
import React, { useCallback } from "react";

type Props = { onChange: (value: number) => void; value: number };

const QuantityCounter: React.FC<Props> = ({ onChange, value }) => {
  const colors = useColors();
  const mode = useModeContext();

  const increase = useCallback(() => {
    onChange(value + 1);
  }, [value]);
  const decrease = useCallback(() => {
    onChange(value - 1);
  }, [value]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: 5,
        borderRadius: 13,
        backgroundColor: "var(--dark-gray)",
        marginBottom: 10,
        minHeight: 50,
        height: 50,
      }}
      className="form-control"
    >
      <div
        onClick={decrease}
        style={{
          backgroundColor: colors.primary,
          // borderWidth: 1,
          borderRadius: 10,
          height: "100%",
          aspectRatio: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <span
          className="fe-minus "
          style={{
            fontSize: 30,
            color: "white",
          }}
        />
      </div>
      <div
        style={{
          minWidth: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontSize: 20,
            lineHeight: "20px",
            userSelect: "none",
          }}
        >
          {value}
        </div>
      </div>

      <div
        onClick={increase}
        style={{
          backgroundColor: colors.primary,
          // borderWidth: 1,
          borderRadius: 10,
          height: "100%",
          aspectRatio: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <span
          className="fe-plus"
          style={{
            fontSize: 30,

            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export default QuantityCounter;
