import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isRTL } from "../utils/isRtl";
import useUpdateEffect from "./useUpdateEffect";

const useInputValueLangaugeDetector = (watch) => {
  const [dir, setDir] = useState<"rtl" | "ltr">();
  const { locale } = useRouter();
  const [focussed, setFocussed] = useState(false);

  useEffect(() => {
    if (watch === "" && locale === "ar") {
      setDir("rtl");
      return;
    }

    if (focussed) {
      isRTL(watch) ? setDir("rtl") : setDir("ltr");
    }
  }, [watch]);

  useUpdateEffect(() => {
    locale === "ar" ? setDir("rtl") : setDir("ltr");
  }, [locale]);


  const onInputFocus = (e) => {
    setFocussed(true);
    if (e.target.value === "" && locale === "ar") {
      setDir("rtl");
      return;
    }
    isRTL(e.target.value) ? setDir("rtl") : setDir("ltr");
  };

  const onInputBlur = (e) => {
    setFocussed(false);
    if (e.target.value === "" && locale === "ar") {
      setDir("rtl");
      return;
    }
    locale === "ar" ? setDir("rtl") : setDir("ltr");
  };

  return {
    dir,
    onInputBlur,
    onInputFocus,
  };
};

export default useInputValueLangaugeDetector;
