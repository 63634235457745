import useTranslation from "next-translate/useTranslation";
import styled from "styled-components";

type Props = {
  m?: string;
};
const Or = ({ m }: Props) => {
  const { t } = useTranslation("common");

  return (
    <OrWrapper
      className={`d-flex justify-content-center text-center ${m ? m : "mb-2"}`}
    >
      <div className="d-inline-block align-self-center "></div>
      <span className="align-self-center  mx-2 ">{t(`or`)}</span>
      <div className="d-inline-block align-self-center "></div>
    </OrWrapper>
  );
};

export default Or;
const OrWrapper = styled.div`
  div {
    height: 1px;
    width: 16% !important;
    background-color: #f3f3f9;
  }
`;
