import {
  PayPalScriptProvider,
  ReactPayPalScriptOptions,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useCheckoutStore } from "components/ReuseableCompos/SharedDonatePages/DonateModal/CheckoutState";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
const constructOptions = (currency: string | undefined, client_id: string) => {
  return {
    // locale: pref?.lang?.shortcut,
    currency: currency?.toUpperCase(),
    disableFunding:
      "card,credit,bancontact,eps,giropay,ideal,mybank,sofort,sepa",
    clientId: client_id,
    components: "buttons",
    vault: true,
    //   debug:process.env.NODE_ENV === "development"
  } as ReactPayPalScriptOptions;
};
const PaypalContext = React.createContext<{
  enable: boolean;
  setEnable: (value: boolean) => void;
}>(null);
///https://www.paypal.com/sdk/js?client-id=AQNLvAe1eTTK6ZyrwQzCAHoBVoaCUmk8kGE0calYPgBCNCnP6JhkKdILclLGAMH7z9rnIPpObmmKhP05&currency=USD&disable-funding=card,credit,bancontact,eps,giropay,ideal,mybank,sofort,sepa
const PayPalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // const { data: utilities } = useUtilities();
  const [enablePaypal, setEnablePaypal] = useState(false);
  return (
    <PaypalContext.Provider
      value={{
        enable: enablePaypal,
        setEnable: setEnablePaypal,
      }}
    >
      {/* @ts-ignore */}
      <PayPalScriptProvider
        // options={constructOptions(
        //   utilities?.payment_providers.paypal.currency,
        //   utilities?.payment_providers.paypal.client_id
        // )}
        // options={{}}
        deferLoading={true}
      >
        {enablePaypal ? <PayPalOptionsChangeHandler /> : null}
        {children}
      </PayPalScriptProvider>
    </PaypalContext.Provider>
  );
};

export default PayPalProvider;
export const usePaypalEnableContext = () => {
  const data = useContext(PaypalContext);
  return data;
};
const PayPalOptionsChangeHandler = () => {
  // const { donor } = useContext(AuthContext);
  const donor = useCheckoutStore().checkoutOrder?.payer;
  const paymentProvider = donor?.payment_providers?.paypal;
  const [, dispatch] = usePayPalScriptReducer();
  useEffect(() => {
    if (paymentProvider?.currency && paymentProvider?.client_id) {
      dispatch({
        type: "resetOptions",
        value: constructOptions(
          paymentProvider.currency,
          // "EUR",
          paymentProvider.client_id
        ),
      });
    }
  }, [paymentProvider?.client_id, paymentProvider?.currency]);
  return null;
};
