import { useCallback, useState } from "react";
const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = useCallback(() => setShowModal(false), []);
  const handleShowModal = useCallback(() => setShowModal(true), []);
  return {
    showModal,
    handleCloseModal,
    handleShowModal,
  };
};
export default useModal;
