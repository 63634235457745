import { Rating, Star } from "@smastrom/react-rating";
import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import { NEXT_URL_PROXY } from "config";
import { ReviewModalContext } from "context/ReviewModalContext";
import useMobile from "customHooks/useMobile";
import { useColors } from "layout/Layout";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { postData } from "utils/postData";
import ReviewAnim from "../../public/lottie/review.json";
import SmileEmoji from "../../public/lottie/smile-emoji.json";
import MolhamButton from "./MolhamButton";
const Lottie = dynamic(() => import("lottie-react"), {});

const ReviewModal = () => {
  const { handleCloseReviewModal, showReviewModal } =
    useContext(ReviewModalContext);
  const onModalHide = () => {
    handleCloseReviewModal();
  };

  const { t } = useTranslation("common");
  const matches = useMobile("lg");
  const [rating, setRating] = useState(0);
  const [details, setDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const onSubmitReview = async () => {
    setIsLoading(true);
    await postData(`${NEXT_URL_PROXY}/reviews`, {
      details,
      rating,
    })
      .then((res) => {
        setIsLoading(false);
        setRating(0);
        setDetails("");
        setDone(true);
        setTimeout(() => {
          setDone(false);
          handleCloseReviewModal();
        }, 5000);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.error.message);
      });
  };
  const colors = useColors();
  return (
    <MolhamModalOffcanvas
      show={showReviewModal === "review"}
      onHide={onModalHide}
      header={{ label: "review_modal_header", borderRadius: false }}
      fullscreen
      body={{ className: "p-0" }}
      swipeToClose={false}
    >
      <div
        className="mx-4 pb-4 pb-md-0 d-flex flex-column justify-content-center align-items-center h-100
      "
      >
        {!done ? (
          <>
            <Lottie
              className=" w-50 mb-3"
              animationData={ReviewAnim}
              loop={true}
              style={{ maxWidth: matches ? 300 : 400 }}
            />
            <p className="text-center mb-3 px-2 fs-lg">{t(`review_prompt`)}</p>
            <div className="w-100 d-flex justify-content-center align-items-center mb-3">
              <Rating
                itemStyles={{
                  itemShapes: Star,
                  activeFillColor: colors.primary2,
                  inactiveFillColor: colors.lightTransPrimary,
                  activeBoxBorderColor: "transparent",
                  inactiveBoxBorderColor: "transparent",
                }}
                style={{ maxWidth: 180 }}
                value={rating}
                onChange={setRating}
              />
            </div>
            {rating > 0 && (
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <textarea
                  className="form-control mb-3"
                  placeholder={t(`review_placeholder`)}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
                <MolhamButton
                  id="review-submit-btn"
                  onClick={onSubmitReview}
                  label={t(`submit_review`)}
                  className={"mb-3 w-100"}
                  isLoading={isLoading}
                />
              </div>
            )}
            <p className="fs-md mb-3 text-center">
              <Link
                onClick={() => {
                  handleCloseReviewModal();
                }}
                href="#login"
                className="fw-medium cursor text-decoration-none"
              >
                {t(`not_now_label`)}
              </Link>
            </p>
          </>
        ) : (
          <>
            <Lottie
              className=" w-50 mb-3"
              animationData={SmileEmoji}
              loop={true}
              style={{ maxWidth: matches ? 300 : 400 }}
            />
            <p>{t(`review_success`)}</p>
            <MolhamButton
              id="review-close-btn"
              onClick={() => {
                setDone(false);
                handleCloseReviewModal();
              }}
              label={t(`close_this_window`)}
              className={"mb-3 w-100"}
            />
          </>
        )}
      </div>
    </MolhamModalOffcanvas>
  );
};

export default ReviewModal;
