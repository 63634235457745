import { useEffect, useState } from "react";

const useStickyScroll = (height: number) => {
  const [scroll, setScroll] = useState(false);

  /**
   * It checks if the user has scrolled past 200px and if so, it sets the scroll state to true.
   * @param e - The event object.
   */
  const handleScroll = (e) => {
    // setScroll(window.scrollY > 200);
    height < e.currentTarget.pageYOffset ? setScroll(true) : setScroll(false);
    e.stopPropagation();
  };
  // adding functionality to make navbar stick to to when scroll happens
  /* When the window is scrolled, call the function handleScroll. */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scroll;
};

export default useStickyScroll;
