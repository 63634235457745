import useMobile from "customHooks/useMobile";
import { ReactSVG } from "react-svg";
import CustomContentLoader from "./CustomContentLoader";

type Props = {
  uri: string;
  color: string;
  p?: string;
  hwm?: string; // height and width for mobile
  hwd?: string; // height and width for desktop
  noReplace?: boolean;
  noStroke?: boolean;
};

function SvgCreator({
  uri,
  color,
  p = "p-3",
  hwm = "64",
  hwd = "72",
  noReplace,
  noStroke,
}: Props) {
  const iconUri = noReplace ? uri : uri.replace("https://molhamteam.com", "");
  const isMobile = useMobile("md");
  const hw = isMobile ? hwm : hwd;
  return (
    <ReactSVG
      loading={() => (
        <CustomContentLoader viewBox={`0 0 ${hw} ${hw}`}>
          <rect x="0" y="0" rx="8" ry="8" width={hw} height={hw}></rect>
        </CustomContentLoader>
      )}
      src={iconUri}
      beforeInjection={(svg) => {
        svg.setAttribute("width", hw);
        svg.setAttribute("height", hw);
        svg.classList.add(p);
        if (!noStroke) svg.setAttribute("stroke", color);

        svg.setAttribute("fill", color);

        const defs = svg.querySelector("defs");
        if (defs) {
          defs.remove();
        }
        const style = svg.querySelector("style");
        if (style) {
          style.remove();
          const g = svg.querySelector("g");
          if (g) {
            g.querySelectorAll("path").forEach((path) => {
              path.classList.remove("st0");
            });
          } else {
            svg.setAttribute("stroke-width", "70");
          }

          svg.setAttribute("stroke-linecap", "round");
          svg.setAttribute("stroke-linejoin", "round");
          const poly = svg.querySelector("polygon");
          if (uri.includes("general")) {
            poly.classList.remove("st0");
            svg.setAttribute("fill", "none");
          }
        }
      }}
    />
  );
}

export default SvgCreator;
