import { PropsWithChildren } from "react";
import CheckboxIcon from "./CheckboxIcon";
type Props = {
  checked: boolean;
  childrenContainerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const Checkbox: React.FC<PropsWithChildren<Props>> = ({
  checked,
  childrenContainerStyle,
  style,
  onClick,
  children,
}) => {
  const handlePress = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingInlineEnd: 15,
        ...style
      }}
      className="cursor"
      onClick={handlePress}
    >
      <CheckboxIcon checked={checked} style={{ marginInlineEnd: 10 }} />
      <div style={{ paddingInlineEnd: 20, ...childrenContainerStyle }} className="smol-input">
        {children}
      </div>
    </div>
  );
};

export default Checkbox;
